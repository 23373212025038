/* eslint class-methods-use-this: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import businessesModule from "../../modules/businesses";

class HomeView extends Component {
  static propTypes = {
    business: PropTypes.object
  };

  render() {
    const { business } = this.props;
    if (!business) return <Redirect to="/businesses" />;
    return <Redirect to={`/business/${business.id}`} />;
  }
}

const mapState = state => ({
  business: businessesModule.selectors.business.getData(state)
});

export default connect(mapState)(HomeView);
