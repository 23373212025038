import {
  Flex,
  Heading,
  Spacer,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

const sizes = [
  undefined,
  '2xl', // default size of <Heading>
  'xl',
  'lg',
  'md',
  'sm',
  'xs',
];

const SectionHeader = ({ children, controls, level = 4 }: {
  children: ReactNode,
  controls?: ReactNode,
  level?: number
}) => {
  const el = level >= 1 && level <= 6
    ? `h${level}`
    : 'h3';

  const size = sizes[level];

  return (
    <Flex>
      <Heading as={el} size={size}>{children}</Heading>
      {
        controls
          ? (
            <>
              <Spacer />
              {controls}
            </>
          )
          : null
      }
    </Flex>
  );
};

export default SectionHeader;
