import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { strings } from '../../i18n';

const BusinessesNotFound = () => (
  <Flex justify='center' p={4}>
    <Text as='i' fontWeight={600}>
      {strings('dashboard.component.businessSelectView.noBusinesses')}
    </Text>
  </Flex>
);

export default BusinessesNotFound;
