/* eslint-disable react/no-unescaped-entities */
import { ChevronLeftIcon } from '@chakra-ui/icons';
import React, {
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Container,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import {
  transport,
  useQuerystringParam,
} from 'shared/src/hooks/useQuerystringParam';
import Carousel from '../Carousel';
import { slides } from './slides';

const SocialSignupPostrollModal = () => {
  const [signup, setSignup] = useQuerystringParam('signup', false, { transport: transport.boolean, squash: true });
  const onClose = useMemo(
    () => () => setSignup(false),
    [setSignup],
  );

  const [isOpen, setIsOpen] = useState(false);

  if (!signup) return null;

  return (
    <Modal isOpen={true} size="full" onClose={() => setSignup(false)} autoFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Container maxW="container.lg">
            <Button
              colorScheme="blue"
              variant="link"
              onClick={() => setSignup(false)}
              leftIcon={<ChevronLeftIcon />}
            >
              Cancel
            </Button>
            <Heading>What To Expect</Heading>
          </Container>
        </ModalHeader>
        <ModalBody>
          <Container maxW="container.lg">
            <Carousel slides={slides} context={{ onClose }} />
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SocialSignupPostrollModal;
