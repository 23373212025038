import { RepeatIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Skeleton,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useState, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import Content from "shared/src/details/ContentSection";
import Preview from "shared/src/details/PreviewSection";
import ActionMenu from "web-react-ui/src/components/actionMenu/ActionMenu";
import Post from "shared/src/interfaces/Post.interface";
import PageHeader from "web-react-ui/src/chakra/page/PageHeader";
import SectionHeader from "../components/SectionHeader";

type ActionType = {
  label: string;
  action: () => void;
  icon?: string;
  hidden: boolean;
  negative?: boolean;
  positive?: boolean;
  extraProps?: Record<string, unknown>;
};

type PostEditProps = {
  onSave: (a: string) => void;
  isInvalid: boolean;
  post: Post & { pageTitle: string };
  actions: ActionType[];
};

const PostEdit = ({ post, onSave, isInvalid, actions }: PostEditProps) => {
  const [msgValue, setMsgValue] = useState(post.output?.message || "");
  let initialPostMessage = post.output?.message || "";

  let handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let inputValue = e.target.value;
    setMsgValue(inputValue);
  };

  const saveAction = {
    label: "Save",
    hidden: false,
    positive: true,
    action: async () => onSave(msgValue),
  };
  const newActions = [saveAction, ...(actions || [])];
  return (
    <Box
      as="div"
      w="auto"
      mx={{ base: "0.5rem", md: "4rem" }}
      mb="4rem"
      className="_mainContainer"
      color="gray.800"
    >
      <Box w="100%" display="flex">
        <PageHeader controls={<ActionMenu actions={newActions} />}>
          {post.pageTitle}
        </PageHeader>
      </Box>
      <Flex
        wrap={{ base: "wrap", md: "nowrap" }}
        direction={{ base: "column", lg: "row" }}
        gap="3em"
        minW="1/2"
        className="_postDetailContainer"
        justifyContent="center"
      >
        <Flex
          direction="column"
          gap="1.5em"
          w="2/3"
          grow={2}
          alignItems="start"
        >
          <Box>
            <SectionHeader level={3}>Content</SectionHeader>
            <Stats post={post} />
          </Box>
          <Content post={post} />
          <Stack w="100%">
            <Flex w="100%" justifyContent="space-between">
              <Text fontWeight="medium" fontSize="lg">
                Post Description
              </Text>
              <RepeatIcon
                aria-label="Restore message"
                color="gray.300"
                w="6"
                h="6"
                className="repeat-icon"
                onClick={() => setMsgValue(initialPostMessage)}
              />
            </Flex>
            <Textarea
              name="output.message"
              isInvalid={isInvalid}
              h="10lh"
              value={msgValue}
              onChange={handleInputChange}
              placeholder="Enter a short post description here"
              variant="outline"
              size="lg"
              focusBorderColor="gray.100"
            />
            {isInvalid && (
              <Text fontWeight="normal" fontSize="md" textColor="red.500">
                Post descriptions cannot remain blank. Please provide at least
                10 characters.
              </Text>
            )}
          </Stack>
        </Flex>
        <Preview post={post} />
      </Flex>
    </Box>
  );
};

const Stats = ({ post }: { post: Post }) => {
  if (!post) {
    return <Skeleton w="100%" h="2em" />;
  }

  return <></>;
};

export default PostEdit;
