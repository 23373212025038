// vvv DO NOT REMOVE vvv -- prevents CRA's fast refresh from spectacularly failing to refresh this component
// @refresh reset
// ^^^ DO NOT REMOVE ^^^
import { Button } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { COUNTRIES } from 'shared/src/constants/countries';
import auth from 'shared/src/modules/auth';
import useQuerystring from 'shared/src/hooks/useQuerystring';
import useRequest from 'shared/src/hooks/useRequest';
import WizardModal from 'web-react-ui/src/components/wizard/WizardModal';
import Loader from 'web-react-ui/src/components/elements/Loader';
import Business from 'web-react-ui/src/types/Business.interface';
import Location from 'web-react-ui/src/types/Location.interface';
import plansViewModel from './plansViewModelThryv';
import useLanguage from '../../../hooks/useLanguage';
import createWizard from '../../businesses/CreateBusinessWizard';
import useProperty from '../../useProperty';
import AuthorizeSpinner from '../AuthorizeSpinner';
import extractPayload from '../extractPayload';
import client from '../../../services/client';
import SsoLayout from '../SsoLayout';
import SsoError from '../error';
import { errorCodes } from '../error/errors';

interface Payload {
  business: {
    name: string;
    address: string;
    country: string;
    description: string;
    website: string;
    phone: string;
    profileUrl: string;
    logoUrl: string;
    category: string;
  };
}

interface Query {
  provider: string,
  payload: string,
  signature: string
}

const resolveBase64Image = async (path: string, query: Query) => {
  try {
    const { provider, payload, signature } = query;
    const result = await client.images.resolve(provider, payload, signature, path);
    return `data:${result.headers['content-type']};base64,${result.data}`;
  } catch (err) {
    // failed to get image, oh well!
    console.error(err); // eslint-disable-line no-console
    return null;
  }
};

const makeInitialBusiness = async (extractedPayload: Payload, query: Query) => {
  if (!extractedPayload.business) return {};

  let thryvLogoImage;
  let thryvProfileImage;
  if (extractedPayload.business.logoUrl || extractedPayload.business.profileUrl) {
    [thryvLogoImage, thryvProfileImage] = await Promise.all([
      resolveBase64Image('business.logoUrl', query),
      resolveBase64Image('business.profileUrl', query),
    ]);
  }

  const {
    name = '',
    description,
    website,
    category,
  } = extractedPayload.business;

  return {
    name,
    shortName: name.substring(0, 24),
    description,
    url: website,
    categorySearchValue: category,
    rawProfileImage: thryvProfileImage,
    rawHeroImage: thryvLogoImage,
  };
};

const makeInitialAddress = async (payload: Payload) => {
  if (!payload?.business?.address) return {};
  let addresses;
  try {
    addresses = await client.addresses.discover({
      q: payload.business.address,
      country: payload.business.country,
    });
  } catch (err) {
    return {};
  }

  const bestMatch = addresses?.items?.[0];
  if (!bestMatch) return {};


  const country = COUNTRIES.find((c: { text: string }) => c.text === bestMatch.country);
  const state = country?.regions.find((region: { text: string }) => region.text === bestMatch.state);

  const initialAddress = {
    country: country?.value,
    state: state?.value,
    streetAddress: bestMatch.streetAddress,
    street: bestMatch.street,
    suite: bestMatch.suite,
    city: bestMatch.city,
    postalcode: bestMatch.postalcode,
    phone: payload.business.phone,
  };

  return initialAddress;
};

const SubmittingChildren = () => (
  <AuthorizeSpinner
    title="Setting Up Your Account"
    // eslint-disable-next-line max-len
    body="We're creating your new GetintheLoop account. This can take a couple minutes. Please do not close or refresh your browser."
  />
);

const BusinessWizardWrapper = ({
  onComplete,
}: {
  onComplete: (business: Business, plan: any, location: Location) => void,
}): JSX.Element => {
  const [query] = useQuerystring();

  const extractedPayload = extractPayload();

  const { provider, payload, signature } = query;
  const authorizeRequest = useRequest(
    () => client.auth.appAuthorize(provider, payload, signature),
    { provider, payload, signature },
  );

  const initialAddressRequest = useRequest(
    () => makeInitialAddress(extractedPayload),
    [],
  );

  const initialBusinessRequest = useRequest(
    () => makeInitialBusiness(extractedPayload, query),
    [],
  );

  if (!authorizeRequest.settled || !initialAddressRequest.settled || !initialBusinessRequest.settled) {
    return <Loader active />;
  }

  if (!authorizeRequest.error) {
    // if authorize returns correctly, the app already exists
    window.location.replace(extractedPayload.returnUrl);
    return <></>;
  }

  if (authorizeRequest.error.messageId === errorCodes.INSTALL_NOT_FOUND) {
    return <BusinessWizard
      onComplete={onComplete}
      initialBusiness={initialBusinessRequest.result}
      initialAddress={initialAddressRequest.result}
    />;
  }

  const errorMessage = authorizeRequest.error.message || initialAddressRequest.error.message;

  return (
    // @ts-ignore
    <SsoLayout returnUrl={extractPayload.returnUrl}>
      <SsoError title="Unable to Sign Up" error={errorMessage} />
    </SsoLayout>
  );
};

const BusinessWizard = ({
  onComplete,
  initialAddress,
  initialBusiness,
}: {
  onComplete: (business: Business, plan: any, location: Location) => void,
  initialAddress: Object,
  initialBusiness: Object
}): JSX.Element => {
  const property = useProperty();
  const [language] = useLanguage();
  const user = useSelector(state => auth.selectors.getUser(state));

  const manager = useMemo(
    () => {
      // @ts-ignore
      return createWizard({
        property,
        business: initialBusiness,
        address: initialAddress,
        locale: language.value,
        user,
        onComplete,
        isPlanViewable: true,
      });
    },
    [],
  );

  return (
    <>
      <WizardModal
        manager={manager}
        submittingChildren={SubmittingChildren}
        plansViewModel={plansViewModel} // only the partnership plan
        isSsoSignup={true}
        isBusinessSignUp={true}
      />
      <Button onClick={() => manager.start()}>Start</Button>
    </>
  );
};

export default BusinessWizardWrapper;
