import { useState } from 'react';
import i18next, { LANGUAGE_OPTIONS } from '../i18n';

export interface Language {
  key: string;
  text: string;
  value: string;
}

const useLanguage = (): [Language, ({ value }: { value: string }, e: { value: string }) => void] => {
  const [currentLanguage, setCurrentLanguage] = useState<Language>(() => {
    const browserLanguage = i18next.language.split('-')[0] || 'en';
    const [language] = LANGUAGE_OPTIONS.filter(lang => lang.value === browserLanguage) || null;
    return language || LANGUAGE_OPTIONS[0]; // set default language to En
  });

  const handleChange = ({ value }: { value: string }, e: { value: string }) => {
    // { value } => preferences.js
    // e => signUpView.js/loginView.js
    const selectedLanguage = e?.value || value;
    i18next.changeLanguage(selectedLanguage, () => {
      const [language] = LANGUAGE_OPTIONS.filter(lang => lang.value === selectedLanguage);
      setCurrentLanguage(language);
    });
  };

  return [currentLanguage, handleChange];
};

export default useLanguage;
