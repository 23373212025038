import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'leaflet';

import Image from '../components/elements/Image';
import { DEFAULT_CENTER, DEFAULT_ZOOM, fixIconBug, getMapKey } from './sharedMapSettings';

fixIconBug(Icon);

const MAP_TILE_KEY = getMapKey();

const centerMarkerStyles = {
  position: 'absolute',
  bottom: '50%',
  left: '50%',
  height: 'auto',
  width: '100%',
};

const generateMapUrl = ({
  style = 'neighbourhood',
  center = DEFAULT_CENTER,
  zoom = DEFAULT_ZOOM,
  width = 300,
  height = 250,
  scale = '',
  format = 'png'
}) => (
  `https://tile.thunderforest.com/static/${style}/${center[1]},${center[0]},${zoom}`
  + `/${width}x${height}${scale}.${format}?apikey=${MAP_TILE_KEY}`
);

class Map extends PureComponent {
  static propTypes = {
    center: PropTypes.arrayOf(PropTypes.number),
    zoom: PropTypes.number,
    marker: PropTypes.bool
  };

  componentDidMount() {
    if (!this.props.marker) return;

    const icon = new Icon.Default();
    const shadowEl = icon.createShadow();
    const iconEl = icon.createIcon();
    this.wrapperEl.appendChild(shadowEl);
    this.wrapperEl.appendChild(iconEl);
  }

  render() {
    const src = generateMapUrl(this.props);
    const {
      marker, // eslint-disable-line no-unused-vars
      ...rest
    } = this.props;
    return (
      <div
        style={{ position: 'relative', display: 'inline-block' }}
      >
        <Image src={src} {...rest} />
        {
          this.props.marker && <div
            style={centerMarkerStyles}
            ref={(ref) => { this.wrapperEl = ref; }}
          />
        }
      </div>
    );
  }
}

export default Map;
