import {
  AddIcon,
  WarningIcon,
} from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import {
  Item,
  List,
} from 'web-react-ui/src/chakra/List';
import { Empty } from 'web-react-ui/src/chakra/TableList/TableList';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import NetworkIcon from './NetworkIcon';
import SectionHeader from './SectionHeader';
import Business from '../interfaces/Business.interface';
import Profile from '../interfaces/Profile.interface';
import useClient from '../hooks/useClient';
import useRequest from '../hooks/useRequest';

type SocialAccountProps = {
  profile: Profile;
  business: Business;
  canEdit: boolean;
};

const SocialAccounts = ({
  profile,
  business,
  canEdit = false,
}: SocialAccountProps) => {
  const client = useClient();
  const items = [...profile?.socialAccounts] || [];
  const manageAccounts = useRequest(
    async () => {
      const { url } = await client.businesses
        .for(business.id)
        .social.manageAccounts({ redirect: window.location.href });
      window.location.href = `${url}&logout=true`;
    },
    null,
    {},
  );

  return (
    <Flex direction="column" gap="0em">
      <Flex w="100%" justifyContent="space-between" pb="1rem">
        <SectionHeader level={canEdit ? 4 : 3}>
          Connected Accounts
        </SectionHeader>
        {canEdit && (
          <Button
            colorScheme="blue"
            variant="ghost"
            width="6rem"
            leftIcon={<AddIcon />}
            onClick={manageAccounts.run as () => void}
            isLoading={manageAccounts.loading}
          >
            Add
          </Button>
        )}
      </Flex>
      <WaitFor waitFor={!manageAccounts.loading} wrapContents>
        <List>
          <Empty isEmpty={!items.length}>
            {canEdit && (
              <Text fontSize="xl">
                No connected social accounts.&nbsp;
                <Button
                  fontSize="inherit"
                  variant="link"
                  onClick={manageAccounts.run as () => void}
                  isLoading={manageAccounts.loading}
                  pr="6px"
                >
                  Add one
                </Button>
                to get started.
              </Text>
            )}
            {!canEdit && (
              <Text
                fontSize="lg"
                textColor="gray.600"
                textAlign="start"
                fontWeight="normal"
              >
                No social accounts connected
              </Text>
            )}
          </Empty>
          {items.map((account: { type: string; linked: boolean }) => (
            <Item
              key={account.type}
              _hover={canEdit ? { bg: 'gray.50' } : null}
              cursor={canEdit ? 'pointer' : null}
              onClickHandler={canEdit ? manageAccounts.run : null}
            >
              <Flex align="center" gap="1em" w="100%">
                <NetworkIcon id={account.type} fontSize="1.5em" />
                <Text textTransform="capitalize">{account.type}</Text>
                {!account.linked && (
                  <Tooltip
                    placement="bottom"
                    label={
                      canEdit
                        ? 'This account connection has been revoked on the social network. Tap the account to reconnect it.'
                        : 'This account connection has been revoked on the social network. Go to your Dashboard to turn it back on.'
                    }
                    hasArrow={true}
                    maxW="20rem"
                    p="0.5rem 1rem"
                    mt="0.5rem"
                    textAlign="center"
                    fontSize="md"
                    ml="auto"
                  >
                    <WarningIcon color="red.500" ml="auto" w={6} h={6} />
                  </Tooltip>
                )}
              </Flex>
            </Item>
          ))}
        </List>
      </WaitFor>
    </Flex>
  );
};

export default SocialAccounts;
