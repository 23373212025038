import React from 'react';
import { Icon, IconButton } from '@chakra-ui/react';
import { AiOutlineBell } from 'react-icons/ai';

const BeamerTrigger = () => {
  return Boolean(process.env.REACT_APP_BEAMER_PRODUCT_ID) && (
    <IconButton
      icon={<Icon as={AiOutlineBell} color='gray.600' height='20px' width='20px' />}
      bg='white'
      borderColor='gray.200'
      borderWidth='1px'
      className='beamerInit'
      isRound={true}
      height={{ base: '32px', md: '48px' }}
      width={{ base: '32px', md: '48px' }}
      position='absolute'
      right={{ base: '32px', md: 0 }}
    />
  );
};

export default BeamerTrigger;
