import {
  Box,
  Button,
  CloseButton,
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React, {
  useCallback,
  useEffect,
  useState
} from 'react';
import { ExternalIdTypes } from 'shared/src/constants/externalListings';
import ErrorAlert from '../../chakra/ErrorAlert';
import InlineSupportLink from '../../support/InlineSupportLink';
import GooglePlaceSelector from './GooglePlaceSelector';

const RENDERERS = {
  google: ({ value, onChange, context }) => {
    return (
      <Flex direction="column" gap="1em">
        <Text>Select the correct Google Place for this location:</Text>
        <GooglePlaceSelector
          address={context?.location?.address}
          name={context?.name}
          onSelect={place => onChange(place.id, true)}
          value={value}
        />
      </Flex>
    );
  },
  default: ({ value, onChange }) => {
    return (
      <FormControl>
        <Input
          value={value} onChange={e => onChange(e.target.value)}
        />
      </FormControl>
    );
  }
};

const AddExternalListingModal = ({ open, sid, values, onClose, onSubmit, context }) => {
  const [value, setValue] = useState(() => values[sid]);

  // Reset form field when we change which value we're editing
  useEffect(
    () => {
      setValue(values[sid]);
    },
    [values, sid]
  );

  const handleSubmit = () => {
    onSubmit(sid, value);
    onClose();
  };

  const handleChange = useCallback(
    (newValue, autoSubmit) => {
      setValue(newValue);
      if (autoSubmit) {
        // Can't call handleSubmit because it's closed over before value is updated
        onSubmit(sid, newValue);
        onClose();
      }
    },
    [sid]
  );

  if (!open) return null;

  const Renderer = RENDERERS[sid] || RENDERERS.default;

  const type = ExternalIdTypes.find(t => t.value === sid);

  // Can be empty
  const invalid = value && type?.pattern && !type.pattern.test(value);

  return (
    <Modal isOpen={open} onClose={onClose} scrollBehavior="inside" autoFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {type?.label ?? sid}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Renderer value={value} onChange={handleChange} context={context} />
            <ErrorAlert error={invalid} message={`Must be ${type.pattern}`} />
          </Box>
        </ModalBody>
        {
          /* HACK: Special footer layout for Google place id. */
          /* If we need more special layouts, abstract this further. */
          /* Eg: Just have a complete custom Modal for each sid instead of just the field renderer. */
          sid === 'google'
            ? (
              <ModalFooter justifyContent="space-between">
                <InlineSupportLink label="Can't Find The Right Place?" />
                <Button onClick={handleSubmit} colorScheme="blue">OK</Button>
              </ModalFooter>
            )
            : (
              <ModalFooter>
                <Button onClick={handleSubmit} colorScheme="blue">OK</Button>
              </ModalFooter>
            )
        }
      </ModalContent>
    </Modal>
  );
};

export default AddExternalListingModal;
