/* eslint-disable-next-line no-redeclare */
/* global window, document */
import { useState, useEffect } from 'react';

const initalizeChargebee = (callback) => {
  const script = document.createElement('script');
  script.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');
  script.setAttribute('data-cb-site', process.env.REACT_APP_CHARGEBEE_SITE);
  script.onload = () => {
    callback(process.env.REACT_APP_CHARGEBEE_SITE, process.env.REACT_APP_CHARGEBEE_KEY);
  };
  document.body.appendChild(script);
};

const useChargebee = () => {
  const [cbInstance, setCbInstance] = useState(null);

  useEffect(() => {
    initalizeChargebee((site, publishableKey) => {
      setCbInstance(window.Chargebee.init({
        site,
        publishableKey,
        isItemsModel: true // needed for Chargebee Catalog v2.0
      }));
      // This function is used to rebind all the events attached.
      // Use this function if a new element inserted dynamically after page load.
      // https://www.chargebee.com/checkout-portal-docs/dropIn.html
      window.Chargebee.registerAgain();
    });
  }, []);

  return cbInstance;
};

export default useChargebee;
