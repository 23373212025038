/* eslint-disable func-names */
import {
  PROPERTY_SET_CHANGE,
  PROPERTY_FLAG_CHANGE
} from './propertyActions';

const initialState = {
  property: null,
  isGitlProperty: false,
  error: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PROPERTY_SET_CHANGE:
      return {
        ...state,
        ...(action.payload && action.payload.property) && {
          property: action.payload.property
        },
        ...(action.payload && action.payload.error) && {
          error: action.payload.error.message
        },
      };
    case PROPERTY_FLAG_CHANGE:
      return {
        ...state,
        isGitlProperty: action.payload,
        error: null
      };
    default:
      return state;
  }
}
