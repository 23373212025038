import React, { useCallback, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import SocialPostEdit from "shared/src/components/PostEdit";
import useRequest from "shared/src/hooks/useRequest";
import businessModule from "../../../../modules/businesses";
import client from "../../../../services/client";
import useProperty from "../../../useProperty";
import getPostPageTitle from "../components/getPostPageTitle";
import Post from "../interfaces/Post.interface";

const fetchPost = async ({
  businessId,
  postId,
}: {
  businessId: string;
  postId: string;
}): Promise<Post> => {
  return client.businesses.for(businessId).social.posts.for(postId).details();
};

const deletePost = async ({
  businessId,
  postId,
}: {
  businessId: string;
  postId: string;
}): Promise<void> => {
  return client.businesses.for(businessId).social.posts.for(postId).delete();
};

const PostEdit = () => {
  const [isInvalid, setIsInvalid] = useState(false);
  const params = useParams<{ postId: string }>();
  const property = useProperty();
  const business = useSelector((state: RootStateOrAny) =>
    businessModule.selectors.business.getData(state)
  );
  const postRequest = useRequest(fetchPost, {
    propertyId: property.id,
    businessId: business.id,
    postId: params.postId,
  });
  const history = useHistory();
  const post = postRequest.result;
  const onSave = useCallback(
    async (msg) => {
      function isMessageValid(userInput: string) {
        // to be improved, we could add type checks here
        return userInput.length >= 10;
      }
      if (isMessageValid(msg)) {
        const data = await client.businesses
          .for(business.id)
          .social.posts.for(params.postId)
          .update({
            message: msg,
          });
        setIsInvalid(false);
        history.replace("../");
        return;
      }
      setIsInvalid(true);
    },
    [history, client, business.id, params.postId]
  );

  const actions = [
    {
      label: "Close",
      hidden: false,
      action: async () => {
        //to be improved in the future; right now, only exits 'edit mode'
        history.goBack();
      },
    },
    {
      label: "Delete",
      hidden: false,
      action: async () => {
        // eslint-disable-next-line no-restricted-globals
        const doDelete = confirm("Delete Post\n\nYou cannot undo this action.");
        if (!doDelete) return;
        deletePost({ businessId: business.id, postId: params.postId }).then(
          () => {
            history.replace(`/business/${business.id}/social`);
          }
        );
      },
    },
  ];

  const pageTitle = getPostPageTitle(post);

  if (post) {
    return (
      <SocialPostEdit
        post={{ ...post, pageTitle }}
        onSave={onSave}
        isInvalid={isInvalid}
        actions={actions}
      />
    );
  }
  return (
    <div
      style={{
        height: "100svh",
        display: "flex",
      }}
    >
      <p
        style={{
          margin: "auto",
          fontSize: "1.2rem",
        }}
      >
        Loading post...
      </p>
    </div>
  );
};

export default PostEdit;
