import React from 'react';
import {
  Button,
  Center,
  Heading,
  Image,
  VStack,
  Text,
  Alert,
  AlertDescription,
  AlertTitle,
  Spacer,
} from '@chakra-ui/react';
import ProServicesAlert from '../components/ProServicesAlert';
import heroImage from '../assets/socialAIBeta.png';

const SocialViewNotEnabled = () => {
  return (
    <Center>
      <VStack gap="3em" marginTop="6em">

        <Image src={heroImage} />

        <Heading size="lg" sx={{ textAlign: 'center' }}>
          Create Engaging Social Media Posts, Automatically
        </Heading>

        <Text fontSize="lg" align="center" maxWidth="70ch">
          GetintheLoop&apos;s Social AI generates engaging social media posts based on
          your offers, promotions, and loyalty programs. Unleash the power of AI
          to supercharge your social media, increase engagement, and drive
          meaningful interactions. The best part? It&apos;s automatic, no extra work
          required!
        </Text>

        <Heading size="md" sx={{ textAlign: 'center' }}>
          Social AI is available on any plan, starting at $99/month.
        </Heading>

        <Button
          size="lg"
          colorScheme="blue"
          as='a'
          target='_blank'
          rel='noopener noreferrer'
          href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=Add%20Social%20AI%20Request`}
        >
          Add Social AI Now
        </Button>

        <Spacer />

        <ProServicesAlert />
      </VStack>
    </Center>
  );
};

export default SocialViewNotEnabled;
