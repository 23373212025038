/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  Box,
  Heading,
  Show,
  Text,
} from '@chakra-ui/react';
import Stepper from 'web-react-ui/src/components/wizard/Stepper';
import CarouselSlide from '../../Carousel/CarouselSlide';

const STEPS = [
  { key: 'pick' },
  { key: 'login' },
  { key: 'allow' },
  { key: 'confirm' },
];
const STEP_LABELS = {
  pick: { label: 'Pick a Social Network' },
  login: { label: 'Login If Needed' },
  allow: { label: 'Allow Access' },
  confirm: { label: 'Confirm Link' },
};

const Simple = ({ supplemental }: { supplemental?: boolean }) => (
  <CarouselSlide textAlign="center" alignItems="center" justifyContent="center">
    <Heading>Simple, Automated Social Media</Heading>
    <Text fontSize="2xl">
      Social AI creates social feed posts and stories using your existing GetintheLoop offers and promotions.
      Getting started is easy—simply link your business Facebook and/or Instagram account, and you’re ready to go!
    </Text>
    <Text fontSize="2xl">
      Linking a social media account is simple and should take less than 2 minutes.
    </Text>
    <Show above="md">
      <Box
        fontSize="2em"
        pt="2em"
        sx={{
          '& .chakra-avatar': {
            borderWidth: '3px',
            borderColor: "blue.400"
          },
        }}
      >
        <Stepper steps={STEPS} labels={STEP_LABELS} size="md" />
      </Box>
    </Show>
  </CarouselSlide>
);

export default Simple;
