import {
  Flex,
  Image,
  LinkBox,
  LinkOverlay,
  SkeletonCircle,
  SkeletonText,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import useRequest from 'shared/src/hooks/useRequest';
import client from 'admin/src/services/client';
import Offer from 'web-react-ui/src/types/Offer.interface';
import useProperty from '../components/useProperty';
import Post from '../interfaces/Post.interface';

const Content = ({ post, admin }: { post: Post, admin?: boolean }) => {
  const items = post?.relatedEntities || [];
  const businessId = items.find((item) => item.type === 'business')?.id;

  if (!post) return null;

  return (
    <>
      {items.map((item) => {
        if (item.type === 'offer') {
          return (
            <OfferContentItem
              key={item.id}
              offerId={item.id}
              businessId={businessId}
              admin={admin}
            />
          );
        }
        return null;
      })}
    </>
  );
};

const OfferContentItem = ({
  offerId,
  businessId,
  admin,
}: {
  offerId: string;
  businessId?: string;
  admin?: boolean;
}) => {
  const property = useProperty();

  if (!businessId) {
    // This is a weird case where we have an Offer but no Business
    // Should we assume this Offer is at _this_ Business???
    // Or just show nothing.
    return null;
  }

  const offerRequest = useRequest(
    async ({ offerId: id }: { offerId: string }) => {
      if (!id) return undefined;
      return client.properties
        .for(property.id)
        .businesses.for(businessId)
        .offers.for(offerId)
        .getIndexedOffer();
    },
    { offerId },
  );

  return (
    <LinkBox
      as={Flex}
      align="center"
      gap="1em"
      borderTopWidth="1px"
      borderBottomWidth="1px"
      p="1em"
      w="100%"
      className="_cssTest"
      data-text="contentsection component"
    >
      {!offerRequest.settled && offerRequest.loading && <SkeletonOfferCard />}
      {!offerRequest.settled && !offerRequest.loading && (
        <Text fontSize="md" fontWeight="medium">
          No available offers
        </Text>
      )}
      {offerRequest.settled && (
        <OfferCard
          admin={admin}
          businessId={businessId}
          offerId={offerId}
          offer={offerRequest.result}
        />
      )}
    </LinkBox>
  );
};

const OfferCard = ({ admin, businessId, offerId, offer }: {
  admin?: boolean,
  businessId: string,
  offerId: string,
  offer: Offer
}) => {
  const href = admin
    ? `/businesses/${businessId}`
    : `/business/${businessId}/offers/${offerId}`;
  return (
    <>
      <Image
        boxSize="4em"
        borderRadius="full"
        src={offer?.heroImageUrl}
        flexShrink={0}
      />
      <Flex gap="0.5em" direction="column">
        <LinkOverlay href={href}>
          <Text fontWeight="bold">{offer?.headline}</Text>
        </LinkOverlay>
        <Text>{offer?.subHeadline}</Text>
      </Flex>
    </>
  );
};

const SkeletonOfferCard = () => {
  return (
    <>
      <SkeletonCircle size="4em" flexShrink={0} />
      <SkeletonText noOfLines={2} w="100%" maxWidth="30ch" />
    </>
  );
};

export default Content;
