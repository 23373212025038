/* eslint class-methods-use-this:0 */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Map from 'web-react-ui/src/map/Map';

import { strings } from '../../i18n';

class LocationsMap extends Component {
  static propTypes = {
    locations: PropTypes.object,
    property: PropTypes.object,
    business: PropTypes.object,
    basePath: PropTypes.string,
    isLarge: PropTypes.bool,
    hideDetails: PropTypes.bool
  };

  state = {
    redirectToLocation: false
  };

  handlePopupClick = ({ data }) => this.setState({ redirectToLocation: data.id });

  render() {
    const {
      locations,
      hideDetails,
      isLarge = false
    } = this.props;
    const { redirectToLocation } = this.state;
    const markers = _.map(locations, (location) => {
      const isEnabled = location.isEnabled !== false;
      const popupContent = hideDetails
        ? [
          `<p class='lead'>${location.name}</p>`,
          location.address ? `<p>${location.address.label}</p>` : ''
        ].join('')
        : [
          `<p class='lead'>${location.name}</p>`,
          location.address ? `<p>${location.address.label}</p>` : '',
          `<button class='ui button primary small'>${strings('dashboard.component.locationsMap.seeDetails')}</button>`
        ].join('');

      return ({
        center: [_.get(location, 'address.centre.lat', 0), _.get(location, 'address.centre.long', 0)],
        title: location.name,
        isEnabled,
        popup: {
          content: popupContent,
          data: {
            id: location.id,
            isEnabled
          }
        }
      });
    });

    const base = '300px';
    const md = isLarge ? '600px' : base;

    return (
      <div>
        {this.state.redirectToLocation}
        {redirectToLocation && (
          <Redirect to={`${this.props.basePath}/${redirectToLocation}`}/>
        )}
        <Map
          h={{ base, md }}
          minW={{ base, md }}
          initialZoom={15}
          markers={markers}
          onPopupClick={this.handlePopupClick}
        />
      </div>
    );
  }
}

export default LocationsMap;
