import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { UseRequestResult } from 'shared/src/types/UseRequestResult.interface';
import ErrorAlert from 'web-react-ui/src/chakra/ErrorAlert';
import businessesModule from '../../../../modules/businesses';

const RequestResultAlert = ({
  request, socialNetwork, contentType,
}: {
  request: UseRequestResult<{ id: string }>,
  socialNetwork: string,
  contentType: string
}) => {
  const business = useSelector(state => businessesModule.selectors.business.getData(state));

  if (!request.settled) return null;

  if (request.error) {
    return <ErrorAlert error={request.error} title={`Error creating ${socialNetwork} ${contentType}`} />;
  }

  return (
    <Alert>
      <AlertTitle textTransform="capitalize">{socialNetwork} {contentType} Created</AlertTitle>
      <AlertDescription>
        <Link to={`/business/${business.id}/social/${request.result.id}`}>Go to {contentType} ➡</Link>
      </AlertDescription>
    </Alert>
  );
};

export default RequestResultAlert;
