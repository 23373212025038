import {
  Box,
  Link,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import Location from 'web-react-ui/src/types/Location.interface';
import AppAuthorization from './AppAuthorization';
import ConnectBusinessLocation from './ConnectBusinessLocation';
import extractPayload from './extractPayload';
import SsoLayout from './SsoLayout';

const Footer = () => (
  <Box my={5} px={3} justifySelf="flex-start" w="100%">
    <Text fontSize="lg">
      {`Can't`} find correct business location or need other help?{` `}
      <Link color="blue.400" textDecoration="underline" href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
        Contact Support
      </Link>.
    </Text>
  </Box>
);

const InstallThryvView = () => {
  const [location, setLocation] = useState<Location>();

  const payload = extractPayload();

  const handleCancel = () => {
    window.location.replace(payload.returnUrl);
  };
  const handleContinue = (selectedLocation: Location) => {
    setLocation(selectedLocation);
  };
  const handleAuthorize = () => {
    if (!location) return;
    // @ts-ignore
    const url = new URL(window.location);
    url.pathname = '/apps/connect/authorize';
    url.searchParams.set('businessId', location.business.id);
    url.searchParams.set('locationId', location.id);
    window.location.replace(url.href);
  };

  return (
    <SsoLayout footer={<Footer />} returnUrl={payload.returnUrl}>
      {!location
        ? <ConnectBusinessLocation onCancel={handleCancel} onContinue={handleContinue} />
        : <AppAuthorization location={location} onCancel={handleCancel} onAuthorize={handleAuthorize} />
      }
    </SsoLayout>
  );
};

export default InstallThryvView;
