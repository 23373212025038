import {
  Button,
  ButtonGroup,
} from '@chakra-ui/react';
import React from 'react';
import PlanController from 'web-react-ui/src/components/PlanCards2/PlanController';

const BillingPeriodSelector = ({ controller }: { controller?: PlanController }): JSX.Element | null => {
  if (!controller) return null;

  if (controller.basePlans.length <= 1) return null;

  return (
    <ButtonGroup padding={4}>
      <Button colorScheme="blue"
              variant={controller.periodUnit === 'month' ? 'solid' : 'ghost'}
              onClick={() => controller.setPeriod(1, 'month')}>Monthly Billing</Button>
      <Button colorScheme="blue"
              variant={controller.periodUnit === 'year' ? 'solid' : 'ghost'}
              onClick={() => controller.setPeriod(1, 'year')}>Annual Billing</Button>
    </ButtonGroup>
  );
};

export default BillingPeriodSelector;
