/* eslint-disable-next-line no-redeclare */
/* global window */

import _ from 'lodash';

const getKey = () => {
  const subdomain = _.get(window, 'lmgDomainConfig.property.subdomain');
  return `bdw:Attribution:${subdomain}`;
};

const getAttributionContext = () => {
  try {
    const attributionJson = window.localStorage.getItem(getKey());
    if (attributionJson) {
      const attribution = JSON.parse(attributionJson);
      // eslint-disable-next-line no-underscore-dangle
      const propertyId = attribution._propertyId;
      if (propertyId) {
        return [propertyId, attribution];
      }
    }
    return undefined;
  } catch (err) {
    // eslint-disable-next-line no-console
    window.console.error('Error parsing stored attribution');
    return undefined;
  }
};

const setAttributionContext = (context) => {
  try {
    const _propertyId = _.get(window, 'lmgDomainConfig.property.id'); // eslint-disable-line no-underscore-dangle
    const key = getKey();
    if (context) {
      /* Fields in the context get written as tags on the business unless they start with an _underscore. */
      window.localStorage.setItem(key, JSON.stringify({
        _timestamp: new Date().toISOString(), // eslint-disable-line no-underscore-dangle
        _propertyId,                          // eslint-disable-line no-underscore-dangle, no-multi-spaces
        ...context
      }));
    } else {
      const attributionJson = window.localStorage.getItem(getKey());
      if (attributionJson) {
        const attribution = JSON.parse(attributionJson);
        const date = new Date(attribution._timestamp); // eslint-disable-line no-underscore-dangle
        const expiredDate = new Date(date.setDate(date.getDate() + 7)); // +7 Days
        if (expiredDate <= new Date()) {
          window.localStorage.removeItem(key);
        }
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error setting attribution localstorage', err);
  }
};

export {
  getAttributionContext,
  setAttributionContext
};
