import React from 'react';
import {
  Alert,
  Button,
  Flex,
  Heading,
  Text,
  Stack
} from '@chakra-ui/react';

import SsoLayout from './SsoLayout';
import IntegrationTerms from './IntegrationTerms';
import NeedHelpFooter from './NeedHelpFooter';

const SignupWelcome = ({
  onClickContinue,
  onClickCancel,
  isLoading,
  error,
}: {
  onClickContinue: (val: boolean) => void,
  onClickCancel: () => void,
  isLoading: boolean,
  error?: string,
}): JSX.Element => {
  return (
    <SsoLayout footer={<NeedHelpFooter />}>
      <Stack spacing={8} position='relative' w='100%'>
        <Heading>Welcome to GetintheLoop!</Heading>
        <Text fontSize='2xl'>
          This will setup a new GetintheLoop account for your business
          and link it to your Thryv account.
        </Text>
        <IntegrationTerms />
        <Text fontSize='xl'>
          Your GetintheLoop account is a separate product from Thryv, and will be billed separately.
        </Text>
        <Flex justify='space-between'>
          <Button
            variant='ghost'
            color='gray.500'
            size='lg'
            onClick={onClickCancel}
            isDisabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            colorScheme='blue'
            size='lg'
            onClick={() => onClickContinue(true)}
            isLoading={isLoading}
          >
            Continue
          </Button>
        </Flex>
        {error && (
          <Alert borderRadius={8} status='error'>{error}</Alert>
        )}
      </Stack>
    </SsoLayout>
  );
};

export default SignupWelcome;
