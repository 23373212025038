import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import ContentFilter from 'shared/src/helpers/valuableContentFilter/valuableContentFilter';
import useAwaitDisclosure from 'shared/src/hooks/useAwaitDisclosure';
import useRequest from 'shared/src/hooks/useRequest';

import businessesModule from '../../modules/businesses';
import client from '../../services/client';
import propertyResolver from '../../services/propertyResolver';
import { usePlanAdvancedSchedule, usePlanState } from '../../hooks/usePlan';
import CreateLoyaltyOfferContainer from './CreateLoyaltyOfferContainer';
import CreatePromotionOfferContainer from './CreatePromotionOfferContainer';

const OFFERTYPE_LOYALTY = 'loyalty';

const filter = new ContentFilter();

const CreateOfferContainer = ({
  offerType,
}: {
  offerType: 'loyalty' | 'promotions',
}): JSX.Element => {
  const history = useHistory();
  const [redirect, setRedirect] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isPriorityDisabled, setIsPriorityDisabled] = useState(false);
  const [initialValues, setInitialValues] = useState<Record<string, any>>();
  const [formValues, setFormValues] = useState<Record<string, any>>({});
  const { onOpen, onClose, isOpen } = useAwaitDisclosure();
  const allowsAdvancedSchedule = usePlanAdvancedSchedule();
  const property = propertyResolver();

  const business = useSelector(state => businessesModule.selectors.business.getData(state));
  const instanceRequest = useRequest(
    () => client.properties.for(property.id).businesses.for(business.id).offers.initialize(),
    [business, allowsAdvancedSchedule]
  );

  const setInitialOfferValues = useRequest(
    async () => {
      if (!instanceRequest.result) return null;
      const offer = await instanceRequest.result.deprecatedGetOldOfferModel();
      const values = offerType === OFFERTYPE_LOYALTY ? { ...offer, offerType: 'punchcard' } : offer;
      setInitialValues(values);
      // instance.setInstance(...) is a hack
      // For example with locations:
      // 1) We get the initialValues from the instance which contains all
      // of the Location ids allowed (so they're all selected by default)
      // 2) We reset the instance's internal value for locations
      // 3) When we save, the OfferUpdatePipeline diffs the form value with its internal state.
      // So if we didn't reset the value of locations
      // it would say, "oh, all these are already selected so I don't need to do anything"
      instanceRequest.result.setInstance('discoverableRegions', []);
      instanceRequest.result.setInstance('locations', []);
      return true;
    },
    [instanceRequest.result]
  );

  useEffect(() => {
    if (instanceRequest.result && formValues.offerType) {
      const resetInitValues = instanceRequest.result.resetOnNewOfferTypeChange(formValues, isPriorityDisabled);
      setInitialValues(resetInitValues);
    }
  }, [instanceRequest.result, formValues.offerType]);

  const handleCancel = () => {
    const returnTo = `/business/${business.id}/${offerType}`;
    if (history.length <= 2) return history.replace(returnTo);
    return history.goBack();
  };

  const handleFormChange = (values: Record<string, any>) => setFormValues(values);
  const handlePriorityDisabled = (value: boolean) => setIsPriorityDisabled(value);

  const handlePerformanceWarningCheck = async () => {
    /* prompt User offer's performance warning */
    if (formValues.enabled && filter.check(formValues)) return onOpen();
    return true;
  };

  const handleSubmit = async () => {
    const shouldSubmit = await handlePerformanceWarningCheck();
    if (!shouldSubmit) return;
    try {
      setIsLoading(true);
      // TODO: Pull out EditOfferPipeline instance values individually?
      // Form EditOfferForm needs to be updated to look for instance elements separately instead of under `offer`
      await instanceRequest.result.deprecatedUpdateWithOldOfferModel(formValues);
      const { id } = instanceRequest.result.getInstance('offer');
      const offerDetailsUrl = `/business/${business.id}/offers/${id}`;
      setRedirect(offerDetailsUrl);
    } catch (err: any) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (redirect) return <Redirect to={redirect} />;

  const Container = offerType === OFFERTYPE_LOYALTY ? CreateLoyaltyOfferContainer : CreatePromotionOfferContainer;

  return (
    <Container
      business={business}
      initialValues={initialValues}
      handleFormChange={handleFormChange}
      handlePriorityDisabled={handlePriorityDisabled}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      isLoading={isLoading || instanceRequest.loading || setInitialOfferValues.loading}
      isModalOpen={isOpen}
      onModalClose={onClose}
    />
  );
};

export default CreateOfferContainer;
