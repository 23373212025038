import {
  Box,
  Text,
  Link,
  Flex,
} from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import ViewHeader from 'web-react-ui/src/chakra/ViewHeader';

import PageBackButton from 'web-react-ui/src/chakra/page/PageBackButton';
import PageLayout from 'web-react-ui/src/chakra/page/PageLayout';
import Business from 'web-react-ui/src/types/Business.interface';

import BrandSettings from 'shared/src/components/social/BrandSettings';
import ContentSettings from 'shared/src/components/social/ContentSettings';
import GeneralSettings from 'shared/src/components/social/GeneralSettings';
import SectionHeader from 'shared/src/components/SectionHeader';
import SocialAccounts from 'shared/src/components/SocialAccounts';
import Profile from 'shared/src/interfaces/Profile.interface';

import { strings } from '../../../../i18n';
import Campaigns from './Campaigns';

const SocialSettings = () => {
  const profile = useSelector<{ social: { profile: Profile } }>(
    (state) => state.social.profile,
  ) as Profile;
  const business = useSelector<{ business: { data: Business } }>(
    (state) => state.business.data,
  ) as Business;
  const showAdvancedSettings = ['plus', 'pro'].includes(profile?.plan);

  return (
    <PageLayout>
      <PageBackButton to="../social">
        {strings('ui.label.cancel')}
      </PageBackButton>
      <ViewHeader title="Social AI Settings" />
      <SocialAccounts canEdit profile={profile} business={business} />
      <Campaigns />
      {
        false && (
          <>
            <SectionHeader>Brand Settings</SectionHeader>
            <BrandSettings
              profile={profile}
              businessId={business.id}
              disabled={!showAdvancedSettings}
              disabledContent={<UpgradeNotice />}
            />
            <SectionHeader>Content Settings</SectionHeader>
            <ContentSettings
              profile={profile}
              businessId={business.id}
              disabled={!showAdvancedSettings}
              disabledContent={<UpgradeNotice />}
            />
          </>
        )
      }
      <SectionHeader>General Settings</SectionHeader>
      <GeneralSettings profile={profile} businessId={business.id} />
    </PageLayout>
  );
};

const UpgradeNotice = () => {
  return (
    <Flex direction="column" gap="1em">
      <Text align="center" fontSize="xl">
        Additional content controls are available on Social AI+ plans and above.
      </Text>
      <Text align="center" fontSize="xl">
        <Link as={RouterLink} to="../plans" textDecoration="underline" fontWeight="bold" color="blue.500">
          Upgrade Now
        </Link>
      </Text>
    </Flex>
  );
};

export default SocialSettings;
