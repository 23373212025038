/* eslint-disable func-names */
import {
  PLAN_FETCHED
} from './planActions';

const initialState = {
  plan: {},
  error: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PLAN_FETCHED:
      return {
        ...state,
        plan: action.payload,
        error: null
      };
    default:
      return state;
  }
}
