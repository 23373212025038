import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@chakra-ui/react';
import { useLocation } from 'react-router';

import { MobileTopBar } from './MobileTopBar';
import { Sidebar } from './Sidebar';
import AlertBanner from './AlertBanner';

const NO_SIDEBAR_ROUTES = [
  '/signup',
  '/login',
  '/auth',
  '/apps',
];

const Shell = ({ children, title, logo, alertStatus, alertMessage }) => {
  const location = useLocation();
  const hideShell = NO_SIDEBAR_ROUTES.find(route => location.pathname.indexOf(route) === 0);

  const childArray = React.Children.toArray(children);
  const menu = childArray.find(c => c.type === Shell.Menu);
  const body = childArray.find(c => c.type === Shell.Body);

  if (hideShell) return body;
  return (
    <Flex h='100vh' flexDirection='column'>
      <MobileTopBar title={title} logo={logo}>
        {menu}
      </MobileTopBar>
      <Flex flexGrow={1} w='100%'>
        {menu && (
          <Sidebar
            display={{
              base: 'none',
              md: 'flex'
            }}
          >
            {menu}
          </Sidebar>
        )}
        <Flex flexDir='column' flexGrow={1}>
          <AlertBanner alertStatus={alertStatus} alertMessage={alertMessage} />
          <Box flexGrow={1} w='100%' maxW='8xl' p={8} mx='auto'>
            {body}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

Shell.Menu = ({ children }) => {
  return children;
};

Shell.Body = ({ children }) => {
  return children;
};

Shell.propTypes = {
  children: PropTypes.node
};

export default Shell;
