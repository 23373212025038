import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Link,
} from '@chakra-ui/react';

const ProServicesAlert = () => {
  return (
    <Alert
      status="warning"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      gap="0.5em"
      p="1em"
      borderRadius="md"
      sx={{
        'text-wrap': 'balance',
      }}
    >
      <AlertTitle fontSize="lg">
        Looking for Fully Customized Social Media Management?
      </AlertTitle>

      <AlertDescription>
        When you&apos;re ready for a more custom approach,
        GetintheLoop&apos;s Professional Marketing Services are here to help.
        &nbsp;
        <Link
          size="lg"
          color="blue.600"
          target="_blank"
          rel="noopener noreferrer"
          // eslint-disable-next-line max-len
          href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=I%27m%20interested%20in%20Social%20Media%20professional%20services`}>
          Contact us
        </Link> to get started.
      </AlertDescription>
    </Alert>
  );
};

export default ProServicesAlert;
