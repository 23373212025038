import PropTypes from 'prop-types';
import React, { Component } from 'react';
import List from 'web-react-ui/src/components/elements/List';
import EmptyList from 'web-react-ui/src/components/entities/EmptyList';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';

import PagedList from 'web-react-ui/src/components/pagedList/PagedList';
import WaitFor from 'web-react-ui/src/data/WaitFor';

import { strings } from '../../i18n';
import client from '../../services/client';
import propertyResolver from '../../services/propertyResolver';

const LocationListItem = ({ location }) => (
  <List.Item className="m1">
    <List.Content>
      <List.Header>{location.name}</List.Header>
      <List.Description>{location.address.label}</List.Description>
    </List.Content>
  </List.Item>
);

LocationListItem.propTypes = {
  location: PropTypes.object
};

class Presenter extends Component {
  state = {};

  render() {
    const { fetchLocations, offer } = this.props;
    return (
      <PagedList fetchList={fetchLocations} params={{ offer }}>
        {({
          items, loading, isEmpty, error
        }) => (
          <WaitFor waitFor={!loading}>
            <List>
              {isEmpty && <EmptyList message={strings('dashboard.label.noLocations')} />}
              {items.map(location => <LocationListItem location={location} key={location.id} />)}
              <ErrorMessage error={error} />
            </List>
          </WaitFor>
        )}
      </PagedList>
    );
  }
}

Presenter.propTypes = {
  fetchLocations: PropTypes.func,
  offer: PropTypes.object
};

class OfferLocationsList extends Component {
  fetchLocations({ offer }) {
    const { business } = this.props;
    if (!offer) return null;
    const property = propertyResolver();
    return client
      .properties.for(property.id)
      .businesses.for(business.id)
      .offers.for(offer.id)
      .locations.search({
        attached: true,
        hasRoles: true,
        accessContext: 'implicit'
      });
  }

  render() {
    return <Presenter fetchLocations={this.fetchLocations.bind(this)} offer={this.props.offer} />;
  }
}

OfferLocationsList.propTypes = {
  property: PropTypes.object,
  basePath: PropTypes.string,
  business: PropTypes.object,
  offer: PropTypes.object
};

export default OfferLocationsList;
