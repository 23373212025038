/* eslint class-methods-use-this:0 */
import React from 'react';
import PropTypes from 'prop-types';

const salesEmail = process.env.REACT_APP_SALES_EMAIL;

const SalesLink = ({ label = salesEmail, classes }) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    className={classes}
    href={`mailto:${salesEmail}`}
  >
    {label}
  </a>
);


SalesLink.propTypes = {
  label: PropTypes.string,
  classes: PropTypes.string
};

export default SalesLink;
