/* eslint-disable */
// @todo Split this into a container/view pattern
// @todo divide Stats and Details screens into separate views
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  Link,
  Redirect,
  useLocation,
} from 'react-router-dom';
import {
  Flex,
  Box,
  Alert,
  AlertIcon,
  CloseButton,
  Heading,
  Text,
} from '@chakra-ui/react';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import useIncrement from 'shared/src/hooks/useIncrement';
import useCopyOfferShareUrl from 'shared/src/hooks/useCopyOfferShareUrl';
import { BUSINESS_DASHBOARD_ENV } from 'shared/models';
import GetOfferRating from 'web-react-ui/src/components/offers/GetOfferRating';

import Business from 'web-react-ui/src/types/Business.interface';
import Offer from 'web-react-ui/src/types/Offer.interface';
import ActionMenu from 'web-react-ui/src/components/actionMenu/ActionMenu';
import Menu from 'web-react-ui/src/components/collections/Menu';
import Message from 'web-react-ui/src/components/collections/Message';
import Label from 'web-react-ui/src/components/elements/Label';
import View from 'web-react-ui/src/components/layout/View';
import Metrics from 'web-react-ui/src/components/metrics/Metrics';
import MetricsLoyalty from 'web-react-ui/src/components/metrics/MetricsLoyalty';
import TimeRangeSelector from 'web-react-ui/src/components/metrics/TimeRangeSelector';
import OfferPriority from 'web-react-ui/src/components/offers/OfferPriority';
import OfferLimitErrorMessage from 'web-react-ui/src/components/offers/OfferLimitErrorMessage';
import OfferPrintPreview from 'web-react-ui/src/components/offers/OfferPrintPreview';
import OfferRedeemLimitsInfo from 'web-react-ui/src/components/offers/OfferRedeemLimitsInfo';
import OfferRedeemValidationInfo from 'web-react-ui/src/components/offers/OfferRedeemValidationInfo';
import OfferScheduleInfo from 'web-react-ui/src/components/offers/OfferScheduleInfo';
import PinTable from 'web-react-ui/src/components/offers/PinTable';
import facebookSDKService from 'web-react-ui/src/components/postToFb/facebookSDKService';
import PostToFb from 'web-react-ui/src/components/postToFb/PostToFb';
import OfferPreview from 'web-react-ui/src/components/preview/OfferPreview';
import Request from 'web-react-ui/src/data/Request';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import getOfferShareUrl from 'web-react-ui/src/utils/getOfferShareUrl';
import HiddenLabel from 'web-react-ui/src/components/offers/HiddenLabel';
import Breadcrumbs from 'web-react-ui/src/chakra/Breadcrumbs';
import ViewHeader from 'web-react-ui/src/chakra/ViewHeader';

import useLanguage from '../../hooks/useLanguage';
import { usePlanIncludesLoyalty } from '../../hooks/usePlan';
import businessesModule from '../../modules/businesses';
import propertyResolver from '../../services/propertyResolver';
import {
  setActiveTab,
  LOYALTY,
  PROMOTIONS,
  unsetActiveTab,
} from '../../modules/sidebar';
import client from '../../services/client';
import MetricsLoyaltyTotalWrapper from '../stats/MetricsLoyaltyTotalWrapper';
import MyLocationSelector from './MyLocationsSelector';
import OfferLocationsList from './OfferLocationsList';

const OfferDetailsHeader = ({ title, noSpacing }: { title: string, noSpacing?: boolean }): JSX.Element => (
  <Box mt={noSpacing ? 0 : 8} mb={3}>
    <Heading as="h2" size="lg" fontWeight="500" borderBottom="1px" borderColor="gray.200" pb={2}>
      {title}
    </Heading>
  </Box>
);

const getOfferUneditableReason = (offer: Offer | undefined, planIncludesLoyalty: boolean): string => {
  if (offer?.flags.includes('punchcard') && !planIncludesLoyalty) return 'plan';
  if (!offer?.canEdit) return 'other';
  if (offer?.syndicated) return 'syndicated';
  return 'none';
};

const OfferDetails = ({
  offer,
  business,
  refresh,
  isOwner,
}: {
  offer?: Offer,
  business: Business,
  refresh: VoidFunction,
  isOwner?: boolean,
}) => {
  const { strings } = useI18Next();

  const [days, setDays] = useState(30);
  const [activeItem, setActiveItem] = useState(strings('dashboard.screen.offerDetails.details'));
  const [isBusy, setIsBusy] = useState(false);
  const [error, setError] = useState<Record<string, any>>();
  const [redirect, setRedirect] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [locationId, setLocationId] = useState('');
  const [loadFb, setLoadFb] = useState(false);
  const property = propertyResolver();
  const [actionLabelCopyUrl, messageStr, showMessage, handleCloseMessage, handleCopyUrl] = useCopyOfferShareUrl();
  const location = useLocation();

  const handleItemClick = (e: any, { name }: { name: string }) => setActiveItem(name);

  const reloadOffer = () => refresh();

  const handleEnable = async () => {
    setIsBusy(true);

    const offerRepo = client
      .properties.for(property.id)
      .businesses.for(business.id)
      .offers.for(offer.id);

    try {
      await offerRepo.checkLimits();
      await offerRepo.enable();
    } catch (err: any) {
      setError(err);
      setIsBusy(false);
    }

    await offerRepo.waitForIndex(({ enabled }: { enabled: boolean }) => enabled);

    reloadOffer();
    setIsBusy(false);
  };

  const handleDisable = async () => {
    setIsBusy(true);

    const offerRepo = client
      .properties.for(property.id)
      .businesses.for(business.id)
      .offers.for(offer.id);

    try {
      await offerRepo.disable();
    } catch (err: any) {
      setError(err);
      setIsBusy(false);
    }

    await offerRepo.waitForIndex(({ enabled }: { enabled: boolean }) => !enabled);

    reloadOffer();
    setIsBusy(false);
  };

  const handleDuplicate = () => {
    const offerCreateUrl = `/business/${business.id}/offers/new/${offer.id}`;
    setRedirect(offerCreateUrl);
  };

  const fetchStats = (params: Record<string, any>) => {
    if (!offer) return null;
    if (params.locationId === undefined) return null;
    const statsRepo = client
      .properties.for(property.id)
      .businesses.for(business.id)
      .locations.for(params.locationId)
      .offers.for(offer.id)
      .stats;

    return params.isLoyalty
      ? statsRepo.getLoyaltyLastNDays(params)
      : statsRepo.getLastNDays(params);
  };

  const handleSetRange = (params: { days: number }) => setDays(params.days);

  const handlePostToFb = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  const handleLocationChange = (id: string) => setLocationId(id);

  const renderSuccessMessage = (): JSX.Element | null => {
    if (!showMessage) return null;
    return (
      <Alert status="success" mt={4}>
        <Flex flex={1} alignItems="center" justifyContent="space-between">
          <Flex>
            <AlertIcon />
            <Text>{messageStr}</Text>
          </Flex>
          <Box onClick={handleCloseMessage}>
            <CloseButton />
          </Box>
        </Flex>
      </Alert>
    );
  };

  useEffect(() => {
    facebookSDKService
      .initFacebookSDK()
      .then(() => setLoadFb(true))
      .catch((err: any) => setError(err));
  }, []);

  useEffect(() => {
    if (offer) {
      const date = new Date();
      const formattedDate = date.toISOString().split('T')[0];
      if ((offer?.schedules[0]?.endDate) < formattedDate) {
        setDays(364);
      }
    }
  }, [offer]);

  const planIncludesLoyalty = usePlanIncludesLoyalty();

  const enabled = offer?.enabled;
  const offerUneditableReason = getOfferUneditableReason(offer, planIncludesLoyalty);
  const canEdit = offerUneditableReason === 'none';
  const isSyndicated = offer?.syndicated;
  const isHidden = offer?.schedules.every((schedule: { isHidden: boolean }) => schedule.isHidden);

  const offerShareUrl = getOfferShareUrl(offer);

  const actions = [
    {
      label: strings('dashboard.screen.offerDetails.edit'),
      extraProps: {
        as: Link,
        to: `${window.location.pathname}/edit`,
      },
      hidden: !canEdit,
    },
    {
      label: actionLabelCopyUrl,
      action: () => handleCopyUrl(offerShareUrl),
      hidden: !offerShareUrl,
    },
    {
      label: strings('ui.label.businessDashboardCopyOfferUrl'),
      action: () => handleCopyUrl(`${BUSINESS_DASHBOARD_ENV[process.env.REACT_APP_ENV!]}${location.pathname}`),
      hidden: !isOwner,
    },
    {
      label: strings('dashboard.screen.offerDetails.enable'),
      action: () => handleEnable(),
      hidden: enabled || !canEdit,
    },
    {
      label: strings('dashboard.screen.offerDetails.duplicate'),
      action: () => handleDuplicate(),
      hidden: !canEdit,
    },
    {
      label: strings('dashboard.screen.offerDetails.facebook'),
      action: () => handlePostToFb(),
      hidden: !loadFb || !enabled || !canEdit,
    },
    {
      label: strings('dashboard.screen.offerDetails.disable'),
      action: () => handleDisable(),
      hidden: !enabled, // Note: We _can_ potentially disable an offer even if we can't edit it
    },
  ];

  if (redirect) return <Redirect to={redirect} />;

  const showDetails = activeItem === strings('dashboard.screen.offerDetails.details');
  const showStats = activeItem === strings('dashboard.screen.offerDetails.stats');

  const offerLocations = offer?.locationIds;
  const isLoyalty = offer?.flags?.includes('punchcard');

  const breadcrumbs = [
    {
      to: `/business/${business.id}`, title: business.name,
    },
    {
      to: `/business/${business.id}/${isLoyalty ? 'loyalty' : 'promotions'}`,
      title: isLoyalty ? 'Loyalty' : 'Promotions',
    },
    {
      to: '#', title: strings('dashboard.screen.offerDetails.offerDetails'), isCurrentPage: true,
    },
  ];

  return (
    <Box maxW="8xl" mx="auto">
      <WaitFor waitFor={offer && !isBusy}>
        <Breadcrumbs items={breadcrumbs} />
        {offer && (
          <Box>
            {renderSuccessMessage()}
            <OfferLimitErrorMessage error={error} dashboard />
            {openModal && (
              <PostToFb
                offer={offer}
                property={property}
                facebookSDKService={facebookSDKService}
                i18nStrings={strings}
                closeModal={handleCloseModal}
              />
            )}
            {(offerUneditableReason === 'other') && (
              <Message info>
                {strings('dashboard.screen.offerDetails.info')}
              </Message>
            )}
            <Box w="100%">
              <ViewHeader title={offer.headline}>
                <Flex align="center">
                  {isHidden && (
                    <Box mr={4}>
                      <HiddenLabel />
                    </Box>
                  )}
                  {isSyndicated && (
                    <Box mr={4}>
                      <Label color="black">
                        {strings('dashboard.screen.offerDetails.syndicated').toUpperCase()}
                      </Label>
                    </Box>
                  )}
                  {!offer.enabled && (
                    <Box mr={4}>
                      <Label color="red">{strings('dashboard.screen.offerDetails.disabled')}</Label>
                    </Box>
                  )}
                  <ActionMenu actions={actions} />
                </Flex>
              </ViewHeader>
              <Heading as="h5" fontSize="xl">{offer.subHeadline}</Heading>
            </Box>
            <Flex justify="flex-end" align="center" w="100%">
              <GetOfferRating offerId={offer.id} propertyId={property?.id} businessId={business?.id} />
              <Box mx={4}>
                {strings('dashboard.screen.offerDetails.priority')}
              </Box>
              <OfferPriority priority={offer.priority} />
            </Flex>
            {isSyndicated && (
              <Message warning>
                <Message.Header>{strings('dashboard.screen.offerDetails.warning.title')}</Message.Header>
                <p>{strings('dashboard.screen.offerDetails.warning.body')}</p>
              </Message>
            )}
            <Menu pointing secondary>
              <Menu.Item
                name={strings('dashboard.screen.offerDetails.details')}
                active={activeItem === strings('dashboard.screen.offerDetails.details')}
                onClick={handleItemClick}
              />
              <Menu.Item
                name={strings('dashboard.screen.offerDetails.stats')}
                active={activeItem === strings('dashboard.screen.offerDetails.stats')}
                onClick={handleItemClick}
              />
            </Menu>
            {showStats && (
              <Box mt={12}>
                <Flex justify="flex-end" flexWrap="wrap">
                  <Flex flexWrap="wrap" w="50%" gap={5}>
                    <Box minW={200} flexGrow={1}>
                      {/* I don't know why these props aren't being accepted by ts */}
                      <MyLocationSelector
                        // @ts-ignore
                        offerLocations={offerLocations}
                        // @ts-ignore
                        onChange={handleLocationChange}
                        // @ts-ignore
                        value={locationId}
                      />
                    </Box>
                    <Box minW={200} flexGrow={1}>
                      <TimeRangeSelector days={days} handleSetRange={handleSetRange} />
                    </Box>
                  </Flex>
                </Flex>
                <Request
                  request={fetchStats}
                  params={{ days, locationId, isLoyalty }}
                >
                  {({
                    data, loading, statsError,
                  }: {
                    data: any, loading: boolean, statsError: { message: string }
                  }) => (
                    <WaitFor waitFor={!loading} wrapContents>
                      <View>
                        <View.Section>
                          {statsError && <Message error>{statsError.message}</Message>}
                          {error && <Message error>{error.message}</Message>}
                          {isLoyalty ? (
                            <React.Fragment>
                              <MetricsLoyaltyTotalWrapper
                                // @ts-ignore
                                businessId={business?.id}
                                offerId={offer?.id}
                                locationId={locationId}
                              />
                              <MetricsLoyalty data={data} plansHref="../plans" />
                            </React.Fragment>
                          ) : (
                            <Metrics data={data} plansHref="../plans" />
                          )}
                        </View.Section>
                      </View>
                    </WaitFor>
                  )}
                </Request>
              </Box>
            )}
            {showDetails && (
              <Flex my={12} flexWrap="wrap" justify="space-between">
                <Box flexGrow={1} mr={12} minW={300}>
                  <OfferDetailsHeader title={strings('dashboard.screen.offerDetails.details')} noSpacing />
                  <Text>{offer.details}</Text>
                  {offer.legal && <h5>{strings('dashboard.label.termsAndConditions')}</h5>}
                  <Text>{offer.legal}</Text>
                  {offer.promoCode && (
                    <div>
                      <Heading>{strings('dashboard.screen.offerDetails.promoCode')}</Heading>
                      <Text>{offer.promoCode}</Text>
                    </div>
                  )}
                  <div>
                    <OfferDetailsHeader title={strings('dashboard.screen.offerDetails.schedule')} />
                    {offer.schedules && (
                      <div>
                        <OfferScheduleInfo schedules={offer.schedules} />
                      </div>
                    )}
                  </div>
                  {!isSyndicated && (
                    <Box>
                      <OfferDetailsHeader title={strings('dashboard.screen.offerDetails.redemptionAction')} />
                      <OfferRedeemLimitsInfo offerFlowVars={offer.flow.vars} />
                      <OfferRedeemValidationInfo offerFlow={offer.flow} />
                      {!!(offer.pins && offer.pins.length) && (
                        <Box mt={8}>
                          <Flex>
                            <strong>{strings('dashboard.screen.offerDetails.pinVerify')}</strong>
                            <Box ml="auto">
                              <OfferPrintPreview offer={offer} property={property} />
                            </Box>
                          </Flex>
                          <PinTable pins={offer.pins} />
                        </Box>
                      )}
                    </Box>
                  )}
                  <OfferDetailsHeader title={strings('dashboard.label.locations')} />
                  <OfferLocationsList offer={offer} business={business} />
                </Box>
                <Box minW={300}>
                  <OfferPreview offer={offer} image={offer.heroImage} />
                </Box>
              </Flex>
            )}
            <Flex justify="flex-end">
              <ActionMenu actions={actions} />
            </Flex>
          </Box>
        )}
      </WaitFor>
    </Box>
  );
};

const fetchOfferDetails = ({
  businessId,
  offerId,
  languageValue,
}: {
  businessId: string,
  offerId: string,
  languageValue: string,
}) => {
  const property = propertyResolver();
  return client
    .properties.for(property.id)
    .businesses.for(businessId)
    .offers.for(offerId)
    .details([languageValue]);
};

const OfferDetailsContainer = ({ offerId, isOwner }: { offerId: string, isOwner: boolean }): JSX.Element => {
  const [language] = useLanguage();
  const [offer, setOffer] = useState<Offer>();
  const business = useSelector(businessesModule.selectors.business.getData);
  const [refreshKey, increment] = useIncrement();
  const dispatch = useDispatch();

  useEffect(
    () => {
      if (!language.value) return undefined;
      fetchOfferDetails({ businessId: business.id, offerId, languageValue: language.value }).then((data: Offer) => {
        if (data.flags.includes('punchcard')) {
          dispatch(setActiveTab(LOYALTY));
        } else {
          dispatch(setActiveTab(PROMOTIONS));
        }
        setOffer(data);
      });
      return () => {
        dispatch(unsetActiveTab());
      };
    },
    [language, refreshKey], // Dependant on the language object updating
  );

  const refresh = useCallback(
    () => {
      increment();
    },
    [increment],
  );

  return (
    <OfferDetails
      offer={offer}
      business={business}
      refresh={refresh}
      isOwner={isOwner}
    />
  );
};

export default OfferDetailsContainer;
