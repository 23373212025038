// UI component for a Wizard modal
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Flex, Heading, Modal,
  ModalContent, ModalHeader, ModalBody, ModalOverlay
} from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';

import I18NextContext, { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import WaitFor from '../../data/WaitFor';
import ErrorMessage from '../entities/ErrorMessage';
import Stepper from './Stepper';

const WizardModal = ({
  manager,
  plansViewModel,
  isBusinessSignUp,
  submittingChildren,
  isSsoSignup,
}) => {
  const [index, setIndex] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(true);
  const [currentStep, setCurrentStep] = useState(manager.getCurrentStep());

  const bodyContainer = React.useRef();

  const { steps } = manager;

  manager.registerOnStateChanged((newState) => {
    if (bodyContainer.current) {
      bodyContainer.current.scrollTop = 0;
    }
    setIndex(newState.index);
    setIsSubmitting(newState.isSubmitting);
    setError(newState.error);
    setModalOpen(newState.modalOpen);
    setCurrentStep(manager.getCurrentStep());
  });

  useEffect(() => {
    manager.start();
  }, []);

  useEffect(() => {
    if (!currentStep) {
      setCurrentStep(manager.getCurrentStep());
    }
  }, [manager]);

  const { strings } = useI18Next();

  const STEPS_LABELS = {
    addBusinessDetails: {
      label: strings("ui.component.wizardModal.details")
    },
    address: {
      label: strings("ui.component.wizardModal.address")
    },
    logo: {
      label: strings("ui.component.wizardModal.images")
    },
    confirmLocation: {
      label: strings("ui.component.wizardModal.mapLocation")
    },
    selectPlan: {
      label: strings("ui.component.wizardModal.selectPlan")
    },
    confirmDetails: {
      label: strings("ui.component.wizardModal.confirm")
    }
  };

  if (!currentStep) return null;

  const CurrentStep = currentStep.render;

  return isSubmitting && isSsoSignup ? submittingChildren() : (
    <I18NextContext.Consumer>
      {({ strings }) => (
        <Modal isOpen={modalOpen} size='full'>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader position='sticky' top={0} bg='white' borderRadius={8}>
              <WaitFor waitFor={!isSubmitting} wrapContents suppressSpinner>
                <Flex maxW='450px' mx='auto' flexDir='column' align='flex-start' gap={5} px={2}>
                  {manager.onCancel && (
                    <Button
                      variant='link'
                      colorScheme='blue'
                      onClick={() => manager.onCancel()}
                      leftIcon={<ChevronLeftIcon />}
                      fontSize='xl'
                    >
                      {strings('ui.label.cancel')}
                    </Button>
                  )}
                  {isSsoSignup ? (
                    <Heading fontSize='3xl' textAlign='center'>
                      {`${strings('ui.component.wizardModal.adding')} ${strings('ui.component.wizardModal.aNewBusiness')}`}
                    </Heading>
                  ) : (
                    <Heading fontSize='3xl' textAlign='center' noOfLines={1}>
                      {strings('ui.component.wizardModal.adding')}&nbsp;
                      {manager.getTitle() || strings('ui.component.wizardModal.aNewBusiness')}
                    </Heading>
                  )}
                </Flex>
              </WaitFor>
              <Box maxW='450px' mx='auto' px={2} mt={10}>
                <Stepper steps={steps} active={index} labels={STEPS_LABELS} />
              </Box>

            </ModalHeader>
            <ModalBody
              ref={bodyContainer}
              style={{ overflow: isSubmitting ? 'hidden' : 'auto' }}
              zIndex={-1}
            >
              <WaitFor
                waitFor={!isSubmitting}
                wrapContents
                loaderChildren={submittingChildren}
                className='c-dimmer--fit-parent'>
                <Box ref={bodyContainer}>
                  <ErrorMessage error={error} />
                  <CurrentStep
                    step={steps[index]}
                    manager={manager}
                    plansViewModel={plansViewModel}
                    isBusinessSignUp={isBusinessSignUp}
                  />
                </Box>
              </WaitFor>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </I18NextContext.Consumer >
  );
}

WizardModal.propTypes = {
  manager: PropTypes.object,
  plansViewModel: PropTypes.array,
  isBusinessSignUp: PropTypes.bool,
  open: PropTypes.bool,
  submittingChildren: PropTypes.element,
  isSsoSignup: PropTypes.bool,
};

export default WizardModal;
