/* eslint-disable-next-line no-redeclare */
import React from 'react';
import { Stack, Box, Flex, Image, Heading } from '@chakra-ui/react';

import Dropdown from 'web-react-ui/src/components/modules/Dropdown';
import OktaWidget from 'web-react-ui/src/components/auth/OktaSignIn';

import Terms from '../siteFooter/Terms';
import client from '../../services/client';
import useLanguage from '../../hooks/useLanguage';
import i18next, { strings, LANGUAGE_OPTIONS } from '../../i18n';

import LMGlogo from '../../assets/gitl_logo.png';
import ownerImage from '../../assets/owner-login.png';
import businessImage from '../../assets/business-login.png';

const width = '480px';
const spacing = '40px';

const LoginView = ({ dashboard = true }: { dashboard: boolean }): JSX.Element => {
  const [currentLanguage, handleLanguageChange] = useLanguage();

  const options = LANGUAGE_OPTIONS.map(lang => ({
    ...lang,
    text: strings(`shared.languages.${lang.key}`)
  }));

  const bgColor = { base: 'white', md: 'gray.50' };
  const loginText = dashboard
    ? strings('dashboard.component.loginView.businessDashboard')
    : strings('dashboard.component.loginView.localOwnerDashboard');

  return (
    <Flex justify='center' bg={bgColor}>
      <Flex align='stretch' maxW='1920px'>
        <Flex
          align='center'
          justify='center'
          direction='column'
          w={{ base: '100%', md: '50%' }}
          minW={{ md: width }}
          bg={bgColor}
        >
          <Box
            px={{ base: '12px', md: spacing }}
            py={{ base: spacing }}
            w='full'
            maxW={width}
            bg='white'
            rounded='8px'
            boxShadow={{ base: 0, md: 'base' }}
            textAlign='left'
          >
            <Box mb={spacing} width='146px'>
              <Image src={LMGlogo} alt='lmg logo' fit='contain' />
            </Box>

            <Stack spacing={1} mb='32px'>
              <Heading
                as='h1'
                mb={0}
                fontWeight='bold'
                fontSize={{ base: '28px', md: '30px' }}
              >
                {loginText}
              </Heading>
            </Stack>

            <Stack spacing='16px'>
              <OktaWidget key={i18next.language} client={client} i18next={i18next} />
              <Terms />
              <Box w='120px'>
                <Dropdown
                  compact
                  selection
                  selectOnBlur={false}
                  placeholder={strings(`dashboard.screen.preferences.${currentLanguage.key}`)}
                  options={options}
                  value={currentLanguage.value}
                  onChange={handleLanguageChange}
                />
              </Box>
            </Stack>
          </Box>
        </Flex>
        <Flex
          align='center'
          justify='center'
          p={{ base: 0 }}
          minH='100vh'
          w={{ base: 0, md: '50%' }}
          background='gray.50'
          ml={{ base: 0, md: 16 }}
        >
          <Image
            loading="lazy"
            src={dashboard ? businessImage : ownerImage}
            alt={loginText}
            w='533px'
            h='auto'
          />
        </Flex>
      </Flex>
    </Flex >
  );
};

export default LoginView;
