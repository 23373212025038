export const US_STATES = [
  { id: 'AL', text: 'Alabama', value: 'Alabama' },
  { id: 'AK', text: 'Alaska', value: 'Alaska' },
  { id: 'AS', text: 'American Samoa', value: 'American Samoa' },
  { id: 'AZ', text: 'Arizona', value: 'Arizona' },
  { id: 'AR', text: 'Arkansas', value: 'Arkansas' },
  { id: 'CA', text: 'California', value: 'California' },
  { id: 'CO', text: 'Colorado', value: 'Colorado' },
  { id: 'CT', text: 'Connecticut', value: 'Connecticut' },
  { id: 'DE', text: 'Delaware', value: 'Delaware' },
  { id: 'DC', text: 'District Of Columbia', value: 'District Of Columbia' },
  { id: 'FM', text: 'Federated States Of Micronesia', value: 'Federated States Of Micronesia' },
  { id: 'FL', text: 'Florida', value: 'Florida' },
  { id: 'GA', text: 'Georgia', value: 'Georgia' },
  { id: 'GU', text: 'Guam', value: 'Guam' },
  { id: 'HI', text: 'Hawaii', value: 'Hawaii' },
  { id: 'ID', text: 'Idaho', value: 'Idaho' },
  { id: 'IL', text: 'Illinois', value: 'Illinois' },
  { id: 'IN', text: 'Indiana', value: 'Indiana' },
  { id: 'IA', text: 'Iowa', value: 'Iowa' },
  { id: 'KS', text: 'Kansas', value: 'Kansas' },
  { id: 'KY', text: 'Kentucky', value: 'Kentucky' },
  { id: 'LA', text: 'Louisiana', value: 'Louisiana' },
  { id: 'ME', text: 'Maine', value: 'Maine' },
  { id: 'MH', text: 'Marshall Islands', value: 'Marshall Islands' },
  { id: 'MD', text: 'Maryland', value: 'Maryland' },
  { id: 'MA', text: 'Massachusetts', value: 'Massachusetts' },
  { id: 'MI', text: 'Michigan', value: 'Michigan' },
  { id: 'MN', text: 'Minnesota', value: 'Minnesota' },
  { id: 'MS', text: 'Mississippi', value: 'Mississippi' },
  { id: 'MO', text: 'Missouri', value: 'Missouri' },
  { id: 'MT', text: 'Montana', value: 'Montana' },
  { id: 'NE', text: 'Nebraska', value: 'Nebraska' },
  { id: 'NV', text: 'Nevada', value: 'Nevada' },
  { id: 'NH', text: 'New Hampshire', value: 'New Hampshire' },
  { id: 'NJ', text: 'New Jersey', value: 'New Jersey' },
  { id: 'NM', text: 'New Mexico', value: 'New Mexico' },
  { id: 'NY', text: 'New York', value: 'New York' },
  { id: 'NC', text: 'North Carolina', value: 'North Carolina' },
  { id: 'ND', text: 'North Dakota', value: 'North Dakota' },
  { id: 'MP', text: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
  { id: 'OH', text: 'Ohio', value: 'Ohio' },
  { id: 'OK', text: 'Oklahoma', value: 'Oklahoma' },
  { id: 'OR', text: 'Oregon', value: 'Oregon' },
  { id: 'PW', text: 'Palau', value: 'Palau' },
  { id: 'PA', text: 'Pennsylvania', value: 'Pennsylvania' },
  { id: 'PR', text: 'Puerto Rico', value: 'Puerto Rico' },
  { id: 'RI', text: 'Rhode Island', value: 'Rhode Island' },
  { id: 'SC', text: 'South Carolina', value: 'South Carolina' },
  { id: 'SD', text: 'South Dakota', value: 'South Dakota' },
  { id: 'TN', text: 'Tennessee', value: 'Tennessee' },
  { id: 'TX', text: 'Texas', value: 'Texas' },
  { id: 'UT', text: 'Utah', value: 'Utah' },
  { id: 'VT', text: 'Vermont', value: 'Vermont' },
  { id: 'VI', text: 'Virgin Islands', value: 'Virgin Islands' },
  { id: 'VA', text: 'Virginia', value: 'Virginia' },
  { id: 'WA', text: 'Washington', value: 'Washington' },
  { id: 'WV', text: 'West Virginia', value: 'West Virginia' },
  { id: 'WI', text: 'Wisconsin', value: 'Wisconsin' },
  { id: 'WY', text: 'Wyoming', value: 'Wyoming' }
];

export const PROVINCES = [
  { id: 'AB', text: 'Alberta', value: 'Alberta' },
  { id: 'BC', text: 'British Columbia', value: 'British Columbia' },
  { id: 'MB', text: 'Manitoba', value: 'Manitoba' },
  { id: 'NB', text: 'New Brunswick', value: 'New Brunswick' },
  { id: 'NL', text: 'Newfoundland and Labrador', value: 'Newfoundland and Labrador' },
  { id: 'NT', text: 'Northwest Territories', value: 'Northwest Territories' },
  { id: 'NS', text: 'Nova Scotia', value: 'Nova Scotia' },
  { id: 'NU', text: 'Nunavut', value: 'Nunavut' },
  { id: 'ON', text: 'Ontario', value: 'Ontario' },
  { id: 'PE', text: 'Prince Edward Island', value: 'Prince Edward Island' },
  { id: 'QC', text: 'Quebec', value: 'Quebec' },
  { id: 'SK', text: 'Saskatchewan', value: 'Saskatchewan' },
  { id: 'YT', text: 'Yukon', value: 'Yukon' }
];

export const COUNTRIES = [{
  id: 1,
  keyName: 'CA',
  text: 'Canada',
  value: 'Canada',
  regionType: 'province',
  codeType: 'postalCode',
  codeText: 'A1A 1B1',
  currencyCode: 'CAD',
  regions: [...PROVINCES],
}, {
  id: 2,
  keyName: 'US',
  text: 'United States',
  value: 'United States',
  regionType: 'state',
  codeType: 'zipCode',
  codeText: '12345',
  currencyCode: 'USD',
  regions: [...US_STATES],
}];
