import useClient from 'shared/src/hooks/useClient';
import usePermissions from 'shared/src/hooks/usePermissions';
import useRequest from 'shared/src/hooks/useRequest';

const useBillingDetails = ({ propertyId, businessId }) => {
  const client = useClient();
  const hasPermissions = usePermissions(
    { propertyId, businessId },
    ['property.business.chargebee.details.get']
  );

  const {
    result: billingDetails,
    error,
    settled
  } = useRequest(
    () => {
      if (hasPermissions !== true) return null;
      return client
        .properties.for(propertyId)
        .businesses.for(businessId)
        .getBillingDetails();
    },
    [propertyId, businessId, hasPermissions]
  );

  return {
    billingDetails,
    // `!settled` instead of `loading` because we want to avoid a
    // moment where `loading` is false before the request starts
    isLoading: !settled || hasPermissions === undefined,
    hasPermissions,
    error
  };
};

export default useBillingDetails;
