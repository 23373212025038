import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormLabel,
  HStack,
  Icon,
  Input,
  Select,
  Spacer,
  VStack,
} from '@chakra-ui/react';
import React, {
  useCallback,
} from 'react';

type Field = {
  id: string,
  label: string,
  disabled?: boolean
}

type RowValue = {
  field: string,
  value: string
}

const FieldRow = (
  { value, fields, onChange, onRemove }
    : { value: RowValue, fields: Array<Field>, onChange: Function, onRemove: Function },
) => {
  const handleChangeValue = useCallback(
    (event) => {
      onChange({
        value: event.currentTarget.value,
      });
    },
    [onChange],
  );

  const handleChangeField = useCallback(
    (event) => {
      onChange({
        field: event.currentTarget.value,
      });
    },
    [onChange],
  );

  const isTypeSelected = !!value?.field;

  return (
    <Box pb={10}>
      <HStack align="flex-start">
        <VStack align="flex-start">
          <FormLabel>Type</FormLabel>
          <Select onChange={handleChangeField}>
            <option selected={!value.field} disabled>Select</option>
            {
              fields
                .map((field: Field) => (
                  <option key={field.id} value={field.id} selected={field.id === value.field} disabled={field.disabled}>
                    {field.label}
                  </option>
                ))
            }
          </Select>
        </VStack>
        <VStack flexGrow={1}>
          <Flex width="100%" justifyContent="space-between">
            <FormLabel>Value</FormLabel>
            <Button
              variant="link"
              mb={2}
              onClick={onRemove}
            >
              Remove
            </Button>
          </Flex>
          <Spacer />
          <Input value={value.value}
                 onChange={handleChangeValue}
                 disabled={!isTypeSelected}
                 placeholder={isTypeSelected ? '' : 'select type...'}
          />
        </VStack>
      </HStack>
    </Box>
  );
};

const AddButton = ({ onClick, label }
  : { onClick: Function, label: string }) => {
  return (
    <Button
      variant="ghost"
      colorScheme="blue"
      fontSize="xl"
      leftIcon={<Icon as={AddIcon} />}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

const FieldList = ({ value = [], fields = [], onChange, addButtonLabel }: {
  value: Array<RowValue>,
  fields: Array<Field>,
  onChange: Function,
  addButtonLabel: string
}) => {
  const addItem = useCallback(
    () => {
      const newState = [...value, {}];
      onChange(newState);
    }, [value],
  );

  const removeItemForIndex = useCallback(
    (index) => {
      return () => {
        const newValue = value.slice();
        newValue.splice(index, 1);
        onChange(newValue);
      };
    },
    [value],
  );

  const handleChangeForIndex = useCallback(
    (index) => {
      return (rowValue: RowValue) => {
        const newValue = value.slice();
        newValue[index] = {
          ...value[index],
          ...rowValue,
        };
        onChange(newValue);
      };
    },
    [value],
  );

  const annotatedFields = fields.map((field) => {
    return ({
      id: field.id,
      label: field.label,
      disabled: (value || []).some(v => v.field === field.id),
    });
  });

  return (
    <Box>
      {value?.map?.(
        (item, index) => (
          <FieldRow
            key={index}
            value={item}
            fields={annotatedFields}
            onChange={handleChangeForIndex(index)}
            onRemove={removeItemForIndex(index)}
          />
        ),
      )}
      <AddButton label={addButtonLabel} onClick={addItem} />
    </Box>
  );
};

export default FieldList;
