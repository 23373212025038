/* eslint-disable react/prop-types */
import get from 'lodash/get';
import { parse } from 'mini-querystring';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import usePromise from 'shared/src/hooks/usePromise';
import auth from 'shared/src/modules/auth';
import Shell from 'web-react-ui/src/chakra/shell/Shell';

import AuthExpiry from 'web-react-ui/src/components/auth/AuthExpiry';
import AuthReceiver from 'web-react-ui/src/components/auth/AuthReceiver';
import NotFound from 'web-react-ui/src/components/NotFound';

import { ToastContainer } from 'web-react-ui/src/components/toasts';

import gitInfo from './.git-info.json';
import AppSidebar from './AppSidebar';
import BusinessDetailsView from './components/businesses/BusinessDetailsView';
import BusinessSelectView from './components/businesses/BusinessSelectView';
import RegisterComplete from './components/businesses/RegisterComplete';
import RegisterView from './components/businesses/RegisterView';
import AddBusinessView from './components/businesses/AddBusinessView';
import AttributionCatcher from './components/core/AttributionCatcher';
import HomeView from './components/home/HomeView';
import InitialState from './components/InitialState';
import LoginView from './components/login/LoginView';
import SignUpView from './components/login/SignUpView';
import Preferences from './components/Preferences';
import PrivateRoute from './components/PrivateRoute';
import Confirm from './components/selfSignUp/Confirm';
import SSOView from './components/sso';
import InstallAppView from './components/sso/InstallAppView';
import ConnectAccountLanding from './components/sso/ConnectAccountLanding';
import InstallThryvView from './components/sso/InstallThryvView';
import SignupPlanView from './components/sso/signup/SignupPlanView';
import SignupThryvView from './components/sso/SignupThryvView';
import businessesModule from './modules/businesses';
import client from './services/client';

import {
  usePropertyDispatch,
  usePropertyState
} from './hooks/useProperty';
import SignupWelcome from './components/sso/SignupWelcome';


const App = () => {
  const [forceUpdate, setForceUpdate] = React.useState(false);
  const isLoggedIn = useSelector(state => auth.selectors.getIsLoggedIn(state));
  const business = useSelector(state => businessesModule.selectors.business.getData(state));
  const { property, isGitlProperty } = usePropertyState();
  const { setProperty, setPropertyFlag } = usePropertyDispatch();

  const [, , businessProfileImage] = usePromise(
    () => {
      if (!business) {
        return undefined;
      }

      return client.images.for(business.profileImage).details();
    },
    [business],
    undefined,
    true
  );

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.info(`${gitInfo.name} v${gitInfo.version} (build ${gitInfo.hash})`);
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      setProperty();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (property !== null) {
      setPropertyFlag(property.subdomain);
    }
  }, [property]);

  return (
    <AuthReceiver redirectToOwnerDashboard>
      <ToastContainer />
      <InitialState>
        <Router>
          <AttributionCatcher />
          <AuthExpiry />
          <Shell
            title={business?.name}
            logo={businessProfileImage?.url}
            alertMessage={process.env.REACT_APP_GLOBAL_ALERT_MESSAGE}
            alertStatus={process.env.REACT_APP_GLOBAL_ALERT_STATUS}
          >
            <Shell.Menu>
              <Switch>
                <Route path="/login" />
                <Route path="/signup" />
                <Route path="/businesses/register/complete/:businessId" />
                <Route>
                  <AppSidebar key={forceUpdate} title={business?.name} logo={businessProfileImage?.url} />
                </Route>
              </Switch>
            </Shell.Menu>
            <Shell.Body>
              <Switch>
                <Route path="/auth/sso/signup" component={SignupWelcome} />
                <Route path="/auth/sso/link" component={ConnectAccountLanding} />
                <Route path="/apps/install" component={InstallThryvView} />
                <Route path="/apps/signup/plan" component={SignupPlanView} />
                <Route path="/apps/signup" component={SignupThryvView} />
                <Route path="/apps/connect/authorize" component={InstallAppView} />
                <Route path="/apps/connect" component={SSOView} />

                <Route path="/login" component={LoginView} />
                <Route path="/signup/confirm" component={Confirm} />
                <Route path="/signup" component={SignUpView} />
                <PrivateRoute path="/" exact={true} component={HomeView} />
                <PrivateRoute
                  path="/businesses/register/complete/:businessId"
                  exact={true}
                  render={() => <RegisterComplete property={property} />}
                />
                <PrivateRoute
                  path="/businesses/register"
                  exact={true}
                  render={() => <RegisterView isPlanViewable={isGitlProperty} />}
                />
                <PrivateRoute
                  path="/businesses/add"
                  exact={true}
                  render={() => (
                    <AddBusinessView property={property} isPlanViewable={isGitlProperty} />
                  )}
                />
                <PrivateRoute
                  path="/businesses"
                  exact={true}
                  render={(props) => {
                    const skipRedirect = parse(props.location.search)['no-redirect'];
                    return (
                      <BusinessSelectView
                        skipRedirect={skipRedirect}
                        history={props.history}
                        isPlanViewable={isGitlProperty}
                      />
                    );
                  }}
                />
                <PrivateRoute
                  path="/business/:businessSlug"
                  render={(props) => {
                    const businessSlug = get(props, 'match.params.businessSlug');
                    return (
                      <BusinessDetailsView businessId={businessSlug} isPlanViewable={isGitlProperty} />
                    );
                  }}
                />
                <Preferences setForceUpdate={setForceUpdate} />
                <Route>
                  <NotFound />
                </Route>
              </Switch>
            </Shell.Body>
          </Shell>
        </Router>
      </InitialState>
    </AuthReceiver>
  );
};

App.propTypes = {
  location: PropTypes.object
};

export default App;
