/* eslint-env browser */
import authOpen from 'web-react-ui/src/components/auth/authOpen';
import admin from '@loopmediagroup/admin-sdk';

const CLIENT_ENV = process.env.REACT_APP_ENV || 'prod';
const STORAGE_KEY_PREFIX = 'bdw';
const AuthProvider = admin.AuthProvider;
const LocalStorageStore = admin.stores.LocalStorageStore;
const authProvider = new AuthProvider(new LocalStorageStore(STORAGE_KEY_PREFIX, window.localStorage));

const client = new admin.Client({
  env: CLIENT_ENV,
  authProvider
});

// FIXME: Figure out where this listener should be defined
client.on(admin.events.AUTH_EXPIRED, authOpen);

export default client;
