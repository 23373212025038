import _ from 'lodash';
import React, {
  useCallback,
  useState
} from 'react';
import { Redirect } from 'react-router';
import View from 'web-react-ui/src/components/layout/View';
import { TextField } from 'web-react-ui/src/reactFinalForm/fields';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';
import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';
import { compose, required, noWhiteSpace } from 'web-react-ui/src/reactFinalForm/validators';
import Dropdown from 'web-react-ui/src/components/modules/Dropdown';

import client from '../../services/client';
import {
  getAttributionContext,
  setAttributionContext
} from '../../services/getAttributionContext';
import Terms from '../siteFooter/Terms';
import useProperty from '../useProperty';
import { strings, LANGUAGE_OPTIONS } from '../../i18n';
import useLanguage from '../../hooks/useLanguage';
import { User } from '../../types/User.interface';

async function signUp(newUser: User, attribution: any) {
  try {
    await client.users.signup(newUser, attribution);
    await client.users.signupActivate(newUser.email);
  } catch (err) {
    throw new Error(strings('dashboard.component.signupView.unableCreateAccount'));
  }
}

const SignupView = (): JSX.Element => {
  const property = useProperty();
  const propertyLogo = _.get(property, 'logoStandardImageUrl');
  const [user, setUser] = useState<User | null>(null);
  const [currentLanguage, handleLanguageChange] = useLanguage();

  const handleSubmit = useCallback(async (newUser) => {
    const attributionContext = getAttributionContext();
    await signUp(newUser, attributionContext);
    setAttributionContext(null);
    setUser(newUser);
  }, []);

  if (user) return <Redirect to={`/signup/confirm?email=${user.email}`} />;

  return (
    <View>
      <View.Section narrow className='p0'>
        <div className='flex fdc acc jcc m4' style={{ minHeight: '100vh' }}>
          <div className='fg1' />
          {propertyLogo && <img src={propertyLogo} alt='' style={{ alignSelf: 'center', maxWidth: '60%' }} />}

          <h3 className='mt4 mb3'>
            {strings('dashboard.component.signupView.claimYourBusiness')}
          </h3>
          <SimpleForm onSubmit={handleSubmit} showSubmitError>
            <TextField
              name='name'
              label={strings('dashboard.component.signupView.field.label.fullName')}
              wrapLabel
              validate={required}
            />
            <TextField
              name='email'
              label={strings('dashboard.component.signupView.field.label.emailAddress')}
              wrapLabel
              validate={compose(required, noWhiteSpace)}
            />
            <FormSubmit
              style={{ paddingTop: '16px', paddingBottom: '16px' }}
              fluid
              size='medium'
              label={strings('dashboard.component.signupView.field.label.createFreeAccount')}
            />
          </SimpleForm>
          <div className='mt3'>
            <p>
              {strings('dashboard.component.signupView.alreadyHaveAnAccount')}&nbsp;
              <a href='/login'>{strings('dashboard.component.signupView.signIn')}</a>
            </p>
          </div>
          <div className='flex fdr aic jcsb mt4'>
            <Terms />
            <Dropdown
              compact
              selection
              selectOnBlur={false}
              placeholder={strings(`dashboard.screen.preferences.${currentLanguage.key}`)}
              options={LANGUAGE_OPTIONS.map(lang => ({
                ...lang,
                text: strings(`shared.languages.${lang.key}`)
              }))}
              value={currentLanguage.value}
              onChange={handleLanguageChange}
            />
          </div>
          <div className='fg2' />
        </div>
      </View.Section>
    </View>
  );
};

export default SignupView;
