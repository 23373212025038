import { CheckIcon } from '@chakra-ui/icons';
import {
  Button,
  FormControl,
} from '@chakra-ui/react';
import React from 'react';
import { useField } from 'react-final-form';

const ToggleButtonField = ({ name, children, ...rest }) => {
  // @ts-ignore
  const field = useField(name);
  return (
    <FormControl>
      <Button
        variant="outline"
        onClick={() => field.input.onChange(!field.input.value)}
        isActive={field.input.value}
        _active={{
          outlineColor: 'blue.500',
        }}
        leftIcon={field.input.value ? <CheckIcon /> : null}
        {...rest}
      >
        {children}
      </Button>
    </FormControl>
  );
};

export default ToggleButtonField;
