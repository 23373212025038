import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Heading, Icon, InputGroup, InputLeftElement, Input, Stack, } from '@chakra-ui/react';
import { BiSearch } from 'react-icons/bi';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

const FilterForm = ({ plans, handleCheckedPlans, onClose }) => {
  const [planQuery, setPlanQuery] = useState('');
  const [pendingPlans, setPendingPlans] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const { strings } = useI18Next();

  React.useEffect(() => {
    if (plans?.length) {
      setPendingPlans(plans);
    }
  }, [plans]);

  const handleChange = ({ name, checked }) => {
    setIsPending(true);
    setPendingPlans(prevState => prevState.map(item => ({
      ...item,
      ...(item.id === name && {
        isChecked: checked
      })
    })));
  };

  const handleReset = () => {
    setIsPending(false);
    setPlanQuery('');
    onClose();
  };

  const handleCancel = () => {
    setPendingPlans(plans);
    handleReset();
  };

  const handleApply = (e) => {
    e.preventDefault();
    handleCheckedPlans(pendingPlans);
    handleReset();
  };

  const currentList = isPending ? pendingPlans : plans;
  const dynamicSearch = currentList.filter(item => item.name.toLowerCase().includes(planQuery.toLowerCase()));

  return (
    <React.Fragment>
      <InputGroup mb={6}>
        <InputLeftElement color='gray.300'>
          <Icon as={BiSearch} h={5} w={5} />
        </InputLeftElement>
        <Input type='text' placeholder='Search' value={planQuery} onChange={e => setPlanQuery(e.target.value)} />
      </InputGroup>
      <Heading size='md' mb={2}>{strings('ui.label.plan')}</Heading>
      <form onSubmit={e => handleApply(e)}>
        <Stack direction='column' spacing={4} mb={6} maxH='200px' overflowY='auto'>
          {dynamicSearch.map(item => (
            <Checkbox
              key={item.id}
              name={item.id}
              isChecked={item.isChecked}
              onChange={e => handleChange(e.target)}
            >
              {item.name}
            </Checkbox>
          ))}
        </Stack>
        <Stack direction='row' spacing={4}>
          <Button variant='outline' w='100%' onClick={handleCancel}>{strings('ui.label.cancel')}</Button>
          <Button type='submit' variant='solid' colorScheme='blue' w='100%'>{strings('ui.label.apply')}</Button>
        </Stack>
      </form>
    </React.Fragment>
  );
};


FilterForm.propTypes = {
  plans: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isChecked: PropTypes.bool,
  })).isRequired,
  handleCheckedPlans: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FilterForm;
