/* eslint-disable max-len */
export const errorCodes = {
  INSTALL_NOT_FOUND: '11-67-013',
  USER_NOT_LINKED: '11-67-009',
  USER_NOT_FOUND: '11-67-003',
  PARAMETER_REQUIRED: '11-80-004',
};

export const errors = [
  {
    messageId: errorCodes.PARAMETER_REQUIRED,
    title: 'Unable to Authorize',
    message: 'This account cannot access any GetintheLoop businesses. Pleasure ensure that you have signed in with the correct account.',
  },
];
