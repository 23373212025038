import PropTypes from 'prop-types';
import React, {
  Component,
  useCallback,
  useState
} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  ButtonGroup,
  Flex
} from '@chakra-ui/react';

import Icon from 'web-react-ui/src/components/elements/Icon';
import List from 'web-react-ui/src/components/elements/List';
import EmptyList from 'web-react-ui/src/components/entities/EmptyList';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import PagedList from 'web-react-ui/src/components/pagedList/PagedList';
import IfPermissions from 'web-react-ui/src/components/utils/IfPermissions';
import WizardModal from 'web-react-ui/src/components/wizard/WizardModal';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import ViewHeader from 'web-react-ui/src/chakra/ViewHeader';

import { strings } from '../../i18n';
import { usePropertyState } from '../../hooks/useProperty';
import businessModule from '../../modules/businesses';
import client from '../../services/client';
import propertyResolver from '../../services/propertyResolver';
import LocationsMap from './LocationsMap';
import createWizard from './NewLocationModal';

const LocationListItem = (props) => {
  const { location, business } = props;
  return (
    <List.Item as={Link} to={`/business/${business.id}/locations/${location.id}`}>
      <Flex align="center">
        <List.Content className="fg1 fb0">
          <List.Header>{location.name}</List.Header>
          <List.Description>{location.address.label}</List.Description>
        </List.Content>
        <List.Content className="fb0">
          <Icon className="long arrow alternate right pull-right" />
        </List.Content>
      </Flex>
    </List.Item>
  );
};

LocationListItem.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      label: PropTypes.string.isRequired
    }).isRequired,
  }),
  business: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

const LocationsPresenter = ({
  fetchLocations,
  business,
  history,
  showView
}) => {
  const { property, isGitlProperty: hasPerLocationPricing } = usePropertyState();
  const [openWizard, setOpenWizard] = useState(false);

  const handleItemClick = (name) => {
    if (!['map', 'list'].includes(name)) {
      return false;
    }

    return history.replace(`/business/${business.id}/locations/${name.toLowerCase()}`);
  };

  const addLocationManager = createWizard({
    property,
    business,
    history,
    onComplete: (finalLocation) => {
      if (finalLocation) {
        history.push(`/business/${business.id}/locations/${finalLocation.id}`);
      }
    },
    onCancel: setOpenWizard,
    hasPerLocationPricing
  });

  const showWizard = useCallback(async () => {
    setOpenWizard(true);
  }, [addLocationManager]);

  return (
    <Box>
      <ViewHeader title={strings('dashboard.screen.locations.locations')}>
        <IfPermissions context={{ businessId: business.id }} permissions={['business.update']}>
          <Button
            colorScheme="blue"
            onClick={showWizard}
            border={0}
          >
            {strings('dashboard.screen.locations.addLocation')}
          </Button>
        </IfPermissions>
      </ViewHeader>
      <Flex justify="flex-end">
        <ButtonGroup isAttached>
          <Button
            colorScheme={showView === 'list' ? 'blue' : 'gray'}
            onClick={() => handleItemClick('list')}
            border={0}
            cursor="pointer"
          >
            {strings('dashboard.screen.locations.list')}
          </Button>
          <Button
            colorScheme={showView === 'map' ? 'blue' : 'gray'}
            onClick={() => handleItemClick('map')}
            border={0}
            cursor="pointer"
          >
            {strings('dashboard.screen.locations.map')}
          </Button>
        </ButtonGroup>
      </Flex>
      <Box mt={4}>
        <PagedList fetchList={fetchLocations}>
          {({ items, loading, isEmpty, error }) => (
            <WaitFor waitFor={!loading}>
              <ErrorMessage error={error} />
              {(showView === 'list') && (
                <List celled selection>
                  {isEmpty && <EmptyList message={strings('dashboard.screen.locations.noLocationFound')} />}
                  {items.map(location => (
                    <LocationListItem
                      business={business}
                      key={location.id}
                      location={location}
                    />
                  ))}
                </List>
              )}
              {(showView === 'map') && (
                <LocationsMap
                  isLarge={true}
                  locations={items}
                  business={business}
                  basePath={`/business/${business.id}/locations`}
                />
              )}
            </WaitFor>
          )}
        </PagedList>
      </Box>
      {openWizard && <WizardModal manager={addLocationManager} submittingChildren={<div>
        <p className="lead">
          {strings('dashboard.screen.locations.addingNewLocation')}
        </p>
        <p className="lead">
          {strings('dashboard.screen.locations.noRefresh')}
        </p>
      </div>
      } />}
    </Box>
  );
};

LocationsPresenter.propTypes = {
  fetchLocations: PropTypes.func,
  business: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }),
  showView: PropTypes.string
};

const Locations = class Locations extends Component {
  fetchLocations() {
    const { business } = this.props;
    const property = propertyResolver();
    return client
      .properties.for(property.id)
      .businesses.for(business.id)
      .locations.list({
        hasRoles: true,
        accessContext: 'implicit'
      });
  }

  render() {
    return (
      <LocationsPresenter
        business={this.props.business}
        history={this.props.history}
        fetchLocations={this.fetchLocations.bind(this)}
        showView={this.props.showView}
      />
    );
  }
};

const mapState = state => ({
  business: businessModule.selectors.business.getData(state)
});

Locations.propTypes = LocationsPresenter.propTypes;

export default connect(mapState)(Locations);
export { LocationsPresenter as Presenter };
