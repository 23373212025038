/* eslint class-methods-use-this:0 */
import PropTypes from 'prop-types';
import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import { useField } from 'react-final-form';

import ClientContext from 'shared/src/components/contexts/ClientContext';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import {
  TextArea,
  TextField
} from '../../reactFinalForm/fields';
import SelectField from '../../reactFinalForm/fields/SelectField';
import FormSubmit from '../../reactFinalForm/FormSubmit';
import SimpleForm from '../../reactFinalForm/SimpleForm';
import {
  compose,
  max,
  required
} from '../../reactFinalForm/validators';
import InlineSupportLink from '../../support/InlineSupportLink';
import View from '../layout/View';
import { WizardStep } from '../wizard/WizardManager';

const categoryCache = {};

const loadCategories = async (client, locale) => {
  if (categoryCache[locale]) return categoryCache[locale];

  const result = await client.categories
    .search({ leafOnly: true, locale: [locale] })
    .then(client.pagination.loadRest);

  categoryCache[locale] = result.items
    .map(cat => ({
      key: cat.id,
      text: cat.name,
      value: cat
    }))
    // eslint-disable-next-line no-nested-ternary
    .sort(({ text: a }, { text: b }) => (a === b
        ? 0
        : a > b
          ? 1
          : -1
    ));

  return categoryCache[locale];
};

/* eslint-disable-next-line react/display-name, function-paren-newline */
const CategorySearch = React.memo(
  ({ locale, initialSearch, name, ...rest }) => {
    const client = useContext(ClientContext);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const { input } = useField(name);

    useEffect(
      () => {
        loadCategories(client, locale)
          .then((options) => {
            setCategories(options);
            if (!input.value && initialSearch) {
              const lookingFor = initialSearch.toLowerCase();
              const match = options.find(o => o.text.toLowerCase() === lookingFor);
              if (match) input.onChange(match.value);
            }
            setLoading(false);
          });
      },
      [client, locale]
    );
    return <SelectField search name={name} options={categories} loading={loading} {...rest} />;
  }
); /* eslint-disable-line function-paren-newline */

CategorySearch.propTypes = {
  locale: PropTypes.string,
  name: PropTypes.string,
  initialSearch: PropTypes.string // default value is Category where `text` matches `initialSearch`
};

function AddBusinessDetails({ step }) {
  const { strings } = useI18Next();
  const { data } = step;
  const locale = data.locale;
  const categorySearchValue = data.business?.categorySearchValue;
  return (
    <SimpleForm initialValues={step.data} onSubmit={data => step.stepComplete(data)}>
      <View>
        <View.Section narrow>
          <h3>{strings('ui.component.addBusinessDetails')}</h3>
        </View.Section>
        <View.Section narrow>
          <TextField
            name="business.name"
            label={strings('ui.field.label.businessName')}
            wrapLabel
            validate={compose(required, max(64))}
            /* eslint-disable-next-line max-len */
            info={strings('ui.field.info.businessName')}
          />
          <TextField
            name="business.shortName"
            label={strings('ui.field.label.shortName')}
            wrapLabel
            validate={compose(required, max(24))}
            /* eslint-disable-next-line max-len */
            info={strings('ui.field.info.shortName')}
          />
          <CategorySearch
            name="business.category"
            label={strings('ui.field.label.businessCategory')}
            initialSearch={categorySearchValue}
            wrapLabel
            placeholder={strings('ui.field.placeholder.businessCategory')}
            info={strings('ui.field.info.businessCategory')}
            validate={required}
            locale={locale}
          />
          <TextArea
            name="business.description"
            label={strings('ui.field.label.description')}
            placeholder={strings('ui.field.placeholder.description')}
            wrapLabel
            validate={compose(max(254), required)}
          />
          <TextField
            name="business.url"
            label={strings('ui.field.label.website')}
            placeholder="https://yourwebsite.com"
            wrapLabel
            optional
            parse={str => str.trim()}
            validate={max(254)}
          />
        </View.Section>
        <View.Section narrow className="flex">
          <FormSubmit label={strings('ui.label.next')} className="mla" />
        </View.Section>
        <View.Section narrow>
          <p>{strings('ui.label.haveAQuestion')} <InlineSupportLink />.</p>
        </View.Section>
      </View>
    </SimpleForm>
  );
}

AddBusinessDetails.propTypes = {
  step: PropTypes.object
};

export default () => new WizardStep({
  key: 'addBusinessDetails',
  getTitle: data => data.business.name,
  onHide: (data) => {
    // eslint-disable-next-line no-param-reassign
    if (data.business.category) data.business.categories = [data.business.category];
    if (data.business.url
      && (data.business.url.indexOf('http://') !== 0
        && data.business.url.indexOf('https://') !== 0)) {
      // eslint-disable-next-line no-param-reassign
      data.business.url = `https://${data.business.url}`;
    }
    return data;
  },
  render: AddBusinessDetails
});
