/* eslint-disable-next-line no-redeclare */
/* global window */
import PropTypes from 'prop-types';
import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import AddAddress from 'web-react-ui/src/components/businessSignUp/AddAddress';
import ConfirmMapLocationStep from 'web-react-ui/src/components/businessSignUp/ConfirmMapLocationStep';
import Button from 'web-react-ui/src/components/elements/Button';
import View from 'web-react-ui/src/components/layout/View';
import {
  WizardManager,
  WizardStep
} from 'web-react-ui/src/components/wizard/WizardManager';
import StaticMap from 'web-react-ui/src/map/StaticMap';
import InlineSupportLink from 'web-react-ui/src/support/InlineSupportLink';
import isEmpty from 'web-react-ui/src/utils/isEmpty';
import Pipeline, { Step } from 'shared/src/modules/pipeline/Pipeline';

import client from '../../services/client';
import confirm from '../../services/confirm';
import {
  saveAddress,
  saveLocation
} from '../businesses/CreateBusinessPipeline';
import plansViewModel from '../../assets/data/plansViewModel';

import { strings } from '../../i18n';

class CreateLocationPipeline extends Pipeline {
  constructor(clientInstance, business) {
    // Adds business to initial results so that saveLocation steps
    // can be reused as-is.
    super(null, { business }, { client: clientInstance });
    this.steps = [
      new Step(saveAddress),
      new Step(saveLocation)
    ];
  }
}

const ConfirmDetailsStep = ({ step, manager }) => {
  const {
    address = {},
    isFreePlan,
    planName,
    isLoading,
    error
  } = step.data;

  const centre = address.centre || {};

  const marker = { center: [centre.lat, centre.long] };

  const hasPerLocationPricing = useSelector(state => state.property.isGitl);

  const jumpToStep = key => manager.showStepByKey(key);

  const editStyle = { fontSize: '75%', cursor: 'pointer', fontWeight: 'normal' };

  const displayStreeAddress = [address.suite, address.streetAddress, address.street]
    .filter(Boolean)
    .join(' ');

  const renderPlanSummary = () => {
    let content;
    if (isFreePlan && !isLoading) {
      content = <p>{strings('dashboard.component.newLocationModal.noCharge')}</p>;
    } else {
      content = <p>{strings('dashboard.component.newLocationModal.priceIncrease')}</p>;
    }
    return (
      <div className='flex fdr jcsb'>
        <h6>{planName}</h6>
        {content}
      </div>
    );
  };

  return (
    <View className='mb4'>
      <View.Section narrow>
        <h2>{strings('dashboard.component.newLocationModal.confirmDetails')}</h2>
        <p>
          {strings('dashboard.component.newLocationModal.fragment.pt1')}&nbsp;
          <em>{strings('dashboard.component.newLocationModal.edit')}</em>&nbsp;
          {strings('dashboard.component.newLocationModal.fragment.pt2')}
          <br />
          {strings('dashboard.component.newLocationModal.fragment.pt3')}
        </p>
      </View.Section>
      <View.Section narrow>
        <h3>
          {strings('dashboard.component.newLocationModal.address')}
          <a
            className='pull-right'
            style={editStyle}
            onClick={() => jumpToStep('address')}
          >
            {strings('dashboard.component.newLocationModal.edit')}
          </a>
        </h3>
        <p>
          {displayStreeAddress}<br />
          {`${address.city}, ${address.state}, ${address.country}`}<br />
          {address.postalcode}
        </p>

        <p>
          <b>{strings('dashboard.component.newLocationModal.number')}:</b>&nbsp;
          {address.phone || strings('dashboard.component.newLocationModal.none')}
        </p>

        <p>
          <b>{strings('ui.field.label.website')}:</b>&nbsp;
          {address.url || strings('dashboard.screen.locationDetails.noWebsiteSet')}
        </p>
      </View.Section>
      <View.Section narrow>
        <h3>
          {strings('dashboard.component.newLocationModal.location')}
          <a
            className='pull-right'
            style={editStyle}
            onClick={() => jumpToStep('confirmLocation')}
          >
            {strings('dashboard.component.newLocationModal.edit')}
          </a>
        </h3>
        <StaticMap
          marker={true}
          zoom={17}
          width={425}
          center={marker.center}
        />
      </View.Section>
      <View.Section narrow>
        <div className='flex fdc'>
          <h3>{strings('dashboard.component.newLocationModal.monthlySubscription')}</h3>
          {isEmpty(error) ? (
            renderPlanSummary()
          ) : (
            <p>{error}</p>
          )}
        </div>
      </View.Section>
      {!!hasPerLocationPricing && (
        <View.Section narrow>
          <div className='flex'>
            <p className='mra'>{strings('dashboard.component.newLocationModal.additionalLocation')}</p>
            <p>{strings('dashboard.component.newLocationModal.increaseOnMonthly')}</p>
          </div>
        </View.Section>
      )}
      <View.Section narrow className='flex'>
        <Button
          primary
          onClick={() => step.stepComplete(step.data)}
          className='mt1 mla'
        >
          {strings('dashboard.component.newLocationModal.saveLocation')}
        </Button>
      </View.Section>
      <View.Section narrow>
        <p>{strings('dashboard.label.haveAQuestion')} <InlineSupportLink />.</p>
      </View.Section>
    </View>
  );
};

ConfirmDetailsStep.propTypes = {
  step: PropTypes.object,
  manager: PropTypes.object
};

const wrapOnCancel = onCancel => (value) => {
  /* eslint-disable-next-line max-len */
  if (!confirm(strings('dashboard.component.newLocationModal.wrapOnCancel.confirm'))) return false;
  if (onCancel) return onCancel(value);
  return value;
};

const publishLocation = async (wizardData, manager) => {
  const {
    address: addressFields,
    property,
    business
  } = wizardData;

  if (!manager.pipeline) throw new Error(strings('dashboard.component.newLocationModal.noPipelineFound'));
  const results = await manager.pipeline.run({ addressFields, business, property });

  return results.location;
};

const LOCATION_PRICING_CONFIRMATION = strings('dashboard.component.newLocationModal.priceConfirm');

const LOCATION_DEFAULT_CONFIRMATION = strings('dashboard.component.newLocationModal.defaultConfirm');

const createWizard = ({
  property,
  business,
  history,
  onComplete,
  onCancel,
  hasPerLocationPricing
}) => {
  const [isFreePlan, setisFreePlan] = useState(false);
  const [planName, setPlanName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const addressStep = AddAddress();
  addressStep.getTitle = () => strings('dashboard.component.newLocationModal.addingNewLocation');
  addressStep.extraProps = { desc: null };

  useEffect(() => {
    if (!isEmpty(error)) {
      const Rollbar = window && window.Rollbar;
      if (Rollbar) window.Rollbar.error(error);
    }
  }, [error]);

  useEffect(() => {
    const runEffect = async () => {
      try {
        if (property) {
          const { id, name } = await client
            .properties.for(property.id)
            .businesses.for(business.id)
            .getPlan();
          setisFreePlan(plansViewModel.some(plan => plan.platformId === id && plan.value === 0));
          setPlanName(name);
        }
      } catch (err) {
        setError(`${strings('dashboard.component.newLocationModal.cannotFindPlan')}`);
      }
      setIsLoading(false);
    };
    runEffect();
  }, [business, property]);

  const onCompleteCallback = useCallback(
    async (wizardData, manager) => {
      if (hasPerLocationPricing) {
        const createLocation = confirm(isFreePlan ? LOCATION_DEFAULT_CONFIRMATION : LOCATION_PRICING_CONFIRMATION);

        if (!createLocation) return false;
      }

      const finalLocation = await publishLocation(wizardData, manager);

      await client.properties.for(property.id)
        .businesses.for(business.id)
        .locations.for(finalLocation.id)
        .waitForIndex();

      if (onComplete) return onComplete(finalLocation);
      return null;
    },
    [history, business, isFreePlan]
  );

  const onStartCallback = useCallback((manager) => {
    // Create a new saving pipeline manager each time the wizard is instantiated.
    // eslint-disable-next-line no-param-reassign
    manager.pipeline = new CreateLocationPipeline(client, business);
  }, [history, business]);

  const onCancelCallback = wrapOnCancel(onCancel);

  return new WizardManager({
    initialData: {
      property,
      business,
      address: { country: 'Canada' },
      error,
      isFreePlan,
      planName, // i.e GITL Local Monthly Plan
      isLoading
    },
    steps: [
      addressStep,
      ConfirmMapLocationStep(),
      new WizardStep({
        key: 'confirmDetails',
        label: strings('dashboard.component.newLocationModal.confirm'),
        getTitle: () => strings('dashboard.component.newLocationModal.addingNewLocation'),
        render: ConfirmDetailsStep
      })
    ],
    onStart: onStartCallback,
    onComplete: onCompleteCallback,
    onCancel: onCancelCallback
  });
};

export default createWizard;
