/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useState
} from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import auth from 'shared/src/modules/auth';
import Image from 'web-react-ui/src/components/elements/Image';
import View from 'web-react-ui/src/components/layout/View';
import WizardModal from 'web-react-ui/src/components/wizard/WizardModal';
import TextField from 'web-react-ui/src/reactFinalForm/fields/TextField';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';
import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';
import {
  compose,
  max,
  required
} from 'web-react-ui/src/reactFinalForm/validators';
import plans from '../../assets/data/plans';
import useLanguage from '../../hooks/useLanguage';

import { strings } from '../../i18n';

import useProperty from '../useProperty';
import createWizard from './CreateBusinessWizard';

function RegisterView({ history, isPlanViewable }) {
  const [open, setOpen] = useState(false);
  const [business, setBusiness] = useState({});
  const paymentPlan = {};
  const property = useProperty();
  const [language] = useLanguage();
  const user = useSelector(state => auth.selectors.getUser(state));

  const manager = createWizard({
    property,
    business,
    user,
    paymentPlan,
    history,
    isPlanViewable,
    onComplete: (finalBusiness, planDetails) => {
      const REDIRECT_PARAMS = isPlanViewable ? `?planId=${planDetails.chargebeePlanId}` : '';
      return history.push(`/businesses/register/complete/${finalBusiness.id}${REDIRECT_PARAMS}`);
    },
    onCancel: setOpen,
    locale: language.value
  });


  const submit = useCallback(async (businessData) => {
    const { name } = businessData;
    setBusiness({
      name,
      shortName: name.slice(0, 24)
    });
    setOpen(true);
  }, [manager]);

  return (
    <View>
      <View.Section narrow>
        <SimpleForm onSubmit={submit} showSubmitError>
          {property.logoStandardImageUrl && (
            <Image src={property.logoStandardImageUrl} alt="" style={{ maxWidth: '60%' }} className="mt2" />
          )}
          <h1>{strings('dashboard.component.registerView.addYourBusiness')}</h1>
          <p>{strings('dashboard.component.registerView.needLogo')}</p>
          <TextField
            name="name"
            label={strings('dashboard.component.registerView.field.businessName')}
            info={strings('dashboard.component.registerView.field.businessInfo')}
            wrapLabel
            validate={compose(required, max(64))}
          />
          <div className="flex">
            <FormSubmit className="mla" label={strings('ui.label.next')} />
          </div>
        </SimpleForm>
        {open && (
          <WizardModal
            manager={manager}
            plansViewModel={plans}
            isBusinessSignUp={true}
            submittingChildren={
              <div>
                <p className="lead">
                  {strings('dashboard.component.registerView.isLoading')}
                </p>
                <p className="lead">
                  {strings('dashboard.component.registerView.noRefresh')}
                </p>
              </div>
            }
          />
        )}
      </View.Section>
    </View>
  );
}

RegisterView.propTypes = {
  history: PropTypes.object,
  isPlanViewable: PropTypes.bool
};

export default withRouter(RegisterView);
