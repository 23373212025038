/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  Heading,
  Hide,
  Image,
  Show,
  Spacer,
  Text,
} from '@chakra-ui/react';
import CarouselSlide from '../../Carousel/CarouselSlide';
import newPosts from './assets/newPosts.png';
import newPostsSm from './assets/newPostsSm.png';

const NewPosts = () => {
  return (
    <CarouselSlide
      textAlign="center"
      alignItems="center"
      pb={[0, 0, 0]}
      height="36em"
    >
      <Spacer />
      <Heading>New Posts Every Week</Heading>
      <Text fontSize="2xl" maxW="45ch">
        Once per week, Social AI will generate new social posts and stories for the upcoming week
        based on your live offers, promotions, and loyalty programs.
      </Text>
      <Spacer />
      <Show above="md">
        <Image src={newPosts} />
      </Show>
      <Hide above="md">
        <Image src={newPostsSm} />
      </Hide>
    </CarouselSlide>
  );
};

export default NewPosts;
