import { combineReducers } from 'redux';
import auth from 'shared/src/modules/auth';

import businesses from './modules/businesses';
import property from './modules/property/propertyReducer';
import plan from './modules/plan/planReducer';
import sidebar from './modules/sidebar';
import social from './modules/social/socialReducer';

export default combineReducers({
  [businesses.constants.NAME]: businesses.reducer,
  [auth.constants.NAME]: auth.reducer,
  property,
  plan,
  sidebar,
  social
});
