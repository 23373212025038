import React from 'react';
import {
  Button,
  Flex,
  Heading,
  Text,
  Stack,
} from '@chakra-ui/react';

import Location from 'web-react-ui/src/types/Location.interface';

import IntegrationTerms from './IntegrationTerms';

const AppAuthorization = ({
  location,
  onCancel,
  onAuthorize
}: {
  location: Location,
  onCancel: () => void,
  onAuthorize: () => void,
}): JSX.Element => {
  return (
    <Stack spacing={8} position="relative" w="100%">
      <Heading>Authorize Thryv</Heading>
      <Text fontSize="2xl">
        Connecting Thryv to <em><strong>{location.business?.name} - {location.name}</strong></em> will allow:
      </Text>
      <IntegrationTerms />
      <Flex justify="space-between">
        <Button variant="ghost" color="gray.500" size="lg" onClick={onCancel}>Deny</Button>
        <Button colorScheme="blue" size="lg" onClick={onAuthorize}>Allow</Button>
      </Flex>
    </Stack>
  );
};

export default AppAuthorization;
