import { Flex } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

const PageLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Flex direction="column" gap="3em">
      {children}
    </Flex>
  );
};

export default PageLayout;
