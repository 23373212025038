import {
  chakra,
  Select,
  useToast,
} from '@chakra-ui/react';
import React, {
  ChangeEvent,
  useState,
} from 'react';
import Spinner from 'web-react-ui/src/chakra/Spinner';
import useRequest from '../../hooks/useRequest';

type OnChangeFunction = ({ name, value }: { name: string, value: string }) => Promise<void>;

const SettingSelect = ({
  name, value, options, onChange, disabled,
}: {
  name: string,
  value: any,
  options: Array<{ value: any, label: string }>,
  onChange: OnChangeFunction,
  disabled?: boolean,
}) => {
  const [actualState, setActualState] = useState(value);
  const [selectState, setSelectState] = useState(value);
  const toast = useToast();

  const onChangeRequest = useRequest(
    async (event: ChangeEvent<HTMLSelectElement>): Promise<void> => {
      const newValue = event.target.value;
      setSelectState(newValue);
      try {
        await onChange({ name, value: newValue });
      } catch (error) {
        // revert to initial value
        setSelectState(actualState);
        toast({
          title: `Unable to update ${name} setting`,
          description: error.message || error,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      setActualState(newValue);
    },
    undefined,
    {
      discardError: true,
    },
  );

  return (
    <Spinner loading={onChangeRequest.loading} flex="1">
      <chakra.div flex="1">
        <Select value={selectState} onChange={onChangeRequest.run} isDisabled={disabled}>
          {options.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </Select>
      </chakra.div>
    </Spinner>
  );
};

export default SettingSelect;
