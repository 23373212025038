import React from 'react';
import { Box } from '@chakra-ui/react';
import { FormSpyRenderProps } from 'react-final-form';

import { useHasPerformanceError } from 'shared/src/hooks/useHasPerformanceError';

import Business from 'web-react-ui/src/types/Business.interface';
import BigModal from 'web-react-ui/src/BigModal/BigModal';
import { PerformanceCheckModal } from 'web-react-ui/src/chakra/PerformanceCheckModal';
import EditOfferMenu from 'web-react-ui/src/components/offers/EditOfferMenu';
import LocalizableSimpleForm from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableSimpleForm';
import FormReducer from 'web-react-ui/src/reactFinalForm/FormReducer';
import { atLeastOneCompleteLocalization } from 'web-react-ui/src/reactFinalForm/validators';
import PunchCardOffer from 'web-react-ui/src/components/offers/PunchCardOffer';
import { FormSpy } from 'web-react-ui/src/reactFinalForm';
import Plan from 'web-react-ui/src/types/Plan.interface';

import pipelines from '../../services/pipelines';
import ConnectedTranslationWrapper from '../i18n/ConnectedTranslationWrapper';
import { usePlanState } from '../../hooks/usePlan';

const PunchcardOfferWrapper = ({
  form,
  businessId,
  handlePriorityDisabled,
  plan
}: {
  form: any,
  businessId: string,
  handlePriorityDisabled: (value: boolean) => void,
  plan: Plan
}): JSX.Element => {
  const [hasPerformanceError] = useHasPerformanceError(form.getState());
  return (
    <PunchCardOffer
      businessId={businessId}
      plansHref='../plans'
      handlePriorityDisabled={handlePriorityDisabled}
      hasPerformanceError={hasPerformanceError}
      plan={plan}
    />
  );
};

interface Props {
  business: Business;
  initialValues?: Record<string, any>;
  handleFormChange: (values: Record<string, any>) => void;
  handlePriorityDisabled: (value: boolean) => void;
  handleSubmit: (values: Record<string, any>) => void;
  handleCancel: () => void;
  isLoading: boolean;
  isModalOpen: boolean;
  onModalClose: () => void;
}

const CreateLoyaltyOfferContainer = ({
  business,
  initialValues,
  handleFormChange,
  handlePriorityDisabled,
  handleSubmit,
  handleCancel,
  isLoading,
  isModalOpen,
  onModalClose,
}: Props): JSX.Element => {
  const { plan } = usePlanState();
  const isFreePlan = plan.id === process.env.REACT_APP_PLATFORM_PLAN_ID_COMMUNITY;

  return (
    <BigModal open>
      <ConnectedTranslationWrapper>
        <LocalizableSimpleForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          loading={isLoading}
          validate={pipelines.OfferUpdatePipeline.deprecatedValidateWithOldOfferModel}
          validateLocale={atLeastOneCompleteLocalization([
            'headlineLocalized', 'subHeadlineLocalized', 'detailsLocalized'
          ])}
          chakra
        >
          <BigModal.Contents>
            <BigModal.Header className='p1'>
              <Box maxW='8xl' mx='auto' p={3}>
                <EditOfferMenu handleCancel={handleCancel} isFreePlan={isFreePlan} dashboard />
              </Box>
            </BigModal.Header>
            <BigModal.Body>
              <Box my={5} maxW='8xl' mx='auto'>
                <PerformanceCheckModal
                  isOpen={isModalOpen}
                  onClose={onModalClose}
                />
                <FormSpy subscription={{ values: true }}>
                  {({ values, form }: FormSpyRenderProps & { values: Record<string, any> }) => {
                    if (!Object.keys(values).length) return null;
                    return (
                      <PunchcardOfferWrapper
                        plan={plan}
                        form={form}
                        businessId={business.id}
                        handlePriorityDisabled={handlePriorityDisabled}
                      />
                    );
                  }}
                </FormSpy>
              </Box>
            </BigModal.Body>
          </BigModal.Contents>
          <FormReducer>
            {(values: Record<string, any>) => {
              const type = values && values.offerType;
              if (type) {
                // Get the current form values out of the form so they can be used
                return handleFormChange(values);
              }
              return null;
            }}
          </FormReducer>
        </LocalizableSimpleForm>
      </ConnectedTranslationWrapper>
    </BigModal>
  );
};

export default CreateLoyaltyOfferContainer;
