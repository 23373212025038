import React, {
  useState,
} from 'react';
import _ from 'lodash';
import {
  Box,
  Flex,
  Grid,
  Heading,
  Spacer,
} from '@chakra-ui/react';

import FieldListField from 'web-react-ui/src/reactFinalForm/fields/FieldListField';
import FormError from 'web-react-ui/src/reactFinalForm/FormError';
import SelectField from 'web-react-ui/src/reactFinalForm/fields/SelectField';
import StateCodeFields from 'web-react-ui/src/components/businessSignUp/StateCodeFields';
import LocaleSelector from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocaleSelector';
import LocalizableSimpleForm from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableSimpleForm';
import LocalizableTextField from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableTextField';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';
import DefaultLocaleMessage from 'web-react-ui/src/reactFinalForm/fields/localizableField/DefaultLocaleMessage';
import { TextField } from 'web-react-ui/src/reactFinalForm/fields';
import {
  atLeastOneCompleteLocalization,
  forAllLocales,
  max,
  phoneNumber,
  required,
  validateUrl,
} from 'web-react-ui/src/reactFinalForm/validators';
import ErrorAlert from 'web-react-ui/src/chakra/ErrorAlert';
import useRequest from '../../hooks/useRequest';
import { useI18Next } from '../contexts/I18NextContext';
import { COUNTRIES } from '../../constants/countries';
import {
  formatSocial,
  parseSocial,
  SOCIAL_PROFILE_FIELDS,
} from '../../constants/socialProfiles';
import MapField from './MapField';
import FormDebugger from 'web-react-ui/src/reactFinalForm/FormDebugger';

const EditLocation = ({ fetchLocation, onUpdate, children }: {
  fetchLocation: () => Promise<any>,
  onUpdate: (originalLocation: any, updates: any) => Promise<void>,
  children: React.ReactNode,
}) => {
  const { strings } = useI18Next();

  const [originalLocation, setOriginalLocation] = useState(null);

  const fetchRequest = useRequest(
    async () => {
      const location = await fetchLocation();
      setOriginalLocation(_.cloneDeep(location));
      return location;
    },
    [],
  );

  const updateRequest = useRequest(
    (formData) => {
      return onUpdate(originalLocation, formData);
    },
  );

  return (
    <LocalizableSimpleForm
      onSubmit={updateRequest.run}
      initialValues={fetchRequest.result}
      loading={fetchRequest.loading || updateRequest.loading}
      validateLocale={atLeastOneCompleteLocalization(['nameLocalized'])}
    >
      <Flex w="100%" pb="4rem">
        <Heading>
          {strings('dashboard.screen.editLocationView.editLocation')}
        </Heading>
        <Spacer />
        <FormSubmit
          label={strings('dashboard.screen.editLocationView.field.label.save')}
          ignoreValidity
        />
      </Flex>

      <ErrorAlert error={fetchRequest.error} />

      <Grid
        gap="2em"
        maxW={{ base: 'container.sm', lg: 'container.lg' }}
        m="auto"
        templateColumns={{ base: '1fr', lg: '1fr 1fr' }}
      >
        <Box gridColumn="1">
          <div className="flex fdr jcc aic">
            <LocaleSelector inline className="mla" />
          </div>
          <DefaultLocaleMessage localeInfoKey="_localization" />
          <FormError />
          <LocalizableTextField
            wrapLabel
            label={strings('dashboard.screen.editLocationView.field.label.name')}
            name="nameLocalized"
            placeholder={strings('dashboard.screen.editLocationView.field.label.name')}
            validate={forAllLocales(max(64))}
          />
          <TextField
            placeholder="604-555-1212"
            wrapLabel
            name="phone"
            label={strings('dashboard.screen.editLocationView.field.label.number')}
            optional
            validate={phoneNumber}
            info={strings('dashboard.screen.editLocationView.field.placeholder.number')}
          />
          <TextField
            wrapLabel
            name="url"
            label={strings('ui.field.label.website')}
            optional
            validate={validateUrl}
            info={strings('ui.field.url.info')}
            parse={v => v}
          />
        </Box>

        <Box gridColumn="1">
          <div className="flex">
            <div className="mr1 fg1">
              <TextField
                placeholder="1234"
                validate={required}
                wrapLabel
                name="address.streetAddress"
                label={strings('dashboard.screen.editLocationView.field.label.streetNumber')}
              />
            </div>
            <div className="fg2">
              <TextField
                placeholder="Oak St."
                validate={required}
                wrapLabel
                name="address.street"
                label={strings('dashboard.screen.editLocationView.field.label.streetName')}
              />
            </div>
          </div>
          <div className="flex mt1">
            <div className="mr1 fg1">
              <TextField
                wrapLabel
                name="address.suite"
                optional
                label={strings('dashboard.screen.editLocationView.field.label.suite')}
              />
            </div>
            <div className="fg3">
              <TextField
                placeholder="Toronto"
                validate={required}
                wrapLabel
                name="address.city"
                label={strings('dashboard.screen.editLocationView.field.label.city')}
              />
            </div>
          </div>
          <div className="flex mt1">
            <StateCodeFields name="address" />
          </div>
          <div className="mt1">
            <SelectField
              validate={required}
              search
              wrapLabel
              name="address.country"
              label={strings('ui.field.label.country')}
              autoSelectFirst
              fluid
              options={COUNTRIES}
            />
          </div>
        </Box>
        {fetchRequest.result && (
          <Box gridArea={{ base: 'auto', lg: '1 / 2 / 4 / 3' }}>
            <MapField name="address.centre" />
          </Box>
        )}
        <Box gridColumn="1">
          <Heading size="md" mb={2}>Social Media Profiles</Heading>
          <FieldListField
            name="socialProfiles"
            addButtonLabel="Add"
            fields={SOCIAL_PROFILE_FIELDS}
            parse={parseSocial}
            format={formatSocial}
          />
        </Box>
        {children}
        <FormDebugger />
      </Grid>
    </LocalizableSimpleForm>
  );
};

export default EditLocation;
