import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import BigModal from 'web-react-ui/src/BigModal/BigModal';
import EditOfferMenu from 'web-react-ui/src/components/offers/EditOfferMenu';
import { FormSpy } from 'web-react-ui/src/reactFinalForm';
import LocalizableSimpleForm from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableSimpleForm';
import { atLeastOneCompleteLocalization } from 'web-react-ui/src/reactFinalForm/validators';
import Offer from 'web-react-ui/src/types/Offer.interface';

import businessesModule from '../../modules/businesses';
import client from '../../services/client';
import pipelines from '../../services/pipelines';
import propertyResolver from '../../services/propertyResolver';
import ConnectedTranslationWrapper from '../i18n/ConnectedTranslationWrapper';
import OfferTypeSelectionWrapper from './OfferTypeSelectionWrapper';
import { usePlanAdvancedSchedule, usePlanState } from '../../hooks/usePlan';

interface Props {
  returnTo: string;
  offerId: string;
}
const DuplicateOfferContainer = ({
  returnTo,
  offerId,
}: Props): JSX.Element => {
  const business = useSelector(state => businessesModule.selectors.business.getData(state));
  const [redirect, setRedirect] = useState<string>();
  const [instance, setInstance] = useState<any>(null);
  const history = useHistory();
  const { plan } = usePlanState();
  const allowsAdvancedSchedule = usePlanAdvancedSchedule();
  const isFreePlan = plan.id === process.env.REACT_APP_PLATFORM_PLAN_ID_COMMUNITY;

  useEffect(() => {
    initialOffer().then(offerEdit => setInstance(offerEdit));
  }, []);

  const handleCancel = () => {
    if (history.length <= 2) return history.replace(returnTo);
    return history.goBack();
  };

  const handleSubmit = async (offer: Offer) => {
    // TODO: Pull out EditOfferPipeline instance values individually?
    // Form EditOfferForm needs to be updated to look for instance elements separately instead of under `offer`
    await instance.deprecatedUpdateWithOldOfferModel(offer);
    const { id } = instance.getInstance('offer');
    const offerDetailsUrl = `/business/${business.id}/offers/${id}`;
    setRedirect(offerDetailsUrl);
  };

  const initialOffer = async () => {
    const property = propertyResolver();
    if (!offerId) return null;

    return client
      .properties.for(property.id)
      .businesses.for(business.id)
      .offers.for(offerId)
      .duplicate(allowsAdvancedSchedule);
  };

  const getOfferType = (flags: string[]): string => {
    if (flags.includes('punchcard')) return 'punchcard';
    if (flags.includes('limited')) return 'limited';
    if (flags.includes('exclusive')) return 'exclusive';
    return 'reach';
  };

  if (redirect) return <Redirect to={redirect} />;

  let initialValues = null;

  // TODO properly handle this in the SDK by passing an object with instances AND updates
  if (instance) {
    initialValues = instance.deprecatedGetOldOfferModel();
    instance.setInstance('discoverableRegions', []);
    instance.setInstance('locations', []);
  }

  return (
    <BigModal open>
      <ConnectedTranslationWrapper>
        <LocalizableSimpleForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          loading={!initialValues}
          validate={pipelines.OfferUpdatePipeline.deprecatedValidateWithOldOfferModel}
          validateLocale={atLeastOneCompleteLocalization([
            'headlineLocalized', 'subHeadlineLocalized', 'detailsLocalized'
          ])}
        >
          <FormSpy subscription={{ values: true }}>
            {({ values }: { values: Record<string, any> }) => {
              if (!Object.keys(values).length) return null;
              return (
                <BigModal.Contents>
                  <BigModal.Header className='p1'>
                    <Box maxW='8xl' mx='auto' p={3}>
                      <EditOfferMenu
                        handleCancel={handleCancel}
                        isEdit={true}
                        isFreePlan={isFreePlan}
                        existingOfferType={getOfferType(values.flags)}
                        dashboard
                      />
                    </Box>
                  </BigModal.Header>
                  <BigModal.Body>
                    <Box my={5} maxW='8xl' mx='auto'>
                      <OfferTypeSelectionWrapper
                        businessId={business.id}
                        existingOfferType={getOfferType(values.flags)}
                        plan={plan}
                        plansHref='../../plans'
                      />
                    </Box>
                  </BigModal.Body>
                </BigModal.Contents>
              );
            }}
          </FormSpy>
        </LocalizableSimpleForm>
      </ConnectedTranslationWrapper>
    </BigModal>
  );
};

export default DuplicateOfferContainer;
