import React from 'react';
import {
  Button,
  Center,
  Heading,
  Image,
  VStack,
  Text,
  Spacer,
  useDisclosure,
  Box,
  Link,
  AlertDescription,
  AlertIcon,
  Alert,
  Flex,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  transport,
  useQuerystringParam,
} from 'shared/src/hooks/useQuerystringParam';

import heroImage from '../assets/socialAIBeta.png';
import ProServicesAlert from '../components/ProServicesAlert';
import Profile from '../interfaces/Profile.interface';
import SocialSignupPrerollModal from './prerollSlideshow/SocialSignupPrerollModal';

const SocialViewNotSignedUp = () => {
  const profile = useSelector<{ social: { profile: Profile } }>(state => state.social.profile) as Profile;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [signup, setSignup] = useQuerystringParam('signup', false, { transport: transport.boolean, squash: true });

  return (
    <Center>
      <SocialSignupPrerollModal isOpen={isOpen} onClose={onClose} />
      <VStack gap="3em" marginTop="6em">
        <Image src={heroImage} />

        <Heading size="lg" sx={{ textAlign: 'center' }}>
          Create Engaging Social Media Posts, Automatically
        </Heading>

        <Text fontSize="xl" align="center" maxWidth="70ch">
          GetintheLoop’s Social AI generates engaging Facebook and Instagram posts and stories based on
          your offers, promotions, and loyalty programs. Unleash the power of AI
          to supercharge your social media, increase engagement, and drive
          meaningful interactions. The best part? It’s automatic, no extra work
          required!
        </Text>

        {profile?.missingPermissions
          ? (
            <Alert status="warning" fontSize="lg">
              <Flex w="100%">
                <AlertIcon />
                <Flex direction="column" gap="1em" align="center" w="100%">
                  <AlertDescription alignSelf="start">
                    You must be a Business Owner, Manager, or Marketing role to setup Social AI.
                  </AlertDescription>
                  <Button colorScheme="blue" as={RouterLink} to="./team">
                    Check Permissions
                  </Button>
                  <Button
                    colorScheme="blue"
                    as="a"
                    href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                    target="_blank"
                  >
                    Contact Support
                  </Button>
                </Flex>
              </Flex>
            </Alert>
          )
          : (
            <Button
              size="lg"
              colorScheme="blue"
              onClick={onOpen}
            >
              Setup Social AI
            </Button>
          )
        }


        <Box textAlign="center" color="gray.500">
          <Text fontSize="14px">Unsure if Social AI is right for you?</Text>
          <Text fontSize="14px">See <Link
            as="button"
            onClick={() => setSignup(true)}
            color="blue.600"
          >What To Expect</Link> before you start setup.</Text>
        </Box>

        <Spacer />

        <ProServicesAlert />
      </VStack>
    </Center>
  );
};

export default SocialViewNotSignedUp;
