import {
  useMemo,
  useState
} from 'react';
import useClient from '../useClient';
import useChargebee from './useChargebee';

const fetchHostedPage = async ({ propertyId, businessId, planId, client }) => {
  return client
    .properties.for(propertyId)
    .businesses.for(businessId)
    .checkout({ itemPriceId: planId });
};

const useChargebeeCheckoutHostedPage = (propertyId, businessId) => {
  const client = useClient();
  const [error, setError] = useState();
  const chargebeeInstance = useChargebee();

  const openCheckout = useMemo(
    () => {
      if (!propertyId || !businessId) return undefined;
      return ({ planId }, chargebeeCallbacks = {}) => {
        return chargebeeInstance.openCheckout({
          hostedPage: () => fetchHostedPage({ propertyId, businessId, planId, client }),
          error: setError,
          ...chargebeeCallbacks
        });
      };
    },
    [propertyId, businessId, chargebeeInstance]
  );

  return [openCheckout, chargebeeInstance, error];
};

export default useChargebeeCheckoutHostedPage;
