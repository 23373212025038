import {
  SOCIAL_PROFILE_SET,
  SOCIAL_PROFILE_INVALIDATE,
} from './socialActions';

const initialState /* :{ networksRequest: Request<{}[]>, profileRequest: Request<{}> } */ = {
  profile: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SOCIAL_PROFILE_SET:
      return {
        ...state,
        profile: action.payload
      };
    case SOCIAL_PROFILE_INVALIDATE:
      return {
        ...state,
        profile: null
      };
    default:
      return state;
  }
}
