const PLAN_ACTIONS = {
  select: 'select',
  contact: 'contact',
  upgrade: 'upgrade',
  currentPlan: 'currentPlan',
  selected: 'selected',
  notAvailable: 'notAvailable'
} as const;

export default PLAN_ACTIONS;
