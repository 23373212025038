/* eslint-disable import/prefer-default-export */

export const SOCIAL_PROFILE_SET = 'social:profileSet';
export const SOCIAL_PROFILE_INVALIDATE = 'social:profileInvalidate';

// eslint-disable-next-line arrow-parens
export const fetchProfile = (payload) => ({
  type: SOCIAL_PROFILE_SET,
  payload
});

export const invalidateProfile = () => ({
  type: SOCIAL_PROFILE_INVALIDATE
});
