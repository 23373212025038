import Post from '../interfaces/Post.interface';
import getContentTypeProps from './getContentTypeProps';

const getPostPageTitle = (post: Post) => (
  [
    post?.campaignName,
    post?.contentType
      ? getContentTypeProps(post.contentType).label
      : undefined,
    'Details',
  ]
    .filter(Boolean)
    .join(' ')
);

export default getPostPageTitle;
