import {
  Button,
  Box,
  Flex,
  Image,
} from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import React from 'react';
import LMGlogo from '../../assets/gitl_logo.png';

const width = '480px';
const spacing = '40px';

const Back = ({ url }: { url: string }): JSX.Element => (
  <Button
    variant='link'
    as='a'
    href={url}
    leftIcon={<ChevronLeftIcon />}
    justifySelf='flex-start'
    alignSelf='flex-start'
    mb={10}
    fontSize='xl'
    colorScheme='blue'
  >
    Back
  </Button>
);

const SsoLayout = ({
  children,
  returnUrl,
  footer
}: {
  children: React.ReactNode,
  returnUrl?: string,
  footer?: React.ReactNode
}): JSX.Element => {
  const bgColor = { base: 'white', md: 'gray.50' };
  return (

    <Flex justify="center" bg={bgColor} height="100vh">
      <Flex align="stretch" maxW="1920px">
        <Flex
          align="center"
          justify="center"
          direction="column"
          w={{ base: '100%', md: '50%' }}
          minW={{ md: width }}
          bg={bgColor}
        >
          {returnUrl && (
            <Back url={returnUrl} />
          )}
          <Box
            px={{ base: '12px', md: spacing }}
            py={{ base: spacing }}
            w="full"
            maxW={width}
            bg="white"
            rounded="8px"
            boxShadow={{ base: 0, md: 'base' }}
            textAlign="left"
          >
            <Box mb={spacing} width="146px">
              <Image src={LMGlogo} alt="lmg logo" fit="contain" />
            </Box>
            {children}
          </Box>
          {footer}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SsoLayout;
