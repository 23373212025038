import {
  Avatar,
  Box,
  Flex,
  Heading,
  Stack
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

const StepLabel = ({
  step,
  label,
  active,
  size = 'sm'
}) => {
  return (
    <Flex flexDir="column" align="center" textAlign="center">
      <Avatar
        name={step.toString()}
        size={size}
        bg={active ? 'blue.800' : 'white'}
        showBorder={!active}
        borderColor="gray.300"
        borderWidth={active ? undefined : '1px'}
        color={active ? 'white' : 'gray.500'}
      />
      <Heading
        fontSize="0.75em"
        color={active ? 'black' : 'gray.500'}
        fontWeight={active ? 500 : 300}
        mt={3}
      >
        {label}
      </Heading>
    </Flex>
  );
};

StepLabel.propTypes = {
  step: PropTypes.number,
  label: PropTypes.string,
  active: PropTypes.bool,
  size: PropTypes.string,
};

const Stepper = ({ steps, active, labels, size }) => {
  return (
    <Stack direction="row" maxW="3xl" w="100%">
      {steps.map((step, index) => {
        const stepDef = labels[step.key];
        return (
          <React.Fragment key={step.key}>
            <StepLabel step={index + 1} label={stepDef?.label} active={active === index} size={size} />
            {index !== steps.length - 1 && (
              <Box h="1px" w="100%" borderBottom="1px" borderColor="gray.300" pt={4} />
            )}
          </React.Fragment>
        );
      })}
    </Stack>
  );
};

Stepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object),
  activeIndex: PropTypes.number,
  size: PropTypes.string
};

export default Stepper;
