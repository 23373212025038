/* eslint-disable-next-line no-redeclare */
/* global window */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import usePermissions from 'shared/src/hooks/usePermissions';
import useChargebee from 'shared/src/hooks/chargebee/useChargebee';
import useBillingDetails from '../../../hooks/useBillingDetails';
import { usePlanState } from '../../../hooks/usePlan';
import client from '../../../services/client';
import useProperty from '../../useProperty';
import BusinessSettingsView from './BusinessSettingsView';

const BusinessSettingsContainer = ({ business, isPlanViewable }) => {
  const [counter, setCounter] = useState(0);
  const { plan } = usePlanState(business.id);
  const property = useProperty();
  const {
    hasPermissions,
    isLoading,
    billingDetails,
    error
  } = useBillingDetails({ propertyId: property.id, businessId: business.id });
  const cbInstance = useChargebee();

  const showAppInstalls = usePermissions(
    { businessId: business.id },
    ['business.app.install.list']
  );

  const onChange = () => {
    const sectionTypes = window.Chargebee.getPortalSections();
    const cbPortal = cbInstance.createChargebeePortal();
    cbInstance.setPortalSession(
      () => client.properties.for(property.id).businesses.for(business.id).getPortalSession()
    );
    cbPortal.open(
      // we won't know if there was a change in the widget, so when it closes we need to re-fetch the billing address
      {
        close() {
          setCounter(counter + 1);
          cbInstance.logout();
        }
      },
      { sectionType: sectionTypes.ADDRESS }
    );
  };

  return (
    <BusinessSettingsView
      business={business}
      plan={plan}
      showBilling={hasPermissions}
      billing={{ billingAddress: billingDetails?.billingAddress, isLoading, error }}
      subscription={billingDetails?.subscription}
      isPlanViewable={isPlanViewable}
      showAppInstalls={showAppInstalls}
      onChange={onChange}
    />
  );
};

BusinessSettingsContainer.propTypes = {
  business: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  isPlanViewable: PropTypes.bool
};

export default BusinessSettingsContainer;
