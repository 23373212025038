import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
} from '@chakra-ui/react';

const Dropdown = (props) => {
  const { buttonText, children, icon, placement, hideArrow, width, onClose, isOpen, onOpen } = props;
  return (
    <Popover placement={placement} isOpen={isOpen} onOpen={onOpen} onClose={onClose} closeOnBlur={true}>
      <PopoverTrigger>
        <Button
          variant='outline'
          leftIcon={icon && <Icon w={6} h={6} as={icon} />}
        >
          {buttonText}
        </Button>
      </PopoverTrigger>
      <PopoverContent width={width}>
        {!hideArrow && <PopoverArrow />}
        <PopoverBody>
          {children}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

Dropdown.defaultProps = {
  hideArrow: false,
  width: '300px',
  placement: 'bottom',
};

Dropdown.propTypes = {
  buttonText: PropTypes.string.isRequired,
  icon: PropTypes.any,
  placement: PropTypes.oneOf(['top', 'left', 'right', 'bottom']),
  hideArrow: PropTypes.bool,
  width: PropTypes.string,
};

export default Dropdown;
