/* eslint-env browser */

let propertyDetailsPromise;

const resolveProperty = () => {
  if (!propertyDetailsPromise) {
    if (!window.lmgDomainConfig.property) throw new Error('Missing domain config');
    return window.lmgDomainConfig.property;
  }
  return propertyDetailsPromise;
};

export default resolveProperty;
