/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Hide,
  Image,
  Show,
  Text,
} from '@chakra-ui/react';
import CarouselSlide from '../../Carousel/CarouselSlide';
import getNotified from './assets/getNotified.png';
import getNotifiedSm from './assets/getNotifiedSm.png';

const GetNotified = () => (
  <CarouselSlide
    textAlign={['center', undefined, 'right']} pb={[0, 0, 0]}
    height="36em"
  >
    <Flex direction={['column', undefined, 'row']} alignItems="center" height="100%">
      <Flex direction="column" gap="2em" py={['1em', undefined, '4em']}>
        <Heading>Get Notified Before Posts Go Live</Heading>
        <Text fontSize="2xl">
          All Business Owner, Manager, or Marketing users will be sent
          the upcoming social content by email for review.
          This email will be sent at least 48 hours before the content is scheduled to go live.
        </Text>
      </Flex>
      <Show above="md">
        <Image
          src={getNotified}
          maxWidth={['100%', undefined, '50%']}
          alignSelf={['center', undefined, 'end']}
          bottom="-3em"
          position="relative"
        />
      </Show>
      <Hide above="md">
        <Image
          src={getNotifiedSm}
          maxWidth={['100%', undefined, '50%']}
          alignSelf={['center', undefined, 'end']}
          bottom="-3em"
          position="relative"
        />
      </Hide>
    </Flex>
  </CarouselSlide>
);

const Supplement = () => (
  <Flex direction="column" alignItems="center" fontSize="text-md" color="gray.400" textAlign="center">
    <Text>Don’t see the email?</Text>
    <Text>
      Check your spam folder and make sure to let email
      from <Box whiteSpace="nowrap" as="span">no-reply@getintheloop.ca</Box> through.
    </Text>
  </Flex>
);

GetNotified.Supplement = Supplement;

export default GetNotified;
