import { useState, useEffect } from 'react';

const useCheckedPlans = (data, planId) => {
  const [plans, setPlans] = useState([]);
  const [checkedPlanIds, setCheckedPlanIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasCheckedPlans, setHasCheckedPlans] = useState(false);

  useEffect(() => {
    if (data?.items?.length) {
      setPlans(data.items.map((item) => {
        return {
          ...item,
          isChecked: item.id === planId
        };
      }));
    }
  }, [data, planId]);

  useEffect(() => {
    setCheckedPlanIds(plans.filter(item => item.isChecked).map(item => item.id));
    setIsLoading(false);
  }, [plans]);

  useEffect(() => {
    if (!isLoading) {
      if (planId) {
        if (checkedPlanIds.length > 0) {
          setHasCheckedPlans(true);
        }
      } else {
        setHasCheckedPlans(true);
      }
    }
  }, [isLoading, checkedPlanIds, planId]);

  const handleUnCheckedPlan = (plan) => {
    setPlans(prevState => prevState.map(item => ({
      ...item,
      ...(item.id === plan.id) && {
        isChecked: !item.isChecked
      }
    })));
  };
  return [plans, setPlans, handleUnCheckedPlan, checkedPlanIds, hasCheckedPlans];
};

export default useCheckedPlans;
