/* eslint-disable react/no-unescaped-entities */
import { UseDisclosureReturn } from '@chakra-ui/hooks/src/use-disclosure';
import React from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  ListItem,
  Spacer,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import CarouselSlide from '../../Carousel/CarouselSlide';

const ExternalLink = ({ href, children }) => (
  <Link sx={{ color: 'blue.500' }} href={href} target="_blank" rel="noreferrer">{children}</Link>
);


const Ready = ({ context }: { context: { confirmDisclosure: UseDisclosureReturn, prerollClose: () => void } }) => (
  <CarouselSlide alignItems="center" textAlign="center" cursor="arrow">
    <Spacer />
    <Heading>Ready to Link?</Heading>
    <Text fontSize="2xl">Before you start, let's confirm you're setup for success:</Text>
    <UnorderedList stylePosition="inside">
      <ListItem fontSize="2xl">
        You have Full Control over the Facebook Page or Instagram account you're connecting
      </ListItem>
      <ListItem fontSize="2xl">
        You have disabled any pop-up blockers in your browser
      </ListItem>
    </UnorderedList>
    &nbsp;
    <Button size="lg" onClick={context.confirmDisclosure.onOpen} colorScheme="blue">
      Link Social Accounts
    </Button>
    <Button variant="ghost" onClick={context.prerollClose}>
      Not Now
    </Button>
    <Spacer />
  </CarouselSlide>
);

const Supplement = () => (
  <Flex direction="column" gap="2em" maxW="90ch">
    <Flex
      justifyContent="space-around"
      direction={['column', undefined, 'row']}
      gap="1em"
      fontSize="text-lg"
      color="gray.700"
    >
      <Box>
        <Text pb="1em" fontWeight="bold">Facebook Requirements</Text>
        <UnorderedList>
          <ListItem>
            <ExternalLink href="https://www.facebook.com/help/187316341316631/">
              Full Control
            </ExternalLink> of the Facebook Page
          </ListItem>
        </UnorderedList>
      </Box>
      <Box>
        <Text pb="1em" fontWeight="bold">Instagram Requirements</Text>
        <UnorderedList>
          <ListItem>
            Instagram <ExternalLink href="https://www.facebook.com/business/help/502981923235522">
            Business or Creator </ExternalLink> account*
          </ListItem>
          <ListItem>
            <ExternalLink href="https://www.facebook.com/business/help/898752960195806">
              Connected
            </ExternalLink> to a Facebook Business Page*
          </ListItem>
          <ListItem>Full Control of the connected Facebook Page</ListItem>
        </UnorderedList>
      </Box>
    </Flex>

    <Text textAlign="center" fontSize="text-md" color="gray.400" fontWeight="normal">
      * Instagram does not currently provide 3rd party access to regular accounts or accounts
      not connected to a Facebook Page.
      Having a Business or Creator account connected to a Facebook Page is required
      by Instagram to use any 3rd party tools like Social AI.
    </Text>
  </Flex>
);

Ready.Supplement = Supplement;

export default Ready;
