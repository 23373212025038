import {
  Flex,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import React, {
  useMemo,
} from 'react';
import timezones from 'shared/src/constants/timezones';
import useClient from '../../hooks/useClient';
import Profile from '../../interfaces/Profile.interface';
import SettingSelect from './SettingSelect';

const GeneralSettings = ({ profile, businessId }: { profile: Profile, businessId: string }) => {
  const client = useClient();

  const timezoneOptions = useMemo(
    () => {
      const tzNames = timezones;

      // If some other timezone is set, it's obviously valid to allow it
      if (!tzNames.includes(profile.timezone)) {
        tzNames.unshift(profile.timezone);
      }

      return tzNames.map((tz: string) => ({ label: tz, value: tz }));
    },
    [profile],
  );

  const onChange = useMemo(
    () => async ({ name, value }: { name: string, value: any }) => {
      await client.businesses.for(businessId).social.updateProfile({
        timezone: value,
      });
    },
    [],
  );

  return (
    <Flex direction="column" gap="1em">
      <FormControl display="flex">
        <FormLabel fontSize="lg" w="50%">Timezone</FormLabel>
        <SettingSelect
          name="timezone"
          value={profile.timezone}
          onChange={onChange}
          options={timezoneOptions}
        />
      </FormControl>

    </Flex>
  );
};

export default GeneralSettings;
