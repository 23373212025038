import {
  Box,
  Flex,
  Image,
  Spacer,
  Heading,
  Text,
  Tooltip,
  Tag,
  TagLabel,
  HStack,
} from '@chakra-ui/react';
import React from 'react';
import {
  BiChevronLeft,
  BiCog,
  BiGridAlt,
  BiHelpCircle,
  BiLineChart,
  BiLogOut,
  BiMap,
  BiPurchaseTag,
  BiSlider,
  BiStore,
  BiUser,
} from 'react-icons/bi';
import {
  PiMegaphoneBold,
} from 'react-icons/pi';
import {
  connect,
  useSelector,
} from 'react-redux';
import {
  Link,
  useLocation,
} from 'react-router-dom';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import usePromise from 'shared/src/hooks/usePromise';
import auth from 'shared/src/modules/auth';

import { NavGroup } from 'web-react-ui/src/chakra/shell/NavGroup';
import {
  NavItem,
  NavLinkItem,
} from 'web-react-ui/src/chakra/shell/NavItem';
import ImageRenderer from 'web-react-ui/src/components/image/ImageRenderer';

import businessesModule from './modules/businesses';
import {
  activeTabSelector,
  LOYALTY,
  PROMOTIONS,
} from './modules/sidebar';
import membershipCard from './components/offers/membership-card.svg';
import client from './services/client';
import propertyResolver from './services/propertyResolver';

const fetchBusinesses = async ({ query, enabled }: { query?: string, enabled?: boolean }) => {
  const property = propertyResolver();

  const hasPermissions = await client.access.checkPermissions(
    { propertyId: property.id },
    ['property.business.search'],
  )
    .catch(() => false);

  // Ok, user has access to zero businesses...
  if (!hasPermissions) return { items: [] };

  return client
    .properties.for(property.id)
    .businesses.list({ query, enabled, hasRoles: true })
    .catch((error: any) => {
      // UI will show error message but log the error to the console for debugging
      /* eslint-disable-next-line no-console */
      console.error(error);

      // We only care about the return value
      // TODO: Consider option to make `usePromise` swallow rejections
      return null;
    });
};

const BusinessHeader = ({ title, logo }: { title: string, logo: string }): JSX.Element => (
  <Flex pb={8} align="center">
    <Box mr={4}>
      <ImageRenderer imageUrl={logo} name={title} size="sm" />
    </Box>
    <Text layerStyle={'defaultFont.size'}>{title}</Text>
  </Flex>
);

interface Props {
  handleLogout: () => void;
  title: string;
  logo: string;
  isOwner: boolean;
}

const AppSidebar = ({ handleLogout, title, logo, isOwner }: Props): JSX.Element => {
  const { strings } = useI18Next();
  const business = useSelector(state => businessesModule.selectors.business.getData(state));
  const user = useSelector(state => auth.selectors.getUser(state));
  const activeOfferTab = useSelector(state => activeTabSelector(state));

  const [, , businesses] = usePromise(
    () => fetchBusinesses({ enabled: true }), [], undefined, true,
  );

  const location = useLocation();

  const settingsPaths = [
    `/business/${business?.id}/settings`,
    `/business/${business?.id}/plans`,
  ];

  const LoyaltyIcon = () => (
    <Image src={membershipCard} boxSize="14px" />
  );

  return (
    <Flex flexDirection="column" height="100%">
      {business ? (
        <React.Fragment>
          {isOwner && businesses?.items?.length > 0 && (
            <Flex as={Link} mb={8} align="center" to="/">
              <BiChevronLeft />&nbsp;
              <Text layerStyle={'defaultFont.size'}>{strings('ui.component.appSidebar.backToDashboard')}</Text>
            </Flex>
          )}
          <NavGroup
            label={
              <Flex justify="space-between" align="center" mb={4}>
                <Text>{strings('Business')}</Text>
                {businesses?.items?.length > 1 && (
                  <Link to="/businesses">{strings('ui.component.appSidebar.change')}</Link>
                )}
              </Flex>
            }
          >
            <BusinessHeader title={title} logo={logo} />
            <NavLinkItem
              to={`/business/${business.id}/loyalty`}
              exact={false}
              icon={<LoyaltyIcon />}
              label={'Loyalty'}
              activeItem={activeOfferTab === LOYALTY}
            />
            <NavLinkItem
              to={`/business/${business.id}/promotions`}
              exact={false}
              icon={<BiPurchaseTag />}
              label={'Promotions'}
              activeItem={activeOfferTab === PROMOTIONS}
            />
            <NavLinkItem
              to={`/business/${business.id}/dashboard`}
              icon={<BiLineChart />}
              label={strings('ui.component.appSidebar.performance')}
            />
            <NavLinkItem
              to={`/business/${business.id}/social`}
              icon={<PiMegaphoneBold />}
              label={
                <HStack spacing="2em">
                  <Box>Social AI</Box>
                  {process.env.REACT_APP_FLAG_SOCIAL_AI === 'true'
                    ? null
                    : (
                      <Tag colorScheme="blue">
                        <TagLabel>BETA</TagLabel>
                      </Tag>
                    )
                  }
                </HStack>
              }
            />
            <NavLinkItem
              to={`/business/${business.id}/locations`}
              icon={<BiMap />}
              label={strings('ui.component.appSidebar.locations')}
              exact={false}
            />
            <NavLinkItem
              to={`/business/${business.id}/team`}
              icon={<BiUser />}
              label={strings('ui.component.appSidebar.team')}
            />
            <NavLinkItem
              to={`/business/${business.id}/settings`}
              icon={<BiCog />}
              label={strings('ui.component.appSidebar.settings')}
              activeItem={settingsPaths.includes(location.pathname)}
              exact={false}
            />
          </NavGroup>
        </React.Fragment>
      ) : (
        <NavGroup label={strings('ui.component.appSidebar.overview')}>
          {isOwner ? (
            <NavLinkItem
              to="/dashboard"
              exact={false}
              icon={<BiGridAlt />}
              label={strings('ui.component.appSidebar.dashboard')}
            />
          ) : <></>}
          <NavLinkItem to={`/businesses`} icon={<BiStore />} label={strings('ui.component.appSidebar.businesses')} />
        </NavGroup>
      )}

      <Spacer />
      {user && (
        <Box mb="5px">
          <Heading
            fontSize="md"
            fontWeight="600"
            color="gray.500"
            textTransform="uppercase"
            mb="20px"
          >
            {strings('ui.component.appSidebar.account')}
          </Heading>
          <Tooltip hasArrow label={user.email} placement="top-end">
            <Text fontSize="md" color="gray.500" pl="9px" isTruncated>{user.email}</Text>
          </Tooltip>
        </Box>
      )}
      <NavGroup>
        <NavLinkItem
          to="/preferences"
          icon={<BiSlider />}
          label={strings('ui.component.appSidebar.accountSettings')}
        />
        <NavItem
          icon={<BiHelpCircle />}
          label={strings('ui.component.appSidebar.support')}
          as="a"
          href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
        />
        <NavItem icon={<BiLogOut />} label={strings('ui.component.appSidebar.logout')} onClick={handleLogout} />
      </NavGroup>
    </Flex>

  );
};

const mapDispatch = { handleLogout: auth.actions.logout };

export default connect(null, mapDispatch)(AppSidebar);
