/*
Polyfill for Promise.allSettled

This was copy & pasted from js-base. Could be replaced with
that version at some point...
*/
// eslint-disable-next-line import/prefer-default-export
export const allSettled = async list => new Promise((resolve) => {
  const results = [];
  const saveResults = (res) => {
    if (!res) throw new Error('Cannot have empty results');
    results.push(res);
    if (results.length !== list.length) return;
    resolve(results
      .sort((a, b) => (a.order - b.order))
      // eslint-disable-next-line no-param-reassign
      .map((i) => { delete i.order; return i; }));
  };
  list.forEach((p, i) => {
    p.then(r => saveResults({ status: 'fulfilled', value: r, order: i }))
      .catch(e => saveResults({ status: 'rejected', reason: e, order: i }));
  });
});
