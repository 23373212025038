import React from 'react';
import { Box, ListItem, UnorderedList, Stack, StackDivider, Text } from '@chakra-ui/react';

const IntegrationTerms = () => (
  <Stack spacing={5} divider={<StackDivider />} borderTop='1px' borderBottom='1px' borderColor='gray.100' py={5}>
    <Box fontSize='xl'>
      <Text fontSize='xl' mb={2} fontWeight={600}>Thryv</Text>
      <UnorderedList>
        <ListItem>
          Will receive updates on offer usage and customer activity from GetintheLoop
        </ListItem>
      </UnorderedList>
    </Box>
    <Box fontSize='xl'>
      <Text fontSize='xl' mb={2} fontWeight={600}>GetintheLoop</Text>
      <UnorderedList>
        <ListItem>
          Will access listing and account details from Thryv
        </ListItem>
      </UnorderedList>
    </Box>
  </Stack>
);

export default IntegrationTerms;
