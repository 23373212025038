import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Heading,
  Text
} from '@chakra-ui/react';
import { useField } from 'react-final-form';
import useRequest from 'shared/src/hooks/useRequest';
import React, {
  useEffect,
  useState,
  Component
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { FormSpy } from '../../reactFinalForm';
import { TextArea } from '../../reactFinalForm/fields';
import SimpleForm from '../../reactFinalForm/SimpleForm';
import SelectField from '../../reactFinalForm/fields/SelectField';

import BigModal from '../../BigModal/BigModal';
import PreviewPost from './PreviewPost';
import View from '../layout/View';
import Icon from '../elements/Icon';
import CancelButton from '../elements/CancelButton';
import Menu from '../collections/Menu';
import ErrorMessage from '../entities/ErrorMessage';
import WaitFor from '../../data/WaitFor';

import './PostToFb.css';

const FACEBOOK_SCOPES = ['pages_manage_posts', 'pages_show_list', 'pages_read_engagement'];

const lookupError = (errorStr, strings) => {
  const TRANSLATION_PATH = 'ui.component.facebookSDKService';

  switch (errorStr) {
    case 'loadError':
      return new Error(strings(`${TRANSLATION_PATH}.${errorStr}`));
    case 'loginError':
      return new Error(strings(`${TRANSLATION_PATH}.${errorStr}`));
    case 'postError':
      return new Error(strings(`${TRANSLATION_PATH}.${errorStr}`));
    case 'accessError':
      return new Error(strings(`${TRANSLATION_PATH}.${errorStr}`));
    default:
      return new Error(errorStr);
  }
};

const FacebookError = ({ error, title, children, strings }) => {
  if (!error) return null;
  const displayError = lookupError(error.message, strings);

  return (
    <Alert status="error" flexDirection="column" gap="1em">
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{displayError.message}</AlertDescription>
      {children}
    </Alert>
  );
};

const Preview = ({ offer, message, strings }) => {
  return (
    <FormSpy subscription={{ values: true }}>
      {({ values: { message, selectedPage } }) => (
        <PreviewPost
          title={selectedPage?.name}
          imageId={offer.heroImage}
          offerName={`${offer.headline} ${offer.subHeadline}`}
          offerDesc={offer.details}
          message={message}
        />
      )}
    </FormSpy>
  );
};

const retryWithLogin = async (thunk, facebookSDKService) => {
  return thunk()
    .catch(
      () => facebookSDKService.login(FACEBOOK_SCOPES)
        .then(thunk)
    );
};

const PostToFb = ({
  facebookSDKService,
  offer,
  closeModal,
  i18nStrings: strings
}) => {
  const [postedPages, setPostedPages] = useState({});

  const pagesRequest = useRequest(
    async () => {
      const accountPages = await retryWithLogin(() => facebookSDKService.getAccount(), facebookSDKService);
      return accountPages.map(p => ({
        text: p.name,
        value: p,
        key: p.id
      }));
    },
    []
  );
  const postRequest = useRequest(
    async ({ selectedPage, message }) => {
      console.log("selectedPage", selectedPage);
      console.log("message", message);
      const shareUrl = `${offer.shareUrl}?utm_campaign=gitl-promo-tools&utm_source=facebook&utm_medium=social&utmContent=${selectedPage?.pageId}`;

      const post = await retryWithLogin(
        () => facebookSDKService.postToPage(
          selectedPage,
          { link: shareUrl, body: message, fields: 'permalink_url' }
        ),
        facebookSDKService
      );

      setPostedPages(pagesMap => ({
        ...pagesMap,
        ...{ [selectedPage.id]: post }
      }));
    }
  );

  return (
    <BigModal open>
      <SimpleForm initialValues={{ selectedPage: pagesRequest.result?.[0].value }} debug={true}>
        <BigModal.Contents>
          <BigModal.Header className="p1">
            <Menu secondary>
              <Menu.Item fitted>
                <h1>{strings('ui.component.postToFb.createPost')}</h1>
              </Menu.Item>
              <Menu.Menu position="right">
                <Menu.Item fitted>
                  <CancelButton onClick={closeModal} disabled={postRequest.loading} />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          </BigModal.Header>
          <BigModal.Body>
            <View>
              <WaitFor waitFor={!postRequest.loading} wrapContents>
                <View.Section narrow>
                  <FacebookError title="Error Posting To Facebook" error={postRequest.error} strings={strings} />
                  <FacebookError title="Error Getting Facebook Pages" error={pagesRequest.error}  strings={strings}>
                    <Button onClick={pagesRequest.run}>Try Again</Button>
                  </FacebookError>

                  <Heading size="md">{strings('ui.component.postToFb.choosePage')}</Heading>
                  <SelectField
                    name="selectedPage"
                    loading={pagesRequest.loading}
                    options={pagesRequest.result || []}
                    wrapLabel
                  />

                  <Heading size="md">{strings('ui.component.postToFb.message')}</Heading>
                  <TextArea wrapLabel name="message" />
                  <small>{strings('ui.component.postToFb.charMaxLimit')}</small>

                  <Heading size="md">{strings('ui.component.postToFb.preview')}</Heading>
                  <Preview offer={offer} />

                  <FormSpy subscription={{ values: true }}>
                    {({ values: { selectedPage, message } }) => (
                      <div className="mt2">
                        {!postedPages[selectedPage && selectedPage.id] && (
                          <Button
                            float="right"
                            disabled={!selectedPage}
                            type="button"
                            colorScheme="facebook"
                            onClick={() => postRequest.run({ selectedPage, message })}
                          >
                            <Icon className="facebook square" />
                            {strings('ui.component.postToFb.postToFb')}
                          </Button>
                        )}
                        {
                          (
                            postedPages[selectedPage?.id]?.permalink_url
                          ) && (
                            <div className="tac">
                              {strings('ui.component.postToFb.offerHasBeenPostedTo')} {selectedPage.name}!
                              <a
                                href={postedPages[selectedPage?.id].permalink_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <br />{strings('ui.component.postToFb.viewPost')}
                              </a>
                            </div>
                          )
                        }
                      </div>
                    )}
                  </FormSpy>
                </View.Section>
              </WaitFor>
            </View>
          </BigModal.Body>
        </BigModal.Contents>
      </SimpleForm>
    </BigModal>
  );
};

export default PostToFb;
