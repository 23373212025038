import Simple from './Simple';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Ready from './Ready';

export const slides = [
  Simple,
  Step1,
  Step2,
  Step3,
  Step4,
  Ready
];
