import { strings } from '../../../i18n';

export default [{
  key: 'partnership',
  name: strings('shared.plansViewModel.partnership.name'),
  platformId: process.env.REACT_APP_PLATFORM_PLAN_ID_PARTNERSHIP,
  pricePoints: [
    {
      value: 22500,
      currencyCode: 'CAD',
      period: 1,
      periodUnit: 'month',
      chargebeePlanId: 'partnership-monthly-cad',
      priceText: strings('ui.component.planConstants.partnership.priceText'),
    },
    {
      value: 22500,
      currencyCode: 'USD',
      period: 1,
      periodUnit: 'month',
      chargebeePlanId: 'partnership-monthly-usd',
      priceText: strings('ui.component.planConstants.partnership.priceText'),
    }
  ],
  features: strings('shared.plansViewModel.partnership.features', { returnObjects: true }),
  subHeader: strings('ui.component.planConstants.partnership.subHeader'),
  featHeader: strings('ui.component.planConstants.partnership.featHeader'),
  hideSelectButton: true,
}];
