/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import React, {
  useEffect,
  useState,
} from 'react';
import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import {
  RootStateOrAny,
  useSelector,
} from 'react-redux';

import authModule from 'shared/src/modules/auth';
import useRequest from 'shared/src/hooks/useRequest';

import Location from 'web-react-ui/src/types/Location.interface';
import UserProfileView from 'web-react-ui/src/chakra/users/UserProfileView';

import businessModule from '../../../modules/businesses';
import client from '../../../services/client';
import useProperty from '../../useProperty';

const UserProfile = ({ baseUrl }: { baseUrl: string }): JSX.Element => {
  const currentUser = useSelector((state: RootStateOrAny) => authModule.selectors.getUser(state));
  const property = useProperty();
  const business = useSelector((state: RootStateOrAny) => businessModule.selectors.business.getData(state));
  const [locationRoles, setLocationRoles] = useState<any[]>([]);
  const history = useHistory();
  const { userId } = useParams<Record<string, string>>();
  const { pathname, state }: { pathname: string, state: { query: string } } = useLocation();

  const { result: roles } = useRequest(() => client.roles.list(), {});
  const { result: user } = useRequest(
    () => client.users.for(userId).details(),
    { userId },
  );

  const { result: businessRoles } = useRequest(
    () => client
      .properties.for(property.id)
      .businesses.for(business.id)
      .users.for(userId).getRoles({ context: ['PB'] }),
    { userId, property, business },
  );

  const listLocations = () => client.properties.for(property.id).businesses.for(business.id).locations.list();
  const locations = useRequest(listLocations, { propertyId: property.id, businessId: business.id });

  const getLocationRoles = (location: Location) => client
    .properties.for(property.id)
    .businesses.for(business.id)
    .locations.for(location.id)
    .users.for(user?.id).getRoles();

  const getLocationPermissions = (location: Location) => client.access.checkPermissions(
    { propertyId: property.id, businessId: business.id, locationId: location.id },
    ['property.business.location.role.search'],
  );

  useEffect(() => {
    async function run() {
      if (user && locations.result?.items && !locationRoles.length) {
        setLocationRoles(await Promise.all(locations.result.items.map(async (location: Location) => {
          const hasPermissions = await getLocationPermissions(location);
          if (!hasPermissions) return null;
          const result = await getLocationRoles(location);
          return result.items ? { id: location.id, items: result.items } : {};
        })));
      }
    }

    run();
  }, [property, business, locations, user, locationRoles]);

  const deleteRequest = useRequest(
    async () => {
      await client
        .properties.for(property.id)
        .businesses.for(business.id)
        .users.for(user.id)
        .remove({ recursive: true });
    },
  );
  const removeUser = async () => {
    if (!user) return null;
    const shouldDelete = confirm(
      /* eslint-disable-next-line max-len */
      `Are you sure you want to delete ${user.email}? Deleting this user will remove all business and location roles.`,
    );
    if (!shouldDelete) return null;
    await deleteRequest.run();

    if (!deleteRequest.error) {
      history.push(`${baseUrl}${state?.query || ''}`);
    }

    return true;
  };

  const onEdit = () => history.push({
    pathname: `${pathname}/edit`,
    state,
  });

  return (
    <UserProfileView
      user={user}
      isCurrentUser={currentUser && user?.id === currentUser.id}
      onEdit={onEdit}
      backUrl={baseUrl}
      removeUser={removeUser}
      isDeleting={deleteRequest.loading}
      operationError={deleteRequest.error}
      roles={roles?.items}
      property={property}
      business={business}
      businessRoles={businessRoles?.items}
      locations={locations.result?.items}
      locationRoles={locationRoles}
    />
  );
};

export default UserProfile;
