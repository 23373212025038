/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  Flex,
  Heading,
  Image,
  Spacer,
  Text,
} from '@chakra-ui/react';
import CarouselSlide from '../../Carousel/CarouselSlide';
import login from './assets/login.png';

const Step2 = () => (
  <CarouselSlide textAlign={['center', undefined, 'left']} pb={[0, 0, 0]}>
    <Flex direction={['column', undefined, 'row-reverse']} height="100%" alignItems="center">
      <Flex direction="column" gap="2em" py={["1em", undefined, "4em"]}>
        <Heading>2. Login If Needed</Heading>
        <Text fontSize="2xl">
          If you're not already signed in to your Facebook account, a Facebook Login window will pop up.
          Login with your personal Facebook account to continue.
        </Text>
        <Text fontSize="lg" color="gray.500">
          Make sure you've allowed popups and have disabled any ad blockers for this step,
          otherwise you might not see these popup windows open correctly.
        </Text>
      </Flex>
      <Image
        px="1em"
        flexShrink={3}
        src={login}
        alignSelf={['center', undefined, 'end']}
        minWidth="50%"
        flexGrow={1}
      />
    </Flex>
  </CarouselSlide>
);

const Supplement = () => (
  <Flex alignItems="center" direction="column" fontSize="text-md" color="gray.400" textAlign="center">
    <Text fontWeight="normal">Linking Facebook pages and Instagram accounts both go through the Facebook API.</Text>
    <Text fontWeight="normal">
      This requires your personal Facebook login has access to the appropriate Facebook page.
    </Text>
    <Text fontWeight="normal">We’ll cover the details at the end.</Text>
  </Flex>
);

Step2.Supplement = Supplement;

export default Step2;
