import { strings } from '../../i18n';

export default [{
  keyName: 'community',
  name: strings('shared.plansViewModel.community.name'),
  value: 0,
  platformId: process.env.REACT_APP_PLATFORM_PLAN_ID_COMMUNITY,
  currentPlan: null,
  isCurrentPlan: false,
  price: {
    CA: {
      value: 0,
      currencyCode: 'CAD',
      chargebeePlanId: 'community-monthly-cad',
    },
    US: {
      value: 0,
      currencyCode: 'USD',
      chargebeePlanId: 'community-monthly-usd',
    }
  },
  features: strings('shared.plansViewModel.community.features'),
  selectButtonLabel: strings('shared.plansViewModel.community.selectButtonLabel'),
  selectButtonAction: "SELECT"
}, {
  keyName: 'loyalty',
  name: strings('shared.plansViewModel.loyalty.name'),
  value: 1,
  platformId: process.env.REACT_APP_PLATFORM_PLAN_ID_LOYALTY,
  currentPlan: null,
  isCurrentPlan: false,
  price: {
    CA: {
      value: 10000,
      currencyCode: 'CAD',
      chargebeePlanId: 'loyalty-plus-monthly-cad',
    },
    US: {
      value: 10000,
      currencyCode: 'USD',
      chargebeePlanId: 'loyalty-plus-monthly-usd',
    }
  },
  features: strings('shared.plansViewModel.loyalty.features'),
  selectButtonLabel: strings('shared.plansViewModel.loyalty.selectButtonLabel'),
  selectButtonAction: "SELECT"
}, {
  keyName: 'partnership',
  name: strings('shared.plansViewModel.partnership.name'),
  platformId: process.env.REACT_APP_PLATFORM_PLAN_ID_PARTNERSHIP,
  currentPlan: null,
  isCurrentPlan: false,
  price: {
    CA: {
      value: 22500,
      currencyCode: 'CAD',
      chargebeePlanId: 'partnership-monthly-cad'
    },
    US: {
      value: 22500,
      currencyCode: 'USD',
      chargebeePlanId: 'partnership-monthly-usd'
    }
  },
  value: 2,
  features: strings('shared.plansViewModel.partnership.features'),
  selectButtonLabel: strings('shared.plansViewModel.partnership.selectButtonLabel'),
  selectButtonAction: "SELECT"
}, {
  keyName: 'enterprise',
  name: strings('shared.plansViewModel.enterprise.name'),
  value: -1,
  platformId: process.env.REACT_APP_PLATFORM_PLAN_ID_ENTERPRISE,
  currentPlan: null,
  isCurrentPlan: false,
  price: {
    CA: {
      value: -1,
      currencyCode: 'CAD',
      chargebeePlanId: 'enterprise-extension-monthly-cad'
    },
    US: {
      value: -1,
      currencyCode: 'USD',
      chargebeePlanId: 'enterprise-extension-monthly-usd'
    }
  },
  features: strings('shared.plansViewModel.enterprise.features'),
  selectButtonLabel: strings('shared.plansViewModel.enterprise.selectButtonLabel'),
  selectButtonAction: "CONTACT"
}];
