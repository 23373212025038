import PropTypes from 'prop-types';
import React from 'react';
import TranslationWrapper from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizationWrapper';
import { usePropertyState } from '../../hooks/useProperty';

const ConnectedTranslationWrapper = ({ children }) => {
  // Destructuring a 'possible' null Property - add default value(s)
  const { property } = usePropertyState();
  // FIXME: Why is this ever not defined?
  if (!property) return null;
  const locales = property.locales || [];

  return (
    <TranslationWrapper locales={locales}>
      {children}
    </TranslationWrapper>
  );
};

ConnectedTranslationWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
};

export default ConnectedTranslationWrapper;
