/* eslint class-methods-use-this:0 */
import React, { Component } from 'react';
import { strings } from '../../i18n';

class Terms extends Component {
  render() {
    const year = new Date().getFullYear();
    // TODO: This should all be translated to a global config
    // object or state so that it's all in one spot.
    const terms = 'https://getintheloop.com/business/terms';
    const privacy = 'https://getintheloop.com/business/privacy';
    return (
      <div>
        <p className='mb0 small'>
          &copy; {year} {strings('dashboard.label.rightsReserved')}<br/>
          <a target="_blank" rel="noopener noreferrer" href={ terms }>
            {strings('dashboard.label.termsOfService')}
          </a>
          &nbsp;{strings('dashboard.fragment.and')}&nbsp;
          <a target="_blank" rel="noopener noreferrer" href={ privacy }>
            {strings('dashboard.label.privacyPolicy')}
          </a>
        </p>
      </div>
    );
  }
}

export default Terms;
