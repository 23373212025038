import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Flex,
  Link,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import usePagedList from 'shared/src/hooks/usePagedList';
import {
  transport,
  useQuerystringParam,
} from 'shared/src/hooks/useQuerystringParam';
import { AutoPagination } from 'web-react-ui/src/chakra/Pagination';
import ViewHeader from 'web-react-ui/src/chakra/ViewHeader';
import ActionMenu from 'web-react-ui/src/components/actionMenu/ActionMenu';
import PageLayout from 'web-react-ui/src/chakra/page/PageLayout';
import businessesModule from '../../../modules/businesses';
import client from '../../../services/client';
import useProperty from '../../useProperty';
import SectionHeader from './components/SectionHeader';
import Post from './interfaces/Post.interface';
import PostList from './PostList';
import ProfileHealth from './ProfileHealth';
import SocialPostListEmpty from './SocialPostListEmpty';

const fetchPosts = async (
  { property, business, status, range }:
    { property: { id: string }, business: { id: string }, status: string, range: number },
): Promise<{ items: Array<Post> } | undefined> => {
  if (!business) return undefined;
  return client
    .businesses.for(business.id)
    .social.posts.list();
};

const PostListView = () => {
  const property = useProperty();
  const business = useSelector(state => businessesModule.selectors.business.getData(state));
  const profile = useSelector(state => state.social.profile);
  const [status, setStatus] = useQuerystringParam('status', '', { squash: true });
  const [range, setRange] = useQuerystringParam('range', '', { transport: transport.positiveNumber, squash: true });

  const postList = usePagedList(fetchPosts, { property, business, status, range });

  // const controls = (
  //   <Flex gap="1em">
  //     <Select value={status} onChange={event => setStatus(event.target.value)} rootProps={{ w: 'unset' }}>
  //       <option value="">All Posts</option>
  //       <option value="scheduled">Scheduled</option>
  //       <option value="posted">Posted</option>
  //       <option value="pending">Pending</option>
  //       <option value="error">Error</option>
  //     </Select>
  //     <Select value={range} onChange={event => setRange(event.target.value)} rootProps={{ w: 'unset' }}>
  //       <option value="30">30 Days</option>
  //       <option value="60">60 Days</option>
  //       <option value="90">90 Days</option>
  //       <option value="">All Time</option>
  //     </Select>
  //   </Flex>
  // );
  const controls = (<></>);

  const hasLinkedSocials = profile.socialAccounts.some(a => a.linked);

  const hasNoPosts = postList.empty && postList.page.current === 1;

  return (
    <PageLayout>
      <ViewHeader title="Social AI">
        <ActionMenu actions={[
          {
            label: 'Settings',
            extraProps: {
              as: RouterLink,
              to: './social/settings',
            },
          },
          {
            label: 'New Custom Post...',
            extraProps: {
              as: RouterLink,
              to: './social/new',
            },
            hidden: true,
          },
        ]} />
      </ViewHeader>

      <Flex direction="column" gap="1em">
        {!hasNoPosts ?
          <>
            <SectionHeader level={4} controls={controls}>
              Posts
            </SectionHeader>
            <ProfileHealth profile={profile} />
            {
              !hasLinkedSocials
                ? (
                  <Alert status="warning" fontSize="lg">
                    <AlertIcon />
                    <Flex direction="column" gap="4px">
                      <AlertTitle>
                        No Linked Social Media Accounts
                      </AlertTitle>
                      <AlertDescription>
                        Social AI cannot find any linked social media accounts.
                        At least one linked account is required for Social AI to generate and manage posts and stories.
                      </AlertDescription>
                      <Link color="blue.500" as={RouterLink} to="./social/settings">Link Account Now</Link>
                    </Flex>
                  </Alert>
                )
                : null
            }
            <PostList pagedList={postList} business={business} />
            <AutoPagination pagedList={postList} />
          </> :
          <SocialPostListEmpty />
        }
      </Flex>
    </PageLayout>
  );
};

export default PostListView;
