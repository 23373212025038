const getContentTypeProps = (type: string) => {
  switch (type) {
    case 'post':
      return {
        label: 'Post',
        colorScheme: 'blue'
      };
    case 'story':
      return {
        label: 'Story',
        colorScheme: 'purple'
      };
    default:
      return {
        label: typeof type === 'string'
          ? type.slice(0, 1).toUpperCase() + type?.slice(1)
          : '',
      };
  }
};

export default getContentTypeProps;
