/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  Heading,
  Text,
} from '@chakra-ui/react';
import CarouselSlide from '../../Carousel/CarouselSlide';
import welcome from './assets/welcome.png';

const Welcome = () => (
  <CarouselSlide
    height="36em"
    textAlign="center"
    gap="3em"
    bgImage={`url('${welcome}')`}
    bgPosition="center"
    bgRepeat="no-repeat"
    bgSize="cover"
    justifyContent='center'
  >
    <Heading>Welcome to Social AI! 👋</Heading>
    <Text fontSize="2xl">
      Now that you've enabled Social AI and linked at least one social media account,
      here's how it works and what to expect.
    </Text>
    <Text fontSize="2xl" color="gray.400">Continue &rarr;</Text>
  </CarouselSlide>
);

export default Welcome;
