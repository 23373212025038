import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Form from 'web-react-ui/src/components/collections/Form';
import Request from 'web-react-ui/src/data/Request';
import businessesModule from '../../modules/businesses';
import client from '../../services/client';
import propertyResolver from '../../services/propertyResolver';
import { strings } from '../../i18n';

const ALL_LOCATIONS = 'ALL_LOCATIONS';

const fetchLocations = async ({ businessId }) => {
  const property = propertyResolver();

  const locations = await client
    .properties.for(property.id)
    .businesses.for(businessId)
    .locations.search({
      hasRoles: true,
      accessContext: 'implicit'
    })
    .then(client.pagination.loadRest);

  const locationList = locations.items.map(location => ({
    key: location.id,
    text: location.name,
    value: location.id
  }));

  locationList.unshift({
    key: 0,
    text: strings('dashboard.component.myLocationSelector'),
    value: ALL_LOCATIONS // can't set to null or label doesn't display (thanks, SUI)
  });


  return locationList;
};

class MyLocationSelector extends Component {
  state = {};

  onChange(event, select) {
    const value = select.value;
    this.props.onChange(value === ALL_LOCATIONS ? null : value);
  }

  fetchLocations(businessId) {
    if (!businessId) return false;
    const { offerLocations, value } = this.props;

    return fetchLocations(businessId)
      .then((locations) => {
        if (!offerLocations) return locations;
        return (
          locations.filter(location => offerLocations.includes(location.value) || location.value === ALL_LOCATIONS)
        );
      })
      .then((locations) => {
        if (!value) this.onChange(null, locations[0]);
        return locations;
      });
  }

  render() {
    const { business, value } = this.props;

    return (
      <Request request={this.fetchLocations.bind(this)} params={{ businessId: business.id }}>
        {({ data, loading }) => (
          <Form.Field>
            <Form.Select
              fluid
              options={data || []}
              value={value || ALL_LOCATIONS}
              loading={loading}
              onChange={this.onChange.bind(this)}
            />
          </Form.Field>
        )}
      </Request>
    );
  }
}

MyLocationSelector.propTypes = {
  onChange: PropTypes.func,
  business: PropTypes.object,
  offerLocations: PropTypes.array,
  value: PropTypes.string
};

const mapState = state => ({
  business: businessesModule.selectors.business.getData(state)
});

export default connect(mapState)(MyLocationSelector);
