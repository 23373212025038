/* eslint import/prefer-default-export: 0 */
import {
  Avatar,
  Box,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  useColorModeValue as mode
} from '@chakra-ui/react';
import * as React from 'react';
import { HiOutlineMenu } from 'react-icons/hi';
import { useHistory } from 'react-router';
import BeamerTrigger from 'web-react-ui/src/chakra/BeamerTrigger';
import { Logo } from './Logo';
import { Sidebar } from './Sidebar';
import useMobileMenuState from './useMobileMenuState';

interface Props {
  children: React.ReactNode;
  title: string;
  logo: string;
}

export const MobileTopBar = ({ children, title, logo }: Props): JSX.Element => {
  const { isOpen, onClose, onOpen } = useMobileMenuState();
  const history = useHistory();

  const handleLogoClick = () => history.push('/');

  return (
    <Flex
      align='center'
      py={2}
      px={4}
      bg={mode('gray.50', 'gray.800')}
      display={{
        base: 'flex',
        md: 'none'
      }}
      borderBottomWidth={1}
    >
      {history.location.pathname === '/dashboard' && (
        <BeamerTrigger />
      )}
      <IconButton
        onClick={onOpen}
        variant='unstyled'
        border='none'
        display='flex'
        cursor='pointer'
        aria-label='Menu'
        icon={<HiOutlineMenu fontSize='1.5rem' />}
      />
      <Box ml={4} cursor='pointer' onClick={() => handleLogoClick()}>
        {title && logo ? (
          <Flex align='center'>
            <Avatar src={logo} size='sm' mr={4} />
            {title}
          </Flex>
        ) : (
          <Logo />
        )}
      </Box>

      <Drawer
        size='xs'
        placement='left'
        isOpen={isOpen}
        blockScrollOnMount={false}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent bg={mode('white', 'gray.800')} shadow='none' position='relative' maxW='256'>
          <Sidebar width='full' height='full' bg='inherit' border='0'>
            {children}
          </Sidebar>
          <DrawerCloseButton
            bg='transparent'
            rounded='0'
            position='absolute'
            color='gray.500'
            right={0}
            top={3}
            border='none'
          />
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};
