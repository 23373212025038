import { Flex } from '@chakra-ui/react';
import React, { ComponentProps } from 'react';

interface CarouselSlideType extends ComponentProps<typeof Flex> {}

const CarouselSlide = ({ children, ...rest }: CarouselSlideType) => {
  return (
    <Flex
      cursor="pointer"
      justifyContent={['start', undefined, 'center']}
      alignContent="center"
      direction="column"
      borderRadius="1em"
      p={['1em', undefined, '2em']}
      bgImage="linear-gradient(to bottom right, #F8FBFD, #EEF2F7)"
      borderWidth="2px"
      borderColor="gray.200"
      gap="2em"
      height="42em"
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default CarouselSlide;
