/* eslint class-methods-use-this:0 */
import React from 'react';
import PropTypes from 'prop-types';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import FormSubmit from '../../reactFinalForm/FormSubmit';
import SimpleForm from '../../reactFinalForm/SimpleForm';
import InlineSupportLink from '../../support/InlineSupportLink';
import { required } from '../../reactFinalForm/validators';
import ImageField from '../image/ImageField';
import View from '../layout/View';
import { WizardStep } from '../wizard/WizardManager';
import Button from '../elements/Button';

function AddImages({ step, manager }) {
  const { strings } = useI18Next();
  return (
    <SimpleForm initialValues={step.data} onSubmit={data => step.stepComplete(data)}>
      <View>
        <View.Section narrow>
          <h3>{strings('ui.component.addImages.images')}</h3>
          <p>{strings('ui.component.addImages.imagesDetails')}</p>
        </View.Section>
        <View.Section narrow>
          <h4>{strings('ui.component.addImages.addLogo')}</h4>
          <p>{strings('ui.component.addImages.addLogoDetails')}</p>
          <ImageField
            name='business.rawProfileImage'
            fileType='business-profile'
            validate={required}
            aspectRatio={1}
            skipUpload />
        </View.Section>
        <View.Section narrow>
          <h4>{strings('ui.component.addImages.addBusinessImage')}</h4>
          <p>{strings('ui.component.addImages.addBusinessImageDetails')}</p>
          <ImageField
            name='business.rawHeroImage'
            fileType='business-hero-standard'
            validate={required}
            skipUpload />
        </View.Section>
        <View.Section narrow className='flex'>
          <Button
            type='button'
            className='mra'
            onClick={() => manager.showPreviousStep()}
          >
            {strings('ui.component.wizard.previous')}
          </Button>
          <FormSubmit label={strings('ui.label.next')} className='mla' />
        </View.Section>
        <View.Section narrow>
          <p>{strings('ui.label.haveAQuestion')} <InlineSupportLink />.</p>
        </View.Section>
      </View>
    </SimpleForm>
  );
}

AddImages.propTypes = {
  step: PropTypes.object
};

export default () => new WizardStep({
  key: 'logo',
  getTitle: data => data.business.name,
  render: AddImages
});
