/* eslint-disable-next-line no-redeclare */
/* globals localStorage */
import {
  PLAN_FETCH,
  PLAN_FETCHED,
  PLAN_PENDING
} from './planActions';
import useProperty from '../../components/useProperty';
import client from '../../services/client';
import { strings } from '../../i18n';

const renderString = planName => (
  `${planName} ${strings('dashboard.screen.plansView.plan')} ${strings('dashboard.screen.plansView.changesPending')}...`
);

const getPlan = (businessId) => {
  const property = useProperty();
  return client
    .properties.for(property.id)
    .businesses.for(businessId)
    .getPlan();
};

const fetchPlan = async (businessId) => {
  const currentPlan = await getPlan(businessId);
  const storedPlan = JSON.parse(localStorage.getItem('plan'));

  if (storedPlan) {
    if (storedPlan.expiresIn) {
      const expirationDate = new Date(storedPlan.expiresIn);
      // if the plan is in localstorage but is expired, remove it
      if (expirationDate <= new Date()) {
        localStorage.removeItem('plan');
        return {
          type: PLAN_FETCHED,
          payload: currentPlan
        };
      }

      // if plan is in localstorage and it's not expired, continue on to...
      if (currentPlan.id === storedPlan.id) {
        // 1. Return current (pending) plan
        localStorage.removeItem('plan');
        return {
          type: PLAN_FETCHED,
          payload: currentPlan
        };
      }

      return { // 2. Continue returning pending plan
        type: PLAN_FETCHED,
        payload: {
          id: storedPlan.id,
          name: renderString(storedPlan.name)
        }
      };
    }
  }
  // if the plan is NOT stored (or you continued from the second step), load the actual plan
  return {
    type: PLAN_FETCHED,
    payload: currentPlan
  };
};

const fetchPendingPlan = async (plan) => {
  const pendingPlan = {
    id: plan.platformId, // ie. b1b52649-48b5-47cd-abdd-06e6ca599e7d
    name: plan.name, // ie. Partnership, Enterprise
    expiresIn: new Date(new Date().getTime() + (60 * 60 * 1000)) // 1HR
  };

  localStorage.setItem('plan', JSON.stringify(pendingPlan));

  return {
    type: PLAN_FETCHED,
    payload: {
      id: plan.platformId, // 'temporarily' switches visual from free -> monthly /plans
      name: renderString(plan.name)
    }
  };
};

export default store => next => async (action) => {
  let update;
  switch (action.type) {
    case (PLAN_FETCH):
      next(action);
      update = await fetchPlan(action.payload);
      store.dispatch(update);
      break;
    case (PLAN_PENDING):
      next(action);
      update = await fetchPendingPlan(action.payload);
      store.dispatch(update);
      break;
    default:
      next(action);
      break;
  }
};
