import {
  AddIcon,
  DeleteIcon,
  EditIcon,
} from '@chakra-ui/icons';
import {
  Button,
  Flex,
  IconButton,
  Spacer,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useField } from 'react-final-form';
import { ExternalIdTypes } from 'shared/src/constants/externalListings';
import AddExternalListingsModal from './ExternalListingsModal';
import fieldRenderers from './fieldRenderers';

const ExternalListingsField = ({ name, context, fieldWhitelist }) => {
  const field = useField(name, {});

  return (
    <ExternalListingsControl
      value={field.input.value}
      onChange={field.input.onChange}
      context={context}
      fieldWhitelist={fieldWhitelist}
    />
  );
};

const ExternalListingsControl = ({ value, onChange, context, fieldWhitelist }) => {
  const [adding, setAdding] = useState('');

  if (!value) return null;


  const handleChange = (sid, newVal) => {
    const newValue = {
      ...value,
      [sid]: newVal
    };

    onChange(newValue);
  };

  return (
    <>
      {Object.entries(value).filter(([, val]) => val !== null).map(([sid, val]) => {
        if (fieldWhitelist && !fieldWhitelist.includes(sid)) return null;
        return (
          <ExternalListingRow
            sid={sid}
            value={val}
            key={sid}
            onEdit={() => setAdding(sid)}
            onDelete={() => handleChange(sid, null)}
          />
        );
      })}
      <AddButton value={value} onSelect={sid => setAdding(sid)} fieldWhitelist={fieldWhitelist} />
      <AddExternalListingsModal
        open={adding}
        sid={adding}
        onClose={() => setAdding('')}
        values={value}
        onSubmit={(sid, newValue) => handleChange(sid, newValue)}
        context={context}
      />
    </>
  );
};

const AddButton = ({ value, fieldWhitelist, onSelect }) => {
  let remainingTypes = ExternalIdTypes
    .filter(type => !value[type.value]);

  if (fieldWhitelist) {
    remainingTypes = remainingTypes.filter(type => fieldWhitelist.includes(type.value));
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="ghost"
        colorScheme="blue"
        fontSize="xl"
        leftIcon={<AddIcon />}
        disabled={!remainingTypes.length}
        onClick={() => {
          if (remainingTypes.length === 1) {
            onSelect(remainingTypes[0].value);
          }
        }}
      >
        Add
      </MenuButton>
      <MenuList>
        {remainingTypes.map(type => {
          return (
            <MenuItem onClick={() => onSelect(type.value)}>{type.label}</MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

const ExternalListingRow = ({ sid, value, onEdit, onDelete }) => {
  const Renderer = fieldRenderers[sid] || fieldRenderers.default;

  return (
    <Flex py="1em" gap="1em" alignItems="center">
      <Renderer sid={sid} value={value} />
      <Spacer />
      <IconButton icon={<EditIcon />} onClick={onEdit} />
      <IconButton icon={<DeleteIcon />} onClick={onDelete} />
    </Flex>
  );
};

export default ExternalListingsField;
