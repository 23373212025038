import {
  Flex,
  Image,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import getContentTypeProps from '../components/getContentTypeProps';
import Post from '../interfaces/Post.interface';
import NetworkIcon from '../components/NetworkIcon';

const Preview = ({ post }: { post: Post }) => {
  return (
    <Skeleton isLoaded={!!post}>
      <Flex direction="column" w="60ch" borderWidth="1px" p="1.5em" gap="1em" borderRadius="0.5em">
        <Flex gap="0.5em" align="center">
          <NetworkIcon id={post?.socialNetwork} fontSize="1.5em" />
          <Text textTransform="capitalize">{post?.socialNetwork} {getContentTypeProps(post?.contentType).label}</Text>
        </Flex>
        <Image src={post?.output.media?.[0]} />
        {
          post?.contentType === 'story'
            ? undefined
            : <Text>{post?.output.message}</Text>
        }
      </Flex>
    </Skeleton>
  );
};

export default Preview;
