import React, {
  useState,
  useEffect,
} from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

import Loader from 'web-react-ui/src/components/elements/Loader';
import Location from 'web-react-ui/src/types/Location.interface';
import Property from 'web-react-ui/src/types/Property.interface';

import useRequest from 'shared/src/hooks/useRequest';

import useProperty from '../useProperty';
import SsoError from './error';
import client from '../../services/client';


// filters out locations for which the thryv app is already linked
const filterOutInstalledApps = async (locations: Location[]) => {
  const getInstalledApps = (businessId: string) => client.businesses.for(businessId).apps.listInstalledApps();
  const installedApps = async (location: Location) => {
    const results = await getInstalledApps(location.business.id);
    return !results.items.find((item: any) => item.context.locationId === location.id);
  };

  const appResults = await Promise.all(locations.map(item => installedApps(item)));
  const filteredLocations = locations.filter((res, index) => appResults[index]);

  return filteredLocations;
};

const fetchLocations = async ({ property }: { property: Property }): Promise<Array<Location>> => {
  const payload = await client.properties.for(property.id)
    .locations.search({
      hasRoles: true,
      accessContext: 'implicit',
    })
    .then(client.pagination.loadRest);
  // filters out locations for which the user doesn't have permission to install an app
  const permissionsFilter = async (items: Location[]) => {
    const results = await Promise.all(items.map(item => hasPermissions(item.business.id)));
    return items.filter((res, index) => results[index]);
  };

  const permittedLocations = await permissionsFilter(payload.items);

  const filtered = await filterOutInstalledApps(permittedLocations);
  return filtered;
};

const hasPermissions = (businessId: string) =>
  client.access.checkPermissions({ businessId }, ['business.app.install.create']);

const useModel = () => {
  const property = useProperty();
  const [selected, setSelected] = useState<Location>();

  const locationRequest = useRequest(fetchLocations, { property });

  useEffect(
    () => {
      if (!locationRequest.result?.length) return;
      if (selected) return;
      setSelected(locationRequest.result[0]);
    },
    [locationRequest.result],
  );

  return {
    selected,
    setSelected,
    locationRequest,
  };
};

const ConnectBusinessLocation = ({
  onCancel,
  onContinue,
}: {
  onCancel: () => void,
  onContinue: (location: Location) => void,
}): JSX.Element => {
  const { selected, setSelected, locationRequest } = useModel();

  if (locationRequest.error) return <SsoError title="Unable to Authorize" error={locationRequest.error} />;

  return (
    <Stack spacing={8} position="relative" w="100%">
      <Heading>Authorize Thryv</Heading>
      <Text fontSize="2xl">Select the GetintheLoop Business Location to link to Thryv:</Text>
      {!locationRequest.result && (
        <Center><Loader active /></Center>
      )}
      {locationRequest.result?.length === 0 && (
        <Alert status="warning" borderRadius={8}>
          <AlertIcon alignSelf="flex-start" />
          <Box>
            <AlertTitle>This user does not have app install access for any businesses.</AlertTitle>
            <AlertDescription>
              If this is incorrect, please contact your business manager.
            </AlertDescription>
          </Box>
        </Alert>
      )}
      {locationRequest.result?.length && (
        <Menu
          matchWidth
          preventOverflow={true}
          eventListeners={false}
          flip={false}
          isLazy={true}
          strategy="fixed"
        >
          <MenuButton
            as={Button}
            bg="white"
            border="1px"
            borderColor="gray.200"
            _active={{ bg: 'gray.50' }}
            h="6rem"
            rightIcon={<ChevronDownIcon />}
          >
            <Flex flexDir="column" h="6rem" align="flex-start" justify="center" overflow="hidden">
              <Text fontSize="lg" fontWeight={500}>{selected?.business?.name} - {selected?.name}</Text>
              <Text fontSize="lg">{selected?.address?.label}</Text>
            </Flex>
          </MenuButton>
          <MenuList
            overflowY="scroll"
            height="inherit"
            top={0}
            w="inherit"
            maxH={300}
          >
            {locationRequest.result?.map((item: Location) => (
              <MenuItem
                key={item.id}
                w="100%"
                onClick={() => setSelected(item)}
                borderBottom="1px"
                borderColor="gray.200"
              >
                <Flex flexDir="column" h="6rem" align="flex-start" justify="center">
                  <Text fontSize="lg" fontWeight={500}>{item.business?.name} - {item.name}</Text>
                  <Text fontSize="lg">{item.address.label}</Text>
                </Flex>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}


      <Divider />
      <Flex justify="space-between">
        <Button onClick={onCancel} variant="ghost" size="lg">Cancel</Button>
        <Button onClick={() => onContinue(selected!)} colorScheme="blue" size="lg">Continue</Button>
      </Flex>
    </Stack>
  );
};

export default ConnectBusinessLocation;
