/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  Flex,
  Heading,
  Image,
  Spacer,
  Text,
} from '@chakra-ui/react';
import CarouselSlide from '../../Carousel/CarouselSlide';
import allowAccess from './assets/allowAccess.png';

const Step3 = () => (
  <CarouselSlide textAlign={['center', undefined, 'right']} pb={[0, 0, 0]} justifyContent="stretch">
    <Flex direction={['column', undefined, 'row']} height="100%" alignItems="center">
      <Spacer />
      <Flex direction="column" gap="2em" py={["1em", undefined, "4em"]}>
        <Heading>3. Allow Access</Heading>
        <Text fontSize="2xl">
          Facebook will show a linking permission window.
          This is what grants Social AI access to post on your behalf.
        </Text>
        <Text fontSize="2xl">
          Click the Continue button to accept the permissions and link your account.
        </Text>
        <Text fontSize="lg" color="gray.500">
          Make sure to accept all the permissions.
          Failure to do so will prevent Social AI from
          creating, publishing, or managing social posts correctly.
        </Text>
      </Flex>
      <Spacer />
      <Image
        px="1em"
        flexShrink={3}
        src={allowAccess}
        alignSelf={['center', undefined, 'end']}
        minWidth="50%"
        flexGrow={1}
      />
    </Flex>
  </CarouselSlide>
);

const Supplement = () => (
  <Text maxW="60ch" textAlign="center" fontSize="text-md" color="gray.400" fontWeight="normal">
    Permissions will be granted to Ayrshare,
    the technology provider we use to manage your Facebook and Instagram page on your behalf.
  </Text>
);

Step3.Supplement = Supplement;

export default Step3;
