import React, {
  useCallback,
  useMemo,
} from 'react';
import { useField } from 'react-final-form';
import Map from 'web-react-ui/src/map/Map';

const serializeGeoPoint = (geoPoint) => {
  if (!geoPoint) return geoPoint;
  return [geoPoint.lat, geoPoint.long];
};

const MapField = ({ name }: { name: string }) => {
  const field = useField(name, {});

  const onMarkerUpdate = useCallback(
    (updatedMarker) => {
      field.input.onChange({
        lat: updatedMarker.latlng.lat,
        long: updatedMarker.latlng.lng,
      });
    },
    [field],
  );

  const center = serializeGeoPoint(field.input.value);
  const markers = useMemo(
    () => {
      if (!center) return [];
      return [{ center, draggable: true, isEnabled: true }];
    },
    [!center],
  );

  if (!center) return null;

  return (
    <Map
      markers={markers}
      onMarkerUpdate={onMarkerUpdate}
      initialFitToMarkers={true}
      fitToMarkers={false}
      initialZoom={15}
    />
  );
};

export default MapField;
