import {
  Button,
  Center,
  Flex,
  Stack,
  Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { RiAlertLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import { COUNTRIES } from 'shared/src/constants/countries';
import usePermissions from 'shared/src/hooks/usePermissions';
import useQuerystring from 'shared/src/hooks/useQuerystring';
import auth from 'shared/src/modules/auth';

import WizardModal from 'web-react-ui/src/components/wizard/WizardModal';
import plans from '../../assets/data/plans';

import useLanguage from '../../hooks/useLanguage';
import createWizard from './CreateBusinessWizard';

const createInitialBusiness = (query) => {
  if (!query.t) return {};

  return {
    token: query.t,
    name: query.n,
    shortName: query.n?.substring(0, 24),
    url: query.ur,
    attributionCode: query.at
  };
};

const createInitialAddress = (query) => {
  if (!query.t) return {};
  const country = COUNTRIES.find(c => c.keyName === query.co);
  const state = country?.regions.find(region => region.id === query.pr);
  return {
    country: country?.value,
    city: query.ci,
    url: query.ur,
    phone: query.p,
    streetAddress: query.sa,
    street: query.st,
    suite: query.su,
    state: state?.value,
    postalcode: query.pc
  };
};

const AddBusinessView = ({ isPlanViewable, property }) => {
  const { strings } = useI18Next();
  const [query] = useQuerystring();
  const isFromReservedIdToken = query.t;

  const [business] = useState(createInitialBusiness(query));
  const [address] = useState(createInitialAddress(query));
  const history = useHistory();
  const [language] = useLanguage();
  const user = useSelector(state => auth.selectors.getUser(state));
  const canCreateFromReservedIdToken = usePermissions({}, ['business.fromReservedIdToken']);
  const canTag = usePermissions({ propertyId: property.id }, ['property.tag.create']);

  const handleCancel = () => {
    if (history.length > 1) return history.goBack();
    return history.replace('../');
  };

  const [businessManager] = useState(
    () => {
      const businessManagerInstance = createWizard({
        property,
        business,
        address,
        user,
        history,
        isPlanViewable,
        locale: language.value,
        onComplete: (finalBusiness, planDetails) => {
          const path = planDetails
            ? `/businesses/register/complete/${finalBusiness.id}?planId=${planDetails.chargebeePlanId}`
            : `/business/${finalBusiness.id}`;
          history.push(path);
        },
        onCancel: handleCancel
      });

      businessManagerInstance.start();

      return businessManagerInstance;
    },
    []
  );

  if (isFromReservedIdToken && (canCreateFromReservedIdToken === false || canTag === false)) {
    return (
      <Flex direction="column" p={8}>
        <Center flexGrow={1}>
          <Stack spacing={8} alignItems="center">
            <RiAlertLine size={50} opacity="50%" />
            <Stack spacing={4} alignItems="center">
              <Text fontSize="xl" bold>Permission Denied</Text>
              <Text>You have insufficient permissions to activate this business.</Text>
            </Stack>
            <Button onClick={handleCancel} colorScheme="blue">Go Back</Button>
          </Stack>
        </Center>
      </Flex>
    );
  }

  return (
    <WizardModal
      manager={businessManager}
      plansViewModel={plans}
      isBusinessSignUp={!isFromReservedIdToken}
      submittingChildren={
        <Flex flexDirection="column">
          <Text textAlign="center">
            {strings('dashboard.component.businessSelectView.isLoading')}
          </Text>
          <Text textAlign="center">
            {strings('dashboard.component.businessSelectView.noRefresh')}
          </Text>
        </Flex>
      }
    />
  );
};

AddBusinessView.propTypes = {
  isPlanViewable: PropTypes.bool,
  property: PropTypes.object.isRequired
};

const AddBusinessViewWrapper = ({ isPlanViewable, property }) => {
  if (!property) return null;
  return (
    <AddBusinessView property={property} isPlanViewable={isPlanViewable} />
  );
};

AddBusinessViewWrapper.propTypes = {
  isPlanViewable: PropTypes.bool,
  property: PropTypes.object
};

export default AddBusinessViewWrapper;
