import React from 'react';
import { Alert, AlertIcon, AlertTitle } from '@chakra-ui/react';

const AlertBanner = ({
  alertMessage,
  alertStatus
}: {
  alertMessage: string,
  alertStatus: "info" | "warning" | "success" | "error"
}): JSX.Element | null => {
  const showBanner = alertMessage && alertStatus;

  return showBanner ? (
    <Alert status={alertStatus}>
      <AlertIcon />
      <AlertTitle mr={2}>{alertMessage}</AlertTitle>
    </Alert>
  ) : null;
};

export default AlertBanner;
