import React from 'react';
import {
  Button,
  Center,
  Container,
  Heading,
  Image,
  VStack,
  Text,
} from '@chakra-ui/react';
import heroImage from './assets/socialAIBeta.png';

const SocialViewPreview = () => {
  const mailtoLink = 'mailto:support@getintheloop.ca?subject=Learn more about Social AI';
  return (
    <Center minHeight="90vh">
      <Container maxWidth="575px">
        <VStack gap="2em">
          <Image src={heroImage} />
          <Heading size="lg" sx={{ textAlign: 'center' }}>Create Engaging Social Media Posts, Automatically</Heading>
          <Text fontSize="lg" align="center">
            GetintheLoop’s Social AI generates engaging social media posts based on your offers, promotions,
            and loyalty programs. Unleash the power of AI to supercharge your social media, increase engagement,
            and drive meaningful interactions. The best part? It’s automatic, no extra work required!
          </Text>
          <Button
            size="lg"
            colorScheme="blue"
            as="a"
            href={mailtoLink}
            target="_blank"
          >Apply for Early Access</Button>
        </VStack>
      </Container>
    </Center>
  );
};

export default SocialViewPreview;
