import React from 'react';
import PropTypes from 'prop-types';

import { injectClient } from 'shared/src/components/contexts/ClientContext';

import Image from '../elements/Image';
import Request from '../../data/Request';
import WaitFor from '../../data/WaitFor';

const OfferImage = ({ imageId, client }) => {
  if (!imageId) return null;
  return (
    <Request
      request={({ imageId }) => client.images.for(imageId).details()} // eslint-disable-line no-shadow
      params={{ imageId }}
      cache="image"
    >
      {({ data, loaded }) => (
        <WaitFor waitFor={loaded}>
          <div style={{ overflow: 'hidden', width: '100%', height: '245px' }}>
            <Image className='mla mra' src={data && data.url}/>
          </div>
        </WaitFor>
      )}
    </Request>
  );
};

OfferImage.propTypes = {
  imageId: PropTypes.string,
  client: PropTypes.object
};

export default injectClient(OfferImage);
