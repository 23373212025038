import {
  Box,
  Flex,
  Image,
  LinkOverlay,
  LinkBox,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  TableCell,
  TableList,
  TableRow,
  Empty,
} from 'web-react-ui/src/chakra/TableList/TableList';

import Business from 'web-react-ui/src/types/Business.interface';
import iconizeImageUrl from 'web-react-ui/src/utils/iconizeImageUrl';
import ContentTypeBadge from './components/ContentTypeBadge';
import NetworkIcon from './components/NetworkIcon';
import StatusBadge from './components/StatusBadge';
import Post from './interfaces/Post.interface';

type PagedListResult<T> = {
  items: Array<T>,
  loading: boolean,
  empty: boolean
}

const postColumns = [
  {
    key: 'content',
    label: 'Content',
    cell: {
      w: '100%',
    },
    skeletonCircle: {
      boxSize: '6em',
    },
    skeletonText: {
      noOfLines: 2,
      w: '100%',
    },
  },
  {
    key: 'date',
    label: 'Scheduled Date',
    cell: {
      minW: '12rem',
    },
    skeletonText: {
      noOfLines: 1,
    },
  },
  {
    key: 'status',
    label: 'Status',
    cell: {
      minW: '8rem',
      textAlign: 'right',
    },
    skeletonText: {
      noOfLines: 1,
    },
  },
];

const PostList = ({ pagedList, business }: { pagedList: PagedListResult<Post>, business: Business }) => {
  return (
    <TableList columns={postColumns} isLoading={pagedList.loading}>
      <Empty isEmpty={pagedList.empty}>
        <Text fontSize="xl">No Posts To Display</Text>
      </Empty>
      {pagedList.items.map((post: Post) => (
        <PostItem key={post.id} post={post} business={business} />
      ))}
    </TableList>
  );
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return `${
    date.toLocaleDateString('en-CA')
  } ${
    date.toLocaleTimeString('en-CA', {
      weekday: 'short',
      hour: 'numeric',
      minute: '2-digit',
    })}`;
};

// eslint-disable-next-line max-len
const emptyFallbackImageSrc = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

const PostItem = ({ business, post }: { business: Business, post: Post }) => {
  return (
    <LinkBox as={TableRow}>
      <TableCell column={postColumns[0]}>
        <Flex gap="1em" align="center">
          <Box position="relative" flexShrink={0}>
            <Box position="absolute" top="-0.5em" right="-0.5em" fontSize="16px" color="#4267B2">
              <NetworkIcon id={post.socialNetwork} fontSize="1.5em" />
            </Box>
            <Image
              src={iconizeImageUrl(post.output.media?.[0], { size: 72 }) || emptyFallbackImageSrc}
              boxSize="72px"
              minW="72px"
              bg="#eee"
            />
          </Box>
          <Flex direction="column">
            <LinkOverlay as={Link} to={`/business/${business.id}/social/${post.id}`}>
              <Flex gap="1em" align="center">
                <ContentTypeBadge type={post.contentType} />
                <Text fontWeight="bold">{post.campaignName}</Text>
              </Flex>
            </LinkOverlay>
            <Text noOfLines={1}>{post.output.message}</Text>
          </Flex>
        </Flex>
      </TableCell>
      <TableCell column={postColumns[1]}>
        <Text whiteSpace="nowrap">{formatDate(post.scheduledDate)}</Text>
      </TableCell>
      <TableCell column={postColumns[2]}>
        <StatusBadge status={post.displayState} />
      </TableCell>
    </LinkBox>
  );
};

export default PostList;
