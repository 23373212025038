import {
  Badge,
} from '@chakra-ui/react';
import React from 'react';
import getContentTypeProps from './getContentTypeProps';

const ContentTypeBadge = ({ type, ...rest }: { type: string }) => {
  const { label, ...propsRest } = getContentTypeProps(type);

  return (
    <Badge
      py={1}
      px={2.5}
      borderRadius={4}
      textTransform="uppercase"
      {...rest}
      {...propsRest}
    >
      {label}
    </Badge>
  );
};

export default ContentTypeBadge;
