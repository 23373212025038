import React from 'react';
import { Box, Flex, FlexProps, Heading } from '@chakra-ui/react';

interface Props extends FlexProps {
  title: string;
}

const ViewHeader = ({ title, children, fontSize }: Props): JSX.Element => (
  <Flex align='center' justify='space-between' mt='16px' mb='24px' w='100%' gap="1em">
    <Heading
      fontWeight={700}
      fontSize={fontSize || '24px'}
      textTransform='capitalize'
      m={0}
    >
      {title}
    </Heading>
    {children && (
      <Box>
        {children}
      </Box>
    )}
  </Flex >
);

export default ViewHeader;
