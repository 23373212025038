import { memo } from 'react';
import useQuerystring from 'shared/src/hooks/useQuerystring';
import { setAttributionContext } from '../../services/getAttributionContext';

const AttributionCatcher = () => {
  const [query] = useQuerystring();

  if (query.utm_source || query.utm_medium || query.utm_campaign) {
    setAttributionContext({
      source: query.utm_source,
      medium: query.utm_medium,
      campaign: query.utm_campaign
    });
  }

  return null;
};

export default memo(AttributionCatcher);
