/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React from 'react';

import { getLabelForType } from 'shared/src/constants/socialProfiles';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useTheme
} from '@chakra-ui/react';
import Label from 'web-react-ui/src/components/elements/Label';
import ImageRenderer from 'web-react-ui/src/components/image/ImageRenderer';
import IfPermissions from 'web-react-ui/src/components/utils/IfPermissions';
import Request from 'web-react-ui/src/data/Request';
import WaitFor from 'web-react-ui/src/data/WaitFor';

import { strings } from '../../i18n';
import client from '../../services/client';
import { usePlanState } from '../../hooks/usePlan';

const BusinessProfileImage = ({ imageId, businessName }) => {
  if (!imageId) return null;
  return (
    <Request
      request={({ id }) => client.images.for(id).details()}
      params={{ id: imageId }}
    >
      {({ data, loaded }) => (
        <WaitFor waitFor={loaded}>
          <div className="mr1">
            <ImageRenderer imageUrl={data && data.url} name={businessName} size="lg" />
          </div>
        </WaitFor>
      )}
    </Request>
  );
};

BusinessProfileImage.propTypes = {
  imageId: PropTypes.string,
  businessName: PropTypes.string
};

function BusinessHeader(props) {
  const { business } = props;
  if (!business) {
    return null;
  }

  const { plan } = usePlanState();
  const theme = useTheme();

  return (
    <Flex bg={props.bg || theme.colors.gray[50]} flexDirection="column" p={props.p ?? 4} borderRadius={4}>
      <Flex mb={4}>
        <BusinessProfileImage imageId={business.profileImage} businessName={business.name} />
        <Box ml={4}>
          <Heading size="lg" fontWeight={500} mb={2}>{business.name}</Heading>
          {business.categories && (
            <Label.Group>
              {business.categories.map(category => <Label key={category.id}>{category.name}</Label>)}
            </Label.Group>
          )}
        </Box>
        <IfPermissions context={{ businessId: business.id }} permissions={['business.update']}>
          <Button
            as={NavLink}
            to={`/business/${business.id}/edit`}
            ml="auto"
            minW="80px"
          >
            {strings('dashboard.action.editDetails')}
          </Button>
        </IfPermissions>
      </Flex>
      <Box>
        {plan && (
          <Text><strong>{strings('dashboard.label.plan')}</strong>: {plan.name}</Text>
        )}
      </Box>
      {(!business.isEnabled) && (
        <Box mt={4}>
          <Label color="red">{strings('dashboard.label.disabled')}</Label>
        </Box>
      )}
      <Text mt={4}>{business.description}</Text>
      {business.url && (
        <Flex align="center">
          <Text mr={4} mb={0}><strong>{strings('dashboard.label.website')}</strong>:</Text>
          <Text mb={0}>{business.url}</Text>
        </Flex>
      )}
      {business.phone && (
        <Flex align="center">
          <Text weight={500} mr={4} mb={0}><strong>{strings('dashboard.label.phone')}</strong>:</Text>
          <Text mb={0}>{business.phone}</Text>
        </Flex>
      )}
      {business.socialProfiles?.map(({ type, id }) => {
        return (
          <Flex align="center" key={type}>
            <Text mr={4} mb={0}><strong>{getLabelForType(type) || type}</strong>:</Text>
            <Text mb={0}>{id}</Text>
          </Flex>
        );
      })}
    </Flex>
  );
}

BusinessHeader.propTypes = {
  bg: PropTypes.any,
  p: PropTypes.any,
  business: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    phone: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    isEnabled: PropTypes.bool,
    profileImage: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    })),
  })
};

export default BusinessHeader;
