import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import Message from '../../../components/collections/Message';
import Button from '../../../components/elements/Button';
import { useFormState } from '../../../reactFinalForm';
import LocalizationWrapper from './LocalizationWrapper';

const DefaultLocaleMessage = ({ localeInfoKey }) => {
  const { locale, setLocale, locales } = useContext(LocalizationWrapper.context);
  const form = useFormState({ subscription: { values: true } });
  const { strings } = useI18Next();

  if (!(locales && locales.length > 1)) return null;
  const values = form.values;
  const modelLocaleInfo = _get(values, localeInfoKey);
  const modelDefaultLocale = _get(modelLocaleInfo, 'modelDefaultLocale');
  const defaultLocale = locales[0];

  if (!modelDefaultLocale) return null;
  if (defaultLocale === modelDefaultLocale) return null;

  const isNonDefault = (locale !== defaultLocale);

  return (
    <Message info>
      {strings('ui.component.defaultLocaleMessage.message')} &nbsp;&nbsp;

      {isNonDefault ?
        <Button
          type='button'
          onClick={() => setLocale(defaultLocale)}
        >
          {strings('ui.component.defaultLocaleMessage.switchTo')} &nbsp;
          {strings(`shared.languages.${defaultLocale}`)}
        </Button>
        : null}
    </Message>
  );
};

DefaultLocaleMessage.propTypes = {
  localeInfoKey: PropTypes.string
};

export default DefaultLocaleMessage;
