import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Code,
  Flex,
  Skeleton,
  SkeletonText,
  Text,
  Td,
  Table,
  Tbody,
  Tr,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import {
  RootStateOrAny,
  useSelector,
} from 'react-redux';
import {
  Link,
  useHistory,
  useParams,
} from 'react-router-dom';

import confirm from 'shared/src/helpers/confirm';
import useRequest from 'shared/src/hooks/useRequest';
import ActionMenu from 'web-react-ui/src/components/actionMenu/ActionMenu';
import PageBackButton from 'web-react-ui/src/chakra/page/PageBackButton';
import PageHeader from 'web-react-ui/src/chakra/page/PageHeader';
import PageLayout from 'web-react-ui/src/chakra/page/PageLayout';
import businessModule from '../../../../modules/businesses';
import useProperty from '../../../useProperty';
import client from '../../../../services/client';
import getPostPageTitle from '../components/getPostPageTitle';
import SectionHeader from '../components/SectionHeader';
import StatusBadge from '../components/StatusBadge';
import Post from '../interfaces/Post.interface';
import Content from './ContentSection';
import Preview from './PreviewSection';

const fetchPost = async ({ businessId, postId }: { businessId: string, postId: string }): Promise<Post> => {
  return client
    .businesses.for(businessId)
    .social.posts.for(postId)
    .details();
};

const deletePost = async ({ businessId, postId }: { businessId: string, postId: string }): Promise<void> => {
  return client
    .businesses.for(businessId)
    .social.posts.for(postId)
    .delete();
};

const PostDetails = () => {
  const history = useHistory();
  const params = useParams<{ postId: string }>();
  const property = useProperty();
  const business = useSelector((state: RootStateOrAny) => businessModule.selectors.business.getData(state));
  const postRequest = useRequest(
    fetchPost,
    { propertyId: property.id, businessId: business.id, postId: params.postId },
  );
  const post = postRequest.result;
  const canEdit = post?.state === 'scheduled' && post?.contentType !== 'story';
  const canDelete = post?.state === 'scheduled';

  const actions = useMemo(
    () => ([
      {
        label: 'Edit',
        hidden: !canEdit,
        extraProps: {
          as: Link,
          to: `./${params.postId}/edit`,
        },
      },
      {
        label: 'Delete',
        hidden: !canDelete,
        action: async () => {
          const doDelete = confirm('Delete Post\n\nYou cannot undo this action.');
          if (!doDelete) return;
          await deletePost({ businessId: business.id, postId: params.postId });
          history.replace('../social');
        },
      },
    ]),
    [params.postId, post, canEdit, canDelete],
  );

  const pageTitle = getPostPageTitle(post);

  return (
    <PageLayout>
      <PageBackButton to="../social">Back</PageBackButton>

      <Skeleton isLoaded={post}>
        <PageHeader controls={<ActionMenu actions={actions} />}>{pageTitle}</PageHeader>
      </Skeleton>

      <Flex wrap="wrap" gap="3em">
        <Flex direction="column" gap="3em" flex="1" minW="40ch" alignItems="start">
          <Details post={post} />

          <SectionHeader>Content</SectionHeader>
          <Content post={post} />

          <SectionHeader>{ /* Stats */}</SectionHeader>
          <Stats post={post} />
        </Flex>
        <Preview post={post} />
      </Flex>
    </PageLayout>
  );
};

const Details = ({ post }: { post: Post }) => {
  return (
    <Table variant="unstyled" w="auto">
      <Tbody>
        <Tr>
          <Td pt={0} pl={0}>
            Schedule Date
          </Td>
          <Td pt={0}>
            <SkeletonText isLoaded={!!post} noOfLines={1} minW="6em">
              <Text>{post?.scheduledDate}</Text>
            </SkeletonText>
          </Td>
        </Tr>
        <Tr>
          <Td pt={0} pl={0}>
            Status
          </Td>
          <Td pt={0}>
            <SkeletonText isLoaded={!!post} noOfLines={1} minW="6em">
              <Flex gap="1em" align="center">
                <StatusBadge status={post?.displayState} />
                {post?.socialNetworkUrl
                  ? (
                    <a href={post.socialNetworkUrl} target="_blank" rel="noreferrer">
                      Go To Post <ExternalLinkIcon />
                    </a>
                  )
                  : undefined
                }
                {!!post?.errorCode && (
                  <details>
                    <summary><Code>{post.errorCode}</Code></summary>
                    <Code>{post.errorMessage}</Code>
                  </details>
                )}
              </Flex>
            </SkeletonText>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
};

const Stats = ({ post }: { post: Post }) => {
  if (!post) {
    return (
      <Skeleton w="100%" h="2em" />
    );
  }

  return <></>;
};

export default PostDetails;
