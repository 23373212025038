import React from 'react';
import OfferTypeSelection from 'web-react-ui/src/components/offers/OfferTypeSelection';
import useProperty from '../useProperty';

const OfferTypeSelectionWrapper = (props) => {
  const property = useProperty();
  return <OfferTypeSelection dashboard propertyId={property.id} {...props}/>;
};

export default OfferTypeSelectionWrapper;
