/* eslint-disable-next-line no-redeclare */
import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Image,
  Link as ChakraLink,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import useQuerystring from 'shared/src/hooks/useQuerystring';

import Dropdown from 'web-react-ui/src/components/modules/Dropdown';
import OktaWidget from 'web-react-ui/src/components/auth/OktaSignIn';

import Terms from '../siteFooter/Terms';
import client from '../../services/client';
import useLanguage from '../../hooks/useLanguage';
import i18next, {
  strings,
  LANGUAGE_OPTIONS,
} from '../../i18n';

import LMGlogo from '../../assets/gitl_logo.png';

const ConnectAccount = (): JSX.Element => {
  const [currentLanguage, handleLanguageChange] = useLanguage();

  const options = LANGUAGE_OPTIONS.map(lang => ({
    ...lang,
    text: strings(`shared.languages.${lang.key}`),
  }));

  const [queryString] = useQuerystring();

  // build signup link
  // @ts-ignore
  const signupUrl = new URL(window.location);
  signupUrl.searchParams.set('action', 'signup');

  return (
    <Flex justify="center" bg="gray.50" height="100vh">
      <Flex align="stretch" maxW="7xl">
        <Flex
          align="center"
          justify="center"
          direction="column"
          w={{ base: '100%', md: '50%' }}
          minW={480}
          bg="gray.50"
        >
          <Box
            p="40px"
            w="full"
            maxW={480}
            bg="white"
            rounded="8px"
            boxShadow="base"
            textAlign="left"
          >
            <Box mb="40px" width="146px">
              <Image src={LMGlogo} alt="lmg logo" fit="contain" />
            </Box>

            <Stack spacing={1} mb="32px">
              <Heading
                as="h1"
                mb={0}
                fontWeight="bold"
                fontSize={{ base: '28px', md: '30px' }}
              >
                Login to Authorize
              </Heading>
              <Text fontSize="xl">Login to connect your GetintheLoop account to Thryv.</Text>
            </Stack>

            <Stack spacing="16px">
              <OktaWidget
                key={i18next.language}
                client={client}
                i18next={i18next}
                redirectUri={`${window.location.protocol}//${window.location.host}/auth/sso/link`}
                state={{
                  provider: queryString.provider,
                  action: queryString.action,
                  payload: queryString.payload,
                  signature: queryString.signature,
                }}
              />
              <Stack spacing={5} my={5} borderTop='1px' borderBottom='1px' borderColor='gray.200' py={5}>
                <Text fontWeight={500} fontSize='xl'>
                  {`Don't`} have a GetintheLoop account?
                </Text>

                <ChakraLink
                  as={Link}
                  color='blue.400'
                  to={`${signupUrl.pathname}${signupUrl.search}`}
                  fontSize='xl'
                >
                  Create Account
                </ChakraLink>
              </Stack>
              <Terms />
              <Box w="120px">
                <Dropdown
                  compact
                  selection
                  selectOnBlur={false}
                  placeholder={strings(`dashboard.screen.preferences.${currentLanguage.key}`)}
                  options={options}
                  value={currentLanguage.value}
                  onChange={handleLanguageChange}
                />
              </Box>
            </Stack>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ConnectAccount;
