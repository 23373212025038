import PropTypes from 'prop-types';
import React from 'react';
import TranslationWrapper from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizationWrapper';

const PlatformTranslationWrapper = ({ children }) => {
  const locales = ['en', 'fr'];
  return (
    <TranslationWrapper locales={locales}>
      {children}
    </TranslationWrapper>
  );
};

PlatformTranslationWrapper.propTypes = {
  children: PropTypes.object
};

export default PlatformTranslationWrapper;
