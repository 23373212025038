/* global window */
const logError = (error) => {
  try {
    // Split out like this because our null chaining transform seems to call `error` without `this`
    if (window?.Rollbar?.error) return window.Rollbar.error(error);
    return console.error(error);
  } catch (err) {
    // like we can do anything if Rollbar blows up
  }
};

const logWarning = (message) => {
  try {
    if (window?.Rollbar?.warn) return window.Rollbar.warn(message);
    return console.warn(message);
  } catch (err) {
    // like we can do anything if Rollbar blows up
  }
};

const logInfo = (message) => {
  try {
    if (window?.Rollbar?.info) return window.Rollbar.info(message);
    return console.log(message);
  } catch (err) {
    // like we can do anything if Rollbar blows up
  }
};

export {
  logError,
  logWarning,
  logInfo
};
