/* global navigator */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Tooltip,
  Text,
  theme,
  Box
} from '@chakra-ui/react';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import { BUSINESS_DASHBOARD_ENV } from 'shared/models';

const RequestPaymentPlanLink = ({ plan, businessId }) => {
  const [hasCopiedUrl, setHasCopiedUrl] = useState(false);
  const { strings } = useI18Next();


  const tokens = [
    plan.key,
    plan.periodUnit === 'year' ? 'annual' : null
  ];

  const planKeyName = tokens.filter(Boolean).join('_');

  const handleCopyLink = () => {
    const urlToCopy = `
      ${BUSINESS_DASHBOARD_ENV[process.env.REACT_APP_ENV]}/business/${businessId}/plans?planId=${planKeyName}
    `;

    navigator.clipboard.writeText(urlToCopy);
    setHasCopiedUrl(true);
    setTimeout(() => setHasCopiedUrl(false), 2000);
  };

  const renderDynamicUrlText = () => {
    const requestPartnerPaymentText =
      <Text color={theme.colors.blue[600]} fontWeight={600} _hover={{ color: 'blue.400', cursor: 'pointer' }}>
        {strings(`ui.component.planCardAction.requestPaymentPlan.${plan.key}`)}
      </Text>;

    if (!hasCopiedUrl) {
      return requestPartnerPaymentText;
    }
    return (
      <Tooltip
        defaultIsOpen
        label={strings(`ui.component.planCardAction.copiedPartnerPaymentLink.${plan.key}`)}
        onClose={() => setHasCopiedUrl(false)}
      >
        {requestPartnerPaymentText}
      </Tooltip>
    );
  };
  return (
    <Flex alignItems="center" justifyContent="center" mt={2} onClick={handleCopyLink}>
      {renderDynamicUrlText()}
    </Flex>
  );
};

RequestPaymentPlanLink.propTypes = {
  plan: PropTypes.shape({
    keyName: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.number,
    platformId: PropTypes.number,
    currentPlan: PropTypes.object,
    isCurrentPlan: PropTypes.boolean,
    price: PropTypes.shape({
      CA: PropTypes.shape({
        value: PropTypes.number,
        currencyCode: PropTypes.string,
        chargebeePlanId: PropTypes.string,
      }),
      US: PropTypes.shape({
        value: PropTypes.number,
        currencyCode: PropTypes.string,
        chargebeePlanId: PropTypes.string,
      }),
    }),
    features: PropTypes.string,
    selectButtonLabel: PropTypes.string,
    selectButtonAction: PropTypes.string,
  }),
  businessId: PropTypes.string
};

export default RequestPaymentPlanLink;
