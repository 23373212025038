import React from 'react';
import PropTypes from 'prop-types';
import lodashSet from 'lodash.set';

import ClientContext from 'shared/src/components/contexts/ClientContext';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import { allSettled } from '../../utils/promise';
import WaitFor from '../../data/WaitFor';
import Map from '../../map/Map';
import Button from '../elements/Button';
import View from '../layout/View';
import Message from '../collections/Message';
import InlineSupportLink from '../../support/InlineSupportLink';
import { WizardStep } from '../wizard/WizardManager';

// Toronto
const defaultCentre = {
  lat: 43.76153,
  long: -79.41107
};

const searchPostalCode = (client, postalcode) => {
  if (!postalcode || !postalcode.length || postalcode.length < 6) {
    // Skip it
    return Promise.reject(new Error('Invalid postalcode'));
  }
  // OSM requires postal codes to have a space in the middle & be uppercase
  let pc = postalcode.replace(/\s/g, '').split('');
  pc.splice(3, 0, ' ');
  pc = pc.join('').toUpperCase();
  return client.addresses.discover({ q: pc });
};

// It's MUCH to easy to get OSM to return garbage.
// This makes 2 loads so that hopefully something sensible can be
// used as a fallback.
const getLocationForAddress = async (client, address) => {
  const {
    streetAddress, street, city, state, country, postalcode
  } = address;
  const fullAddress = `${streetAddress} ${street}, ${city}, ${state}, ${country}`;
  const cityOnly = `${city}, ${state}, ${country}`;
  return allSettled([
    client.addresses.discover({ q: fullAddress }),
    searchPostalCode(client, postalcode),
    client.addresses.discover({ q: cityOnly })
  ])
    .then((res) => {
      const [addressLookup, pcLookup, cityLookup] = res;
      let option;
      if (addressLookup.status === 'fulfilled') {
        option = addressLookup.value.items.length ? addressLookup.value.items[0] : {};

        // Only use the address if it's going to be roughly correct.
        // Fixes https://github.com/loopmediagroup/business-dashboard-web/issues/699
        if (option.centre && option.city && option.city.toLowerCase().indexOf(city.trim().toLowerCase()) >= 0) {
          return { centre: option.centre, isFallback: false };
        }
      }

      // Check postalcode query
      if (pcLookup.status === 'fulfilled') {
        option = pcLookup.value.items.length ? pcLookup.value.items[0] : {};

        // Same city "fitting" rules apply for postalcode lookups
        if (option.centre && option.city && option.city.toLowerCase().indexOf(city.trim().toLowerCase()) >= 0) {
          return { centre: option.centre, isFallback: true };
        }
      }

      // Fallback to city query
      if (cityLookup.status === 'fulfilled') {
        option = cityLookup.value.items.length ? cityLookup.value.items[0] : {};
        if (option.centre) {
          return { centre: option.centre, isFallback: true };
        }
      }

      // Fallback to Toronto... :(
      return { centre: defaultCentre, isFallback: true };
    });
};

const ConfirmMapLocationStep = ({ step, manager }) => {
  const client = React.useContext(ClientContext);
  const { strings } = useI18Next();
  const { address = {} } = step.data;
  const { centre = {} } = address;
  const [isGeocoding, setIsGeocoding] = React.useState(false);
  const [isFallbackLocation, setIsFallbackLocation] = React.useState(false);

  const [editMode, setEditMode] = React.useState(false);
  const [lat, setLat] = React.useState(centre.lat || 0);
  const [long, setLong] = React.useState(centre.long || 0);
  // Only re-create marker when not dragging
  const [markerVersion, setMarkerVersion] = React.useState(0);

  const [fitToMarkers, setFitToMarkers] = React.useState(true);

  React.useEffect(() => {
    // Skip if there is already a centre on the address
    if (centre && centre.lat && centre.long) return;

    // Find a good pin starting spot
    setIsGeocoding(true);
    getLocationForAddress(client, address)
      .then((res) => {
        if (!res.centre) return false;
        setLat(res.centre.lat);
        setLong(res.centre.long);
        setMarkerVersion(markerVersion + 1);
        setIsFallbackLocation(res.isFallback);
        return true;
      })
      .finally(() => setIsGeocoding(false));
  }, [step, setLat, setLong]);

  // Capture last center when entering edit mode so we can reset
  const lastCenter = React.useMemo(
    () => ({ lat, long }),
    [editMode]
  );

  const markers = React.useMemo(
    () => [{
      center: [lat, long],
      draggable: editMode,
      isEnabled: editMode // make pin blue
    }],
    [editMode, markerVersion]
  );

  const onMarkerUpdate = React.useCallback((e) => {
    setLat(e.latlng.lat);
    setLong(e.latlng.lng);
  });

  const onComplete = React.useCallback((newLat, newLong) => {
    lodashSet(step.data, 'address.centre.lat', newLat);
    lodashSet(step.data, 'address.centre.long', newLong);
    step.stepComplete(step.data);
  });

  const editMarker = () => {
    setFitToMarkers(false);
    setEditMode(true);
  };

  const finishEdit = (cancel) => {
    if (cancel) {
      setLat(lastCenter.lat);
      setLong(lastCenter.long);
    }

    setFitToMarkers(true);
    setEditMode(false);
  };

  return (
    <View>
      <View.Section>
        <h2>{strings('ui.component.confirmMapLocationStep.confirmMapPin')}</h2>
        <p>{strings('ui.component.confirmMapLocationStep.confirmCorrectBusiness')}</p>
        {isFallbackLocation &&
          <Message warning><p>{strings('ui.component.confirmMapLocationStep.fallbackLocation')}</p></Message>
        }
        <p>{strings('ui.component.confirmMapLocationStep.ifCorrectBusiness')}:</p>
        <ul className='paragraph'>
          <li>
            {strings('ui.component.confirmMapLocationStep.fragment.clickThe')}&nbsp;
            <em>{strings('ui.component.confirmMapLocationStep.movePin').toUpperCase()}</em>&nbsp;
            {strings('ui.component.confirmMapLocationStep.fragment.toMove')}
          </li>
          <li>
            {strings('ui.component.confirmMapLocationStep.fragment.clickThe')}&nbsp;
            <em>{strings('ui.component.confirmMapLocationStep.savePin').toUpperCase()}</em>&nbsp;
            {strings('ui.component.confirmMapLocationStep.fragment.toUpdate')}&nbsp;
            <em>{strings('ui.label.cancel').toUpperCase()}</em>&nbsp;
            {strings('ui.component.confirmMapLocationStep.fragment.toSetback')}
          </li>
        </ul>
      </View.Section>
      {!editMode ? (
        <View.Section narrow className='flex'>
          <Button
            disabled={editMode}
            className='mla'
            onClick={editMarker}
          >
            {strings('ui.component.confirmMapLocationStep.movePin')}
          </Button>
        </View.Section>
      ) : (
        <View.Section narrow className='flex'>
          <Button
            onClick={() => finishEdit(true)}
          >
            {strings('ui.label.cancel')}
          </Button>
          <Button
            primary
            onClick={() => finishEdit(false)}
            className='mla'
          >
            {strings('ui.component.confirmMapLocationStep.savePin')}
          </Button>
        </View.Section>
      )}
      <View.Section>
        <WaitFor waitFor={!isGeocoding} wrapContents>
          <Map
            markers={markers}
            onMarkerUpdate={onMarkerUpdate}
            fitToMarkers={fitToMarkers}
            initialZoom={15} />
        </WaitFor>
      </View.Section>
      <View.Section narrow className='flex'>
        <Button
          type='button'
          className='mra'
          onClick={() => manager.showPreviousStep()}
        >
          {strings('ui.component.wizard.previous')}
        </Button>
        <Button
          primary
          onClick={() => onComplete(lat, long)}
          className='mla'
          disabled={editMode || isGeocoding}
        >
          {strings('ui.component.confirmMapLocationStep.confirmLocation')}
        </Button>
      </View.Section>
      <View.Section narrow>
        <p>{strings('ui.label.haveAQuestion')} <InlineSupportLink />.</p>
      </View.Section>
    </View>
  );
};

ConfirmMapLocationStep.propTypes = {
  step: PropTypes.object
};

export default () => new WizardStep({
  key: 'confirmLocation',
  getTitle: data => data.business.name,
  render: ConfirmMapLocationStep
});
