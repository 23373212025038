import usePermissions from 'shared/src/hooks/usePermissions';
import Property from 'web-react-ui/src/types/Property.interface';
import useBillingDetails from '../../../hooks/useBillingDetails';
import { usePlanState } from '../../../hooks/usePlan';
import useProperty from '../../useProperty';

const DEFAULT_STATE = {
  isReady: false,
  property: {} as Property
};

const useInitialState = (businessId: string): {
  property: Property,
  hasPermission?: boolean,
  platformPlan?: any,
  billingAddress?: any,
  billingPlan?: any,
  isReady: boolean
} => {
  const property = useProperty();
  const propertyId = property?.id;
  const hasPermission = usePermissions({ propertyId, businessId }, ['property.business.chargebee.checkout.create']);
  const platformPlan = usePlanState();
  const { billingDetails, isLoading } = useBillingDetails({ propertyId, businessId });

  const isReady = property && platformPlan && !isLoading && hasPermission !== undefined;

  if (!isReady) return DEFAULT_STATE;

  return {
    property,
    hasPermission,
    platformPlan,
    billingAddress: billingDetails?.billingAddress,
    billingPlan: billingDetails?.subscription,
    isReady,
  };
};

export default useInitialState;
