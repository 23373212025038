import React from 'react';
import PropTypes from 'prop-types';

import { Tag, TagLabel, TagCloseButton } from '@chakra-ui/react';

const TagsWithCloseBtn = ({ data, handleRemoveTag }) => (
  !!data?.length && data.filter(plan => plan.isChecked).map(plan => (
    <Tag key={plan.id} size='lg' variant='solid' mr='4px' mt='4px' borderRadius='lg'>
      <TagLabel>{plan.name}</TagLabel>
      <TagCloseButton onClick={() => handleRemoveTag(plan)} />
    </Tag>
  ))
);

TagsWithCloseBtn.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isChecked: PropTypes.bool,
  })).isRequired,
  handleRemoveTag: PropTypes.func.isRequired
};

export default TagsWithCloseBtn;
