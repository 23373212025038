import React from 'react';
import {
  Box,
  Flex,
  Button,
  Link,
  Center
} from '@chakra-ui/react';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import RequestPaymentPlanLink from './RequestPaymentPlanLink';
import PLAN_ACTIONS from './planActions';

const getLabel = (plan, isLocalOwnerDashboard, strings) => {
  switch (plan.action) {
    case PLAN_ACTIONS.select:
      return strings('ui.component.planCardAction.select');
    case PLAN_ACTIONS.upgrade:
      return strings('ui.component.planCardAction.upgradeNow');
    case PLAN_ACTIONS.contact:
      return isLocalOwnerDashboard
        ? strings('ui.component.planCardAction.requestFromHq')
        : strings('ui.component.planCardAction.contactUs');
    case PLAN_ACTIONS.currentPlan:
      return strings('ui.label.currentPlan');
    case PLAN_ACTIONS.selected:
      return 'Selected';
    case PLAN_ACTIONS.notAvailable:
      return strings('dashboard.screen.registerComplete.notAvailable');
    default:
      return '';
  }
};

const PlanCardAction = ({ plan, businessId, onSelect, isLocalOwnerDashboard }) => {
  const { strings } = useI18Next();

  const label = getLabel(plan, isLocalOwnerDashboard, strings);

  const disabled = [
    PLAN_ACTIONS.currentPlan,
    PLAN_ACTIONS.selected,
    PLAN_ACTIONS.notAvailable
  ].includes(plan.action);

  return (
    <Flex flexDir="column" flex={1} my={5}>
      {plan.action === PLAN_ACTIONS.contact ? (
        <Center>
          <Link
            color="blue.500"
            m="3"
            fontSize="lg"
            textDecoration="underline"
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=Change%20Plan%20Request`}
          >
            {label}
          </Link>
          <Box mt={2}>&nbsp;</Box>
        </Center>
      ) : (
        <>
          <Button
            colorScheme="blue"
            disabled={disabled}
            data-testid={`button-${plan.keyName}`}
            onClick={() => onSelect(plan)}
            mb={1}
          >
            {label}
          </Button>
          {plan.action === PLAN_ACTIONS.upgrade && isLocalOwnerDashboard ? (
            <RequestPaymentPlanLink plan={plan} businessId={businessId} />
          ) : (
            <Box mt={2}>&nbsp;</Box>
          )}
        </>
      )}
    </Flex>
  );
};

export default PlanCardAction;
