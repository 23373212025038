/* eslint no-underscore-dangle: 0 */
/* eslint-disable-next-line no-redeclare */
/* global window */
import { createStore, applyMiddleware, compose } from 'redux';
import authMiddleware from 'shared/src/modules/auth/middleware';
import businessMiddleware from '../modules/businesses/middleware';
import rootReducer from '../rootReducer';
import property from '../modules/property/propertyMiddleware';
import plan from '../modules/plan/planMiddleware';
import client from '../services/client';

// Redux Devtools -- skipped in production
const composeEnhancers = (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const middlewares = [authMiddleware(client), businessMiddleware(client)];

export default createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(
    ...middlewares,
    property,
    plan
  ))
);
