/* eslint-disable-next-line no-redeclare */
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import useQuerystring from 'shared/src/hooks/useQuerystring';
import useRequest from 'shared/src/hooks/useRequest';
import ClientContext from 'shared/src/components/contexts/ClientContext';

import SsoError from './error';
import SsoLayout from './SsoLayout';

const parseState = (state: string) => {
  try {
    return JSON.parse(state);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return {};
  }
};

const linkAccount = ({
  client,
  code,
  state,
}: {
  client: any,
  code: string,
  state: { provider: string, payload: string, signature: string }
}) => {
  const redirectUri = `${window.location.protocol}//${window.location.host}/auth/sso/link`;

  return client.auth.loginSsoRequest(
    state.provider,
    ['link-user', 'login-user'],
    state.payload,
    state.signature,
    code,
    redirectUri,
  );
};

const redirectToSso = (history: History, state: any) => {
  // @ts-ignore
  const url = new URL(window.location);
  url.search = '';
  url.pathname = '/apps/connect';
  url.searchParams.set('provider', state.provider);
  url.searchParams.set('action', state.action);
  url.searchParams.set('payload', state.payload);
  url.searchParams.set('signature', state.signature);

  history.replace(`${url.pathname}${url.search}`);
};

const ConnectAccount = (): JSX.Element => {
  const [queryString] = useQuerystring();
  const history = useHistory();
  const client = useContext(ClientContext);

  const code = queryString.code;
  const state = parseState(queryString.state);

  const linkRequest = useRequest(
    linkAccount,
    {
      client,
      code,
      state,
    },
  );

  if (linkRequest.result) {
    redirectToSso(history, state);
  }

  if (linkRequest.error) {
    return (
      <SsoLayout returnUrl={state.payload.returnUrl}>
        <SsoError title='Unable to Link Account' error={linkRequest.error} />
      </SsoLayout>
    );
  }

  return (
    <SsoLayout>
      <h1>Authorizing...</h1>
    </SsoLayout>
  );
};

export default ConnectAccount;
