import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@chakra-ui/react';
import { BiFilter } from 'react-icons/bi';
import TagsWithCloseBtn from 'web-react-ui/src/chakra/tag/TagsWithCloseBtn';
import Dropdown from 'web-react-ui/src/chakra/Dropdown';
import FilterForm from 'web-react-ui/src/chakra/FilterForm';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

const PlanFilter = ({
  plans,
  checkedPlans,
  handleCheckedPlans,
  handleUnCheckedPlan,
  isOpen,
  onOpen,
  onClose
}) => {
  // If we don't have at least two plans, what's the point?
  if ((plans?.items?.length || 0) < 2) {
    return null;
  }

  const { strings } = useI18Next();
  const hasCheckedPlans = checkedPlans.some(item => item.isChecked);

  return (
    <Flex
      flexDirection={{ base: 'column-reverse', lg: 'row' }}
      alignItems={{ base: 'flex-start', lg: 'center' }}
      mb={{ base: '32px', lg: 0 }}
    >
      {hasCheckedPlans && (
        <Flex flexWrap='wrap' mt={{ base: '16px', lg: 0 }}>
          <TagsWithCloseBtn data={checkedPlans} handleRemoveTag={handleUnCheckedPlan} />
        </Flex>
      )}

      <Dropdown
        buttonText={strings('dashboard.component.filter.filterByPlan')}
        icon={BiFilter}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      >
        <FilterForm
          plans={checkedPlans}
          handleCheckedPlans={handleCheckedPlans}
          onClose={onClose}
        />
      </Dropdown>
    </Flex>
  );
};

PlanFilter.propTypes = {
  plans: PropTypes.object,
  checkedPlans: PropTypes.arrayOf(PropTypes.object),
  handleCheckedPlans: PropTypes.func,
  handleUnCheckedPlan: PropTypes.func,
  handleRemoveTag: PropTypes.func,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

export default PlanFilter;
