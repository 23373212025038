import { types } from './types';

export const details = args => ({
  type: types.BUSINESS_DETAILS_REQUEST,
  payload: {
    businessId: args.businessId,
    propertyId: args.propertyId,
  }
});

export const invalidate = () => ({
  type: types.BUSINESS_INVALIDATE,
});

export const invalidateAndRefresh = args => ({
  type: types.BUSINESS_INVALIDATE_REFRESH,
  payload: {
    businessId: args.businessId,
    propertyId: args.propertyId
  }
});

export const setHasLoyalty = () => ({
  type: types.BUSINESS_SET_HAS_LOYALTY
});

export const setHasPromotion = () => ({
  type: types.BUSINESS_SET_HAS_PROMOTION
});
