/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  Flex,
  Heading,
  Hide,
  Image,
  Show,
  Text,
} from '@chakra-ui/react';
import CarouselSlide from '../../Carousel/CarouselSlide';
import postedAutomatically from './assets/postedAutomatically.png';
import postedAutomaticallySm from './assets/postedAutomaticallySm.png';

const Slide4 = () => (
  <CarouselSlide
    textAlign={['center', undefined, 'left']} pb={[0, 0, 0]}
    height="36em"
  >
    <Flex direction={['column', undefined, 'row-reverse']} height="100%" alignItems="center">
      <Flex direction="column" gap="2em" py={['1em', undefined, '4em']}>
        <Heading>Content Is Posted Automatically</Heading>
        <Text fontSize="2xl">
          Posts and stories from Social AI get published to your social account automatically.
          No additional effort required!
        </Text>
      </Flex>
      <Show above="md">
        <Image
          src={postedAutomatically}
          maxWidth={['100%', undefined, '50%']}
          alignSelf={['center', undefined, 'end']}
          bottom="-3em"
          position="relative"
        />
      </Show>
      <Hide above="md">
        <Image
          src={postedAutomaticallySm}
          maxWidth={['100%', undefined, '50%']}
          alignSelf={['center', undefined, 'end']}
          bottom="-3em"
          position="relative"
        />
      </Hide>
    </Flex>
  </CarouselSlide>
);

export default Slide4;
