import {
  Button,
  ButtonGroup,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Skeleton,
  SkeletonText,
  Spacer,
  Text,
} from '@chakra-ui/react';
import React, {
  useMemo,
} from 'react';
import { Field } from 'react-final-form';
import {
  RootStateOrAny,
  useSelector,
} from 'react-redux';
import {
  Link,
  Route,
  Switch,
} from 'react-router-dom';
import useRequest from 'shared/src/hooks/useRequest';
import PageBackButton from 'web-react-ui/src/chakra/page/PageBackButton';
import PageHeader from 'web-react-ui/src/chakra/page/PageHeader';
import PageLayout from 'web-react-ui/src/chakra/page/PageLayout';
import ImageField from 'web-react-ui/src/components/image/ImageField';
import FieldError from 'web-react-ui/src/reactFinalForm/fields/FieldError';
import TextArea from 'web-react-ui/src/reactFinalForm/fields/TextArea';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';
import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';
import { required } from 'web-react-ui/src/reactFinalForm/validators';
import DateTimeField from 'web-react-ui/src/reactFinalForm/fields/dateTimeField/DateTimeField';
import businessesModule from '../../../../modules/businesses';
import client from '../../../../services/client';
import SectionHeader from '../components/SectionHeader';
import RequestResultAlert from './RequestResultAlert';
import ToggleButtonField from './ToggleButtonField';

interface FormData {
  contentType: string;
  scheduledDate: string;
  facebook: Boolean;
  instagram: Boolean;
  image: string;
  message?: string;
}

const TypeButton = ({ children, ...rest }: React.ComponentProps<typeof Button>) => {
  return (
    <Button w="10em" h="14em" p="2em" {...rest}>
      <Flex direction="column" gap="1em" justify="center" h="100%">
        {children}
      </Flex>
    </Button>
  );
};

const createSocialPost = ({
  businessId,
  contentType,
  socialNetwork,
  scheduledDate,
  image,
  message,
}: {
  businessId: string,
  contentType: string,
  socialNetwork: string,
  scheduledDate: string,
  image: string,
  message?: string,
}) => {
  return client
    .businesses.for(businessId)
    .social.posts.create({
      socialNetwork,
      contentType,
      scheduledDate,
      imageRefId: image,
      message,
    })
    .catch((err: Error & { messageId: string, context: any }) => {
      // If the error is "this social post already exists", then we've won!
      if (err.messageId === '25-67-013') {
        return {
          id: err.context.context, // id of the existing social post
        };
      }
      throw err;
    });
};

const PostTypeSelector = () => {
  const business = useSelector(state => businessesModule.selectors.business.getData(state));

  return (
    <Flex wrap="wrap" gap="3em">
      <Flex direction="column" gap="3em" flex="1" minW="40ch" alignItems="start">
        <PageHeader>Custom Social Post</PageHeader>
        <SectionHeader>Select the type of post to create</SectionHeader>
        <ButtonGroup variant="outline">
          <TypeButton as={Link} to={`/business/${business.id}/social/new/post`}>
            <Skeleton w="7em" h="3em" speed={0} endColor="gray.100" />
            <SkeletonText noOfLines={3} speed={0} endColor="gray.100" />
            <Spacer />
            <Text>Post</Text>
          </TypeButton>
          <TypeButton as={Link} to={`/business/${business.id}/social/new/story`}>
            <Skeleton w="5em" h="7em" speed={0} endColor="gray.100" />
            <Spacer />
            <Text>Story</Text>
          </TypeButton>
        </ButtonGroup>
      </Flex>
    </Flex>
  );
};

const CreateCustomSocialPost = ({ contentType }: { contentType: string }) => {
  const business = useSelector(businessesModule.selectors.business.getData);

  const profile = useSelector((state: RootStateOrAny) => state.social.profile);

  const isFacebookLinked = useMemo(
    () => {
      return profile.socialAccounts.find((s: any) => s.type === 'facebook' && s.linked);
    },
    [profile],
  );

  const isInstagramLinked = useMemo(
    () => {
      return profile.socialAccounts.find((s: any) => s.type === 'instagram' && s.linked);
    },
    [profile],
  );


  const initialValues = useMemo(
    () => ({
      contentType,
    }),
    [],
  );

  const createFacebookRequest = useRequest(
    async (formData: FormData) => {
      if (!formData.facebook) return null;

      if (createFacebookRequest.result) return createFacebookRequest.result;

      return createSocialPost({
        ...formData,
        socialNetwork: 'facebook',
        businessId: business.id,
      });
    },
  );
  const createInstagramRequest = useRequest(
    async (formData: FormData) => {
      if (!formData.instagram) return null;
      if (createInstagramRequest.result) return createInstagramRequest.result;

      return createSocialPost({
        ...formData,
        socialNetwork: 'instagram',
        businessId: business.id,
      });
    },
  );

  const submitForm = async (formData: FormData) => {
    await Promise.all([
      createFacebookRequest.run(formData),
      createInstagramRequest.run(formData),
    ]);
  };

  return (
    <SimpleForm onSubmit={submitForm} initialValues={initialValues} debug>
      <Flex direction="column" gap="3em" flex="1" minW="40ch" alignItems="start">
        <PageHeader textTransform="capitalize">{`Create Custom ${contentType}`}</PageHeader>

        <Flex direction="column" gap="1em" alignContent="stretch" w="100%">
          <FormControl>
            <FormLabel fontSize="lg">Social Networks</FormLabel>
            <ButtonGroup size="lg" spacing="1em">
              <ToggleButtonField
                name="facebook"
                isDisabled={createFacebookRequest.result || !isFacebookLinked}
              >
                Facebook
              </ToggleButtonField>
              <ToggleButtonField
                name="instagram"
                isDisabled={createInstagramRequest.result || !isInstagramLinked}
              >
                Instagram
              </ToggleButtonField>
            </ButtonGroup>
            <Field
              name="socialNetworks"
              validate={(_: any, formValues: FormData) => {
                const { facebook, instagram } = formValues;
                if (facebook || instagram) return undefined;

                return 'At least one Social Network is required';
              }}
            >
              {() => (
                <FieldError name="socialNetworks" alwaysShow={false} />
              )}
            </Field>
          </FormControl>
          <RequestResultAlert request={createFacebookRequest} socialNetwork="Facebook" contentType={contentType} />
          <RequestResultAlert request={createInstagramRequest} socialNetwork="Instagram" contentType={contentType} />
        </Flex>

        <FormControl>
          <FormLabel fontSize="lg">Scheduled Date</FormLabel>
          <DateTimeField name="scheduledDate" validate={required} />
        </FormControl>

        <FormControl>
          <FormLabel fontSize="lg">Image</FormLabel>
          <ImageField
            name="image"
            fileType={`social-ai:${contentType}`}
            validate={required}
            aspectRatio={(contentType === 'post') ? 1 : 1080 / 1920}
          />
        </FormControl>

        {
          (contentType === 'post')
            ? (
              <FormControl>
                <FormLabel fontSize="lg">Message</FormLabel>
                <TextArea name="message" validate={required} wrapLabel />
              </FormControl>
            )
            : null
        }

        <FormSubmit
          ml="auto"
          colorScheme="blue"
          label={`Create ${contentType}`}
          ignoreValidity
          textTransform="capitalize"
        />
      </Flex>
    </SimpleForm>
  );
};

const NewPostView = () => {
  const business = useSelector(state => businessesModule.selectors.business.getData(state));

  return (
    <Container maxW="40em">
      <PageLayout>
        <PageBackButton to={`/business/${business.id}/social`}>Back</PageBackButton>
        <Switch>
          <Route path="/business/:businessId/social/new/post">
            <CreateCustomSocialPost contentType="post" />
          </Route>
          <Route path="/business/:businessId/social/new/story">
            <CreateCustomSocialPost contentType="story" />
          </Route>
          <Route>
            <PostTypeSelector />
          </Route>
        </Switch>
      </PageLayout>
    </Container>
  );
};

export default NewPostView;
