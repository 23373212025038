import React, {
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import { useField, useForm, useFormState } from 'react-final-form';
import { Box, Flex } from '@chakra-ui/react';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import { COUNTRIES } from 'shared/src/constants/countries';

import {
  compose,
  required,
  postalCode,
  zipCode
} from '../../reactFinalForm/validators';
import SelectField from '../../reactFinalForm/fields/SelectField';
import TextField from '../../reactFinalForm/fields/TextField';


const StateCodeFields = ({ name }) => {
  const { strings } = useI18Next();
  const form = useForm();
  const formState = useFormState();

  useEffect(() => {
    if (formState.modified[`${name}.country`]) {
      form.batch(() => {
        form.change(`${name}.state`, '');
        form.change(`${name}.postalcode`, '');
        form.resetFieldState(`${name}.state`);
        form.resetFieldState(`${name}.postalcode`);
        form.resetFieldState(`${name}.country`);
      });
    }
  }, [formState]);

  const getCodeValidation = (countryName) => {
    switch (countryName) {
      case 'United States':
        return zipCode;
      default:
        return postalCode;
    }
  };

  const { input: { value } } = useField(`${name}.country`); // Canada, United States

  const country = COUNTRIES.find(item => item.value === value);

  if (!country) return null;
  return (
    <Flex mt={4}>
      <Box flexGrow={1} mr={3}>
        <SelectField
          name={`${name}.state`}
          label={strings(`ui.field.label.${country.regionType}`)}
          placeholder={strings('ui.field.placeholder.choose')}
          search
          validate={required}
          fluid
          options={country.regions}
        />
      </Box>
      <Box flexGrow={1}>
        <TextField
          validate={compose(required, getCodeValidation(country.value))}
          name={`${name}.postalcode`}
          label={strings(`ui.field.label.${country.codeType}`)}
          placeholder={country.codeText}
        />
      </Box>
    </Flex>
  );
};

StateCodeFields.propTypes = {
  name: PropTypes.string
};

export default StateCodeFields;
