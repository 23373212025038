import { Flex, Heading, Spacer } from "@chakra-ui/react";
import React, { ReactNode } from "react";

const PageHeader = ({
  children,
  controls,
  textTransform,
  ...rest
}: {
  children: ReactNode;
  controls?: ReactNode;
  textTransform?: string;
}) => {
  return (
    <Flex w="100%" pt="2rem" pb="4rem">
      {/* @ts-ignore textTransform is totally valid on Heading */}
      <Heading textTransform={textTransform} {...rest}>
        {children}
      </Heading>
      <Spacer />
      {controls}
    </Flex>
  );
};

export default PageHeader;
