import {
  Heading,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';

import { errors } from './errors';

const SsoError = ({
  title = 'Unable to Authorize',
  error,
  children
}: {
  title?: string,
  error?: Record<string, any>,
  children?: React.ReactNode
}) => {
  const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;
  const errorEntry = error ? errors.find(err => err.messageId === error.messageId) : undefined;
  return (
    <Stack spacing={16}>
      <Heading
        as="h1"
        mb={0}
        fontWeight="bold"
        fontSize={{ base: '28px', md: '30px' }}
      >
        {errorEntry?.title || title}
      </Heading>
      {children || (
        <>
          <Text fontSize='xl'>{errorEntry?.message || error?.message}</Text>
          <Text fontSize="xl">
            Try again later or contact{` `}
            <Link textDecoration="underline" href={`mailto:${supportEmail}`}>
              {supportEmail}
            </Link>
            {` `}for help.
          </Text>
        </>
      )}

    </Stack>
  );
};

export default SsoError;
