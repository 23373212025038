import {
  Alert, AlertDescription, AlertIcon, AlertTitle,
  Box, Button, Flex, SkeletonCircle, SkeletonText,
  Text, useBreakpointValue, useDisclosure
} from '@chakra-ui/react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { Link, Redirect, useLocation } from 'react-router-dom';
import useCheckedPlans from 'shared/src/hooks/useCheckedPlans';
import usePagedList from 'shared/src/hooks/usePagedList';
import usePermissions from 'shared/src/hooks/usePermissions';
import useQueryDebounce from 'shared/src/hooks/useQueryDebounce';

import Business from 'web-react-ui/src/types/Business.interface';
import List from 'web-react-ui/src/chakra/List';
import { AutoPagination } from 'web-react-ui/src/chakra/Pagination';
import SearchInput from 'web-react-ui/src/chakra/SearchInput';
import ViewHeader from 'web-react-ui/src/chakra/ViewHeader';
import ProfileImage from 'web-react-ui/src/components/image/ProfileImage';
import InlineSupportLink from 'web-react-ui/src/support/InlineSupportLink';

import useMyPlans from '../../hooks/useMyPlans';
import { strings } from '../../i18n';
import client from '../../services/client';
import PlanFilter from '../PlanFilter';
import useProperty from '../useProperty';
import MobileBusinessList from './MobileBusinessList';
import BusinessesNotFound from './BusinessesNotFound';

const LOADING_ROWS = 5;

const BusinessItem = ({ business }: { business: Business }): JSX.Element => {
  const imageId = _.get(business, 'logoImage');
  return (
    <Flex
      as={Link}
      to={`/business/${business.id}`}
      align='center'
      p={4}
      _hover={{ bg: 'gray.50' }}
      justify='flex-start'
      data-testid='businessListItem'
    >
      <Flex w={{ base: '100%', lg: '50%' }} align='center'>
        <ProfileImage imageId={imageId} name={business.name} />
        <Flex flexDirection='column' ml={4}>
          <List.Header>{business.name}</List.Header>
          <List.Body>
            {business.locations?.length !== 1 && `${business.locations?.length} locations`}
            {business.locations?.length === 1 && `${business.locations[0]?.name}`}
          </List.Body>
        </Flex>
      </Flex>
      <Flex w={{ lg: '50%' }} align='center'>
        <Text fontSize='lg'>{business.plan?.name}</Text>
      </Flex>
    </Flex>
  );
};

const LoadingRow = (): JSX.Element => (
  <Flex borderTop='1px' layerStyle='widgetBorderColor' p={4}>
    <Flex w={['50%', '50%', '75%']}>
      <SkeletonCircle h='36px' w='36px' />
      <SkeletonText mb={2} ml={4} w='75%' />
    </Flex>
    <Flex w={['50%', '50%', '25%']}>
      <SkeletonText w='75%' />
    </Flex>
  </Flex>
);

const BusinessSelectView = ({
  skipRedirect,
  isOwner = false
}: {
  skipRedirect?: boolean,
  isOwner: boolean,
}): JSX.Element => {
  // Used to force a rerender for the business list when the refresh button is clicked
  const [refetchKey, setRefetchKey] = useState(0);
  const property = useProperty();
  const [query, debouncedQueryHandler] = useQueryDebounce(property);
  const [redirect, setRedirect] = useState('');
  const [isFiltered, setIsFiltered] = useState(false);

  const canRegister = usePermissions({}, ['business.claim']);
  const hasPermissions = usePermissions(
    { propertyId: property.id },
    ['property.business.search']
  );

  const plans = useMyPlans(property);
  const location: { state: { planId: string } } = useLocation();
  const { planId } = location.state ?? false;
  const [
    checkedPlans,
    handleCheckedPlans,
    handleUnCheckedPlan,
    checkedPlanIds,
  ] = useCheckedPlans(plans, planId);

  useEffect(() => {
    setIsFiltered(!!(query || checkedPlanIds.length));
  }, [query, checkedPlanIds]);

  const fetchBusinesses = async () => {
    if (hasPermissions) {
      let planIds;
      if (checkedPlanIds.length) {
        planIds = checkedPlanIds;
      }

      return client
        .properties.for(property.id)
        .businesses.list({
          query,
          enabled: true,
          fetchPlan: true,
          fetchLocations: true,
          hasRoles: true,
          locationsEnabled: true,
          planIds,
        });
    }
    return false;
  };

  const businessList = usePagedList(
    fetchBusinesses,
    { query, checkedPlanIds, refetchKey, hasPermissions },
  );

  const noBusinesses = businessList.settled && businessList.items?.length <= 0 && !isFiltered;
  const { onOpen, onClose, isOpen } = useDisclosure();
  const isSmallScreen = useBreakpointValue({ base: true, lg: false });

  useEffect(() => {
    // only dashboard users should be redirected
    if (!isOwner && !isFiltered && businessList.settled && businessList.items) {
      if (businessList.empty && canRegister) {
        // no businesses, redirect to registration
        setRedirect('/businesses/register');
      }
      if (businessList.items.length === 1 && !businessList.prev && skipRedirect === undefined) {
        // one business only, redirect to business
        setRedirect(`/business/${businessList?.items[0].id}`);
      }
    }
  }, [isOwner, isFiltered, businessList, skipRedirect]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Box>
      <ViewHeader title={strings('dashboard.component.businessSelectView.businesses')}>
        <Flex flexDirection={{ base: 'column', md: 'row' }} align='center'>
          {!isSmallScreen && (
            <PlanFilter
              plans={plans}
              checkedPlans={checkedPlans}
              handleRemoveTag={handleUnCheckedPlan}
              handleCheckedPlans={handleCheckedPlans}
              handleUnCheckedPlan={handleUnCheckedPlan}
              onClose={onClose}
              onOpen={onOpen}
              isOpen={isOpen}
            />
          )}

          {canRegister && (
            <Button
              as={Link}
              ml={4}
              mt={{ base: 1, md: 0 }}
              colorScheme='blue'
              leftIcon={<BiPlus />}
              fontWeight={400}
              cursor='pointer'
              to='/businesses/add'
            >
              {strings('dashboard.component.businessSelectView.addBusiness')}
            </Button>
          )}
        </Flex>
      </ViewHeader>
      {businessList.error && (
        <Alert status='error' mb={4}>
          <AlertIcon />
          <AlertDescription mr={2}>
            {strings('dashboard.component.businessSelectView.errorLoadingBusinesses')}
            &nbsp;<InlineSupportLink label={strings('dashboard.label.contactSupport')} />.
          </AlertDescription>
          <Button ml='auto' onClick={() => setRefetchKey(refetchKey + 1)}>Refresh</Button>
        </Alert>
      )}
      {noBusinesses && (
        <Alert status='warning' mb={4}>
          <AlertIcon />
          <AlertTitle mr={2}>{strings('dashboard.component.businessSelectView.noAccess')}</AlertTitle>
          <AlertDescription>
            <InlineSupportLink label={strings('dashboard.label.contactSupport')} />
            &nbsp;{strings('dashboard.component.businessSelectView.setUpAccount')}
          </AlertDescription>
        </Alert>
      )}
      <Box>
        <Box mb='16px'>
          <SearchInput onChange={debouncedQueryHandler} defaultValue={query} />
        </Box>
        {isSmallScreen && (
          <Box mt='16px'>
            <PlanFilter
              plans={plans}
              checkedPlans={checkedPlans}
              handleRemoveTag={handleUnCheckedPlan}
              handleCheckedPlans={handleCheckedPlans}
              handleUnCheckedPlan={handleUnCheckedPlan}
              onClose={onClose}
              onOpen={onOpen}
              isOpen={isOpen}
            />
          </Box>
        )}
      </Box>
      {isSmallScreen ? (
        <Box border='1px' borderBottom={0} layerStyle='widgetBorderColor'>
          <MobileBusinessList businesses={businessList} />
        </Box>
      ) : (
        <Box border='1px' layerStyle='widgetBorderColor'>
          {/* TODO: Abstract these styles to make a non-Table table header */}
          <Flex px={6} py={3} bg='gray.50' borderBottom='1px' borderColor='gray.200'>
            <Text w={{ base: '100%', lg: '50%' }} fontWeight='bold'>
              {strings('owners.widget.locationsWithoutOffers.business')}
            </Text>
            <Text w={{ lg: '50%' }} fontWeight='bold'>
              {strings('dashboard.label.plan')}
            </Text>
          </Flex>
          {!businessList.settled ? Array(LOADING_ROWS).fill(null).map((item, index) => (
            <LoadingRow key={`loadingRow-${index}`} />
          )) : (
            <>
              {businessList.empty && (
                <BusinessesNotFound />
              )}
              {businessList.items?.map((item: Business, idx: number) => (
                <Box borderTop={idx > 0 ? '1px' : undefined} borderColor='gray.200' key={item.id}>
                  <BusinessItem business={item} />
                </Box>
              ))}
            </>
          )}
        </Box>
      )}
      <Box py={6}>
        <AutoPagination pagedList={businessList} />
      </Box>
    </Box >
  );
};

export default BusinessSelectView;
