/* eslint no-unreachable: 0 */
/* eslint-disable-next-line no-redeclare */
import React, { useContext, useState } from 'react';
import {
  Button,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import useQuerystring from 'shared/src/hooks/useQuerystring';
import useRequest from 'shared/src/hooks/useRequest';
import ClientContext from 'shared/src/components/contexts/ClientContext';

import Loader from 'web-react-ui/src/components/elements/Loader';

import SsoError from './error';
import { errorCodes } from './error/errors';
import ConnectAccount from './ConnectAccount';
import SsoLayout from './SsoLayout';
import extractPayload from './extractPayload';
import NeedHelpFooter from './NeedHelpFooter';

// eslint-disable-next-line max-len
// Example querystring: ?provider=thryv&action=login&payload=ewogIHRpbWVzdGFtcDogMAogIHVzZXJJZDogIlVTRVJfSUQiLAogIHVzZXJFbWFpbDogInVzZXJAdGhyeXYuY29tIiwKICBhY2NvdW50SWQ6ICJBQ0NPVU5UX0lEIiwKICBidXNpbmVzczogewogICAgbmFtZTogJ0ZvbyBCdXNpbmVzcycKICB9Cn0&signature=whee&returnUrl=google.com
/*
payload is
{
  timestamp: 0
  returnUrl: "https://google.ca",
  user: {
    email: "john.smith@thryv.com",
    id: "USER_ID",
    firstName: "John",
    lastName: "Smith",
  },
  business: {
    id: "BUSINESS_ID",
    name: "Foo Business"
  }
*/

const validatePayload = () => {
  const payload = extractPayload();
  if (!payload.returnUrl) {
    throw new Error('Missing required field: returnUrl');
  }

  const maxFieldLength = 1000;
  const maxLengthFields: Record<string, string> = {
    'business.id': payload.business.id,
    'user.firstName': payload.user.firstName,
    'user.lastName': payload.user.lastName,
    'user.email': payload.user.email,
  };

  Object.entries(maxLengthFields).forEach(([key, value]) => {
    if (value.length > maxFieldLength) {
      throw new Error(
        `${key} is too long:\n\n ${value}\n\n (${value.length} characters)`
      );
    }
  });

  return true;
};

interface ActionParams {
  query: {
    provider: string;
    payload: string;
    signature: string;
    action: 'login' | 'install' | 'signup';
  };
  client: any;
  setCreateUser?: (val: boolean) => void;
}

const handleLogin = async ({ query, client }: ActionParams) => {
  const { provider, payload, signature } = query;
  await client.auth.ssoLogin({
    provider,
    // We assume the account already exists
    action: ['login-user'],
    payload,
    signature,
  });
  return authAndRedirect({ query, client });
};

const handleLoginCreate = async ({ query, client }: ActionParams) => {
  const { provider, payload, signature } = query;
  await client.auth.ssoLogin({
    provider,
    action: ['create-user', 'login-user'],
    payload,
    signature,
  });

  return authAndRedirect({ query, client });
};

const authAndRedirect = async ({ query, client }: ActionParams) => {
  const { provider, payload, signature } = query;
  const { businessId } = await client.auth.appAuthorize(
    provider,
    payload,
    signature,
  );

  // @ts-ignore
  const url = new URL(window.location);
  url.pathname = `/business/${businessId}`;
  url.search = '';

  // Completely replace location because we replaced the auth tokens
  window.location.replace(url.href);
  return true;
};

const handleInstall = async ({ query, client }: ActionParams) => {
  const {
    provider,
    payload,
    signature,
  } = query;
  await client.auth.ssoLogin({
    provider,
    // We don't want to create a user here if they don't exist
    action: ['login-user'],
    payload,
    signature,
  });
  // @ts-ignore
  const url = new URL(window.location);
  url.pathname = `/apps/install`;
  return window.location.replace(url.href);
};

const handleSignup = async () => {
  // @ts-ignore
  const url = new URL(window.location);
  url.pathname = `/apps/signup`;
  return window.location.replace(url.href);
};

const handleSsoAction = ({ query, client, setCreateUser }: ActionParams) => {
  validatePayload();

  if (query.action === 'login') {
    return handleLogin({ query, client, setCreateUser });
  }
  if (query.action === 'install') {
    return handleInstall({ query, client });
  }
  if (query.action === 'signup') {
    return handleSignup();
  }
  throw new Error(`Unrecognized action ${query.action}`);
};

const SSOView = (): JSX.Element => {
  const [query] = useQuerystring();
  const payload = extractPayload();
  const client = useContext(ClientContext);

  const signinRequest = useRequest(handleSsoAction, { query, client });
  const createLoginRequest = useRequest(handleLoginCreate);

  // this should only ever be shown on the 'login' action
  if (
    query.action === 'login' &&
    !createLoginRequest.error &&
    signinRequest.error?.messageId === errorCodes.USER_NOT_FOUND
  ) {
    return (
      <SsoLayout footer={<NeedHelpFooter />}>
        <Stack spacing={8} position="relative" w="100%">
          <Heading>Confirm Sign In</Heading>
          <Text fontSize="2xl">You are signing in to GetintheLoop with your Thryv account.</Text>
          <Text fontSize='2xl'>
            To create your GetintheLoop account, Thryv will share you name and email address with GetintheLoop.
          </Text>
          <Divider />
          <Flex justify="space-between">
            <Button onClick={() => window.location.replace(payload.returnUrl)} variant="ghost" size="lg">Cancel</Button>
            <Button
              onClick={() => createLoginRequest.run({ query, client })}
              colorScheme="blue"
              size="lg"
              isLoading={createLoginRequest.loading}
            >
              Confirm
            </Button>
          </Flex>
        </Stack>
      </SsoLayout >
    );
  }

  if (createLoginRequest.error || signinRequest.error) {
    const error = createLoginRequest.error || signinRequest.error;
    if ([errorCodes.USER_NOT_LINKED, errorCodes.USER_NOT_FOUND].includes(error.messageId)) {
      return <ConnectAccount />;
    }
    // eslint-disable-next-line no-nested-ternary
    const errorTitle = query.action === 'login' ? 'Login' : (query.action === 'signup' ? 'Sign Up' : 'Install');
    return (
      <SsoLayout returnUrl={payload.returnUrl}>
        <SsoError title={`Unable to ${errorTitle}`} error={error} />
      </SsoLayout >
    );
  }

  return (
    <Flex h="100vh" w="100vw" justify="center" align="center">
      <Loader active />
    </Flex>
  );
};

export default SSOView;
