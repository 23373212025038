import React, { useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import Business from 'web-react-ui/src/types/Business.interface';
import Message from 'web-react-ui/src/components/collections/Message';
import Metrics from 'web-react-ui/src/components/metrics/Metrics';
import MetricsLoyalty from 'web-react-ui/src/components/metrics/MetricsLoyalty';
import TimeRangeSelector from 'web-react-ui/src/components/metrics/TimeRangeSelector';
import Request from 'web-react-ui/src/data/Request';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import ViewHeader from 'web-react-ui/src/chakra/ViewHeader';

import { strings } from '../../i18n';
import client from '../../services/client';
import propertyResolver from '../../services/propertyResolver';
import MyLocationSelector from '../offers/MyLocationsSelector';
import MetricsLoyaltyTotalWrapper from '../stats/MetricsLoyaltyTotalWrapper';

interface Props {
  businessId: string;
  business: Business;
}

const Overview = ({ businessId, business }: Props): JSX.Element => {
  const [days, setDays] = useState(30);
  const [locationId, setLocationId] = useState('');

  const fetchStats = () => {
    if (locationId === undefined) return null;
    const property = propertyResolver();

    const statsRepo = client
      .properties.for(property.id)
      .businesses.for(businessId)
      .locations.for(locationId)
      .stats;

    return Promise.all([
      statsRepo.getLastNDays({ locationId, days }),
      statsRepo.getLoyaltyLastNDays({ locationId, days })
    ]);
  };

  return (
    <Box>
      <ViewHeader title='Performance' />
      <Box>
        <Flex ml='auto' flexWrap='wrap'>
          <Box w='50%' />
          <Flex flexGrow={1} flexWrap='wrap'>
            <Box flexGrow={1} m={4} minW='200px'>
              <MyLocationSelector
                onChange={(newLocationId: string) => setLocationId(newLocationId)}
                value={locationId}
              />
            </Box>
            <Box flexGrow={1} mt={8} m={4} minW='200px'>
              <TimeRangeSelector
                days={days}
                handleSetRange={({ days: newDays }: { days: number }) => setDays(newDays)}
              />
            </Box>
          </Flex>
        </Flex>
        <Request
          request={fetchStats}
          params={{ days, locationId }}
        >
          {({ data, loading, error }: {
            data: [
              stats: any,
              loyaltyStats: any
            ],
            loading: boolean,
            error: { message: string },
          }) => {
            const [stats, loyaltyStats] = data || [];
            return (
              <WaitFor waitFor={!loading} wrapContents>
                {error && <Message error>{error.message}</Message>}
                <h2>{strings('dashboard.screen.overview.total')}</h2>
                <Metrics data={stats} plansHref='./plans' />
                <h2>{strings('dashboard.screen.overview.loyalty')}</h2>
                {/* @ts-ignore */}
                <MetricsLoyaltyTotalWrapper businessId={business.id} locationId={locationId} />
                <MetricsLoyalty data={loyaltyStats} plansHref='./plans' />
              </WaitFor>
            );
          }}
        </Request>
      </Box>
    </Box>
  );
};

export default Overview;
