/* eslint-disable import/prefer-default-export */
export const formatMoney = ({ currencyCode, value }, strings) => {
  const options = {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 2
  };

  if (value % 100 === 0) options.minimumFractionDigits = 0;
  const formatter = new Intl.NumberFormat('en-US', options);
  if (value === -1) {
    return strings('ui.shared.formatMoney.customPricing');
  }
  return formatter.format(value / 100);
};
