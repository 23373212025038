/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  Heading,
  Hide,
  Image,
  Show,
  Spacer,
  Text,
} from '@chakra-ui/react';
import CarouselSlide from '../../Carousel/CarouselSlide';
import confirmLink from './assets/confirmLink.png';
import confirmLinkSm from './assets/confirmLinkSm.png';


const Step4 = () => (
  <CarouselSlide textAlign="center" alignItems="center" pb={[0, 0, 0]} justifyContent="center">
    <Spacer />
    <Heading>4. Confirm Link</Heading>
    <Text fontSize="2xl">
      The account linking screen will update to show you that your account has been correctly linked.
      You can proceed to link other accounts, or click Close to finish setting up Social AI.
    </Text>
    <Spacer />
    <Show above="md">
      <Image src={confirmLink} />
    </Show>
    <Hide above="md">
      <Image src={confirmLinkSm} />
    </Hide>
  </CarouselSlide>
);

const Supplement = () => (
  <Text maxW="60ch" textAlign="center" fontSize="text-md" color="gray.400" fontWeight="normal">
    If you have admin access to multiple Facebook or Instagram profiles, a drop down menu will appear.
    Please choose the business that matches your GetintheLoop business profile.
  </Text>
);

Step4.Supplement = Supplement;

export default Step4;
