import { Circle } from '@chakra-ui/react';
import React from 'react';
import { AiFillInstagram } from 'react-icons/ai';
import { FaFacebook } from 'react-icons/fa';

const getNetwork = (id: string) => {
  switch (id) {
    case 'facebook':
    case 'facebook-post':
      return {
        name: 'facebook',
        icon: <FaFacebook />,
        color: '#4267B2',
      };
    case 'instagram':
    case 'instagram-post':
      return {
        name: 'instagram',
        icon: <AiFillInstagram />,
        color: '#bc2a8d',
      };
    default:
      return {
        name: 'unknown',
        icon: null,
        color: '#000000',
      };
  }
};
const NetworkIcon = ({ id, fontSize }: { id: string, fontSize: string }) => {
  const network = getNetwork(id);

  return (
    <Circle color={network.color} bg="#fff" padding="2px" fontSize={fontSize}>
      {network.icon}
    </Circle>
  );
};

export default NetworkIcon;
