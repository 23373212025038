import PLAN_ACTIONS from './planActions';
import {
  BasePlan,
  BillingPlan,
  Plan,
  PricePoint,
} from './types';

const PERIOD_UNIT_INDEX: { [key: string]: number } = {
  month: 0,
  year: 1,
};

const getAction = (
  pricePoint: PricePoint, currentPricePoint: PricePoint, isSelected: boolean,
): keyof typeof PLAN_ACTIONS => {
  if (isSelected) return PLAN_ACTIONS.selected;

  // No current plan, all are selectable
  if (!currentPricePoint) return PLAN_ACTIONS.select;

  // Same plan. Do nothing.
  if (currentPricePoint.chargebeePlanId === pricePoint.chargebeePlanId) return PLAN_ACTIONS.currentPlan;

  // Target plan is price -1 or 0 -- contact
  if (pricePoint.value <= 0) return PLAN_ACTIONS.contact;

  // Moving to lesser time unit. Contact.
  const periodUnitIndex = PERIOD_UNIT_INDEX[pricePoint.periodUnit];
  const currentPeriodUnitIndex = PERIOD_UNIT_INDEX[currentPricePoint.periodUnit];
  if (periodUnitIndex < currentPeriodUnitIndex) return PLAN_ACTIONS.contact;

  // Same time unit, but fewer. Contact.
  if (periodUnitIndex === currentPeriodUnitIndex) {
    if (pricePoint.period < currentPricePoint.period) return PLAN_ACTIONS.contact;
  }

  // Lower-tier plan. Contact.
  if (pricePoint.tier <= currentPricePoint.tier) return PLAN_ACTIONS.contact;

  return PLAN_ACTIONS.upgrade;
};

const generatePlans = (planController: PlanController): Array<Plan> => {
  const currentPricePoint = planController.basePlans
    .flatMap(p => p.pricePoints)
    .find(p => p.chargebeePlanId === planController.billingPlanId);

  const plans = planController.basePlans.map(
    (plan) => {
      const pricePoint = plan.pricePoints.find((p: PricePoint) => p.currencyCode === planController.currencyCode
        && p.periodUnit === planController.periodUnit
        && p.period === planController.period,
      );

      if (!pricePoint) return null;

      const isSelected = plan.key === planController.selectedPlanKey;

      const action = plan.action || getAction(pricePoint, currentPricePoint, isSelected);

      return ({
        ...plan,
        ...pricePoint,
        isCurrent: pricePoint.chargebeePlanId === planController.billingPlanId,
        isSelected,
        action,
      });
    },
  );

  return plans.filter(Boolean);
};

class PlanController {
  plans?: Array<Plan>;

  basePlans: Array<BasePlan>;

  currencyCode: string;

  period: number;

  periodUnit: string;

  billingPlanId?: string;

  selectedPlanKey?: string;

  isLegacy?: boolean;

  onSelect?: Function;

  onChange?: Function;

  constructor(
    { basePlans, billingPlan, onSelect, onChange }:
      { basePlans: Array<any>, billingPlan?: BillingPlan, onSelect?: Function, onChange?: Function },
  ) {
    this.basePlans = basePlans;

    this.currencyCode = billingPlan?.currencyCode || 'CAD';
    this.period = 1;
    this.periodUnit = billingPlan?.billingPeriodUnit === 'year' ? 'year' : 'month';
    this.billingPlanId = billingPlan?.itemPriceId;

    this.onSelect = onSelect;
    this.onChange = onChange;

    this.update();
  }

  update({ skipPlans }: { skipPlans?: boolean } = {}) {
    if (!skipPlans) {
      this.plans = generatePlans(this);
    }

    const currentPricePoint = this.basePlans
      .flatMap(p => p.pricePoints)
      .find(p => p.chargebeePlanId === this.billingPlanId);

    this.isLegacy = Boolean(this.billingPlanId && !currentPricePoint);

    this.onChange?.({
      plans: this.plans,
      currencyCode: this.currencyCode,
      period: this.period,
      periodUnit: this.periodUnit,
      billingPlanId: this.billingPlanId,
      isLegacy: this.isLegacy,
    });
  }

  setSelectedPlan(plan: Plan) {
    this.selectedPlanKey = plan?.key;
    this.update();
    if (this.selectedPlanKey && this.onSelect) {
      this.onSelect(plan);
    }
  }

  setSelectedPlanByKey(key: string) {
    const plan = this.plans?.find(p => p.key === key);
    if (!plan) return;
    this.setSelectedPlan(plan);
  }

  setBillingPlanId(id: string) {
    this.billingPlanId = id;
    this.update();
  }

  setOnSelect(onSelect?: Function) {
    this.onSelect = onSelect;
    this.update({ skipPlans: true });
  }

  setPeriod(period: number, periodUnit: string) {
    if (period === undefined || periodUnit === undefined) return;
    this.period = period;
    this.periodUnit = periodUnit;
    this.update();
    // Select "parallel" plan with new period
    if (this.selectedPlanKey) this.setSelectedPlanByKey(this.selectedPlanKey);
  }

  setCurrencyCode(code: string) {
    this.currencyCode = code;
    this.update();
    // Select "parallel" plan with new currency code
    if (this.selectedPlanKey) this.setSelectedPlanByKey(this.selectedPlanKey);
  }
}

export default PlanController;
