import {
  useEffect,
  useState
} from 'react';
import client from '../services/client';

function useBillingAddress({ propertyId, businessId, counter, defaultCountry }) {
  const [billingAddress, setBillingAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasPermissions, setHasPermissions] = useState(false);

  useEffect(() => {
    if (propertyId) {
      client.access.checkPermissions(
        { propertyId, businessId },
        ['property.business.chargebee.billingAddress.get']
      ).then((result) => {
        setHasPermissions(result);
      });
    }
  }, [propertyId]);

  useEffect(() => {
    if (hasPermissions && propertyId && businessId) {
      setIsLoading(true);
      client
        .properties.for(propertyId)
        .businesses.for(businessId)
        .getBillingAddress()
        .then((data) => {
          // eslint-disable-next-line no-param-reassign
          data = data || {}; // cannot use parameter default because it's returned as `null`
          // eslint-disable-next-line no-param-reassign
          if (!data.country) data.country = defaultCountry;
          setBillingAddress(data);
        }).catch((err) => {
          setError(err);
          // Default address for Billing
          setBillingAddress({ country: 'CA', isDefault: true });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [propertyId, businessId, hasPermissions, counter]);

  return { hasPermissions, isLoading, billingAddress, error };
}

export default useBillingAddress;
