import { RootStateOrAny } from "react-redux";

const SET_ACTIVE_TAB = 'sidebar/SET_ACTIVE_TAB';
const UNSET_ACTIVE_TAB = 'sidebar/UNSET_ACTIVE_TAB';

export const LOYALTY = 'loyalty';
export const PROMOTIONS = 'promotions';

const defaultState: Record<string, any> = {
  activeTab: null,
};

interface Action {
  type: typeof SET_ACTIVE_TAB | typeof UNSET_ACTIVE_TAB;
  activeTab?: typeof LOYALTY | typeof PROMOTIONS;
}

// reducer
export default (state = defaultState, action: Action) => {
  const { type, activeTab } = action;

  switch (type) {
    case SET_ACTIVE_TAB:
      return { ...state, activeTab };
    case UNSET_ACTIVE_TAB:
      return { ...state, activeTab: null };
    default:
      return state;
  }
};

// actions
export const setActiveTab = (activeTab: typeof LOYALTY | typeof PROMOTIONS) => ({
  type: SET_ACTIVE_TAB,
  activeTab,
});

export const unsetActiveTab = () => ({
  type: UNSET_ACTIVE_TAB
});

// selector
export const activeTabSelector = (state: RootStateOrAny) => state.sidebar.activeTab;
