class WizardManager {
  constructor({
    initialData,
    steps,
    onStart,
    onComplete,
    onCancel
  }) {
    this.data = initialData || {};
    this.onStart = onStart;
    this.onComplete = onComplete;
    this.onCancel = onCancel;
    this.steps = steps;
    this.error = null;
    this.modalOpen = false;
    this.index = -1;
  }

  start() {
    this.index = 0;
    if (this.onStart) this.onStart(this);
    return this.showStep(this.index);
  }

  getCurrentStep() {
    if (this.index < 0) return null;
    return this.steps[this.index];
  }

  showStep(index) {
    const step = this.steps[index];
    step.setCompleteCallback(this.onStepComplete.bind(this));
    step.show(this.data);
    this.error = null;
    this.modalOpen = true;
    this.index = index;
    this.notifyStateChanged();
  }

  showStepByKey(key) {
    const index = this.steps.findIndex(step => (step.key === key));
    if (index >= 0) return this.showStep(index);
    return undefined;
  }

  notifyStateChanged() {
    if (this.stateChangedHandler) {
      this.stateChangedHandler({
        index: this.index,
        isSubmitting: this.isSubmitting,
        error: this.error,
        modalOpen: this.modalOpen
      });
    }
  }

  showPreviousStep() {
    this.modalOpen = true;
    if (this.index <= 0) return this.index;
    return this.showStep(this.index - 1);
  }

  onStepComplete(step, data) {
    // eslint-disable-next-line no-param-reassign
    step.done = true;
    this.data = data;
    if (this.index + 1 === this.steps.length) return this.onCompleteCallback();
    return this.showStep(this.index + 1);
  }

  registerOnStateChanged(handler) {
    this.stateChangedHandler = handler;
  }

  getTitle() {
    const step = this.steps[this.index];

    if (step && step.getTitle) return step.getTitle(this.data);
    return '';
  }

  async onCompleteCallback() {
    this.error = null;
    if (!this.onComplete) return null;

    this.isSubmitting = true;
    this.notifyStateChanged();

    try {
      const result = await this.onComplete(this.data, this);
      // return `false` from onComplete handler to cancel closing the modal
      if (result !== false) this.modalOpen = false;
    } catch (err) {
      this.error = err;
    }

    this.isSubmitting = false;
    this.notifyStateChanged();
    return null;
  }

  onCancelCallback() {
    this.modalOpen = false;
    this.notifyStateChanged();
    if (this.onCancel) this.onCancel(this);
  }
}

class WizardStep {
  constructor({
    render, key, onShow, onHide, getTitle
  }) {
    this.render = render;
    this.key = key;
    this.onShow = onShow;
    this.onHide = onHide;
    this.getTitle = getTitle;
  }

  setCompleteCallback(fn) {
    this.onCompleteCallback = fn;
  }

  show(data) {
    this.data = this.onShow ? this.onShow(data) : data;
  }

  stepComplete(data) {
    const result = this.onHide ? this.onHide(data) : data;
    return this.onCompleteCallback(this, result);
  }
}

export {
  WizardStep,
  WizardManager
};
