import React from 'react';
import {
  Button,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { strings } from '../../../i18n';

const ErrorView = ({ title, error, message }: { title?: string, error?: Error | null, message?: string }) => {
  const titleText = title || strings('shared.errors.error');
  const messageText = message || error?.message;

  const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;
  return (
    <Stack spacing={16} maxWidth="lg" margin="auto">
      <Heading
        as="h1"
        mb={0}
        fontWeight="bold"
        fontSize={{ base: '28px', md: '30px' }}
      >
        {titleText}
      </Heading>

      <Text fontSize="xl">
        {messageText}
      </Text>

      <Button colorScheme="blue" as="a" href={`mailto:${supportEmail}`} target="_blank">
        {strings('ui.label.contactSupport')}
      </Button>
    </Stack>
  );
};


export default ErrorView;
