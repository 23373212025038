import React from 'react';
import { Link, Stack, Text } from '@chakra-ui/react';
import SsoLayout from './SsoLayout';
import SsoError from './error';

const SignupError = ({ email, returnUrl }: { email: string, returnUrl: string }): JSX.Element => (
  <SsoLayout returnUrl={returnUrl}>
    <SsoError title='Unable to Sign Up'>
      <Stack spacing={6}>
        <Text fontSize='2xl'>
          A GetintheLoop account already exists for <b style={{ wordBreak: 'break-all' }}>{email}</b>.
        </Text>
        <Text fontSize='xl'>
          If you already have a GetintheLoop account, select the Link Account option instead.
        </Text>
      </Stack>

      <Text fontSize='xl'>
        If you do not have a GetintheLoop account, or are unsure, contact{' '}
        <Link color='blue.400' textDecoration='underline' href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
          {process.env.REACT_APP_SUPPORT_EMAIL}
        </Link>
        {' '}for help.
      </Text>
    </SsoError>
  </SsoLayout>
);

export default SignupError;
