/* eslint class-methods-use-this:0 */
import { strings } from 'business-dashboard/src/i18n';
import React, {
  useCallback,
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import {
  Box,
  Heading,
  Text
} from '@chakra-ui/react';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import {
  phoneNumber,
  cleanPhoneNumber,
  validateUrl
} from '../../reactFinalForm/validators';
import Button from '../elements/Button';
import FormSubmit from '../../reactFinalForm/FormSubmit';
import SimpleForm from '../../reactFinalForm/SimpleForm';
import TextField from '../../reactFinalForm/fields/TextField';
import InlineSupportLink from '../../support/InlineSupportLink';

import View from '../layout/View';
import { WizardStep } from '../wizard/WizardManager';

import Address from './Address';
import ToggleBillingAddress from './ToggleBillingAddress';

const defaultAddress = Object.freeze({
  address: {
    sameAsLocation: true
  }
});

const AddAddress = ({ step, isBusinessSignUp, manager }) => {
  const [initialAddressValues, setInitialAddressValues] = useState(defaultAddress);

  const { strings } = useI18Next();

  const extraProps = step.extraProps || {
    desc: strings('ui.component.addAddress.extraProps.desc')
  };

  useEffect(() => {
    if (step.data?.address) {
      setInitialAddressValues({
        ...(!(step.data.address.sameAsLocation) && step.data.address.billing && {
          billing: { ...step.data.address.billing }
        }),
        address: {
          sameAsLocation: true,
          ...step.data.address
        },
        phone: step.data.address.phone,
        url: step.data.address.url
      });
    }
  }, [step]);

  const onComplete = useCallback(({
    address: locationAddress,
    billing: billingAddress,
    phone,
    url
  }) => {
    const data = {
      ...step.data,
      address: {
        ...locationAddress,
        billing: locationAddress.sameAsLocation ? locationAddress : billingAddress,
        phone: phone ? cleanPhoneNumber(phone) : undefined,
        url
      }
    };

    step.stepComplete(data);
  });

  return (
    <View>
      <View.Section narrow>
        <Heading fontSize="2xl" fontWeight="medium" mb={4}>
          {strings('ui.component.addAddress.whatIsTheAddress')}
        </Heading>
        {extraProps?.desc && <Text mb={2}>{extraProps.desc}</Text>}
      </View.Section>

      <SimpleForm initialValues={initialAddressValues} onSubmit={onComplete}>
        <View.Section narrow>
          <Address name="address" />
          <Box mt={4}>
            <TextField
              placeholder="604-555-1212"
              wrapLabel
              name="phone"
              label={strings('ui.field.label.phone')}
              optional
              validate={phoneNumber}
              info={strings('ui.field.info.phone')} />
          </Box>
          <Box mt={4}>
            <TextField
              wrapLabel
              name="url"
              label={strings('ui.field.label.website')}
              optional
              validate={validateUrl}
              info={strings('ui.field.url.info')}
              parse={v => v}
            />
          </Box>
        </View.Section>

        {isBusinessSignUp && (
          <View.Section narrow>
            <Box my={8}>
              <Heading fontSize="2xl" fontWeight="medium" mb={4} data-testid="billingAddress-heading">
                {strings('ui.field.label.billingAddress')}
              </Heading>
              <ToggleBillingAddress />
            </Box>
            <Field name="address.sameAsLocation">
              {({ input: { value } }) => (!value ? (
                <Address name="billing" data-testid="billingAddress-fields" />
              ) : null)}
            </Field>
          </View.Section>
        )}

        <View.Section narrow className="flex">
          <Button
            type='button'
            className='mra'
            onClick={() => manager.showPreviousStep()}
          >
            {strings('ui.component.wizard.previous')}
          </Button>
          <FormSubmit label={strings('ui.label.next')} className="mla" />
        </View.Section>
        <View.Section narrow>
          <p>{strings('ui.label.haveAQuestion')} <InlineSupportLink />.</p>
        </View.Section>
      </SimpleForm>
    </View>
  );
};

AddAddress.propTypes = {
  step: PropTypes.object,
  isBusinessSignUp: PropTypes.boolean
};

export default () => new WizardStep({
  key: 'address',
  getTitle: data => data.business.name,
  render: AddAddress
});

