import React from 'react';
import { Link } from 'react-router-dom';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import View from '../components/layout/View';
import notFoundImage from '../assets/not-found-icon.png';

const NotFound = () => {
  const { strings } = useI18Next();
  const containerHeight = { minHeight: '100vh' };

  return (
    <View>
      <View.Section className='flex aic jcc'>
        <div className='flex fdc aic jcc' style={containerHeight}>
          <img style={{ maxWidth: '10%' }} src={notFoundImage} alt='notFoundImage' />
          <h1 className='mb3'><b>{strings('ui.component.notFound.title')}</b></h1>
          <p className='mb3 lead'>{strings('ui.component.notFound.desc')}</p>
          <Link to='/' className='ui primary button'>{strings('ui.component.notFound.buttonLabel')}</Link>
        </div>
      </View.Section>
    </View>
  );
};

export default NotFound;
