/* eslint camelcase: 0, class-methods-use-this: 0 */
import _ from 'lodash';
import PropTypes from 'prop-types';
/* eslint-disable-next-line no-redeclare */
/* globals window */
import React from 'react';

import { useQuerystringParam } from 'shared/src/hooks/useQuerystringParam';
import View from 'web-react-ui/src/components/layout/View';

import Terms from '../siteFooter/Terms';
import SupportLink from '../support/SupportLink';
import { strings } from '../../i18n';

function Confirm() {
  const [email] = useQuerystringParam('email', '');
  const propertyLogo = _.get(window, 'lmgDomainConfig.property.logoStandardImageUrl');

  return (
    <View.Section>
      <View.Section narrow>
        {propertyLogo && <img src={propertyLogo} alt='' style={{ alignSelf: 'center', maxWidth: '60%' }} />}
        <h2>{strings('dashboard.component.confirm.sent')}</h2>
        <p className='mb0'>{strings('dashboard.component.confirm.emailHasBeenSent')}</p>
        <a target="_blank" rel="noopener noreferrer" href={`mailto:${email}`}>
          {email}
        </a>
        <p className='mt2 pb1'>{strings('dashboard.component.confirm.checkEmail')}</p>
        <div className='flex mt4'>
          <p className='mr1 mb0'>{strings('dashboard.component.confirm.noEmail')}</p>
          <SupportLink label={strings('dashboard.component.confirm.fixIt')} />
        </div>
        <div className='flex mb1'>
          <p className='mr1'>{strings('dashboard.component.confirm.hasAccount')}</p>
          <a href={'/login'}>
            {strings('dashboard.label.signIn')}
          </a>
        </div>
        <Terms />
      </View.Section>
    </View.Section>
  );
}

Confirm.propTypes = {
  label: PropTypes.string,
  fluid: PropTypes.bool,
  narrow: PropTypes.bool
};

export default Confirm;
