import React from 'react';
import { Box, Flex, Heading, Spinner, VStack, Text } from '@chakra-ui/react';

const AuthorizeSpinner = ({
  title,
  body,
}: {
  title?: string | React.ReactNode,
  body?: string | React.ReactNode
}) => {
  return (
    <Box bg='white'>
      <Flex h='100vh' justify='center' align='center'>
        <VStack spacing={6} w='480px'>
          <Spinner size='lg' />
          {typeof title === 'string' ? (
            <Heading fontWeight={300}>{title}</Heading>
          ) : title}
          {typeof body === 'string' ? (
            <Text fontSize='xl' color='gray.500' textAlign='center'>{body}</Text>
          ) : body}
        </VStack>
      </Flex>
    </Box>
  );
};

export default AuthorizeSpinner;
