/* eslint-disable no-bitwise */
import PropTypes from 'prop-types';
import React from 'react';
import SearchBase from 'web-react-ui/src/components/newSearchBase/SearchBase';
import { Field } from 'web-react-ui/src/reactFinalForm';
import FieldError from 'web-react-ui/src/reactFinalForm/fields/FieldError';
import client from '../../services/client';


const searchCategoriesFactory = leafOnly => query => client.categories
  .search({ query, leafOnly })
  .then(pagedList => pagedList.items);

const CategoryField = ({
  name, label, validate, leafOnly
}) => {
  const searchCategories = searchCategoriesFactory(leafOnly);
  return (
    <Field name={name} validate={validate}>
      {({ input }) => (
        <label>
          {label}
          <SearchBase multiSelect search={searchCategories} idField='id' defaultSelectedItem={input.value} {...input}/>
          <FieldError name={name}/>
        </label>
      )}
    </Field>
  );
};

CategoryField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  validate: PropTypes.func,
  leafOnly: PropTypes.bool
};

export default CategoryField;
