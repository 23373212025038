import {
  Badge,
} from '@chakra-ui/react';
import React from 'react';

const getLabel = (status: string) => {
  switch (status) {
    case 'scheduled':
      return {
        label: 'SCHEDULED',
        scheme: 'blue',
      };
    case 'live':
      return {
        label: 'LIVE',
        scheme: 'green',
      };
    case 'error':
      return {
        label: 'ERROR',
        scheme: 'red',
      };
    default:
      return {
        label: status?.toUpperCase(),
      };
  }
};
const StatusBadge = ({ status, ...rest }: { status: string }) => {
  const { label, scheme } = getLabel(status);

  return (
    <Badge
      variant="solid"
      colorScheme={scheme}
      py={1}
      px={2.5}
      borderRadius={4}
      {...rest}
    >
      {status}
    </Badge>
  );
};

export default StatusBadge;
