import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  Box,
  Heading,
  Link,
  Text
} from '@chakra-ui/react';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import StaticMap from '../../map/StaticMap';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Label from '../elements/Label';
import ImageRenderer from '../image/ImageRenderer';
import View from '../layout/View';
import { WizardStep } from '../wizard/WizardManager';
import InlineSupportLink from '../../support/InlineSupportLink';
import { formatMoney } from '../plansCard/formatMoney';

const ConfirmDetailsStep = ({ step, manager }) => {
  const { strings } = useI18Next();
  const { business = {}, address = {}, paymentPlan = null } = step.data;
  const centre = address.centre || {};

  const marker = { center: [centre.lat, centre.long] };

  const jumpToStep = key => manager.showStepByKey(key);

  const displayComposedAddress = (addressObj) => {
    if (!addressObj) return null;
    const {
      suite,
      streetAddress,
      street,
      city,
      state,
      country,
      postalcode
    } = addressObj;
    const composedStreetAddress = [suite, streetAddress, street]
      .filter(part => !!part)
      .join(' ');
    return `
        ${composedStreetAddress}
        ${`${city}, ${state}, ${country}`}
        ${postalcode}
      `;
  };

  const getPaymentDesc = (plan) => {
    let paymentDesc;

    switch (plan.value) {
      case -1:
        paymentDesc = strings('ui.component.confirmDetailsStep.enterprise');
        break;
      case 0:
        paymentDesc = strings('ui.component.confirmDetailsStep.free');
        break;
      default:
        paymentDesc = (
          <>
            {formatMoney(plan, strings)}
            {
              plan.periodUnit === 'year'
                ? strings('ui.component.confirmDetailsStep.chargedAnnually')
                : strings('ui.component.confirmDetailsStep.chargedMonthly')
            }
          </>
        );
        break;
    }
    return (
      <Text data-testid="paymentDescText">
        {paymentDesc}
      </Text>
    );
  };

  const renderPaymentView = (plan) => {
    if (plan === null) return null;

    return (
      <View.Section narrow>
        <Flex flexDir="column" mb={8}>
          <Flex alignItems="center" justifyContent="space-between">
            <Heading fontSize="2xl" fontWeight="bold">
              {strings('ui.component.confirmDetailsStep.selectedPlan')}
            </Heading>
            <Link fontSize="lg" textColor="blue.500" fontWeight="medium" onClick={() => jumpToStep('selectPlan')}>
              {strings('ui.component.confirmDetailsStep.edit')}
            </Link>
          </Flex>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" mb={8}>
          <Text>
            {strings(`shared.plansViewModel.${plan?.key}.name`)}
            {strings('ui.component.confirmDetailsStep.plan')}
          </Text>
          {getPaymentDesc(plan)}
        </Flex>
      </View.Section>
    );
  };

  return (
    <View className="mb4">
      <View.Section narrow>
        <Heading fontSize="2xl" fontWeight="medium" mb={4}>
          {strings('ui.component.confirmDetailsStep.confirmDetails')}
        </Heading>
        <Text>
          {strings('ui.component.confirmDetailsStep.fragment.pt1')}&nbsp;
          <em>{strings('ui.component.confirmDetailsStep.edit')}</em>&nbsp;
          {strings('ui.component.confirmDetailsStep.fragment.pt2')}
          <br />
          {strings('ui.component.confirmDetailsStep.fragment.pt3')}
        </Text>
      </View.Section>
      <View.Section narrow>
        <Flex flexDir="column">
          <Flex alignItems="center" justifyContent="space-between" mb={4}>
            <Heading fontSize="2xl" fontWeight="medium">
              {business.name}
            </Heading>
            <Link
              fontSize="lg"
              textColor="blue.500"
              fontWeight="medium"
              onClick={() => jumpToStep('addBusinessDetails')}
            >
              {strings('ui.component.confirmDetailsStep.edit')}
            </Link>
          </Flex>
          <Box mb={4}>
            <Label.Group>
              {business.categories?.map(category => <Label key={category.id}>{category.name}</Label>)}
            </Label.Group>
          </Box>
          <Box mb={4}>
            <Text fontWeight="bold" mb={4}>{strings('ui.component.confirmDetailsStep.shortName')}</Text>
            <Text>{business.shortName}</Text>
          </Box>

          <Box mb={4}>
            <Text fontWeight="bold" mb={4}>{strings('ui.component.confirmDetailsStep.description')}</Text>
            <Text>{business.description}</Text>
          </Box>

          <Text>
            <b>{strings('ui.component.confirmDetailsStep.website')}:</b>&nbsp;
            {business.url || strings('ui.component.confirmDetailsStep.none')}
          </Text>
        </Flex>
      </View.Section>
      <View.Section narrow>
        <Flex flexDir="column" mb={8}>
          <Flex alignItems="center" justifyContent="space-between" mb={8}>
            <Heading fontSize="2xl" fontWeight="medium">
              {strings('ui.component.confirmDetailsStep.location')}
              &nbsp;{strings('ui.component.confirmDetailsStep.address')}
            </Heading>
            <Link fontSize="lg" textColor="blue.500" fontWeight="medium" onClick={() => jumpToStep('address')}>
              {strings('ui.component.confirmDetailsStep.edit')}
            </Link>
          </Flex>
          <Text>
            {displayComposedAddress(address)}
          </Text>
        </Flex>
        <Text mb={8}>
          <b>{strings('ui.component.confirmDetailsStep.number')}:</b>&nbsp;
          {address.phone || strings('ui.component.confirmDetailsStep.none')}
        </Text>

        <Text mb={8}>
          <b>{strings('ui.field.label.website')}:</b>&nbsp;
          {address.url || strings('dashboard.screen.locationDetails.noWebsiteSet')}
        </Text>

        <Flex flexDir="column" mb={8}>
          <Flex alignItems="center" justifyContent="space-between" mb={8}>
            <Heading fontSize="2xl" fontWeight="medium">
              {strings('ui.component.confirmDetailsStep.billing')}
              &nbsp;{strings('ui.component.confirmDetailsStep.address')}
            </Heading>
            <Link fontSize="lg" textColor="blue.500" fontWeight="medium" onClick={() => jumpToStep('address')}>
              {strings('ui.component.confirmDetailsStep.edit')}
            </Link>
          </Flex>

          {address.sameAsLocation ? (
            <Text data-testid="billingAddress-notPresent">
              {strings('ui.field.label.sameAsLocationAddress')}
            </Text>
          ) : (
            <Text data-testid="billingAddress-present">
              {displayComposedAddress(address.billing)}
            </Text>
          )}
        </Flex>
      </View.Section>
      <View.Section narrow>
        <Flex flexDir="column" mb={8}>
          <Flex alignItems="center" justifyContent="space-between">
            <Heading fontSize="2xl" fontWeight="medium">
              {strings('ui.component.confirmDetailsStep.location')}
            </Heading>
            <Link fontSize="lg" textColor="blue.500" fontWeight="medium" onClick={() => jumpToStep('confirmLocation')}>
              {strings('ui.component.confirmDetailsStep.edit')}
            </Link>
          </Flex>
        </Flex>
        <StaticMap
          marker={true}
          zoom={17}
          width={425}
          center={marker.center}
        />
      </View.Section>
      <View.Section narrow>
        <Flex flexDir="column" mb={8}>
          <Flex alignItems="center" justifyContent="space-between" mb={4}>
            <Heading fontSize="2xl" fontWeight="medium">
              {strings('ui.component.confirmDetailsStep.images')}
            </Heading>
            <Link fontSize="lg" textColor="blue.500" fontWeight="medium" onClick={() => jumpToStep('logo')}>
              {strings('ui.component.confirmDetailsStep.edit')}
            </Link>
          </Flex>
          <Box mb={4}>
            <Text mb={4} fontWeight="medium">{strings('ui.component.confirmDetailsStep.profileImages')}</Text>
            <ImageRenderer imageUrl={business.rawProfileImage} />
          </Box>
          <Box>
            <Text mb={4} fontWeight="medium">{strings('ui.component.confirmDetailsStep.businessImage')}</Text>
            <Image src={business.rawHeroImage} style={{ width: '100%', height: 'auto' }} />
          </Box>
        </Flex>
      </View.Section>

      {renderPaymentView(paymentPlan)}

      <View.Section narrow className="flex">
        <Button
          type="button"
          className="mra"
          onClick={() => manager.showPreviousStep()}
        >
          {strings('ui.component.wizard.previous')}
        </Button>
        <Button
          primary
          onClick={() => step.stepComplete(step.data)}
          className="mt1 mla">{strings('ui.component.confirmDetailsStep.saveCreate')}
        </Button>
      </View.Section>
      <View.Section narrow>
        <Flex alignItems="center">
          <Text fontSize="md" fontWeight="medium" mr={1}>{strings('ui.label.haveAQuestion')}</Text>
          <InlineSupportLink />
        </Flex>
      </View.Section>
    </View>
  );
};

ConfirmDetailsStep.propTypes = {
  step: PropTypes.object,
  manager: PropTypes.object
};

export default () => new WizardStep({
  key: 'confirmDetails',
  getTitle: data => data.business.name,
  render: ConfirmDetailsStep
});
