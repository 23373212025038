import {
  Flex,
  SkeletonCircle,
  SkeletonText
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import List from 'web-react-ui/src/chakra/List';
import ProfileImage from 'web-react-ui/src/components/image/ProfileImage';
import BusinessesNotFound from './BusinessesNotFound';

const LoadingItem = () => (
  <List.Item>
    <Flex mr='16px'>
      <SkeletonCircle h='32px' w='32px' />
    </Flex>
    <SkeletonText w='100%' noOfLines={3} />
  </List.Item>
);

const ListItem = ({ item }) => (
  <List.Item
    as={Link}
    to={`/business/${item.id}`}
  >
    <Flex>
      <Flex mr='16px'>
        <ProfileImage imageId={item.logoImage} name={item.name} />
      </Flex>
      <Flex flexDir='column'>
        <List.Header>{item.name}</List.Header>
        <List.Body>
          {item.locations?.length !== 1 && `${item.locations?.length} locations`}
          {item.locations?.length === 1 && `${item.locations[0]?.name}`}
        </List.Body>
        <List.Footnote>
          {item.plan?.name}
        </List.Footnote>
      </Flex>
    </Flex>
  </List.Item>
);

ListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    logoImage: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string
    })),
    plan: PropTypes.shape({
      name: PropTypes.string
    })
  })
};

const MobileBusinessList = ({ businesses }) => (
  <React.Fragment>
    {businesses.loading && (
      <LoadingItem />
    )}
    {businesses.empty && (
      <BusinessesNotFound />
    )}
    {businesses.items?.map(item => (
      <ListItem item={item} key={item.id} />
    ))}
  </React.Fragment>
);

MobileBusinessList.propTypes = {
  businesses: PropTypes.arrayOf(PropTypes.any)
};

export default MobileBusinessList;
