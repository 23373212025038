import {
  Heading,
  HStack,
  Image,
  Link,
  SkeletonText,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import useRequest from 'shared/src/hooks/useRequest';
import businessesModule from '../../../modules/businesses';
import client from '../../../services/client';
import useProperty from '../../useProperty';
import heroImage from './assets/postSignupHero.png';

const hasLiveOffers = async ({ businessId, propertyId }) => {
  if (!businessId || !propertyId) return null;

  // NOTE: We only check `live` because, although they _could_ get a Social Post scheduled for a `scheduled`
  // Offer... who cares?
  const offers = await client
    .properties.for(propertyId)
    .businesses.for(businessId)
    .offers.list({
      limit: 1,
      status: ['live'],
    });

  return offers.items.length;
};

const SocialPostListEmpty = () => {
  const property = useProperty();
  const business = useSelector(state => businessesModule.selectors.business.getData(state));
  const profile = useSelector(state => state.social.profile);
  const hasLiveOffersRequest = useRequest(hasLiveOffers, { businessId: business.id, propertyId: property.id });

  if (!profile.socialAccounts.length) {
    return (
      <HStack spacing="3em" marginTop="2em">
        <Image src={heroImage} />
        <VStack gap="3em" alignItems="flex-start">
          <Heading size="lg" textAlign="left">
            Start by Linking Facebook or Instagram
          </Heading>
          <Text fontSize="lg">
            Social AI needs to have at least one social media account linked to start generating posts and stories.
          </Text>
          <Link
            as={RouterLink}
            to="./social/settings"
            color="blue.500"
            fontSize="lg"
          >
            Link Account Now
          </Link>
        </VStack>
      </HStack>
    );
  }

  return (
    <HStack spacing="3em" marginTop="2em">
      <Image src={heroImage} />
      <SkeletonText isLoaded={hasLiveOffersRequest.settled}>
        {
          hasLiveOffersRequest.result
            ? (
              <VStack gap="3em" alignItems="flex-start">
                <Heading size="lg" textAlign="left">
                  New Social Posts are Being Created
                </Heading>
                <Text fontSize="lg">
                  Social AI generates posts and stories automatically from your Promotions and Loyalty Offers.
                  It can take up to 10 minutes for your first post to be generated.
                </Text>
              </VStack>
            )
            : (
              <VStack gap="3em" alignItems="flex-start">
                <Heading size="lg" textAlign="left">
                  Waiting for Promotions or Loyalty Rewards
                </Heading>
                <Text fontSize="lg">
                  Social AI generates posts and stories automatically from your Promotions and Loyalty Offers.
                  Make sure you have at least one Promotion or Loyalty Offer active to start getting posts*
                </Text>
                <Text fontSize="text-md" color="gray.400" fontWeight="normal">
                  * It can take up to 24 hours after your first offer goes live to generate posts.
                </Text>
              </VStack>
            )
        }

      </SkeletonText>
    </HStack>
  );
};

export default SocialPostListEmpty;
