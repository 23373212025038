import {
  SOCIAL_PROFILE_FIELDS,
  formatSocial,
  parseSocial
} from 'shared/src/constants/socialProfiles';
import { Heading } from '@chakra-ui/react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Redirect,
  withRouter
} from 'react-router-dom';
import { bindActionCreators } from 'redux';

import BigModal from 'web-react-ui/src/BigModal/BigModal';
import Menu from 'web-react-ui/src/components/collections/Menu';
import CancelButton from 'web-react-ui/src/components/elements/CancelButton';
import ImageField from 'web-react-ui/src/components/image/ImageField';
import View from 'web-react-ui/src/components/layout/View';
import { TextField } from 'web-react-ui/src/reactFinalForm/fields';
import FieldListField from 'web-react-ui/src/reactFinalForm/fields/FieldListField';
import DefaultLocaleMessage from 'web-react-ui/src/reactFinalForm/fields/localizableField/DefaultLocaleMessage';
import LocaleSelector from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocaleSelector';
import LocalizableSimpleForm from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableSimpleForm';
import LocalizableTextArea from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableTextArea';
import LocalizableTextField from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableTextField';
import FormDebugger from 'web-react-ui/src/reactFinalForm/FormDebugger';
import FormError from 'web-react-ui/src/reactFinalForm/FormError';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';
import {
  atLeastOneCompleteLocalization,
  forAllLocales,
  max,
  required,
  requiredArray
} from 'web-react-ui/src/reactFinalForm/validators';
import { strings } from '../../i18n';
import businessModule from '../../modules/businesses/index';
import client from '../../services/client';

import CategoriesSelectorField from '../categories/CategoriesSelectorField';
import PlatformTranslationWrapper from '../i18n/PlatformTranslationWrapper';
import useProperty from '../useProperty';

const compareBusinessChangesForIndex = changes => (indexBusiness) => {
  if (indexBusiness.name !== changes.name) return false;
  if (indexBusiness.shortName !== changes.shortName) return false;
  if (_.difference(indexBusiness.categories.map(c => c.id), changes.categories.map(c => c.id)).length) return false;
  if (indexBusiness.desc !== changes.description) return false;
  if (_.difference(
    [changes.heroImage, changes.logoImage, changes.profileImage].filter(Boolean),
    indexBusiness.images.filter(Boolean)
  ).length) return false;

  if (indexBusiness.slogan != changes.slogan) return false; // eslint-disable-line eqeqeq
  if (indexBusiness.phone !== changes.phone) return false;
  if (indexBusiness.url !== changes.url) return false;

  return true;
};

class EditBusinessView extends Component {
  state = {
    redirect: null
  };

  componentDidMount() {
    this.loadBusiness();
  }

  cancelEditBusiness() {
    const { businessId } = this.props;
    const offerDetailsUrl = `/business/${businessId}`;
    this.setState({ redirect: offerDetailsUrl });
  }

  loadBusiness() {
    const { businessId } = this.props;
    this.setState({ isLoadingBusiness: true });
    client
      .businesses.for(businessId)
      .editWithLocalization()
      .then((business) => {
        // fixes validation issues if they're not caught early...
        if (business.url && business.url.indexOf('http') !== 0) {
          // eslint-disable-next-line no-param-reassign
          business.url = `https://${business.url}`;
        }

        this.setState({
          isLoadingBusiness: false,
          business
        });
      });
  }

  async saveBusiness(newFields) {
    const property = useProperty();
    const { history, businessId, invalidateAndRefresh } = this.props;
    const { business } = this.state;
    const offerDetailsUrl = `/business/${businessId}`;

    await client
      .businesses.for(businessId)
      .update(business, newFields);

    const compareFn = compareBusinessChangesForIndex(newFields);

    await client
      .properties.for(property.id)
      .businesses.for(businessId)
      .waitForIndex(
        compareFn,
        () => {
          throw new Error(strings('dashboard.screen.editBusinessView.businessUpdated'));
        }
      );

    invalidateAndRefresh({
      businessId,
      propertyId: property.id
    }).then(() => {
      history.push(offerDetailsUrl);
    });
  }

  render() {
    const { business, isLoadingBusiness } = this.state;
    if (this.state.redirect) return <Redirect to={this.state.redirect} />;

    return (
      <PlatformTranslationWrapper>
        <BigModal open={true}>
          <LocalizableSimpleForm
            onSubmit={this.saveBusiness.bind(this)}
            initialValues={business}
            loading={isLoadingBusiness}
            validateLocale={atLeastOneCompleteLocalization([
              'nameLocalized', 'shortNameLocalized', 'descriptionLocalized'
            ])}
          >
            <BigModal.Contents>
              <BigModal.Header className="p1">
                <Menu secondary>
                  <Menu.Item fitted>
                    <CancelButton type="button" onClick={this.cancelEditBusiness.bind(this)} />
                  </Menu.Item>
                  <Menu.Menu position="right">
                    <Menu.Item fitted>
                      <FormSubmit label={strings('dashboard.screen.editBusinessView.label.save')} ignoreValidity />
                    </Menu.Item>
                  </Menu.Menu>
                </Menu>
              </BigModal.Header>
              <BigModal.Body>
                <View>
                  <View.Section narrow>
                    <div className="flex">
                      <h2 className="mra">{strings('dashboard.screen.editBusinessView.editBusinessDetails')}</h2>
                      <LocaleSelector inline />
                    </div>
                    <DefaultLocaleMessage localeInfoKey="_localization" />
                    <FormDebugger />
                    <FormError />
                    <LocalizableTextField
                      label={strings('dashboard.screen.editBusinessView.label.name')}
                      name="nameLocalized"
                      wrapLabel
                      validateLocale={forAllLocales(max(64))}
                      info={strings('dashboard.screen.editBusinessView.label.nameInfo')} />
                    <LocalizableTextField
                      label={strings('dashboard.screen.editBusinessView.label.shortName')}
                      name="shortNameLocalized"
                      wrapLabel
                      validateLocale={forAllLocales(max(24))}
                      info={strings('dashboard.screen.editBusinessView.label.shortName')}
                    />
                    <LocalizableTextArea
                      label={strings('dashboard.screen.editBusinessView.label.description')}
                      name="descriptionLocalized"
                      placeholder={strings('dashboard.screen.editBusinessView.label.descriptionPlaceholder')}
                      wrapLabel
                      validateLocale={forAllLocales(max(254))}
                    />
                    <CategoriesSelectorField
                      label={strings('dashboard.screen.editBusinessView.label.categories')}
                      name="categories"
                      wrapLabel
                      validate={requiredArray}
                      leafOnly
                    />
                    <p className="small">{strings('dashboard.screen.editBusinessView.chooseTheCategory')}</p>
                    <TextField
                      label={strings('dashboard.screen.editBusinessView.label.website')}
                      name="url"
                      type="url"
                      wrapLabel
                      validate={max(64)}
                      parse={v => v}
                    />
                  </View.Section>
                  <View.Section narrow>
                    <h3>{strings('dashboard.screen.editBusinessView.images.title')}</h3>
                    <h6>{strings('dashboard.screen.editBusinessView.images.profileImage')}</h6>
                    <p>{strings('dashboard.screen.editBusinessView.images.description')}</p>
                    <ImageField
                      name="profileImage"
                      fileType="business-profile" />

                    <h6>{strings('dashboard.screen.editBusinessView.logo.title')}</h6>
                    <p>{strings('dashboard.screen.editBusinessView.logo.description')}</p>
                    <ImageField name="logoImage" fileType="business-logo-standard" />

                    <h6>{strings('dashboard.screen.editBusinessView.businessImage.title')}</h6>
                    <p>{strings('dashboard.screen.editBusinessView.businessImage.description')}</p>
                    <ImageField
                      name="heroImage"
                      fileType="business-hero-standard"
                      validate={required}
                    />
                  </View.Section>
                </View>
                <View.Section narrow>
                  <Heading size="md" mb={2}>Social Media Profiles</Heading>
                  <FieldListField
                    name="socialProfiles"
                    addButtonLabel="Add"
                    fields={SOCIAL_PROFILE_FIELDS}
                    parse={parseSocial}
                    format={formatSocial}
                  />
                </View.Section>

              </BigModal.Body>
            </BigModal.Contents>
          </LocalizableSimpleForm>
        </BigModal>
      </PlatformTranslationWrapper>
    );
  }
}

EditBusinessView.propTypes = {
  businessId: PropTypes.string,
  history: PropTypes.object,
  invalidateAndRefresh: PropTypes.func,
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(businessModule.actions, dispatch);
};

export default withRouter(connect(null, mapDispatch)(EditBusinessView));
