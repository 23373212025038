import React from 'react';
import { Field } from 'react-final-form';
import FieldList from '../../chakra/FieldList/FieldList';
import SuiForm from '../../components/collections/Form';

import FieldError from './FieldError';
import FieldLabel from './FieldLabel';

const FieldListField = (props) => {
  const {
    name,
    label,
    validate,
    format,
    formatOnBlur = false,
    parse,
    fluid = true,
    inputProps,
    optional,
    wrapLabel = true,
    info,
    disabled,
    chakra,
    ...rest
  } = props;

  const FieldCmp = chakra ? React.Fragment : SuiForm.Field;

  return (
    <Field name={name} validate={validate} format={format} parse={parse} info={info} formatOnBlur={formatOnBlur}>
      {({ input }) => {
        return (
          <FieldCmp>
            <FieldLabel label={label} optional={optional} wrapLabel={wrapLabel} disabled={disabled}>
              <FieldList {...input} disabled={disabled} {...rest} />
              {info && !chakra && <p className="text--muted">{info}</p>}
              <FieldError name={name} chakra={chakra} />
            </FieldLabel>
          </FieldCmp>
        );
      }}
    </Field>
  );
};

export default FieldListField;
