import {
  Box,
  Heading
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useMemo,
} from 'react';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import EditLocation from 'shared/src/components/locations/EditLocation';
import BigModal from 'web-react-ui/src/BigModal/BigModal';
import PageBackButton from 'web-react-ui/src/chakra/page/PageBackButton';
import ExternalListingsField from 'web-react-ui/src/components/locations/ExternalListingsField';
import { strings } from '../../i18n';
import businessesModule from '../../modules/businesses';
import client from '../../services/client';

import useProperty from '../useProperty';

const compareLocationChanges = changes => (indexLocation) => {
  if (indexLocation.name !== changes.name) return false;
  if ((indexLocation.url || '') !== (changes.url || '')) return false;
  if (indexLocation.phone !== changes.phone) return false;
  if (indexLocation.address.streetAddress !== changes.address.streetAddress) return false;
  if (indexLocation.address.street !== changes.address.street) return false;
  if (indexLocation.address.suite !== changes.address.suite) return false;
  if (indexLocation.address.state !== changes.address.state) return false;
  if (indexLocation.address.city !== changes.address.city) return false;
  if (indexLocation.address.postalcode !== changes.address.postalcode) return false;
  if (indexLocation.address.country !== changes.address.country) return false;
  if (indexLocation.address.centre[0] !== changes.address.centre.long) return false;
  if (indexLocation.address.centre[1] !== changes.address.centre.lat) return false;

  return true;
};

const EditLocationView = ({ businessId, locationId, history }) => {
  const property = useProperty();

  const fetchLocation = useMemo(
    () => async () => {
      const locationRepo = client
        .businesses.for(businessId)
        .locations.for(locationId);

      const location = await locationRepo.editWithLocalization();
      const externalListings = await locationRepo.getExternalPlaceIds();

      return {
        ...location,
        externalListings
      };
    },
    [businessId, locationId]
  );

  const updateLocation = useCallback(
    async (originalLocation, updates) => {
      const locationRepo = client
        .businesses.for(businessId)
        .locations.for(locationId);

      await locationRepo.update(originalLocation, updates);
      await locationRepo.setExternalPlaceIds(updates.externalListings);

      // Wait for search index to be updated...
      const compareFn = compareLocationChanges(updates);
      await client
        .properties.for(property.id)
        .businesses.for(businessId)
        .locations.for(locationId)
        .waitForIndex(
          compareFn,
          () => {
            throw new Error(strings('dashboard.screen.editLocationView.locationUpdated'));
          }
        );

      history.push(`../${locationId}`);
    },
    [property, businessId, locationId]
  );

  return (
    <BigModal open={true}>
      <BigModal.Contents>
        <BigModal.Header className="p1">
          <PageBackButton to={`../${locationId}`}>Back</PageBackButton>
        </BigModal.Header>
        <BigModal.Body>
          <EditLocation fetchLocation={fetchLocation} onUpdate={updateLocation}>
            <Box gridColumn="1">
              <Heading size="md" mb={2}>External Listings</Heading>
              <AddExternalListingsField />
            </Box>
          </EditLocation>
        </BigModal.Body>
      </BigModal.Contents>
    </BigModal>
  );
};

const AddExternalListingsField = () => {
  // Hack to get the location details to pass to ExternalListingsField...
  const formState = useFormState();
  const location = formState.values;

  const business = useSelector(state => businessesModule.selectors.business.getData(state));

  return (
    <ExternalListingsField
      name="externalListings"
      context={{ location, name: business.name }}
      fieldWhitelist={['google']}
    />
  );
};

EditLocationView.propTypes = {
  businessId: PropTypes.string,
  locationId: PropTypes.string,
  history: PropTypes.object
};

export default EditLocationView;
