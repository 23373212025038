import React from 'react';
import {
  Flex,
  Box,
  Tag,
  List,
  ListItem,
  ListIcon,
  Heading,
  Text,
} from '@chakra-ui/react';

import { BiCheck } from 'react-icons/bi';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import PlanCardAction from './PlanCardAction';
import { formatMoney } from './formatMoney';
import { Plan } from './types';

const PlanCard = (
  { plan, onSelect, businessId, isLocalOwnerDashboard }:
    { plan: Plan, onSelect: (p: Plan) => void, businessId: string, isLocalOwnerDashboard: boolean },
) => {
  const { strings } = useI18Next();

  const {
    subHeader, priceText, savings, featHeader,
    features = [],
    isCurrent, isSelected,
  } = plan;

  const savingsLabel = savings
    ? strings('shared.plansViewModel.all.savings', { savings: Math.floor(savings * 100) })
    : '';

  return (
    <Box
      borderWidth="1px"
      borderColor={isSelected ? 'blue.200' : 'gray.200'}
      borderRadius="lg"
      padding={8}
      position="relative"
      data-testid={plan.key}
    >
      {isCurrent && (
        <Box position="absolute" top="-1px" right="-1px">
          <Tag
            size="lg"
            variant="solid"
            colorScheme="orange"
            borderBottomLeftRadius="lg"
            borderTopRightRadius="lg"
            borderRadius={0}
          >
            {strings('ui.label.currentPlan')}
          </Tag>
        </Box>
      )}

      <Flex flexDir="column" align="center" minHeight="12em" mb={6}>
        <Box textAlign="center" mb="12px">
          <Heading as="h2" size="lg" mb={2}>{plan.name}</Heading>
          <Text minH={['20px', '30px']}>{subHeader}</Text>
        </Box>
        <Box textAlign="center" mb="8px">
          <Box mb="4px">
            <Heading as="h2" size="lg" fontWeight={400} data-testid={`price-${plan.keyName}`}>
              {formatMoney(plan, strings)}
            </Heading>
          </Box>
          <Text color="gray.400">{priceText}</Text>
          {<Text color="green.400" fontWeight="bold">{savingsLabel}&nbsp;</Text>}
        </Box>
      </Flex>

      {!plan.hideSelectButton && (
        <PlanCardAction
          plan={plan} onSelect={onSelect} businessId={businessId} isLocalOwnerDashboard={isLocalOwnerDashboard}
        />
      )}

      <Text fontSize="md" mb={5}>{featHeader}</Text>
      <List spacing={3}>
        {(features || []).map((item: string) => (
          <ListItem key={item}>
            <ListIcon as={BiCheck} color="blue.400" />
            {item}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default PlanCard;
