import {
  useEffect,
  useState
} from 'react';
import client from '../services/client';

const useMyPlans = (property) => {
  const [myPlans, setMyPlans] = useState(null);

  useEffect(() => {
    (async () => {
      if (!property) return null;

      const hasPermissions = await client.access.checkPermissions(
        { propertyId: property.id },
        ['self.property.summary.search']
      );

      if (!hasPermissions) return null;

      const plans = await client.properties.for(property.id).plans.listMine();

      setMyPlans({ items: plans });

      return null;
    })();
  }, [property]);

  return myPlans;
};

export default useMyPlans;
