import React, { useEffect } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';

import { Flex, Text } from '@chakra-ui/react';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

const ToggleBillingAddress = () => {
  const { strings } = useI18Next();

  const form = useForm();
  const formState = useFormState();

  useEffect(() => {
    if (formState.modified['address.sameAsLocation'] && formState.values.address.sameAsLocation) {
      form.batch(() => {
        form.change('billing.state', '');
        form.change('billing.postalcode', '');
        form.change('billing.streetAddress', '');
        form.change('billing.street', '');
        form.change('billing.suite', '');
        form.change('billing.city', '');
      });
    }
  }, [formState]);

  return (
    <Text as='label'>
      <Flex alignItems='center'>
        <Field
          data-testid='checkbox'
          name="address.sameAsLocation"
          type="checkbox"
          component="input"
          />
        <Text ml={4}>{strings('ui.field.label.sameAsLocationAddress')}</Text>
      </Flex>
    </Text>
  );
};

export default ToggleBillingAddress;
