import {
  Flex,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import React, {
  useMemo,
} from 'react';
import _ from 'lodash';
import useClient from '../../hooks/useClient';
import Profile from '../../interfaces/Profile.interface';
import DisabledOverlay from './DisabledOverlay';
import SettingSelect from './SettingSelect';

const SETTINGS = [
  {
    label: 'Message Length',
    key: 'content.messageLength',
    options: [
      {
        label: 'Concise',
        value: 'concise',
      },
      {
        label: 'Normal',
        value: 'normal',
      },
      {
        label: 'Verbose',
        value: 'verbose',
      },
    ],
  },
  {
    label: 'Hashtags',
    key: 'content.hashtags',
    options: [
      {
        label: 'None',
        value: 'none',
      },
      {
        label: 'Some',
        value: 'some',
      },
      {
        label: 'Lots',
        value: 'many',
      },
    ],
  },
  {
    label: 'Emoji Use',
    key: 'content.emojiUse',
    options: [
      {
        label: 'None',
        value: 'none',
      },
      {
        label: 'Some',
        value: 'light',
      },
      {
        label: '👍💪😎❤️',
        value: 'heavy',
      },
    ],
  },
  {
    label: 'Post Frequency',
    key: 'postFrequency',
    options: [
      {
        label: 'Infrequent',
        value: 'light',
      },
      {
        label: 'Standard',
        value: 'moderate',
      },
      {
        label: 'Frequent',
        value: 'heavy',
      },
    ],
  },
];

const ContentSettings = ({ profile, businessId, disabled, disabledContent }: {
  profile: Profile,
  businessId: string,
  disabled?: boolean,
  disabledContent?: React.ReactNode,
}) => {
  const values = profile?.config ?? {};

  const client = useClient();

  const onChange = useMemo(
    () => async ({ name, value }: { name: string, value: any }) => {
      if (disabled) return;

      const body = {};
      _.set(body, name, value);

      await client
        .businesses.for(businessId)
        .social.modifyConfig(body);
    },
    [],
  );

  return (
    <Flex direction="column" gap="1em" position="relative">
      {
        SETTINGS.map(setting => (
          <FormControl key={setting.key} display="flex">
            <FormLabel fontSize="lg" w="50%">{setting.label}</FormLabel>
            <SettingSelect
              name={setting.key}
              value={_.get(values, setting.key)}
              onChange={onChange}
              options={setting.options}
            />
          </FormControl>
        ))
      }
      <DisabledOverlay disabled={disabled}>
        {disabledContent}
      </DisabledOverlay>
    </Flex>
  );
};

export default ContentSettings;
