import NeedToMakeAChange from './NeedToMakeAChange';
import Welcome from './Welcome';
import NewPosts from './NewPosts';
import GetNotified from './GetNotified';
import PostedAutomatically from './PostedAutomatically';

export const slides = [
  Welcome,
  NewPosts,
  GetNotified,
  PostedAutomatically,
  NeedToMakeAChange
];
