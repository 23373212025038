export const NAME = 'business';

export const types = {
  BUSINESS_DETAILS_REQUEST: 'business/DETAILS_REQUEST',
  BUSINESS_DETAILS_SUCCESS: 'business/DETAILS_SUCCESS',
  BUSINESS_DETAILS_FAILURE: 'business/DETAILS_FAILURE',
  BUSINESS_INVALIDATE: 'business/INVALIDATE',
  BUSINESS_INVALIDATE_REFRESH: 'business/INVALIDATE_REFRESH',
  BUSINESS_SET_HAS_LOYALTY: 'business/BUSINESS_SET_HAS_LOYALTY',
  BUSINESS_SET_HAS_PROMOTION: 'business/BUSINESS_SET_HAS_PROMOTION',
};
