import { strings } from '../../i18n';

export default [
  {
    key: 'essential',
    name: 'Starter',
    platformId: process.env.REACT_APP_PLATFORM_PLAN_ID_ESSENTIAL,
    pricePoints: [
      {
        tier: 0,
        value: 0,
        currencyCode: 'CAD',
        period: 1,
        periodUnit: 'month',
        chargebeePlanId: 'essential-local-monthly-cad',
      },
      {
        tier: 0,
        value: 0,
        currencyCode: 'USD',
        period: 1,
        periodUnit: 'month',
        chargebeePlanId: 'essential-local-monthly-usd',
      },
      {
        tier: 0,
        value: 0,
        currencyCode: 'CAD',
        period: 1,
        periodUnit: 'year',
        chargebeePlanId: null, // unselectable
      },
      {
        tier: 0,
        value: 0,
        currencyCode: 'USD',
        period: 1,
        periodUnit: 'year',
        chargebeePlanId: null, // unselectable
      }
    ],
    features: strings('shared.plansViewModel.essential.features', { returnObjects: true }),
    subHeader: strings('ui.component.planConstants.essential.subHeader'),
    priceText: strings('ui.component.planConstants.essential.priceText'),
    featHeader: strings('ui.component.planConstants.essential.featHeader')
  },
  {
    key: 'socialai',
    name: 'Social AI',
    platformId: process.env.REACT_APP_PLATFORM_PLAN_ID_SOCIALAI,
    pricePoints: [
      {
        tier: 1,
        value: 9900,
        currencyCode: 'CAD',
        period: 1,
        periodUnit: 'month',
        chargebeePlanId: 'social-ai-local-monthly-cad',
        priceText: strings('ui.component.planConstants.socialai.priceText'),
      },
      {
        tier: 1,
        value: 9900,
        currencyCode: 'USD',
        period: 1,
        periodUnit: 'month',
        chargebeePlanId: 'social-ai-local-monthly-usd',
        priceText: strings('ui.component.planConstants.socialai.priceText'),
      },
      {
        tier: 1,
        value: 99000,
        currencyCode: 'CAD',
        period: 1,
        periodUnit: 'year',
        chargebeePlanId: 'social-ai-local-annual-cad',
        priceText: strings('ui.component.planConstants.socialai.priceTextAnnual'),
        savings: 1 - (99000 / (9900 * 12))
      },
      {
        tier: 1,
        value: 99000,
        currencyCode: 'USD',
        period: 1,
        periodUnit: 'year',
        chargebeePlanId: 'social-ai-local-annual-usd',
        priceText: strings('ui.component.planConstants.socialai.priceTextAnnual'),
        savings: 1 - (99000 / (9900 * 12))
      }
    ],
    features: strings('shared.plansViewModel.socialai.features', { returnObjects: true }),
    subHeader: strings('ui.component.planConstants.socialai.subHeader'),
    featHeader: strings('ui.component.planConstants.socialai.featHeader')
  },
  {
    key: 'loyaltyv2',
    name: 'Loyalty',
    platformId: process.env.REACT_APP_PLATFORM_PLAN_ID_LOYALTYv2,
    pricePoints: [
      {
        tier: 1,
        value: 9900,
        currencyCode: 'CAD',
        period: 1,
        periodUnit: 'month',
        chargebeePlanId: 'loyalty-local-monthly-cad',
        priceText: strings('ui.component.planConstants.loyaltyv2.priceText'),
      },
      {
        tier: 1,
        value: 9900,
        currencyCode: 'USD',
        period: 1,
        periodUnit: 'month',
        chargebeePlanId: 'loyalty-local-monthly-usd',
        priceText: strings('ui.component.planConstants.loyaltyv2.priceText'),
      },
      {
        tier: 1,
        value: 99000,
        currencyCode: 'CAD',
        period: 1,
        periodUnit: 'year',
        chargebeePlanId: 'loyalty-local-annual-cad',
        priceText: strings('ui.component.planConstants.loyaltyv2.priceTextAnnual'),
        savings: 1 - (99000 / (9900 * 12))
      },
      {
        tier: 1,
        value: 99000,
        currencyCode: 'USD',
        period: 1,
        periodUnit: 'year',
        chargebeePlanId: 'loyalty-local-annual-usd',
        priceText: strings('ui.component.planConstants.loyaltyv2.priceTextAnnual'),
        savings: 1 - (99000 / (9900 * 12))
      }
    ],
    features: strings('shared.plansViewModel.loyaltyv2.features', { returnObjects: true }),
    subHeader: strings('ui.component.planConstants.loyaltyv2.subHeader'),
    featHeader: strings('ui.component.planConstants.loyaltyv2.featHeader')
  },
  {
    key: 'premium',
    name: 'Premium',
    platformId: process.env.REACT_APP_PLATFORM_PLAN_ID_PREMIUM,
    pricePoints: [
      {
        tier: 2,
        value: 24900,
        currencyCode: 'CAD',
        period: 1,
        periodUnit: 'month',
        chargebeePlanId: 'premium-local-monthly-cad',
        priceText: strings('ui.component.planConstants.premium.priceText'),
      },
      {
        tier: 2,
        value: 24900,
        currencyCode: 'USD',
        period: 1,
        periodUnit: 'month',
        chargebeePlanId: 'premium-local-monthly-usd',
        priceText: strings('ui.component.planConstants.premium.priceText'),
      },
      {
        tier: 2,
        value: 249000,
        currencyCode: 'CAD',
        period: 1,
        periodUnit: 'year',
        chargebeePlanId: 'premium-local-annual-cad',
        priceText: strings('ui.component.planConstants.premium.priceTextAnnual'),
        savings: 1 - (249000 / (24900 * 12))
      },
      {
        tier: 2,
        value: 249000,
        currencyCode: 'USD',
        period: 1,
        periodUnit: 'year',
        chargebeePlanId: 'premium-local-annual-usd',
        priceText: strings('ui.component.planConstants.premium.priceTextAnnual'),
        savings: 1 - (249000 / (24900 * 12))
      }
    ],
    features: strings('shared.plansViewModel.premium.features', { returnObjects: true }),
    subHeader: strings('ui.component.planConstants.premium.subHeader'),
    featHeader: strings('ui.component.planConstants.premium.featHeader')
  }
];
