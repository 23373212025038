import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Image,
  Link,
} from '@chakra-ui/react';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import {
  RootStateOrAny,
  useSelector,
} from 'react-redux';
import ViewHeader from 'web-react-ui/src/chakra/ViewHeader';
import businessesModule from '../../modules/businesses';
import Offers from './Offers';
import discountCoupon from './discount-coupon.svg';

import { strings } from '../../i18n';

const PromotionOffers = (): JSX.Element => {
  const business = useSelector((state: RootStateOrAny) => businessesModule.selectors.business.getData(state));
  if (!business.hasPromotions) {
    return (
      <Box maxW="7xl" mx="auto">
        <ViewHeader title={'Promotions'} />
        <Flex flexDir="column" textAlign="center" align="center" h="100%" justify="center" gap={8}>
          <Image src={discountCoupon} w="192px" h="192px" />
          <Heading>{strings('dashboard.component.offerCta.promotions.heading')}</Heading>
          <Text fontSize="xl" maxWidth="35em">
            {strings('dashboard.component.offerCta.promotions.body')}
          </Text>
          <Button
            as={RouterLink}
            to={'./promotions/create'}
            colorScheme="blue"
            size="lg"
          >
            {strings('dashboard.component.offerCta.promotions.buttonLabel')}
          </Button>
          <Text fontSize="xl" maxWidth="35em">
            {/* Wrapping Link onto a new line screws up the spacing when rendered */}
            <Trans i18nKey={'dashboard.component.offerCta.promotions.learnMoreLabel'}>
              Learn how <Link
                textDecoration="underline"
                href="https://getintheloop.ca/business/promotions"
                isExternal>promotional offers</Link> help your business reach its full potential.
            </Trans>
          </Text>
        </Flex>
      </Box>
    );
  }
  return <Offers business={business} offerType="promotions" hideBusiness />;
};

export default PromotionOffers;
