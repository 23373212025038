import { Button, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import useQuerystring from 'shared/src/hooks/useQuerystring';
import { COUNTRIES } from 'shared/src/constants/countries';
import useChargebeeCheckoutHostedPage from 'shared/src/hooks/chargebee/useChargebeeCheckoutHostedPage';

import plansViewModel from '../../../assets/data/plansViewModel';
import useBillingAddress from '../../../hooks/useBillingAddress';
import { strings } from '../../../i18n';
import useProperty from '../../useProperty';
import SsoLayout from '../SsoLayout';

const PLAN_KEY = 'partnership';

const useChargebeePlanId = (propertyId: string, businessId: string, planKey: string) => {
  const [planId, setPlanId] = useState<string>();

  const { billingAddress }: any = useBillingAddress({
    propertyId,
    businessId,
    defaultCountry: 'CA',
    counter: undefined,
  });

  useEffect(
    () => {
      if (!billingAddress) return;
      const country: Record<string, any> = COUNTRIES.find(
        (item: Record<string, any>) => item.keyName === billingAddress?.country
      );
      const [plan]: Record<string, any>[] = plansViewModel.filter(p => p.keyName === planKey);
      setPlanId(plan.price[country.keyName].chargebeePlanId);
    },
    [billingAddress],
  );

  return planId;
};

const handleComplete = () => {
  const url = new URL(window.location.href);
  url.pathname = '/apps/connect/authorize';
  window.location.replace(url.href);
};

const SignupPlanView = () => {
  const property = useProperty();
  const [{ businessId }] = useQuerystring();

  const chargebeePlanId = useChargebeePlanId(property.id, businessId, PLAN_KEY);

  const [openCheckout, chargebeeInstance, checkoutError] = useChargebeeCheckoutHostedPage(property.id, businessId);


  const handleCheckout = () => {
    if (!(openCheckout instanceof Function)) return;
    if (!chargebeePlanId) return;
    openCheckout(
      {
        planId: chargebeePlanId,
      },
      {
        step: (step: string) => {
          if (step === 'thankyou_screen') {
            handleComplete();
          }
        },
      },
    );
  };

  useEffect(
    () => {
      if (!chargebeeInstance || !chargebeePlanId) return;

      handleCheckout();
    },
    [openCheckout, chargebeeInstance, chargebeePlanId],
  );

  return (
    <SsoLayout>
      <Stack spacing={8} position="relative" w="100%">
        <Button variant="solid" colorScheme="blue" onClick={handleCheckout}>
          {strings('dashboard.screen.registerComplete.finishBusinessSetup')}
        </Button>
      </Stack>
    </SsoLayout>
  );
};

export default SignupPlanView;
