/* eslint-disable-next-line no-redeclare */
/* globals window */
import { entities } from '@loopmediagroup/admin-sdk';

let property = null;

const useProperty = () => {
  if (!property) {
    const propertyDetails = window.lmgDomainConfig.property;
    property = new entities.Property({
      id: propertyDetails.id,
      subdomain: propertyDetails.subdomain,
      status: propertyDetails.status,
      name: propertyDetails.name,
      installUrlIos: propertyDetails.installUrlIos,
      installUrlAndroid: propertyDetails.installUrlAndroid,
      homepage: propertyDetails.homepage,
      logoStandardImageUrl: propertyDetails.logoStandardImageUrl,
      profileImageUrl: propertyDetails.profileImageUrl,
      splashStandardImageUrl: propertyDetails.splashStandardImageUrl,
      theme: propertyDetails.theme,
      audienceRedirectEnabled: propertyDetails.audienceRedirectEnabled,
      audienceRedirectUrlOffer: propertyDetails.audienceRedirectUrlOffer,
      audienceRedirectUrlBusiness: propertyDetails.audienceRedirectUrlBusiness,
      audienceRedirectUrlBusinessLocation: propertyDetails.audienceRedirectUrlBusinessLocation
    });
    property.locales = propertyDetails.locales;
  }
  return property;
};

export default useProperty;
