import React from 'react';
import useQuerystring from 'shared/src/hooks/useQuerystring';
import useClient from 'shared/src/hooks/useClient';
import useRequest from 'shared/src/hooks/useRequest';
import AuthorizeSpinner from './AuthorizeSpinner';
import extractPayload from './extractPayload';
import SsoError from './error';
import SsoLayout from './SsoLayout';

const install = async ({
  client,
  query,
}: {
  client: any,
  query: { businessId: string, locationId: string, provider: string, payload: string, signature: string },
}) => {
  const { returnUrl } = extractPayload();

  const { businessId, locationId, provider, payload, signature } = query;

  await client.businesses.for(businessId).apps.install({
    appId: provider,
    name: `${provider} -> ${businessId}`,
    context: {
      locationId,
      thryvPayload: payload,
      thryvSignature: signature,
    },
  });


  if (returnUrl) window.location.replace(returnUrl);

  return true;
};

const InstallAppView = () => {
  const [query] = useQuerystring();
  const client = useClient();
  const { returnUrl } = extractPayload();

  const installRequest = useRequest(install, { client, query });

  if (installRequest.error) {
    return (
      <SsoLayout returnUrl={returnUrl}>
        <SsoError title='Unable to Install' error={installRequest.error} />
      </SsoLayout>
    );
  }

  if (!installRequest.result) {
    return (
      <AuthorizeSpinner
        title="Authorizing Thryv"
        body="Sit tight, this could take a minute or two..."
      />
    );
  }

  return <></>;
};

export default InstallAppView;
