import { Flex, Image, Skeleton, Text } from "@chakra-ui/react";
import React from "react";
import ActionMenu from "web-react-ui/src/components/actionMenu/ActionMenu";
import getContentTypeProps from "../components/getContentTypeProps";
import Post from "../interfaces/Post.interface";
import NetworkIcon from "../components/NetworkIcon";

const Preview = ({ post }: { post: Post }) => {
  return (
    <Flex
      direction="column"
      w="auto"
      maxW="50ch"
      borderWidth="1px"
      p="1.5em"
      className="_postPreview"
      gap="1em"
      rounded="xl"
    >
      <Flex gap="0.5em" align="center">
        <NetworkIcon id={post?.socialNetwork} fontSize="2em" />
        <Text
          textTransform="uppercase"
          fontWeight="semibold"
          fontSize="lg"
          textColor="gray.500"
        >
          {post?.socialNetwork} {getContentTypeProps(post?.contentType).label}
        </Text>
      </Flex>
      {post?.output?.media?.[0] && (
        <Image
          src={post?.output.media?.[0]}
          maxHeight="70svh"
          fit="scale-down"
        />
      )}
      {post?.contentType === "story" ? undefined : (
        <Text fontWeight="normal" fontSize="xl" textColor="gray.800">
          {post?.output?.message || ""}
        </Text>
      )}
    </Flex>
  );
};

export default Preview;
