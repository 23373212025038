import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Alert, AlertIcon, AlertDescription,
  Avatar, Button, Flex, Heading, Stack, Text, useDisclosure,
  Modal, ModalOverlay, ModalHeader, ModalBody, ModalContent, ModalFooter, ModalProps
} from '@chakra-ui/react';

import useRequest from 'shared/src/hooks/useRequest';

import WaitFor from 'web-react-ui/src/data/WaitFor';
import Breadcrumbs from 'web-react-ui/src/chakra/Breadcrumbs';

import businessesModule from '../../../modules/businesses';
import client from '../../../services/client';
import { appTypes } from './appTypes';

interface DeleteModalProps {
  appInfo?: Record<string, any>;
  onClickDelete: VoidFunction;
  isLoading: boolean;
  error?: { message: string } | null;
}

const DeleteModal = ({
  appInfo,
  isOpen,
  onClose,
  onClickDelete,
  isLoading,
  error,
}: Omit<ModalProps, 'children'> & DeleteModalProps): JSX.Element => {
  return (
    <Modal size='xl' isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading fontSize='2xl'>Uninstall {appInfo?.name}?</Heading>
        </ModalHeader>
        <ModalBody>
          {error && (
            <Alert status='error' mb={3}>
              <AlertIcon />
              <AlertDescription>{error.message}</AlertDescription>
            </Alert>
          )}
          <Text fontSize='xl'>This will uninstall the {appInfo?.name} app from your GetintheLoop account.</Text>
        </ModalBody>
        <ModalFooter>
          <Flex gap={5}>
            <Button size='lg' onClick={onClose}>Cancel</Button>
            <Button
              size='lg'
              colorScheme='red'
              onClick={onClickDelete}
              isLoading={isLoading}
            >
              Uninstall
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const AppInstallView = ({ appId }: { appId: string }): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const [appInfo, setAppInfo] = useState<Record<string, any>>();
  const [refetchId, setRefetchId] = useState(0);
  const business = useSelector((state: RootStateOrAny) => businessesModule.selectors.business.getData(state));

  const app = useRequest(
    () => client.businesses.for(business.id).apps.for(appId).details(),
    [business.id, refetchId]
  );
  useEffect(() => {
    if (app.result && app.settled) {
      setAppInfo(appTypes[app.result.appId]);
    }
  }, [app.result, app.settled]);

  const deleteRequest = useRequest(
    () => client.businesses.for(business.id).apps.for(appId).uninstall()
  );

  useEffect(() => {
    if (!app.result && app.settled) {
      onClose();
      history.push(`/business/${business.id}/settings/apps`);
    }
  }, [refetchId, app.result, app.settled]);

  const breadcrumbs = [
    {
      to: `/business/${business.id}/settings`, title: 'Settings'
    },
    {
      to: `/business/${business.id}/settings/apps`, title: 'Installed Apps',
    },
    {
      title: appInfo?.name, isCurrentPage: true,
    }
  ];

  const onClickDelete = async () => {
    await deleteRequest.run();
    setRefetchId(refetchId + 1);
  };

  return (
    <>
      <DeleteModal
        appInfo={appInfo}
        onClose={onClose}
        isOpen={isOpen}
        onClickDelete={onClickDelete}
        isLoading={deleteRequest.loading}
        error={deleteRequest.error}
      />
      <Breadcrumbs items={breadcrumbs} />
      <WaitFor waitFor={app.settled}>
        {!app.result && (
          <Alert status='error' mt={10}>
            <AlertIcon />
            App not found.
          </Alert>
        )}
        {app.result && (
          <>
            <Flex
              align='center'
              justify='space-between'
              mt={10}
              borderBottom='1px'
              borderColor='gray.200'
              pb={5}
              mb={5}
            >
              <Flex align='center'>
                <Avatar
                  bg='gray.500'
                  name={appInfo?.name}
                  src={appInfo?.logo}
                  mr={5}
                  size='lg'
                  borderRadius={8}
                />
                <Heading>{appInfo?.name}</Heading>
              </Flex>
              <Button size='lg' variant='solid' colorScheme='red' onClick={onOpen}>Uninstall</Button>
            </Flex>
            <Stack spacing={5}>
              <Flex align='center'>
                <Flex w={200}>
                  <Text fontSize='xl'>{appInfo?.name} Account ID</Text>
                </Flex>

                <Text>{app.result?.externalEntityId}</Text>
              </Flex>
              <Flex align='center'>
                <Flex w={200}>
                  <Text fontSize='xl'>Linked Location ID</Text>
                </Flex>
                <Text>{app.result?.context.locationId}</Text>
              </Flex>
            </Stack>
          </>
        )}
      </WaitFor>
    </>
  );
};

export default AppInstallView;
