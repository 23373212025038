/* eslint-disable import/prefer-default-export */
export const PROPERTY_SET_START = 'property:setStart';
export const PROPERTY_SET_LOCALES_START = 'property:setLocalesStart';
export const PROPERTY_SET_CHANGE = 'property:setChange';
export const PROPERTY_FLAG_START = 'property:flagStart';
export const PROPERTY_FLAG_CHANGE = 'property:flagChange';

export const setGitlPropertyAction = () => ({
  type: PROPERTY_SET_START
});

export const setGitlPropertyLocalesAction = () => ({
  type: PROPERTY_SET_LOCALES_START
});

export const setGitlPropertyFlagAction = flag => ({
  type: PROPERTY_FLAG_START,
  payload: flag
});
