import {
  ChevronLeftIcon,
} from '@chakra-ui/icons';
import {
  Button,
  Container,
  Flex,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  useFormState,
} from 'react-final-form';
import CheckboxField from 'web-react-ui/src/reactFinalForm/fields/CheckboxField';
import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';
import useRequest from 'shared/src/hooks/useRequest';
import businessesModule from '../../../../modules/businesses';
import client from '../../../../services/client';
import useProperty from '../../../useProperty';

const PROFILE_ALREADY_EXISTS = '25-67-002';

const buildReturnUrl = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  params.set('signup', true);
  url.search = params;
  return url.href;
};

const SocialSignupModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean,
  onClose: (values?: object) => void
}) => {
  const property = useProperty();
  const business = useSelector(state => businessesModule.selectors.business.getData(state));

  const signupRequest = useRequest(
    async () => {
      const locations = await client
        .properties.for(property.id)
        .businesses.for(business.id)
        .locations.search({}, { fields: ['address.timezone'] })
        .catch(
          // On failure, use default timezone
          () => ({ items: [] }),
        );

      const timezones = locations.items.map(loc => loc.address.timezone);
      timezones.push('America/Vancouver'); // Default timezone if we don't have Locations for them

      try {
        await client.businesses.for(business.id).social.createProfile({
          name: business.name,
          timezone: timezones[0],
        });
      } catch (err) {
        if (err.messageId === PROFILE_ALREADY_EXISTS) {
          await client.businesses.for(business.id).social.updateProfile({ enabled: true });
        }
      }

      const social = client.businesses.for(business.id).social;

      await social.pollForProfileLink();

      const returnUrl = buildReturnUrl();

      const { url } = await client
        .businesses.for(business.id)
        .social.manageAccounts({ redirect: returnUrl });

      window.location.href = `${url}&logout=true`;
    },
  );


  /**
   * Despite nothing outside of the Modal remounting, and despite `isOpen` not changing,
   * signupRequest causes the Modal's enter animation to play multiple times, causing an annoying flicker.
   * So, we just disable it with `motionPreset="none"
   */

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()} motionPreset="none" size="4xl" autoFocus={false}>
      <SimpleForm onSubmit={signupRequest.run}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex>
              <Button colorScheme="blue" variant="link" onClick={onClose} leftIcon={<ChevronLeftIcon />}>Cancel</Button>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Container maxW="container.lg">
              <Heading>Setup Social AI</Heading>

              <VStack align="start" gap="1em" pt="2em">
                <Heading size="lg">Connect Social Accounts</Heading>
                <Text fontSize="xl">
                  Connect your social media accounts to get started with Social AI.
                  At least one connected account is required.
                </Text>
                <CheckboxField name="acceptPost" validate={v => (v ? undefined : 'Required checkbox')}>
                  I understand that GetintheLoop&apos;s Social AI will automatically post to the social
                  media accounts I have connected. I authorize GetintheLoop to post to my connected social
                  media accounts on my behalf.
                </CheckboxField>
                <CheckboxField name="acceptTerms" validate={v => (v ? undefined : 'Required checkbox')}>
                  I have read and agree to GetintheLoop&apos;s Social AI&nbsp;
                  <Link
                    color="blue.600"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://getintheloop.com/legal/business/social-ai-terms">
                    Terms &amp; Conditions
                  </Link>.
                </CheckboxField>
              </VStack>
            </Container>
          </ModalBody>
          <ModalFooter>
            <ConnectButton isLoading={signupRequest.loading} />
          </ModalFooter>
        </ModalContent>
      </SimpleForm>
    </Modal>
  );
};

const ConnectButton = ({ isLoading }: { isLoading: boolean }) => {
  const formState = useFormState();

  return (
    <Button
      type="submit"
      colorScheme={formState.valid ? 'blue' : 'gray'}
      isLoading={isLoading}
    >
      Connect Social Accounts
    </Button>
  );
};

export default SocialSignupModal;
