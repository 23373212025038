import CancelablePromise from 'shared/src/modules/promise/CancelablePromise';
import { fetchPlanAction } from '../plan/planActions';
import { types } from './types';

const fetchBusiness = async (client, propertyId, businessId) => {
  if (!propertyId) {
    return client
      .businesses.for(businessId)
      .details();
  }

  const businessRepo = client
    .properties.for(propertyId)
    .businesses.for(businessId);

  const [business, offerHistoryStatus] = await Promise.all([
    businessRepo.details(),
    fetchOfferHistoryStatus(businessRepo)
  ]);

  business.hasLoyalty = offerHistoryStatus.hasLoyalty;
  business.hasPromotions = offerHistoryStatus.hasPromotions;

  return business;
};

const fetchOfferHistoryStatus = async (businessRepo) => {
  const [
    loyalty,
    promotions
  ] = await Promise.all([
    businessRepo.offers.list({ skipAccessCheck: true, flags: ['punchcard'] }),
    businessRepo.offers.list({ skipAccessCheck: true, flags: ['!punchcard'] }),
  ]);

  return {
    hasLoyalty: !!loyalty.items.length,
    hasPromotions: !!promotions.items.length
  };
};

let pending;

const businessMiddleware = client => store => next => async (action) => {
  const { type, payload } = action;
  const { businessId, propertyId } = payload || {};
  switch (type) {
    case types.BUSINESS_INVALIDATE:
      pending?.cancel();
      pending = null;
      return next(action); // Let reducer handle it too
    case types.BUSINESS_DETAILS_REQUEST:
      // already loading for this action, ignore request
      if (pending?.action.payload.businessId === businessId) {
        return null;
      }

      // pending promise, not yet settled, cancel it
      if (pending && !pending.isSettled) {
        pending.cancel();
      }

      pending = new CancelablePromise(fetchBusiness(client, propertyId, businessId), action);

      return pending.promise.then((entity) => {
        store.dispatch(fetchPlanAction(businessId)); // Fetch business Plan ie. GITL Local Monthly Plan
        store.dispatch({
          type: types.BUSINESS_DETAILS_SUCCESS,
          payload: {
            data: entity,
          }
        });
        return entity;
      }).catch((error) => {
        if (error.message === CancelablePromise.CANCELED) return null;

        if (pending.isCanceled === action) {
          return null;
        }

        store.dispatch({
          type: types.BUSINESS_DETAILS_FAILURE,
          error,
        });
        return error;
      });
    case types.BUSINESS_INVALIDATE_REFRESH:
      return store.dispatch({
        type: types.BUSINESS_INVALIDATE
      }).then(() => {
        store.dispatch({
          type: types.BUSINESS_DETAILS_REQUEST,
          payload: { propertyId, businessId }
        });
      });
    default:
      return next(action);
  }
};

export default businessMiddleware;
