const extractPayload = () => {
  const url = new URL(window.location.href);
  const payload = url.searchParams.get('payload');
  if (!payload) return payload;

  // Swap back the characters we swapped to make the payload URL-safe
  // (atob doesn't like it when you change the base64 alphabet)
  const unsafe = payload
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  // noinspection JSDeprecatedSymbols -- this is only deprecated for node
  const decoded = atob(unsafe);
  return JSON.parse(decoded);
};

export default extractPayload;
