/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  Flex,
  Heading,
  Hide,
  Image,
  Show,
  Spacer,
  Text,
} from '@chakra-ui/react';
import CarouselSlide from '../../Carousel/CarouselSlide';
import pickSocial from './assets/pickSocial.png';
import pickSocialSm from './assets/pickSocialSm.png';

const Step1 = () => (
  <CarouselSlide textAlign="center" alignItems="center" pb={[0, 0, 0]} justifyContent="center">
    <Spacer />
    <Heading>1. Pick a Social Network</Heading>
    <Text fontSize="2xl">
      When the account linking page opens, find and click the social network you want to link.
    </Text>
    <Spacer />
    <Show above="md">
      <Image src={pickSocial} />
    </Show>
    <Hide above="md">
      <Image src={pickSocialSm} />
    </Hide>
  </CarouselSlide>
);


const Supplement = () => (
  <Flex justifyContent="center" fontSize="text-md" color="gray.400" textAlign="center">
    <Text fontWeight="normal">If you’re linking both Facebook and Instagram, do one then the other.</Text>
  </Flex>
);

Step1.Supplement = Supplement;

export default Step1;
