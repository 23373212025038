import React, { useState } from 'react';
import Business from 'web-react-ui/src/types/Business.interface';
import Location from 'web-react-ui/src/types/Location.interface';
import useQuerystring from 'shared/src/hooks/useQuerystring';
import BusinessWizard from './signup/BusinessWizard';
import extractPayload from './extractPayload';
import SignupWelcome from './SignupWelcome';
import client from '../../services/client';
import SignupError from './SignupError';

const USER_EXISTS_CODE = '11-67-004';

const onBusinessCreate = (business: Business, plan: any, location: Location) => {
  const url = new URL(window.location.href);
  url.pathname = '/apps/signup/plan';
  url.searchParams.set('businessId', business.id);
  url.searchParams.set('locationId', location.id);
  window.location.replace(url.href);
};

const onCancel = () => {
  const payload = extractPayload();
  window.location.replace(payload.returnUrl);
};

const SignupThryvView = (): JSX.Element => {
  const [agree, setAgree] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState<string>();
  const [query] = useQuerystring();
  const extractedPayload = extractPayload();

  const handleSignup = async () => {
    const { provider, payload, signature } = query;
    setIsLoading(true);
    try {
      await client.auth.ssoLogin({
        provider,
        // We DO want to create a user here!
        action: ['create-user', 'login-user'],
        payload,
        signature,
      });
      setAgree(true);
    } catch (err: any) {
      setError(err.messageId);
    } finally {
      setIsLoading(false);
    }
  };

  if (error === USER_EXISTS_CODE) {
    return (
      <SignupError email={extractedPayload.user.email} returnUrl={extractedPayload.returnUrl} />
    );
  }

  if (!agree) {
    return (
      <SignupWelcome
        onClickContinue={handleSignup}
        onClickCancel={onCancel}
        isLoading={isLoading}
        error={error}
      />
    );
  }

  return (
    <BusinessWizard onComplete={onBusinessCreate} />
  );
};

export default SignupThryvView;
