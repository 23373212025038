import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import View from 'web-react-ui/src/components/layout/View';

import { strings } from '../../i18n';

const ContactActions = ({ businessId, href }) => (
  <View.Section className='flex fdr jcsb aic' narrow>
    <Link
      to={href}
      className='ui icon primary button'
    >
      {strings('dashboard.component.contactActions.returnToDashboard')}
    </Link>
    <a
      target='_blank'
      rel='noopener noreferrer'
      className='ui icon primary button'
      href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=Support%20Request%20For:%20${businessId}`}>
      {strings('dashboard.component.contactActions.contactUs')}
    </a>
  </View.Section>
);

ContactActions.propTypes = {
  businessId: PropTypes.string,
  href: PropTypes.string
};

export default ContactActions;
