/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  Button,
  Heading,
  Text,
} from '@chakra-ui/react';
import CarouselSlide from '../../Carousel/CarouselSlide';

const NeedToMakeAChange = ({ context: { onClose } }) => (
  <CarouselSlide
    textAlign="center" onClick={onClose} justifyContent="center" alignItems="center"
    height="36em"
  >
    <Heading>Need to Make a Change?</Heading>
    <Text fontSize="2xl">
      Don’t like one of the posts Social AI has scheduled for the upcoming week?
    </Text>
    <Text fontSize="2xl">
      Edit or discard the post by clicking the edit link in your notification email
      or directly from your business dashboard.
    </Text>
    <Button size="lg" onClick={onClose} colorScheme="blue">Get Started</Button>
  </CarouselSlide>
);


export default NeedToMakeAChange;
