import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert, AlertIcon, Avatar, Box, Center, Flex, Stack, Text } from '@chakra-ui/react';

import useRequest from 'shared/src/hooks/useRequest';
import usePermissions from 'shared/src/hooks/usePermissions';

import Location from 'web-react-ui/src/types/Location.interface';
import ViewHeader from 'web-react-ui/src/chakra/ViewHeader';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import Breadcrumbs from 'web-react-ui/src/chakra/Breadcrumbs';

import businessesModule from '../../../modules/businesses';
import client from '../../../services/client';
import { appTypes } from './appTypes';
import useProperty from '../../useProperty';

const AppInstalls = () => {
  const property = useProperty();
  const business = useSelector((state: RootStateOrAny) => businessesModule.selectors.business.getData(state));
  const apps = useRequest(
    () => client.businesses.for(business.id).apps.listInstalledApps(),
    { businessId: business.id }
  );

  const locations = useRequest(
    () => client.properties.for(property.id).businesses.for(business.id).locations.list(),
    { businessId: business.id, propertyId: property.id }
  );

  const breadcrumbs = [
    {
      to: `/business/${business.id}/settings`, title: 'Settings'
    },
    {
      to: `/business/${business.id}/settings/apps`, title: 'Installed Apps', isCurrentPage: true
    },
  ];

  const hasPermissions = usePermissions(
    { businessId: business.id },
    ['business.app.install.list']
  );

  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      <ViewHeader title='Installed Apps' />
      {hasPermissions && (
        <Box borderBottom='1px' borderColor='gray.200'>
          <WaitFor waitFor={apps.settled}>
            <Stack spacing={5}>
              {hasPermissions && apps.result?.items?.map((app: any) => {
                const linkedLocation = locations.result?.items.find(
                  (item: Location) => item.id === app.context.locationId
                );
                return (
                  <Flex
                    align='center'
                    borderTop='1px'
                    borderColor='gray.200'
                    p={5}
                    key={app.id}
                    as={Link}
                    to={`apps/${app.id}`}
                    _hover={{ bg: 'gray.50' }}
                  >
                    <Avatar
                      bg='transparent'
                      name={appTypes[app.appId].name}
                      src={appTypes[app.appId].logo}
                      mr={5}
                      size='lg'
                      borderRadius={8}
                    />
                    <Flex align='flex-start' flexDir='column' justify='center'>
                      <Text fontSize='2xl' fontWeight={500}>{appTypes[app.appId].name}</Text>
                      {linkedLocation && (
                        <Text fontSize='lg'>Linked to location: <b>{linkedLocation.name}</b></Text>
                      )}
                    </Flex>
                  </Flex>
                );
              })}
            </Stack>
          </WaitFor>
        </Box>
      )}
      {hasPermissions && apps.settled && (!apps.result || apps.result?.items.length === 0) && (
        <Center m={10}>
          <Text fontSize='xl' fontWeight={500}>No apps have been installed</Text>
        </Center>
      )}
      {hasPermissions === false && (
        <Alert status='warning'>
          <AlertIcon />
          You do not have permission to view or modify installed apps.
        </Alert>
      )}
    </>
  );
};

export default AppInstalls;
