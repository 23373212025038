import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import {
  RootStateOrAny,
  useSelector,
} from 'react-redux';
import ViewHeader from 'web-react-ui/src/chakra/ViewHeader';
import Loader from 'web-react-ui/src/components/elements/Loader';
import businessesModule from '../../modules/businesses';
import {
  usePlanIncludesLoyalty,
  usePlanState,
} from '../../hooks/usePlan';
import Offers from './Offers';
import membershipCard from './membership-card.svg';
import { strings } from '../../i18n';


const LoyaltyCTA = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <Box maxW="7xl" mx="auto">
    <ViewHeader title={'Loyalty'} />
    <Flex flexDir="column" textAlign="center" align="center" h="100%" justify="center" gap={8}>
      <Image src={membershipCard} w="192px" h="192px" />
      <Heading>{strings('dashboard.component.offerCta.loyalty.heading')}</Heading>
      <Text fontSize="xl" maxWidth="35em">
        {strings('dashboard.component.offerCta.loyalty.body')}
      </Text>
      {children}
    </Flex>
  </Box>
);

const LoyaltyOffers = (): JSX.Element => {
  const business = useSelector((state: RootStateOrAny) => businessesModule.selectors.business.getData(state));
  const businessLoading = useSelector(
    (state: RootStateOrAny) => businessesModule.selectors.business.getIsPending(state),
  );
  const { plan } = usePlanState();
  const planIncludesLoyalty = usePlanIncludesLoyalty();

  if (!plan.id || businessLoading) {
    return <Center m={9}><Loader active /></Center>;
  }

  if (business.hasLoyalty) {
    // If they have loyalty offers, we always show the offer list, regardless of the plan
    return <Offers business={business} offerType="loyalty" hideBusiness />;
  }

  if (!planIncludesLoyalty) {
    return (
      <LoyaltyCTA>
        <Text fontSize="xl" maxWidth="35em" fontWeight={600} fontStyle="italic">
          {strings('dashboard.component.offerCta.loyalty.upgradeNote')}
        </Text>
        <Button as={RouterLink} to="./plans" colorScheme="blue" size="lg">
          {strings('dashboard.component.offerCta.loyalty.upgradeButton')}
        </Button>
      </LoyaltyCTA>
    );
  }

  return (
    <LoyaltyCTA>
      <Button
        as={RouterLink}
        to={'./loyalty/create'}
        colorScheme="blue"
        size="lg"
      >
        {strings('dashboard.component.offerCta.loyalty.buttonLabel')}
      </Button>
      <Text fontSize="xl" maxWidth="35em">
        {/* Wrapping Link onto a new line screws up the spacing when rendered */}
        <Trans i18nKey={'dashboard.component.offerCta.loyalty.learnMoreLabel'}>
          Discover what a <Link
          textDecoration="underline"
          href="https://getintheloop.ca/business/promotions"
          isExternal>loyalty program</Link> can do for your business.
        </Trans>
      </Text>
    </LoyaltyCTA>
  );
};

export default LoyaltyOffers;
