import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Button,
  Container,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import Carousel from '../Carousel';
import SocialSignupModal from '../SocialSignupModal';
import { slides } from './slides';

const SocialSignupPrerollModal = ({ isOpen, onClose }: { isOpen: boolean, onClose: () => void }) => {
  const modalDisclosure = useDisclosure();

  return (
    <>
      <SocialSignupModal isOpen={modalDisclosure.isOpen} onClose={modalDisclosure.onClose} />
      <Modal isOpen={isOpen} onClose={onClose} size="full" autoFocus={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Container maxW="container.lg">
              <Button colorScheme="blue" variant="link" onClick={onClose} leftIcon={<ChevronLeftIcon />}>Cancel</Button>
              <Heading>Setup Social AI</Heading>
            </Container>
          </ModalHeader>
          <ModalBody>
            <Container maxW="container.lg">
              <Carousel slides={slides} context={{ confirmDisclosure: modalDisclosure, prerollClose: onClose }} />
            </Container>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};


export default SocialSignupPrerollModal;
