import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { BiChevronRight } from "react-icons/bi";

const Breadcrumbs = ({ items }) => (
  <Breadcrumb separator={<BiChevronRight size={14} style={{ marginBottom: '-3px' }} />} sx={{
    "& ol": {
      padding: 0
    },
  }}>
    {items.map((item, index) => (
      <BreadcrumbItem isCurrentPage={!!item.isCurrentPage} key={`breadcrumbItem-${index}`}>
        <BreadcrumbLink as={Link} to={item.to}>
          {item.title}
        </BreadcrumbLink>
      </BreadcrumbItem>
    ))}
  </Breadcrumb>
);

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    to: PropTypes.string.isRequired,
    isCurrentPage: PropTypes.bool,
  }))
};

export default Breadcrumbs;
