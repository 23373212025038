import useProperty from '../../components/useProperty';
import client from '../../services/client';
import {
  PROPERTY_FLAG_CHANGE,
  PROPERTY_FLAG_START,
  PROPERTY_SET_CHANGE,
  PROPERTY_SET_LOCALES_START,
  PROPERTY_SET_START
} from './propertyActions';

const setGitlProperty = async ({ auth }) => {
  const property = useProperty();
  try {
    if (auth && auth.isLoggedIn) {
      const locales = await client
        .properties.for(property.id)
        .getLocales()
        .catch(() => (['en', 'fr']));

      // Gross
      // Move all this to the property resolver in property-webapp-server so can just synchronously set property here
      property.locales = locales.locales;

      return {
        type: PROPERTY_SET_CHANGE,
        payload: { property }
      };
    }
    return {
      type: PROPERTY_SET_CHANGE,
      payload: { property: {} }
    };
  } catch (error) { // will error, 'This request requires authentication' when 'isLoggedIn = false'
    return {
      type: PROPERTY_SET_CHANGE,
      payload: { error }
    };
  }
};

const setGitlPropertyLocales = async () => {
  const property = useProperty();

  const locales = await client
    .properties.for(property.id)
    .detailsWithLocales();

  return {
    type: PROPERTY_SET_CHANGE,
    payload: locales
  };
};

const isGitlSelector = (flag) => {
  const GITL_PROPERTIES = ['getintheloop', 'getintheloop-stage', 'getintheloop-dev', 'unicorn', 'global-offer-pool'];

  const isPlanViewable = GITL_PROPERTIES.includes(flag);

  return {
    type: PROPERTY_FLAG_CHANGE,
    payload: isPlanViewable
  };
};

export default store => next => async (action) => {
  let update;
  switch (action.type) {
    case (PROPERTY_SET_START):
      next(action);
      update = await setGitlProperty(store.getState());
      store.dispatch(update);
      break;
    case (PROPERTY_SET_LOCALES_START):
      next(action);
      update = await setGitlPropertyLocales();
      store.dispatch(update);
      break;
    case (PROPERTY_FLAG_START):
      next(action);
      update = isGitlSelector(action.payload);
      store.dispatch(update);
      break;
    default:
      next(action);
      break;
  }
};
