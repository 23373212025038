import { types } from './types';

const defaultState = {
  isPending: true,
  data: null,
  error: null,
};

export default (state = defaultState, action) => {
  const { type, payload, error } = action;
  const { data } = payload || {};

  switch (type) {
    case types.BUSINESS_DETAILS_REQUEST:
      return { ...state, data: null, isPending: true };
    case types.BUSINESS_DETAILS_SUCCESS:
      return { ...state, isPending: false, data };
    case types.BUSINESS_DETAILS_FAILURE:
      return { ...state, isPending: false, error };
    case types.BUSINESS_INVALIDATE:
      return { ...state, data: null };
    case types.BUSINESS_INVALIDATE_REFRESH:
      return { ...state, data: null, isPending: true };
    case types.BUSINESS_SET_HAS_LOYALTY:
      return { ...state, data: { ...state.data, hasLoyalty: true } };
    case types.BUSINESS_SET_HAS_PROMOTION:
      return { ...state, data: { ...state.data, hasPromotion: true } };
    default:
      return state;
  }
};
