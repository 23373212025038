import { useSelector, useDispatch } from 'react-redux';
import {
  setGitlPropertyAction,
  setGitlPropertyLocalesAction,
  setGitlPropertyFlagAction
} from '../modules/property/propertyActions';

export const usePropertyState = () => ({
  property: useSelector(state => state.property.property),
  isGitlProperty: useSelector(state => state.property.isGitlProperty)
});

export const usePropertyDispatch = () => {
  const dispatch = useDispatch();
  return {
    setProperty: () => dispatch(setGitlPropertyAction()),
    setPropertyLocales: () => dispatch(setGitlPropertyLocalesAction()),
    setPropertyFlag: flag => dispatch(setGitlPropertyFlagAction(flag))
  };
};
