import {
  Collapse,
  Flex,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import React, {
  useRef,
  useState,
} from 'react';
import {
  Field,
  useField,
} from 'react-final-form';
import FieldError from '../FieldError';

type Props = {
  name: string;
  validate?: (val: string) => any;
  label?: string;
}

const formatDate = (isoString: string) => {
  if (!isoString) return '';
  const date = new Date(isoString);
  if (Number.isNaN(date.getTime())) return '';
  return new Date(isoString).toString();
};

const parseDate = (formattedString: string) => {
  if (!formattedString) return '';
  return new Date(formattedString).toISOString();
};

const formatLocalDate = (isoString: string) => {
  const date = new Date(isoString);
  if (Number.isNaN(date.getTime())) return '0000-00-00';
  return `${
    date.getFullYear()
  }-${
    String(date.getMonth() + 1).padStart(2, '0')
  }-${
    String(date.getDate()).padStart(2, '0')
  }`;
};

const formatLocalTime = (isoString: string) => {
  const date = new Date(isoString);
  if (Number.isNaN(date.getTime())) return '00:00';
  return `${
    String(date.getHours()).padStart(2, '0')
  }:${
    String(date.getMinutes()).padStart(2, '0')
  }`;
};

const DateTimeField = ({ name, validate, label }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [hasFocus, setHasFocus] = useState(false);

  const updateFocus = (event: { type: string; relatedTarget: Node | null; }) => {
    if (!ref.current) return;
    if (event.type === 'focus') {
      setHasFocus(true);
    } else if (event.type === 'blur') {
      const isMovingWithin = ref.current.contains(event.relatedTarget);
      setHasFocus(isMovingWithin);
    }
  };

  // @ts-ignore -- The second parameter is optional
  const { input: { value } } = useField(name);

  return (
    <Field name={name} validate={validate} format={formatDate} parse={parseDate}>
      {({ input }) => {
        const date = formatLocalDate(value);
        const time = formatLocalTime(value) || '00:00';

        return (
          <FormControl ref={ref} onBlur={updateFocus} onFocus={updateFocus}>
            <FormLabel>{label}</FormLabel>
            <Input {...input} />
            <Collapse in={hasFocus}>
              <Flex gap="1em">
                <Input
                  type="date"
                  value={date}
                  onChange={e => input.onChange(`${e.target.value}T${time}`)}
                />
                <Input
                  type="time"
                  value={time}
                  onChange={e => input.onChange(`${date}T${e.target.value}`)}
                />
              </Flex>
            </Collapse>
            <FieldError name={name} alwaysShow={false} />
          </FormControl>
        );
      }}
    </Field>
  );
};

export default DateTimeField;
