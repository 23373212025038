/* eslint-env browser */
import _ from 'lodash';

const key = process.env.REACT_APP_FACEBOOK_APP_ID;

let sdkPromise;

function getFacebookSDK() {
  if (window.FB) return Promise.resolve(window.FB);
  return Promise.reject(new Error('loadError'));
}

function initFacebookSDK() {
  if (sdkPromise) return sdkPromise;

  sdkPromise = new Promise((resolve, reject) => {
    if (!key) return reject();
    if (window.FB) return resolve();

    /* eslint-disable */
    // This is Facebook's SDK snippet
    window.fbAsyncInit = function() {
      window.FB.init({
        appId            : key, // changed this
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v14.0',
        status           : true
      });
      return resolve(); // added this
    };

    (function(d, s, id){
      let js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(window.document, 'script', 'facebook-jssdk'));
    /* eslint-enable */
    return null;
  });
  return sdkPromise;
}

function loginAuthResponse(permissions) {
  return this.getAuthResponse()
    .then((authResponse) => {
      if (authResponse) return Promise.resolve({ authResponse });
      return this.login(permissions);
    });
}

function login(permissions) {
  return new Promise((resolve, reject) => {
    this.getFacebookSDK()
      .then(FB => FB.login(
        (response) => {
          if (response.authResponse) return resolve(response);
          return reject(new Error('loginError'));
        },
        {
          scope: (permissions || []).join(','),
          return_scopes: true
        }
      ));
  });
}

function getFacebook() {
  return this.initFacebookSDK();
}

function getAuthResponse() {
  return this.getFacebookSDK()
    .then(FB => FB.getAuthResponse());
}

function getAccount() {
  return this.get('/me/accounts')
    .then(response => response.data);
}

function postToPage(page, message) {
  const accessToken = page && page.access_token;
  const pageId = page && page.id;
  return new Promise((resolve, reject) => {
    this.getFacebookSDK()
      .then(FB => FB.api(
        `/${pageId}/feed`,
        'POST',
        {
          message: message.body,
          access_token: accessToken,
          link: message.link,
          fields: message.fields
        },
        ((response) => {
          if (!response || response.error) {
            reject(new Error(_.get(response, 'error.message', 'postError')));
          }
          return resolve(response);
        })
      ));
  });
}

function get(path, fields = {}) {
  return new Promise((resolve, reject) => {
    this.getFacebookSDK()
      .then(FB => FB.api(
        path,
        'GET',
        fields,
        (response) => {
          if (!response || response.error) {
            const FB_UNKNOWN_ERROR = 'unknown error';
            let message = _.get(response, 'error.message', FB_UNKNOWN_ERROR);
            if (message === FB_UNKNOWN_ERROR) message = 'accessError';
            return reject(new Error(message));
          }
          return resolve(response);
        }
      ));
  });
}

const mod = {
  get,
  postToPage,
  getAccount,
  getAuthResponse,
  getFacebook,
  login,
  loginAuthResponse,
  initFacebookSDK,
  getFacebookSDK
};

export default mod;
