import {
  Box,
  Flex,
} from '@chakra-ui/react';
import React, {
  FunctionComponent,
  useState,
} from 'react';
import SwiperCore, {
  Mousewheel,
  Navigation,
  Pagination,
} from 'swiper';
import {
  Swiper,
  SwiperSlide,
} from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';

SwiperCore.use([Navigation, Mousewheel, Pagination]);

const Nop = () => null;

const Carousel = ({ slides = [], config = {}, context = {} }: {
  config?: any,
  slides?: Array<React.FunctionComponent & {Supplement: React.FunctionComponent | undefined}>,
  context: any
}) => {
  const [Supplement, setSupplement] = useState<FunctionComponent>(() => Nop);
  const handleSlideChange = (swiper) => {
    const supComponent = slides?.[swiper.realIndex]?.Supplement;

    if (supComponent) {
      setSupplement(() => supComponent);
    } else {
      setSupplement(() => Nop);
    }
  };

  return (
    <Box pb="2em">
      <Swiper
        autoHeight
        onSlideChange={handleSlideChange}
        navigation={{
          nextEl: '.swiper-container',
        }}
        pagination={{
          clickable: true,
          el: '.pagination-container',
        }}
        spaceBetween={50}
        {...config}
      >
        {
          slides.map(
            (Slide, index) => (
              <SwiperSlide key={index}>
                <Slide context={context} />
              </SwiperSlide>
            ),
          )
        }
      </Swiper>
      <Flex justifyContent="center" justifyItems="center" gap="1em" padding="2em" className="pagination-container" />
      <Flex justifyContent="center">
        <Supplement />
      </Flex>
    </Box>
  );
};

export default Carousel;
