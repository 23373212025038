import { useSelector, useDispatch } from 'react-redux';
import { fetchPlanAction, setPlanPendingAction } from '../modules/plan/planActions';

export const usePlanState = () => ({
  plan: useSelector(state => state.plan.plan)
});

export const usePlanIncludesLoyalty = () => {
  const { plan } = usePlanState();
  return plan?.settings?.offer__feature__flag__control_punchcard !== false;
};

export const usePlanAdvancedSchedule = () => {
  const { plan } = usePlanState();
  return plan?.settings?.offer__feature__misc__allow_advanced_scheduling;
};

export const usePlanDispatch = () => {
  const dispatch = useDispatch();
  return {
    fetchPlan: businessId => dispatch(fetchPlanAction(businessId)),
    setPlanPending: plan => dispatch(setPlanPendingAction(plan))
  };
};
