/* eslint-env browser */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import UiClientContext from 'shared/src/components/contexts/ClientContext';
import I18NextContext from 'shared/src/components/contexts/I18NextContext';
import chakraTheme from 'web-react-ui/src/chakra-theme';

import 'web-react-ui/src/css';
import App from './App';
import Rollbar from './components/RollbarLogger';

import * as i18Next from './i18n';

import './index.css';
import store from './redux/store';
import registerServiceWorker from './registerServiceWorker';
import client from './services/client';

ReactDOM.render(
  <Rollbar>
    <Provider store={store}>
      <UiClientContext.Provider value={client}>
        <I18NextContext.Provider value={i18Next}>
          <ChakraProvider theme={chakraTheme}>
            <App />
          </ChakraProvider>
        </I18NextContext.Provider>
      </UiClientContext.Provider>
    </Provider>
  </Rollbar>,
  document.getElementById('root')
);

registerServiceWorker();
