/* eslint-disable no-undef */
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import PlanController from './PlanController';
import {
  BasePlan,
  BillingPlan,
} from './types';

const usePlanController = (
  { basePlans, billingPlan, onSelect }:
    { basePlans: Array<BasePlan>, billingPlan: BillingPlan, onSelect?: Function },
): [PlanController] => {
  const [planControllerState, setPlanControllerState] = useState({});
  const planControllerRef = useRef<PlanController>(
    new PlanController({
      basePlans, billingPlan, onSelect,
    }),
  );

  // Avoid infinite loop by not setting onChange in the PlanController constructor
  // Otherwise, setPlanControllerState gets called every time the useRef expression is evaluated
  // ...which re-renders the parent which re-runs uePlanController...
  useEffect(
    () => {
      planControllerRef.current.onChange = setPlanControllerState;
      planControllerRef.current.update();
    },
    [planControllerRef.current],
  );

  return [planControllerRef.current];
};

export default usePlanController;
