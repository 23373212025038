import {
  Box,
  Flex,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import Breadcrumbs from 'web-react-ui/src/chakra/Breadcrumbs';
import ViewHeader from 'web-react-ui/src/chakra/ViewHeader';
import InlineSupportLink from 'web-react-ui/src/support/InlineSupportLink';
import Business from 'web-react-ui/src/types/Business.interface';
import useChargebeeCheckoutHostedPage from 'shared/src/hooks/chargebee/useChargebeeCheckoutHostedPage';
import { strings } from '../../../i18n';
import ErrorView from './ErrorView';
import PlansView from './PlansView';
import useInitialState from './useInitialState';

const PlansViewWrapper = ({ business }: { business: Business }): JSX.Element => {
  const { hasPermission, billingPlan, property, isReady } = useInitialState(business.id);
  const [openCheckout, chargebeeInstance, checkoutError] = useChargebeeCheckoutHostedPage(property?.id, business.id);

  // @ts-ignore
  const state = getState(isReady, hasPermission, checkoutError, openCheckout);

  const breadcrumbs = useMemo(
    () => getBreadcrumbs(business),
    [business, strings],
  );

  return (
    <Box>
      <Flex flexDirection="column">
        <Breadcrumbs items={breadcrumbs} />
        <ViewHeader title={strings('dashboard.screen.plansView.planOptions')} />
        {state === STATES.LOADING && (
          <Skeleton width="auto" height="200px" />
        )}
        {state === STATES.CHECKOUT_ERROR && (
          <ErrorView error={checkoutError as Error | undefined} />
        )}
        {state === STATES.NO_PERMISSION && (
          <ErrorView message={strings('dashboard.screen.plansView.error.haveAccessNoPermissions')} />
        )}
        {state === STATES.READY && (
          <PlansView
            business={business}
            billingPlan={billingPlan}
            openCheckout={openCheckout as Function}
          />
        )}
        <Text mt={8} textAlign="center">{strings('dashboard.label.haveAQuestion')} <InlineSupportLink />.</Text>
      </Flex>
    </Box>
  );
};

const STATES = {
  LOADING: 'LOADING',
  NO_PERMISSION: 'NO_PERMISSION',
  CHECKOUT_ERROR: 'CHECKOUT_ERROR',
  READY: 'READY',
};

const getState = (
  isReady: boolean,
  hasPermission: boolean | undefined,
  checkoutError: Error | undefined,
  openCheckout: any,
) => {
  if (!isReady || !openCheckout) return STATES.LOADING;
  if (!hasPermission) return STATES.NO_PERMISSION;
  if (checkoutError) return STATES.CHECKOUT_ERROR;
  return STATES.READY;
};

const getBreadcrumbs = (business: Business) => {
  return [
    {
      to: `/businesses/${business?.id}`,
      title: business.name,
    },
    {
      to: `/business/${business.id}/settings`, // Go ← back a page
      title: strings('dashboard.screen.settings.settings'),
    },
    {
      to: '#',
      title: strings('dashboard.screen.plansView.planOptions'),
      isCurrentPage: true,
    },
  ];
};

export default PlansViewWrapper;
