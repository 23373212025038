/* eslint-disable import/prefer-default-export */
export const PLAN_FETCH = 'plan:setFetch';
export const PLAN_FETCHED = 'plan:setFetched';
export const PLAN_PENDING = 'plan:setPending';

export const fetchPlanAction = businessId => ({
  type: PLAN_FETCH,
  payload: businessId
});

export const setPlanPendingAction = plan => ({
  type: PLAN_PENDING,
  payload: plan
});
