/* eslint-disable no-unused-vars */

import { Skeleton } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  Link,
  useHistory,
  useParams
} from 'react-router-dom';

import { useQuerystringParam } from 'shared/src/hooks/useQuerystringParam';
import useRequest from 'shared/src/hooks/useRequest';

import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import View from 'web-react-ui/src/components/layout/View';
import useChargebeeCheckoutHostedPage from 'shared/src/hooks/chargebee/useChargebeeCheckoutHostedPage';

import plans from '../../assets/data/plans';


import { usePlanDispatch } from '../../hooks/usePlan';

import { strings } from '../../i18n';

import client from '../../services/client';
import { setAttributionContext } from '../../services/getAttributionContext';

const RegisterComplete = ({ property }) => {
  const [loadedLocation, setLoadedLocation] = useState(false);
  const [error, setError] = useState(null);
  const [chargebeePlanId] = useQuerystringParam('planId'); // Actual Chargebee item id
  const { businessId } = useParams();
  const history = useHistory();

  const [openCheckout, chargebeeInstance, checkoutError] = useChargebeeCheckoutHostedPage(property?.id, businessId);

  const { fetchPlan } = usePlanDispatch();

  const REDIRECT_URL = `/business/${businessId}`;

  const handleCheckout = useMemo(
    () => (planId) => {
      openCheckout(
        { planId },
        {
          step: (step) => {
            if (step === 'thankyou_screen') {
              setTimeout(() => fetchPlan(businessId), 15000); // 15sec
              chargebeeInstance.closeAll();
              history.replace(REDIRECT_URL);
            }
          }
        }
      );
    },
    [openCheckout, fetchPlan, chargebeeInstance, history]
  );

  // Clear attribution context
  useEffect(() => {
    setAttributionContext(null);
  }, []);


  // Get Business Name (By Location for some reason...)
  const locationRequest = useRequest(
    // eslint-disable-next-line no-shadow
    async ({ businessId, property }) => {
      if (!businessId || !property?.id) return null;
      const locations = await client
        .properties.for(property.id)
        .businesses.for(businessId)
        .locations.list();

      return locations.items[0];
    },
    { businessId, property }
  );

  // Get Selected Plan Details
  const selectedPlan = useMemo(
    () => {
      return plans.find(
        plan => plan.pricePoints.find(
          pricePoint => pricePoint.chargebeePlanId === chargebeePlanId
        )
      );
    },
    []
  );

  // Auto-open checkou
  useEffect(() => {
    if (openCheckout && chargebeeInstance && chargebeePlanId) {
      handleCheckout(chargebeePlanId);

      return () => {
        chargebeeInstance.closeAll();
      };
    }
    return undefined;
  }, [openCheckout, chargebeeInstance, chargebeePlanId]);

  return (
    <View>
      <View.Section narrow>
        <ErrorMessage error={error || checkoutError} />
        {(error || checkoutError) ? (
          <div className="flex fdr jcsb">
            <Link to="/businesses">{strings('dashboard.screen.registerComplete.returnToDashboard')}</Link>
            <p>{strings('dashboard.screen.registerComplete.aProblem')} {process.env.REACT_APP_SUPPORT_EMAIL}.</p>
          </div>
        ) : (
          <React.Fragment>
            <h2 className="mt1 mb3" data-testid="pageTitle">
              {strings('dashboard.screen.registerComplete.businessDetails')}
            </h2>
            <h6 className="mb1"><b>{strings('dashboard.screen.registerComplete.businessName')}</b></h6>
            <Skeleton isLoaded={locationRequest.settled}>
            <p className="mb4" data-testid="businessName">
              {locationRequest.result?.business?.name || strings('dashboard.screen.registerComplete.notAvailable')}
            </p>
            </Skeleton>

            <h6 className="mb1"><b>{strings('dashboard.screen.registerComplete.businessPlan')}</b></h6>
            <p className="mb4" data-testid="chargebeePaymentPlan">
              {selectedPlan?.name ? (
                `${selectedPlan.name} ${strings('dashboard.screen.registerComplete.plan')}`
              ) : (
                strings('dashboard.screen.registerComplete.notAvailable')
              )}
            </p>
            <p className="mb2">
              {strings('dashboard.screen.registerComplete.clickToComplete')}
            </p>
            <div className="flex jcsb">
              <button className="ui icon primary button" onClick={() => handleCheckout(chargebeePlanId)}>
                {strings('dashboard.screen.registerComplete.finishBusinessSetup')}
              </button>
            </div>
          </React.Fragment>
        )}
      </View.Section>
    </View>
  );
};

RegisterComplete.propTypes = {
  property: PropTypes.object
};

export default RegisterComplete;
