/* eslint import/prefer-default-export: 0 */
import {
  Flex,
  Stack,
  useColorModeValue as mode
} from '@chakra-ui/react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Logo } from './Logo';

export const Sidebar = ({ children, ...props }) => {
  return (
    <Flex
      bg={mode('gray.50', 'gray.800')}
      direction='column'
      borderRightWidth={1}
      width={256}
      height='100%'
      {...props}
    >
      <Flex direction='column' flex={1} py={8} px='16px' overflowY='auto'>
        <Stack spacing={8} height="100%">
          <Link to='/'><Logo h='6' /></Link>
          {children}
        </Stack>
      </Flex>
    </Flex>
  );
};
