import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Badge, Box, Button, Heading, Text } from '@chakra-ui/react';
import { BiPlus } from 'react-icons/bi';

import auth from 'shared/src/modules/auth';

import List from 'web-react-ui/src/components/elements/List';
import ViewHeader from 'web-react-ui/src/chakra/ViewHeader';

import { strings, LANGUAGE_OPTIONS } from '../i18n';
import useLanguage from '../hooks/useLanguage';

const Preferences = ({ dashboard = true, setForceUpdate }) => {
  const [currentLanguage, handleLanguageChange] = useLanguage();
  const user = useSelector(state => auth.selectors.getUser(state));

  React.useEffect(() => {
    if (currentLanguage) {
      setForceUpdate(prevState => !prevState);
    }
  }, [currentLanguage]);

  return (
    <Box>
      <ViewHeader title={strings('dashboard.screen.preferences.accountSettings')} />

      {user && (
        <Box mb='48px'>
          <Heading fontSize='xl' mb='16px'>{strings('ui.component.appSidebar.account')}</Heading>
          <Box>
            <Text mb='4px' fontSize='14px' fontWeight='500' color='gray.700'>{user.name}</Text>
            <Text fontSize='14px' color='gray.500'>{user.email}</Text>
          </Box>
        </Box>
      )}

      <Box mb={8}>
        <Heading fontWeight={500} size='lg'>{strings('dashboard.screen.preferences.language')}</Heading>
        <List celled selection>
          {LANGUAGE_OPTIONS.map(lang => (
            <List.Item
              key={lang.key}
              className='flex fdc'
              onClick={() => handleLanguageChange(lang)}
              style={{ height: '48px' }}
            >
              <List.Content className='flex fdr mt1 mb1'>
                <List.Content>{strings(`shared.languages.${lang.key}`)}</List.Content>
                {lang.value === currentLanguage.value && (
                  <Badge colorScheme='blue' ml={12}>Current</Badge>
                )}
              </List.Content>
            </List.Item>
          ))}
        </List>
      </Box>
      <Box>
        {dashboard && (
          <React.Fragment>
            <Heading fontWeight={500} size='lg' mb={4}>
              {strings('dashboard.screen.preferences.businessMangagement')}
            </Heading>
            <Button
              as={NavLink}
              to='/businesses/add'
              leftIcon={<BiPlus />}
              colorScheme='blue'
              variant='solid'
              border='none'
              cursor='pointer'
            >
              {strings('dashboard.component.businessSelectView.addBusiness')}
            </Button>
          </React.Fragment>
        )}
      </Box>
    </Box>
  );
};

Preferences.propTypes = {
  dashboard: PropTypes.bool,
  setForceUpdate: PropTypes.func
};

export default Preferences;
