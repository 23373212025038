import React from 'react';
import { Box, Text, Link } from '@chakra-ui/react';

const NeedHelpFooter = (): JSX.Element => (
  <Box my={5} px={3} justifySelf='flex-start' w='100%'>
    <Text fontSize='lg'>
      Have questions or need help?{' '}
      <Link color='blue.400' textDecoration='underline' href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
        Contact Support
      </Link>.
    </Text>
  </Box>
);

export default NeedHelpFooter;
