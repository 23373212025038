import React, { Component } from 'react';
import PropTypes from "prop-types";

import OfferImage from '../image/OfferImage';
import './PreviewPost.css';

class PreviewPost extends Component {
  static propTypes = {
    imageId: PropTypes.string,
    offerName: PropTypes.string,
    offerDesc: PropTypes.string,
    message: PropTypes.string,
    title: PropTypes.string
  };

  state = {};

  render() {
    const {
      imageId,
      offerName,
      offerDesc,
      message,
      title
    } = this.props;
    return (
      <div className='preview-post'>
        <div className='preview-post__border-large'>
          <div className="preview-post__layout mb1">
            <div className="pull-left preview-post__greyed-pic"/>
            <div className="ml1">
              <div className="fb-blue preview-post__page-title">{title}</div>
              <div className="preview-post__greyed-text"/>
            </div>
            <div className="preview-post__greyed-button preview-post__margin-sm">
            </div>
          </div>
          <div className="mb1">
            <p>{ message }</p>
          </div>
          <div>
            <OfferImage className="flex" imageId={imageId} width={'100%'} height={245}/>
          </div>
          <div className="preview-post__border preview-post__padding">
            <div>
              <p className="preview-post__offer-name">{offerName}</p>
            </div>
            <div>
              <p className="preview-post__font-sm">{offerDesc}</p>
            </div>
            <div className="preview-post__layout">
              <div className="preview-post__greyed-link"/>
              <div className="pull-right preview-post__greyed-button"/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PreviewPost;
