/* eslint class-methods-use-this:0 */
import {
  Divider,
  Flex,
  Spacer
} from '@chakra-ui/react';
import BillingPeriodSelector from 'business-dashboard/src/components/businesses/PlansView2/BillingPeriodSelector';
import React, {
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import { COUNTRIES } from 'shared/src/constants/countries';

import View from '../layout/View';
import Button from '../elements/Button';
import PlanCardLayout from '../PlanCards2/PlanCardLayout';
import usePlanController from '../PlanCards2/usePlanController';
import { WizardStep } from '../wizard/WizardManager';
import InlineSupportLink from '../../support/InlineSupportLink';

const AddPlanStep = ({ step, plansViewModel, manager }) => {
  const [selectedPlan, setSelectedPlan] = useState();
  const { strings } = useI18Next();

  const [controller] = usePlanController({
    basePlans: plansViewModel,
    onSelect: setSelectedPlan
  });

  const onComplete = () => {
    const updateStep = { ...step };

    updateStep.data.paymentPlan = selectedPlan;

    step.stepComplete(updateStep.data);
  };

  useEffect(() => {
    const locationAddress = { ...step.data.address };
    const billingAddress = locationAddress.sameAsLocation ? locationAddress : locationAddress.billing;
    const country = COUNTRIES.find(item => item.value === billingAddress?.country);
    controller?.setCurrencyCode(country?.currencyCode || 'CAD');

    if (step.data.paymentPlan) {
      controller?.setPeriod(step.data.paymentPlan.period, step.data.paymentPlan.periodUnit);
      controller?.setSelectedPlanByKey(step.data.paymentPlan.key);
    }
  }, [step, controller]);

  useEffect(() => {
    if (plansViewModel.length === 1) { // only one plan, select it
      controller?.setSelectedPlanByKey(plansViewModel[0].key);
    }
  }, [plansViewModel, setSelectedPlan, controller]);

  const canContinue = !!selectedPlan;

  return (
    <View>
      <View.Section>
        <Flex direction="column" gap="2em">
          <BillingPeriodSelector controller={controller} />
          <PlanCardLayout plans={controller?.plans} onSelect={plan => controller.setSelectedPlan(plan)} />
          <Divider />
        </Flex>
      </View.Section>
      <Spacer h="2em" />
      <View.Section className="flex" narrow>
        <Button className="mra" onClick={() => manager.showPreviousStep()}>
          {strings('ui.component.wizard.previous')}
        </Button>
        <Button
          primary
          disabled={!canContinue}
          onClick={onComplete}
          className="mla"
        >
          {strings('ui.label.next')}
        </Button>
      </View.Section>
      <View.Section>
        <p>{strings('ui.label.haveAQuestion')} <InlineSupportLink />.</p>
      </View.Section>
    </View>
  );
};

AddPlanStep.propTypes = {
  step: PropTypes.object,
  plansViewModel: PropTypes.array
};

export default () => new WizardStep({
  key: 'selectPlan',
  getTitle: data => data.business.name,
  render: AddPlanStep
});
